import { MilestoneListColumnTypeV2 } from './common';
import { isAllowed } from '@utils';
import { costTypeMap } from '@constants';
import { PermissionNamesEnums } from '@interfaces';

const costType: MilestoneListColumnTypeV2 = {
  field: 'cost_type',
  valueGetter: (value) => value?.['display'] || value,
  valueFormatter: (value) => value?.['display'],
  type: 'singleSelect',
  headerName: 'Cost type',
  description: 'Cost type',
  headerClassName: 'text',
  headerAlign: 'left',
  align: 'left',
  cellClassName: 'select',
  editable: true,
  sortable: true,
  filterable: true,
  groupable: false,
  aggregable: false,
  hideable: true,
  minWidth: 120,
  valueOptions: () => Object.keys(costTypeMap).map((item) => costTypeMap[item].toString()),
  editableByMilestone: (row) => {
    return (
      row.activeToEdit &&
      row.localNew &&
      !row.disabled?.value &&
      !(row?.draw_requests?.length && row.retainage_rate)
    );
  },
  editableByPermissions: (permissions) =>
    isAllowed(PermissionNamesEnums.DRAWREQUESTS_LINE_ITEM_CREATE, permissions),
};

export default costType;
