import React, { FC, useContext } from 'react';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { StatusChip } from '@components';
import { colors } from '@theme';
import { statusMap } from '@constants';
import { isRequestDraft, isProjectPaused } from '@utils';
import { SettingsContext } from '@context';
import { IDrawRequest, IProject } from '@interfaces';

interface DrawRequestStatusChipProps {
  drawRequest: Pick<
    IDrawRequest,
    | 'project'
    | 'status'
    | 'is_resubmit'
    | 'is_resubmit_change_reason'
    | 'is_on_hold'
    | 'is_on_hold_change_reason'
  >;
  direction?: 'row' | 'column';
}

const DrawRequestStatusChip: FC<DrawRequestStatusChipProps> = ({
  drawRequest,
  direction = 'row',
}) => {
  const { settings } = useContext(SettingsContext);

  if (!drawRequest?.status) return null;

  const {
    status,
    is_resubmit,
    is_resubmit_change_reason,
    is_on_hold,
    project,
    is_on_hold_change_reason,
  } = drawRequest;
  const statusValue = statusMap(status, settings.display, 'draw_request');
  const text = statusValue.text;

  statusValue.text = text;

  const getAdditionalChipText = () => {
    if (is_resubmit && isRequestDraft(status)) return 'Returned';
    if (is_on_hold) return 'On hold';
    if (isProjectPaused(project?.status as unknown as Pick<IProject, 'status'>))
      return 'Project paused';
    return null;
  };

  const getHTMLLabel = () => {
    if (is_on_hold) return is_on_hold_change_reason;
    if (is_resubmit) return is_resubmit_change_reason;
    return '';
  };

  return (
    <Stack direction={direction} spacing={0.5}>
      <StatusChip
        color={statusValue.color}
        backgroundColor={statusValue.backgroundColor}
        label={text}
        size="small"
        dataTestName="draw_status"
      />
      {getAdditionalChipText() && (
        <Tooltip
          title={
            <Typography
              variant="label"
              dangerouslySetInnerHTML={{
                __html: getHTMLLabel(),
              }}
            />
          }
        >
          <Box>
            <StatusChip
              color={colors.status.error.medium}
              backgroundColor={colors.status.error.lighter}
              label={getAdditionalChipText()}
              size="small"
            />
          </Box>
        </Tooltip>
      )}
    </Stack>
  );
};

export default DrawRequestStatusChip;
