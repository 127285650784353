import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import {
  getChecklistIdByRole,
  getCheckListItemsByRole,
  getTeamRole,
  isActiveProject,
  isCreatedProject,
  parseLoanErrors,
} from '@utils';
import {
  ChecklistItemLocal,
  ICustomLoanError,
  ILoanError,
  IProjectChecklist,
  ProjectStatusEnum,
  QueryNamesEnums,
  ProjectUpdatePayload,
} from '@interfaces';
import { getProjectChecklist, inviteProjectBorrowers, updateProjectFields } from '@globalService';
import { AuthContext, useGraphQuery } from '@context';
import {
  ConfirmModalHookInterface,
  useConfirmationModal,
  useSafeSnackbar,
  useUpdateProjectStatusInvalidation,
} from '@hooks';
import { ProjectStatusMap } from '@constants';
import { useParams } from 'react-router-dom';

interface LocalConfirmationModalHookInterface extends ConfirmModalHookInterface {
  confirmText: { value: string; text: string };
  confirmChangeStatus: ({ value, changeReasons }) => void;
}
export interface ControllerInterface {
  changeStatusModal: {
    open: boolean;
    handleOpen: (value: boolean) => void;
    changeStatus: (status: string) => void;
    initialValue: string | null;
  };
  confirmModal: LocalConfirmationModalHookInterface;
  newStatus: ProjectStatusEnum;
  inviteBorrowers: boolean;
  setInviteBorrowers: Dispatch<SetStateAction<boolean>>;
  checklistModalShow: boolean;
  setChecklistModalShow: Dispatch<SetStateAction<boolean>>;
  projectId: string;
  policyItems: ChecklistItemLocal[];
  checklistId: string;
  errors: ILoanError[];
  setErrors: Dispatch<SetStateAction<ILoanError[]>>;
  value: ProjectStatusEnum;
  setValue: Dispatch<SetStateAction<ProjectStatusEnum>>;
}

const confirmExplanation = {
  archive:
    'Updating the project status to Archived will disable features such as request submittal and approval, document uploads and ordering inspections.',
  activate:
    'Updating the project status to Active will enable features such as request submittal and approval, document uploads.',
  deactivate:
    'The Inactive status permits the modification of sensitive project settings and the deletion of completed draw and change requests.',
};

const confirmExplanationText = {
  [ProjectStatusEnum.INACTIVE_INCOMPLETE]: confirmExplanation.archive,
  [ProjectStatusEnum.INACTIVE_COMPLETE]: confirmExplanation.archive,
  [ProjectStatusEnum.ACTIVE]: confirmExplanation.activate,
  [ProjectStatusEnum.CREATED]: confirmExplanation.deactivate,
  [ProjectStatusEnum.DISCARDED]: confirmExplanation.archive,
};

export const useProjectOptions = (open, handleOpen): ControllerInterface => {
  const { projectId } = useParams();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();
  const [inviteBorrowers, setInviteBorrowers] = useState(false);
  const [checklistModalShow, setChecklistModalShow] = useState(false);
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const [errors, setErrors] = useState(null);
  const [value, setValue] = useState(null);
  const [initialValue, setInitialValue] = useState(null);

  useEffect(() => {
    // to clear value on modal close
    if (open) {
      setValue(initialValue);
      setInviteBorrowers(false);
    }
  }, [open]);
  const handleProjectStatusUpdateInvalidation = useUpdateProjectStatusInvalidation();

  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['status'],
    args: { project_id: projectId },
  });

  const projectPolicyQuery = useQuery<IProjectChecklist[], Error>(
    [QueryNamesEnums.GET_PROJECT_CHECKLIST, { projectId }],
    getProjectChecklist.bind(this, projectId),
  );

  useEffect(() => {
    const initStatus = project?.data?.status;
    if (initStatus) setInitialValue(initStatus);
  }, [project?.data?.status]);

  useEffect(() => {
    if (isActiveProject(value) && isCreatedProject(initialValue)) setInviteBorrowers(true);
  }, [value, initialValue]);

  const isChecklistCompleted = useMemo(
    () => projectPolicyQuery?.data?.find((x) => x.assignee_role === teamRole)?.is_completed,
    [projectPolicyQuery?.data, teamRole],
  );

  // changing status
  const [confirmText, setConfirmText] = useState({ value: '', text: '' });
  const confirmModal = useConfirmationModal();
  const [newStatus, setNewStatus] = useState(null);

  const changeStatus = (value) => {
    handleOpen(false);
    setNewStatus(value);
    setConfirmText({
      value: ProjectStatusMap[value],
      text: confirmExplanationText[value] || '',
    });
    if (
      isActiveProject(value) &&
      policyItems?.length &&
      !isChecklistCompleted &&
      isCreatedProject(project?.data?.status)
    ) {
      setChecklistModalShow(true);
    } else {
      confirmModal.askConfirm({ value });
    }
  };

  const confirmChangeStatus = ({ value, changeReasons }) => {
    postStatus.mutateAsync({
      projectId,
      json: { status: value, status_change_reason: changeReasons || '' },
    });
    handleOpen(false);
  };

  const postStatus = useMutation<
    Response,
    Error,
    { projectId: string; json: ProjectUpdatePayload }
  >(updateProjectFields, {
    onSuccess: (_data, variables) => {
      handleProjectStatusUpdateInvalidation({ projectId });
      enqueueSnackbar('Status was changed.', { variant: 'success' });
      if (isActiveProject(variables.json?.status)) {
        if (inviteBorrowers) sendInviteMutation.mutateAsync({ projectId });
      }
    },
    onError: (error) => {
      const message = error?.message as string | ICustomLoanError;
      if (typeof message === 'object') {
        const loanErrors = parseLoanErrors(message);
        if (loanErrors.length > 0) setErrors(loanErrors);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
  });

  const sendInviteMutation = useMutation<Response, Error, { projectId: string }>(
    inviteProjectBorrowers,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryNamesEnums.GET_COMPANY_TEAMS);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const policyItems = useMemo(() => {
    if (!projectPolicyQuery?.data) return [];
    return getCheckListItemsByRole({
      policies: projectPolicyQuery.data,
      teamRole,
      teamId: user?.active_team?.id,
    }).filter((item) => !item.checked);
  }, [projectPolicyQuery?.data, teamRole]);

  const checklistId = useMemo(
    () => getChecklistIdByRole(projectPolicyQuery?.data, teamRole),
    [projectPolicyQuery?.data, teamRole],
  );

  return {
    confirmModal: { ...confirmModal, confirmText, confirmChangeStatus },
    changeStatusModal: {
      open,
      handleOpen,
      changeStatus,
      initialValue,
    },
    newStatus,
    inviteBorrowers,
    setInviteBorrowers,
    checklistModalShow,
    setChecklistModalShow,
    projectId,
    policyItems,
    checklistId,
    errors,
    setErrors,
    value,
    setValue,
  };
};
