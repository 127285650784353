import { useEffect, useMemo, useState } from 'react';
import { DocumentArray, ITablePagination } from '@interfaces';
import { useTablePagination } from '@hooks';
import { useDocumentQueries } from './queries';

export interface IMilestonePhotosItem {
  name: string;
  photos: DocumentArray;
  photos_count: number;
  id?: string;
}

interface ControllerInterface {
  photos: DocumentArray;
  isLoading: boolean;
  tablePagination: ITablePagination;
  onPageChange: (event, newPage) => void;
}

export const useMilestonePhotosItem = ({
  projectId,
  drawRequestId,
  item,
  isProjectTab,
}: {
  projectId: string;
  drawRequestId: string;
  item: IMilestonePhotosItem;
  isProjectTab: boolean;
}): ControllerInterface => {
  const [photos, setPhotos] = useState(item.photos);
  const isRelatedPhotos = useMemo(() => !item.id, [item.id]);
  const tablePagination = useTablePagination({
    initialRowsPerPage: 7,
  });

  const context = useMemo(() => {
    return drawRequestId || isProjectTab ? 'context=primary' : '';
  }, [drawRequestId, isProjectTab]);

  const stringQueryParams = useMemo(() => {
    return `&offset=${tablePagination.page * tablePagination.rowsPerPage}&limit=${
      tablePagination.rowsPerPage
    }&${context}`;
  }, [tablePagination.page, tablePagination.rowsPerPage, context]);

  const { currentPhotosQuery, isLoading } = useDocumentQueries({
    projectId,
    drawRequestId,
    item,
    isProjectTab,
    stringQueryParams,
    isRelatedPhotos,
  });

  useEffect(() => {
    tablePagination.setPage(0);
  }, [isProjectTab, drawRequestId]);

  useEffect(() => {
    if (tablePagination.page === 0) {
      setPhotos(item.photos);
      return;
    }

    const photos = currentPhotosQuery.data?.results;

    if (photos?.length) {
      setPhotos(photos);
    } else {
      currentPhotosQuery.refetch();
    }
  }, [item.photos, currentPhotosQuery]);

  const onPageChange = (event, newPage) => {
    tablePagination.onPageChange(event, newPage);
  };

  return {
    photos,
    isLoading,
    tablePagination,
    onPageChange,
  };
};
