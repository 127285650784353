import { ActiveFundingSource, IProjectFundingSource } from '@interfaces';
import { StringFieldModel } from '@models';

//we need to use 12 decimal digits for shares to calculate amount correctly
export const calculateFraction = (amount, total) =>
  total ? Number(((amount * 100) / total).toFixed(12)) : 0;
export const calculateAmount = (fraction, total) => Number((fraction * (total / 100)).toFixed(2));

// workaround for negative 0
export const roundToTwoDigits = (value: number) => (value ? +value.toFixed(2) + 0 : 0);

export const updateFSValues = ({
  value,
  amountField,
  otherValue,
  fieldFraction,
  otherFieldFraction,
  constructionBudget,
}: {
  value: string;
  amountField: StringFieldModel;
  otherValue: number;
  fieldFraction: StringFieldModel;
  otherFieldFraction: StringFieldModel;
  constructionBudget: StringFieldModel;
}) => {
  amountField.setValue(value);
  const postFundingConstructionBudget = +otherValue + +value;
  constructionBudget.setAsFloat(postFundingConstructionBudget);

  fieldFraction.setValue(calculateFraction(value, postFundingConstructionBudget)?.toString());

  otherFieldFraction.setValue(
    calculateFraction(roundToTwoDigits(+otherValue), postFundingConstructionBudget)?.toString(),
  );
};

export const getCustomFundingSources = (
  fundingSources: IProjectFundingSource[],
): ActiveFundingSource[] => {
  return fundingSources
    .filter((source) => !source.internal_identifier)
    .sort((a, b) => a.index - b.index);
};
