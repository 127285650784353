import * as Yup from 'yup';

export const initialValues = {
  email: '',
  password: '',
  passwordConfirmation: '',
};

export const initialErrors = [
  { message: 'Required', path: 'passwordConfirmation' },
  { message: 'Passwords must match', path: 'passwordConfirmation' },
  { message: 'passwordLength', path: 'password' },
  { message: 'upperCase', path: 'password' },
  { message: 'number', path: 'password' },
  { message: 'specialChar', path: 'password' },
];

export const emailAndPasswordValidationSchema = Yup.object({
  email: Yup.string().trim().email('Invalid email address').required('Required'),
  password: Yup.string().trim().required('Required'),
});

export const emailValidationSchema = Yup.object({
  email: Yup.string().trim().email('Invalid email address').required('Required'),
});

export const passwordValidationSchema = Yup.object({
  password: Yup.string()
    .trim()
    .min(8, 'passwordLength')
    .matches(/[A-Z]/, 'upperCase')
    .matches(/[0-9]/, 'number')
    .matches(/[!@#$%^&*()_+]/, 'specialChar')
    .required('Required'),
  passwordConfirmation: Yup.string()
    .trim()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Required'),
});

export const validatePassword = (
  values: { password: string; passwordConfirmation: string },
  setValidationErrors,
) => {
  return passwordValidationSchema
    .validate(values, { abortEarly: false })
    .then(() => setValidationErrors([]))
    .catch((error) => {
      const errors = [];
      const formikErrors = {};
      error.inner.forEach((e) => {
        errors.push({ message: e.message, path: e.path });
        if (e.path === 'passwordConfirmation') {
          formikErrors['passwordConfirmation'] = e.message;
        }
      });
      setValidationErrors(errors);
      if (formikErrors['passwordConfirmation']) return formikErrors;
    });
};
