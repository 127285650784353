import { ColumnType } from '@utils';

const orderedAt: ColumnType = ({ dateFormatter }) => ({
  field: 'ordered_at',
  graphFields: ['ordered_at'],
  headerName: 'Date ordered',
  minWidth: 180,
  groupable: false,
  aggregable: false,
  renderCell: ({ row }) => {
    return row.ordered_at ? dateFormatter({ date: row.ordered_at, withTime: true }) : '-';
  },
});

export default orderedAt;
