import { useEffect, useState, useMemo } from 'react';
import { EditPaymentConfigurationTypeInterface, PaymentConfiguration } from '@interfaces';

export const useEditPaymentConfigurationType = ({
  initialConfigurationType,
}: {
  initialConfigurationType: PaymentConfiguration;
}): EditPaymentConfigurationTypeInterface => {
  const [configurationType, setConfigurationType] = useState(initialConfigurationType || null);

  useEffect(() => {
    initialConfigurationType && setConfigurationType(initialConfigurationType);
  }, [initialConfigurationType]);

  const configurationTypeOptions = [
    {
      value: PaymentConfiguration.PER_DRAW_REQUEST,
      label: 'Pari Passu funding',
    },
    {
      value: PaymentConfiguration.SEQUENTIAL,
      label: 'Borrower equity first',
    },
    {
      value: PaymentConfiguration.REVERSE_SEQUENTIAL,
      label: 'Construction holdback first',
    },
  ];

  const handleConfigurationTypeChange = (event) => {
    setConfigurationType(event.target.value);
  };

  const isConfigurationTypeChanged = useMemo(() => {
    return configurationType && configurationType !== initialConfigurationType;
  }, [configurationType, initialConfigurationType]);

  return {
    configurationType,
    configurationTypeOptions,
    handleConfigurationTypeChange,
    setConfigurationType,
    isConfigurationTypeChanged,
  };
};
