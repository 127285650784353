import React, { FC, useContext } from 'react';
import { SettingsContext } from '@context';
import { Button, Tooltip } from '@mui/material';
import { getTooltipText } from '@utils';
import { UploadIcon } from '@svgAsComponents';

const UploadButton: FC<{
  onClick: () => void;
  source: string;
}> = ({ onClick, source }) => {
  const { isCurrentProjectArchived } = useContext(SettingsContext);
  const tooltipText = getTooltipText({ isCurrentProjectArchived });

  const button = (
    <Button
      variant="new"
      color="secondary"
      size="small"
      onClick={onClick}
      disabled={isCurrentProjectArchived}
      startIcon={<UploadIcon size={16} />}
      data-cy={`${source}__upload__button`}
      sx={{ ml: 1 }}
    >
      Upload
    </Button>
  );

  return isCurrentProjectArchived ? (
    <Tooltip title={tooltipText}>
      <span>{button}</span>
    </Tooltip>
  ) : (
    button
  );
};

export default UploadButton;
