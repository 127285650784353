import { IDrawRequest } from '@interfaces';
import { ColumnType } from '@utils';
import React from 'react';

const loan_number: ColumnType<IDrawRequest> = () => ({
  field: 'project.loan.external_id',
  renderCell: ({ row }: { row: Partial<IDrawRequest> }) => (
    <span data-cy="requests_queue__table__body__loan_number">{row.project?.loan?.external_id}</span>
  ),
  graphFields: [],
  graphNested: {
    'project.loan': ['external_id'],
  },
  headerName: 'Loan #',
  minWidth: 160,
  groupable: false,
  sortable: false,
  aggregable: false,
});

export default loan_number;
