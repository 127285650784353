import React from 'react';
import { Stack, Button } from '@mui/material';

import * as Controller from './controller';
import { colors } from '@theme';
import { ButtonWithTooltip } from '@components';

// Component that wraps tab content and includes "Save" and "Close" buttons with a leave warning modal
const TabWrapperWithLeaveWarning = ({
  onSave,
  isUpdated,
  tabValue,
  path,
  isSubmitting,
  currentPagePathname,
  isDisabled = false,
  children,
  isLoading = false,
}) => {
  const { handleSubmitClick, handleCloseClick, getLeavePageConfirmModal } =
    Controller.useTabWithLeaveWarning({
      onSave,
      isUpdated,
      path,
      currentPagePathname,
    });

  return (
    <Stack
      direction="column"
      sx={{ height: '100%', scrollMargin: '64px', flex: 1 }}
      justifyContent="space-between"
    >
      <Stack spacing={3} sx={{ p: { md: 3, xs: 2 } }}>
        {children} {/* Render the current tab's content */}
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        sx={{ position: 'relative', p: 3, minHeight: 10, backgroundColor: colors.background.white }}
        spacing={1}
      >
        <Button
          variant="text"
          onClick={handleCloseClick}
          loading={isSubmitting || isLoading}
          data-cy={`project__settings__${tabValue}__close__button`}
        >
          Close
        </Button>
        <ButtonWithTooltip
          onClick={handleSubmitClick}
          loading={isSubmitting || isLoading}
          disabled={isDisabled || !isUpdated}
          dataTestName={`project__settings__${tabValue}__save__button`}
          tooltipText={
            isDisabled ? 'Please enter valid values' : isUpdated ? '' : 'No changes to save'
          }
        >
          Save
        </ButtonWithTooltip>
      </Stack>
      {getLeavePageConfirmModal()}
    </Stack>
  );
};

export default TabWrapperWithLeaveWarning;
