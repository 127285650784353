import { IDrawRequest, PermissionNamesEnums } from '@interfaces';
import { isAllowed, ColumnType } from '@utils';

const days_waiting_for_inspection: ColumnType<IDrawRequest> = () => ({
  field: 'inspection.days_waiting_for_inspection',
  nested: {
    inspection: ['days_waiting_for_inspection'],
  },
  renderCell: ({ row }) => row.inspection?.days_waiting_for_inspection,
  enableByPermissions: (permissions) =>
    isAllowed(PermissionNamesEnums.INSPECTIONS_VIEW, permissions),
  headerName: 'Weekdays waiting for inspection',
  minWidth: 100,
  groupable: false,
  aggregable: false,
});

export default days_waiting_for_inspection;
