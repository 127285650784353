import React from 'react';
import { currencyFormatter, currencyToNumber, notAllowNegative } from '@utils';
import { CurrencyInput } from '@components';
import { CellRenderWithError, MilestoneListColumnTypeV2 } from './common';
import { GridEditInputCell } from '@mui/x-data-grid';
import { InputBaseComponentProps } from '@mui/material';
import { TableKeyEnum } from '@interfaces';

// TODO tableKey !== TableKeyEnum.CHANGE_REQUEST
const approvedAmountCumulative: MilestoneListColumnTypeV2 = {
  field: 'approved_amount_cumulative',
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Lender allowance ($)',
  description: 'Cumulative amount approved to fund per Lender',
  valueFormatter: (value) => currencyFormatter(value, ''),
  valueParser: (value) => currencyToNumber(value),
  renderCell: (params) => <CellRenderWithError params={params} />,
  renderEditCell: (props) => (
    <GridEditInputCell
      {...props}
      inputComponent={CurrencyInput as unknown as React.ElementType<InputBaseComponentProps>}
      inputProps={{
        isAllowed: notAllowNegative,
      }}
    />
  ),
  minWidth: 120,
  editableByMilestone: (row, tableKey) =>
    row.isEditableV2 && row?.nonBorrower && tableKey !== TableKeyEnum.CHANGE_REQUEST,
};

export default approvedAmountCumulative;
