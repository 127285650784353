import React from 'react';
import { SxProps, Theme } from '@mui/material/styles';
import { Box, Button, Stack, Typography } from '@mui/material';
import {
  CenteredStyledBox,
  ConfirmationModal,
  DocumentButton,
  DocumentUploaderWithForm,
  LoadingSkeleton,
  TablePaginationNew,
} from '@components';
import { HookState, PopupTypeEnum } from '@interfaces';
import { isAppraisalDocumentType } from '@utils';
import * as Controller from './controller';

const InspectionRelatedDocuments = ({
  drawRequestId,
  inspectionId,
  isInspectionReports = false,
  source = 'order_inspection_dialog',
  onDocumentClick,
  isEditable = true,
  activeDocumentId = '',
  sx = {},
}: {
  drawRequestId: string;
  inspectionId: string;
  isInspectionReports?: boolean;
  source?: string;
  activeDocumentId?: string;
  isEditable?: boolean;
  onDocumentClick?: (document) => void;
  sx?: SxProps<Theme>;
}) => {
  const controller = Controller.useInspectionRelatedDocuments({
    drawRequestId,
    inspectionId,
    isInspectionReports,
  });

  const {
    state,
    handleOpenDocumentUploader,
    isFilesUploaderOpened,
    transloaditSignature,
    filesUploaderClose,
    documentTypes,
    refetchDocuments,
    documents,
    isLoading,
    restrictions,
    setIsAppraisal,
    handleDelete,
    deleteModal,
    handleDeleteIconClick,
    selectedFileName,
    tablePagination: { page, rowsPerPage, rowsPerPageOptions, onPageChange, onRowsPerPageChange },
    documentsCount,
  } = controller;

  return (
    <CenteredStyledBox sx={{ overflow: 'unset', ...(isInspectionReports && { p: 0 }), ...sx }}>
      <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
        <Typography variant="h3">
          {isInspectionReports ? 'Result documents' : 'Related documents'}
        </Typography>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
          {documentsCount > 10 && (
            <TablePaginationNew
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
              itemsCount={documentsCount}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
              source={source}
            />
          )}
          <Button
            size="small"
            variant={isInspectionReports ? undefined : 'new'}
            color={isInspectionReports ? undefined : 'secondary'}
            onClick={() => {
              setIsAppraisal(false);
              handleOpenDocumentUploader();
            }}
            data-cy={`${source}__add_other_documents__button`}
            disabled={!isEditable}
          >
            {isInspectionReports ? 'Add document(s)' : 'Add other doc(s)'}
          </Button>
        </Stack>
      </Stack>
      {state === HookState.LOADING && (
        <Stack spacing={2} direction="row">
          <LoadingSkeleton type="overviewBlock" />
        </Stack>
      )}
      {state === HookState.SUCCESS && (
        <>
          {!isInspectionReports && (
            <Typography variant="body3" sx={{ mb: 3 }}>
              Upload documents such as previous inspection reports. Documents will only be included
              with the current one.
            </Typography>
          )}
          <Box
            sx={{ display: 'flex', flexWrap: 'wrap', mt: 2 }}
            onClick={(e) => (isLoading ? e.stopPropagation() : null)}
          >
            {documents?.map((document) => (
              <Box sx={{ mb: 2, mr: 2 }} key={document.id}>
                <DocumentButton
                  document={document}
                  onButtonClick={() => onDocumentClick?.(document)}
                  dataTestName={`${source}__docs_button__button`}
                  handleDelete={
                    isAppraisalDocumentType(document.document_type) || !isEditable
                      ? undefined
                      : handleDeleteIconClick
                  }
                  handleDeleteProps={{
                    fileName: document.name,
                  }}
                  isActive={activeDocumentId === document.id}
                  width="175px"
                />
              </Box>
            ))}
          </Box>
        </>
      )}
      {transloaditSignature?.signature && (
        <DocumentUploaderWithForm
          isOpen={isFilesUploaderOpened}
          documentTypes={documentTypes}
          closeUploader={filesUploaderClose}
          transloaditSignature={transloaditSignature}
          restrictions={restrictions}
          source={`${source}__documents`}
          hideDocumentTypeSelector={isInspectionReports}
        />
      )}

      <ConfirmationModal
        open={deleteModal.isConfirmModalOpened}
        title="Delete file"
        text={`You are about to delete the file "${selectedFileName}".`}
        onClose={deleteModal.closeConfirmModal}
        confirmCallback={() =>
          deleteModal.confirmCallback({
            action: async (args) => {
              await handleDelete(args);
              refetchDocuments();
            },
          })
        }
        type={PopupTypeEnum.ERROR}
        source={`${source}__documents__delete`}
        confirmButtonLabel="Delete"
      />
    </CenteredStyledBox>
  );
};

export default InspectionRelatedDocuments;
