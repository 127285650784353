import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from 'react-query';
import { updateCompany } from '@globalService';
import { ErrorDual, ICompanyOverview, QueryNamesEnums } from '@interfaces';

export const usePasswordAuthSettings = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const updateCompanyMutation = useMutation<Response, ErrorDual, ICompanyOverview>(updateCompany, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryNamesEnums.GET_MY_COMPANY);
    },
  });

  const updatePasswordAuth = useCallback(
    async (value: boolean) => {
      try {
        await updateCompanyMutation.mutateAsync({
          company: { is_password_authentication_enabled: value },
        } as ICompanyOverview);

        enqueueSnackbar(
          value
            ? 'Password-based authentication has been enabled'
            : 'Password-based authentication has been disabled',
          { variant: 'success' },
        );
      } catch (error) {
        console.error('Failed to update password auth settings:', error);
        enqueueSnackbar('Failed to update authentication settings', { variant: 'error' });
      }
    },
    [enqueueSnackbar, updateCompanyMutation],
  );

  return {
    updatePasswordAuth,
  };
};
