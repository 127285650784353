import React, { useContext, useEffect } from 'react';
import { Box, CssBaseline, Stack, Typography } from '@mui/material';
import { isAndroid, isIOS, isMobile, useMobileOrientation } from 'react-device-detect';
import { Outlet, useSearchParams } from 'react-router-dom';

import { AuthContext, LaunchdarklyProvider } from '@context';
import { colors } from '@theme';
import { DotIcon, LogoWithName } from '@svgAsComponents';
import { useMobileWarning, useNavigateToOriginalUrl } from '@hooks';
import { COMPANY_DATA } from '@constants';
import AutoLogoutMessage from './AutoLogoutMessage';
import { NewAppVersionMessage, StyledLinkExternal } from '@components';

import loginImage from '@assets/login-image.png';
import loginImageHorizontal from '@assets/background_horizontal.png';
import config from '@config';

const LoginLayout = ({ updateAvailable }) => {
  const { MobileWarningComponent } = useMobileWarning();
  const { isLandscape } = useMobileOrientation();
  const { handleRedirect, fromRedirect, from } = useNavigateToOriginalUrl();

  const [searchParams] = useSearchParams();
  const sso_access_token = searchParams.get('sso_access_token');
  const accountToken = searchParams.get('accountToken');

  const { user } = useContext(AuthContext);
  const { isAllowedToLogin } = user || {};

  const extractNestedParams = (nextParam: string): string | null => {
    try {
      // Split to get query string part
      const [, queryString] = nextParam.split('?');
      if (!queryString) return null;

      const decodedQueryString = decodeURIComponent(queryString);

      // Parse and clean up parameters
      const params = new URLSearchParams(decodedQueryString);
      params.delete('prompt'); // Remove 'prompt' parameter

      return params.toString();
    } catch (error) {
      console.error('Error processing URL parameters:', error);
      return null;
    }
  };

  useEffect(() => {
    if (!isAllowedToLogin) return;

    const nextHubSpotParam = searchParams.get('next');
    console.log({ nextHubSpotParam });
    if (!nextHubSpotParam) {
      handleRedirect();
      return;
    }

    const nestedParams = extractNestedParams(nextHubSpotParam);
    console.log({ nestedParams });
    if (!nestedParams) {
      handleRedirect();
      return;
    }

    const url = `${config.oauthOidcUrl}?${nestedParams}`;
    console.log({ configUrl: config.oauthOidcUrl, url });
    if (!config.oauthOidcUrl) {
      console.error('OAuth OIDC URL not configured');
      handleRedirect();
      return;
    }

    window.location.href = url;
  }, [isAllowedToLogin]);

  if (MobileWarningComponent) return <MobileWarningComponent />;

  const appHeight = () => {
    const doc = document.documentElement;
    if (isAndroid && doc.style.getPropertyValue('--app-height')) return;

    doc.style.setProperty(
      '--app-height',
      `${isLandscape ? window.innerWidth : window.innerHeight}px`,
    );
  };
  if (isIOS) window.addEventListener('resize', appHeight);
  appHeight();

  const disableIosTextFieldZoom = () => {
    const el = document.querySelector('meta[name=viewport]');

    if (el !== null) {
      let content = el.getAttribute('content');
      const re = /maximum\-scale=[0-9\.]+/g;

      if (re.test(content)) {
        content = content.replace(re, 'maximum-scale=1.0');
      } else {
        content = [content, 'maximum-scale=1.0'].join(', ');
      }

      el.setAttribute('content', content);
    }
  };

  if (isIOS) disableIosTextFieldZoom();

  // to show custom sso redirect process without our login layout
  if (fromRedirect || location?.pathname === '/login-with-sso/callback' || sso_access_token)
    return <Outlet />;

  return (
    <LaunchdarklyProvider>
      {updateAvailable && <NewAppVersionMessage />}
      <Stack
        direction="row"
        sx={{
          height: isMobile ? 'var(--app-height)' : '100vh',
          overflow: 'scroll',
          backgroundImage: { xs: 'none', md: `url(${loginImage})`, lg: 'none' },
          backgroundSize: 'cover',
          padding: { xs: 0, md: isLandscape ? '40px 96px' : '96px', lg: 0 },
        }}
      >
        <CssBaseline />
        <Stack
          alignItems="center"
          sx={{
            justifyContent: 'space-between',
            width: { xs: '100%', lg: '42%' },
            backgroundColor: colors.background.white,
            paddingRight: { md: '32px', xs: '16px' },
            paddingLeft: { md: '32px', xs: '16px' },
            minHeight: { md: '625px', lg: 'auto' },
          }}
        >
          <Stack
            alignItems="center"
            direction="row"
            sx={{
              position: 'initial',
              mt: { md: '72px', xs: '40px' },
              mb: accountToken
                ? { xl: '10px', xs: '24px', md: '60px' }
                : { lg: '20px', xs: '60px', md: isLandscape ? '60px' : '120px' },
              width: { lg: '25rem', md: '22rem', xs: '100%' },
            }}
          >
            <LogoWithName />
          </Stack>

          <Stack
            justifyContent="center"
            sx={{
              width: { lg: '25rem', md: '22rem', xs: '100%' },
              height: { sm: '100%', xs: '100%' },
            }}
          >
            {from && <AutoLogoutMessage />}
            <Outlet />
          </Stack>

          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{ paddingBottom: '67px', display: { sm: 'none', md: 'flex' } }}
          >
            <StyledLinkExternal href={COMPANY_DATA.WEBSITE} title="TrustPoint.ai" />
            <DotIcon />
            <StyledLinkExternal href={COMPANY_DATA.HELP_CENTER_LINK} title="Help center" />
            <DotIcon />
            <StyledLinkExternal href={COMPANY_DATA.TERMS_OF_SERVICE_LINK} title="Terms" />
            <DotIcon />
            <StyledLinkExternal href={COMPANY_DATA.PRIVACY_POLICY_LINK} title="Policy" />
          </Stack>
        </Stack>
        <Stack
          sx={{
            width: '58%',
            display: { lg: 'flex', xs: 'none' },
            backgroundSize: 'cover',
          }}
        >
          <Box
            sx={{
              width: '100%',
              flex: 1,
              display: { lg: 'flex', xs: 'none' },
              backgroundImage: `url(${loginImageHorizontal})`,
              backgroundSize: 'cover',
              backgroundColor: colors.main.primary.main,
            }}
          />
          <Stack
            sx={{ flex: 1, justifyContent: 'center', height: '280px', maxHeight: '280px' }}
            spacing={3}
          >
            <Typography variant="h1">
              Portfolio Risk Platform <br />
              for Construction Finance
            </Typography>
            <Typography variant="body1">
              Get real-time insights, streamline draw approvals, send verified disbursements <br />&
              score loan health in real-time.
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </LaunchdarklyProvider>
  );
};

export default LoginLayout;
