import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import { LabelAndValue, LoadingSkeleton, StyledBox } from '@components';
import * as Controller from './controller';
import { colors } from '@theme';
import { currencyFormatter } from '@utils';
import { WarningIcon } from '@svgAsComponents';

const BudgetSummary = () => {
  const { projectId } = useParams();
  const controller = Controller.useBudgetSummary(projectId);

  if (controller.isLoading) {
    return <LoadingSkeleton type="twoBlocks" />;
  }

  return <BudgetSummaryBody controller={controller} />;
};

const BudgetSummaryBody: FC<{
  controller: Controller.ControllerInterface;
}> = ({ controller }) => {
  const { hasPrefundingCostError, hasScheduleOfValuesError, loan, valueIconTooltip } = controller;

  if (!loan) {
    return null;
  }

  return (
    <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
      <StyledBox sx={{ flex: 1 }}>
        <Stack spacing={1}>
          <Typography variant="labelSemiBold" lineHeight={2}>
            Budget
          </Typography>
          <LabelAndValue
            label="Prefunding cost"
            text={currencyFormatter(loan.budget_prefunding_cost)}
            textDataTestName="budget_summary_prefunding_cost"
            {...(hasPrefundingCostError && {
              color: colors.status.error.medium,
              valueIcon: <WarningIcon />,
              valueIconTooltip: valueIconTooltip(loan.funding_source_inactive_amount),
              valueIconTooltipDataTestName: 'budget_summary_prefunding_cost_tooltip',
            })}
          />
          <LabelAndValue
            label="Revised scheduled value"
            text={currencyFormatter(loan.budget_post_funding_construction_budget)}
            textDataTestName="budget_summary_revised_scheduled_value"
            {...(hasScheduleOfValuesError && {
              color: colors.status.error.medium,
              valueIcon: <WarningIcon />,
              valueIconTooltip: valueIconTooltip(loan.funding_source_active_amount),
              valueIconTooltipDataTestName: 'budget_summary_revised_scheduled_value_tooltip',
            })}
          />
        </Stack>
      </StyledBox>

      <StyledBox sx={{ flex: 1 }}>
        <Stack spacing={1}>
          <Typography variant="labelSemiBold" lineHeight={2}>
            Funding sources
          </Typography>
          <LabelAndValue
            label="Prefunding cost"
            text={currencyFormatter(loan.funding_source_inactive_amount)}
            textDataTestName="budget_summary_funding_sources_prefunding_cost"
            {...(hasPrefundingCostError && {
              color: colors.status.error.medium,
              valueIcon: <WarningIcon />,
              valueIconTooltip: valueIconTooltip(loan.funding_source_inactive_amount),
              valueIconTooltipDataTestName:
                'budget_summary_funding_sources_prefunding_cost_tooltip',
            })}
          />
          <LabelAndValue
            label="Revised scheduled value"
            text={currencyFormatter(loan.funding_source_active_amount)}
            textDataTestName="budget_summary_funding_sources_revised_scheduled_value"
            {...(hasScheduleOfValuesError && {
              color: colors.status.error.medium,
              valueIcon: <WarningIcon />,
              valueIconTooltip: valueIconTooltip(loan.funding_source_active_amount),
              valueIconTooltipDataTestName:
                'budget_summary_funding_sources_revised_scheduled_value_tooltip',
            })}
          />
        </Stack>
      </StyledBox>
    </Stack>
  );
};

export default BudgetSummary;
