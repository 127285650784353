import React, { Dispatch, FC, SetStateAction, useCallback, useContext } from 'react';
import { Box, Divider, IconButton, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';

import { DateValidationEnum, HookState, PermissionNamesEnums } from '@interfaces';
import NodeCard from '../NodeCard';
import { LabelAndValue, LoadingSkeleton, ServiceMessage } from '@components';
import { currencyFormatter, percentFormatter, WithPermission } from '@utils';
import * as Controller from './controller';
import { SettingsContext, useLaunchDarklyFlags } from '@context';
import { useDayJsFormatter } from '@hooks';
import { EditIcon } from '@svgAsComponents';
import { IChangedData, SummaryEditableDataEnum } from '../interface';

const LoanDetails: FC<{
  onlyInfo?: boolean;
  setChangedData?: Dispatch<SetStateAction<IChangedData | null>>;
}> = ({ onlyInfo, setChangedData }) => {
  const { projectId } = useParams();
  const controller = Controller.useProject(projectId);
  switch (controller.state) {
    case HookState.LOADING: {
      // TODO: remove this
      return (
        <NodeCard title="Loan details">
          <LoadingSkeleton type="overviewBlock" />
        </NodeCard>
      );
    }
    case HookState.ERROR: {
      return <ServiceMessage text="funds" />;
    }

    case HookState.SUCCESS: {
      return (
        <DetailsBody controller={controller} onlyInfo={onlyInfo} setChangedData={setChangedData} />
      );
    }

    default:
      return null;
  }
};

export default LoanDetails;

const DetailsBody: FC<{
  controller: Controller.ControllerInterface;
  onlyInfo?: boolean;
  setChangedData: Dispatch<SetStateAction<IChangedData | null>>;
}> = ({ controller, onlyInfo = false, setChangedData }) => {
  const {
    project: { loan, retainage_rate } = {},
    servicingStatus,
    loanType,
    interestMethodDisplay,
  } = controller;
  const { dateFormatter, getDateValidationRule } = useDayJsFormatter();
  const { isCurrentProjectArchived, isCurrentProjectActive } = useContext(SettingsContext);
  const flags = useLaunchDarklyFlags();

  const getEditIcon = useCallback(
    ({
      title,
      type,
      data,
      additionalProps = {},
    }: {
      title: string;
      type: SummaryEditableDataEnum;
      data: any;
      additionalProps?: Record<string, any>;
    }) => {
      if (
        !flags?.['ENG_10024_add_interest_method_to_loan'] &&
        type !== SummaryEditableDataEnum.SERVICING_STATUS &&
        type !== SummaryEditableDataEnum.EXTENDED_MATURITY_DATE
      ) {
        return null;
      }

      if (isCurrentProjectArchived || onlyInfo) {
        return null;
      }

      const alwaysEditableFields = [
        SummaryEditableDataEnum.SERVICING_STATUS,
        SummaryEditableDataEnum.EXTENDED_MATURITY_DATE,
      ];

      if (isCurrentProjectActive && !alwaysEditableFields.includes(type)) {
        return null;
      }

      return (
        <WithPermission permissionKey={PermissionNamesEnums.PROJECTS_DETAILS_EDIT}>
          <IconButton
            onClick={() => setChangedData({ title, type, data, ...additionalProps })}
            sx={{ p: 0, pl: 1 }}
            data-cy={`project__loan_details__${type}__edit__icon`}
          >
            <EditIcon size={20} />
          </IconButton>
        </WithPermission>
      );
    },
    [flags, isCurrentProjectArchived, isCurrentProjectActive, onlyInfo],
  );

  return (
    <NodeCard title="Loan details">
      <Stack spacing={1} sx={{ pt: 1 }}>
        <LabelAndValue
          label="Loan type"
          text={loan?.type ? loanType : 'n/a'}
          textDataTestName="project__loan_details__type"
          tooltipText="The type of loan"
          icon={getEditIcon({
            title: 'Loan type',
            type: SummaryEditableDataEnum.LOAN_TYPE,
            data: loan?.type,
          })}
        />
        <LabelAndValue
          label="Funding date"
          text={loan?.funding_date ? dateFormatter({ date: loan.funding_date }) : 'n/a'}
          textDataTestName="project__loan_details__funding_date"
          tooltipText="The date the loan was closed"
          icon={getEditIcon({
            title: 'Funding date',
            type: SummaryEditableDataEnum.FUNDING_DATE,
            data: loan?.funding_date,
          })}
        />
        <LabelAndValue
          label="Servicing status"
          text={servicingStatus || 'n/a'}
          textDataTestName="project__loan_details__servicing_status"
          tooltipText="The current servicing status of the loan"
          icon={getEditIcon({
            title: 'Servicing status',
            type: SummaryEditableDataEnum.SERVICING_STATUS,
            data: loan?.servicing_status,
          })}
        />
        {flags?.['ENG_10024_add_interest_method_to_loan'] && (
          <LabelAndValue
            label="Interest method"
            text={interestMethodDisplay || 'n/a'}
            textDataTestName="project__loan_details__interest_method"
            tooltipText="Method used to calculate interest on the loan"
            icon={getEditIcon({
              title: 'Interest method',
              type: SummaryEditableDataEnum.INTEREST_METHOD,
              data: loan?.interest_method,
            })}
          />
        )}
        <Box sx={{ py: 1.5 }}>
          <Divider />
        </Box>
        <LabelAndValue
          label="Total loan commitment"
          text={currencyFormatter(loan?.loc_commitment, '-')}
          textDataTestName="project__loan_details__total_loan_commitment"
        />
        <LabelAndValue
          label="Construction holdback"
          text={currencyFormatter(loan?.construction_holdback, '-')}
          textDataTestName="project__loan_details__construction_holdback"
        />
        <LabelAndValue
          label="Total construction budget"
          text={currencyFormatter(loan?.total_construction_budget, '-')}
          textDataTestName="project__loan_details__total_construction_budget"
        />
        <LabelAndValue
          label="Schedule of values"
          text={currencyFormatter(loan?.post_funding_construction_budget, '-')}
          textDataTestName="project__loan_details__construction_budget_post_funding"
        />
        <LabelAndValue
          label="Borrower equity"
          text={currencyFormatter(loan?.borrower_equity, '-')}
          textDataTestName="project__loan_details__borrower_equity"
        />
        <LabelAndValue
          label="Contingency"
          text={currencyFormatter(loan?.contingency, '-')}
          textDataTestName="project__loan_details__contingency"
        />
        <LabelAndValue
          label="Original maturity date"
          text={dateFormatter({ date: loan?.maturity_date }) || 'n/a'}
          textDataTestName="project__loan_details__original_maturity_date"
        />
        <LabelAndValue
          label="Extended maturity date"
          text={
            dateFormatter({ date: loan?.extended_maturity_date || loan?.maturity_date }) || 'n/a'
          }
          textDataTestName="project__loan_details__extended_maturity_date"
          icon={getEditIcon({
            title: 'Extended maturity date',
            type: SummaryEditableDataEnum.EXTENDED_MATURITY_DATE,
            data: loan?.extended_maturity_date || loan?.maturity_date,
            additionalProps: {
              validationRule: (value) =>
                getDateValidationRule({
                  value,
                  minDate: new Date(loan?.maturity_date),
                  rule: DateValidationEnum.MORE_OR_EQUAL,
                  dateDescription: 'original maturity date',
                  required: false,
                }),
              minDate: new Date(loan?.maturity_date),
            },
          })}
        />
        {Boolean(retainage_rate) && (
          <LabelAndValue
            label="Retainage rate"
            text={percentFormatter({
              value: retainage_rate,
            })}
            textDataTestName="project__loan_details__retainage_rate"
          />
        )}
      </Stack>
    </NodeCard>
  );
};
