import React, { FC } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useBulkApplyPolicies } from './controller';
import { PoliciesTypeEnum, PolicyListSourceEnums, TeamRolesEnum } from '@interfaces';
import { PoliciesTypeLabel, PoliciesTypeMap } from '@constants';
import {
  CustomAutocomplete,
  FullScreenDialog,
  IconButtonWithTooltip,
  StyledBox,
  SuccessModal,
  ToolTipLine,
} from '@components';
import { ArrowBackIcon } from '@svgAsComponents';

const BulkApplyPolicies: FC<{
  open: boolean;
  onClose: () => void;
  projectIds: string[];
  companyId: string;
}> = ({ open, onClose, projectIds = [], companyId }) => {
  const {
    selectedTemplates,
    applyPolicies,
    getFilteredTemplates,
    isLoading,
    showSuccessPopup,
    setShowSuccessPopup,
  } = useBulkApplyPolicies({
    companyId,
    projectIds,
  });

  const content = () => (
    <Stack spacing={3} sx={{ p: 1, width: '100%', margin: '0 auto' }}>
      <StyledBox spacing={3} sx={{ p: 3 }}>
        <Typography variant="h3">Policy templates</Typography>
        <Stack my={3}>
          <ToolTipLine
            typographyVariant="body2"
            sx={{ justifyContent: 'flex-start' }}
            text={`The selected policy templates will be applied to the 
              ${projectIds.length} chosen projects. Please note that this process may take some time. 
              Completed and active requests will not be affected.`}
          />
        </Stack>
        <Stack spacing={6} sx={{ width: '100%', my: 6 }}>
          {Object.values(PoliciesTypeEnum).map((type) => (
            <Stack spacing={2} direction="row" key={type} sx={{ alignItems: 'center' }}>
              <Stack sx={{ width: '20%', maxWidth: 180 }}>
                <Typography variant="body1SemiBold">
                  {PoliciesTypeLabel[PoliciesTypeMap[type]]}
                </Typography>
              </Stack>
              <CustomAutocomplete
                field={selectedTemplates[PolicyListSourceEnums.SUBMISSION][type]}
                label="Submission"
                options={getFilteredTemplates(TeamRolesEnum.OWNER, type as PoliciesTypeEnum)}
                inputProps={{
                  'data-cy': `bulk_apply_policies__approval__${PoliciesTypeMap[type]}_select`,
                }}
                sx={{ width: '40%', maxWidth: 360 }}
              />
              <CustomAutocomplete
                field={selectedTemplates[PolicyListSourceEnums.APPROVAL][type]}
                label="Approval"
                options={getFilteredTemplates(TeamRolesEnum.LENDER, type as PoliciesTypeEnum)}
                inputProps={{
                  'data-cy': `bulk_apply_policies__approval__${PoliciesTypeMap[type]}_select`,
                }}
                sx={{ width: '40%', maxWidth: 360 }}
              />
            </Stack>
          ))}
        </Stack>
      </StyledBox>
      {showSuccessPopup && (
        <SuccessModal
          dataTestName="bulk_apply_policies__success_modal"
          text={showSuccessPopup}
          open
          onClose={() => {
            setShowSuccessPopup('');
            onClose();
          }}
        />
      )}
    </Stack>
  );

  const actionButtons = () => (
    <Stack direction="row" spacing={2} justifyContent="flex-end">
      <Button color="secondary" onClick={onClose}>
        Cancel
      </Button>
      <Button variant="contained" onClick={applyPolicies} loading={isLoading}>
        Apply
      </Button>
    </Stack>
  );

  const title = () => (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ width: '100%', mr: 2 }}
    >
      <Stack direction="row" alignItems="center" spacing={2}>
        <IconButtonWithTooltip
          tooltipText="Go back"
          onClick={onClose}
          data-cy="bulk_apply_policies__back__icon"
        >
          <ArrowBackIcon />
        </IconButtonWithTooltip>
        <Typography variant="h1">Apply policies</Typography>
      </Stack>
      <Typography variant="body1">Projects selected: {projectIds.length}</Typography>
    </Stack>
  );

  return (
    <FullScreenDialog open={open} title={title} content={content} actionButtons={actionButtons} />
  );
};

export default BulkApplyPolicies;
