import { useContext, useEffect, useMemo, useState } from 'react';
import { AuthContext, PermissionsContext, useGraphInfiniteQuery } from '@context';
import { useTablePagination } from '@hooks';
import { UseFilterData } from './interface';
import { IPermission, IUser } from '@interfaces';
import { InfiniteData } from 'react-query';
import { GetFilterParamsPaginated } from '../Filters/interface';

interface UseFilterButtonProps {
  initValues: string[];
  withPagination?: boolean;
  getDataParamsPaginated: GetFilterParamsPaginated;
  strictSerialize?: (
    data: InfiniteData<unknown>,
    permissions?: IPermission[],
    user?: IUser,
  ) => Array<any>;
}

export const useAsyncFilterData = ({
  initValues,
  withPagination,
  getDataParamsPaginated,
  strictSerialize,
}: UseFilterButtonProps): UseFilterData => {
  const { permissions } = useContext(PermissionsContext);
  const { user } = useContext(AuthContext);
  const [q, setQ] = useState<string>('');
  const [nonExistingValues, setNonExistingValues] = useState<string[]>([]);

  const { page, rowsPerPage } = useTablePagination({
    initialRowsPerPage: withPagination ? 15 : 9999,
  });

  const {
    isLoading,
    data: dataValues,
    fetchNextPage,
  } = useGraphInfiniteQuery(
    getDataParamsPaginated({
      pagination: {
        page,
        rowsPerPage,
      },
      q,
    }) as any,
  );

  const { data: pendingData } = useGraphInfiniteQuery(
    getDataParamsPaginated({
      id: nonExistingValues,
      skip: !nonExistingValues.length || nonExistingValues[0] === 'null',
    }) as any,
  );

  const pendingValues = useMemo(() => {
    return strictSerialize
      ? strictSerialize(pendingData, permissions, user)
      : pendingData?.['results'];
  }, [pendingData, strictSerialize]);

  const valuesList = useMemo(() => {
    return strictSerialize
      ? strictSerialize(dataValues, permissions, user)
      : dataValues?.['results'];
  }, [dataValues, strictSerialize]);

  const valuesWithExistingData = useMemo(() => {
    const existingValue = valuesList.filter(
      (item) => !pendingValues.find((pv) => pv.id === item.value || pv.value === item.value), // for value: 'null' like 'Unassigned' option
    );
    return [...pendingValues, ...existingValue];
  }, [valuesList, pendingValues]);

  const stringToShow = useMemo(
    () =>
      valuesWithExistingData
        ?.filter((item) => initValues.includes(item.value))
        ?.map((item) => item.label || item.id)
        ?.join(','),
    [valuesWithExistingData, initValues],
  );
  useEffect(() => {
    if (valuesList?.length && !nonExistingValues.length) {
      const initialNonExistingValues = initValues.filter((value) => {
        return !valuesList.find((item) => item.value === value);
      });
      setNonExistingValues(initialNonExistingValues);
    }
  }, [valuesList, initValues]);

  return {
    setQ,
    nextPageCallback: fetchNextPage,
    isLoading,
    valuesWithExistingData,
    stringToShow,
  };
};
