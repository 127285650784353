import { useState } from 'react';
import { FundingSourceStatusEnum, FundingSourceTypeEnum } from '@interfaces';
import { ControllerInterface } from './interface';
import { useStringFieldModel } from '@models';

export const useEditFundingSourceModal = ({
  name,
  value,
  is_active,
  is_transactable,
}: {
  name: string;
  value: string;
  is_active: boolean;
  is_transactable: boolean;
}): ControllerInterface => {
  const sourceTypeOptions = [
    { value: FundingSourceTypeEnum.BORROWER_EQUITY, label: 'Disbursable' },
    { value: FundingSourceTypeEnum.LOAN, label: 'Non-disbursable' },
  ];

  const sourceStatusOptions = [
    { value: FundingSourceStatusEnum.ACTIVE, label: 'Active' },
    { value: FundingSourceStatusEnum.HISTORICAL, label: 'Historical' },
  ];

  const [sourceType, setSourceType] = useState<FundingSourceTypeEnum>(
    is_transactable ? FundingSourceTypeEnum.BORROWER_EQUITY : FundingSourceTypeEnum.LOAN,
  );

  const nameField = useStringFieldModel({
    initValue: name,
    withProgressCheck: true,
    validationRule: (value) => Boolean(value?.trim()),
  });

  const amountField = useStringFieldModel({
    initValue: value || '',
    withProgressCheck: true,
  });

  const sourceStatus = is_active
    ? FundingSourceStatusEnum.ACTIVE
    : FundingSourceStatusEnum.HISTORICAL;

  return {
    sourceTypeOptions,
    sourceStatusOptions,
    nameField,
    amountField,
    sourceStatus,
    sourceType,
    setSourceType,
  };
};
