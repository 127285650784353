import { useCallback, useMemo, useState, useContext } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  ICompany,
  ICoordinator,
  IProjectCoordinator,
  IUser,
  QueryNamesEnums,
  UpdateRequestPayload,
  EnumTypeForList,
  PermissionNamesEnums,
} from '@interfaces';
import {
  getMyCompany,
  updateDrawRequestCoordinator,
  updateProjectCoordinator,
  getProjectsUsersV2,
} from '@globalService';
import { useAssignCoordinatorInvalidation, useSafeSnackbar } from '@hooks';
import { getUserFullNameOrEmail, isAllowed } from '@utils';
import { DropdownFieldModel, useDropdownFieldModel } from '@models';
import { PermissionsContext } from '@context';
interface ControllerInterface {
  onAssignCoordinator: () => Promise<void>;
  onUnassignCoordinator: () => Promise<void>;
  isCoordinatorMutating: boolean;
  isCoordinatorChanged: boolean;
  shouldUpdateProjectCoordinator: boolean;
  setShouldUpdateProjectCoordinator: (value: boolean) => void;
  handleAssignCoordinatorInvalidation: (params: {
    projectId: string;
    drawRequestId?: string;
  }) => Promise<void>;
  coordinatorField: DropdownFieldModel;
  list: EnumTypeForList[];
  isCoordinatorEditable: boolean;
  isCoordinatorsListLoading: boolean;
}

export const useCoordinator = ({
  initCoordinator,
  projectId,
  requestId,
  onClose,
}: {
  initCoordinator?: ICoordinator;
  projectId: string;
  requestId?: string;
  onClose?: () => void;
}): ControllerInterface => {
  const { permissions } = useContext(PermissionsContext);

  const { enqueueSnackbar } = useSafeSnackbar();
  const coordinatorField = useDropdownFieldModel({
    initValue: initCoordinator?.id
      ? {
          id: initCoordinator.id,
          name: initCoordinator.full_name,
          name_display:
            initCoordinator.full_name ||
            getUserFullNameOrEmail(initCoordinator) ||
            initCoordinator.email,
        }
      : null,
  });
  const [shouldUpdateProjectCoordinator, setShouldUpdateProjectCoordinator] =
    useState<boolean>(false);
  const handleAssignCoordinatorInvalidation = useAssignCoordinatorInvalidation();

  const companyQuery = useQuery<ICompany, Error>(
    [QueryNamesEnums.GET_MY_COMPANY],
    getMyCompany.bind(this),
  );

  const isCoordinatorEditable = useMemo(
    () => isAllowed(PermissionNamesEnums.DRAWREQUESTS_COORDINATOR_EDIT, permissions),
    [permissions],
  );

  const projectsUsersByCompanyQuery = useQuery<{ results: IUser[] }, Error>(
    [QueryNamesEnums.GET_PROJECTS_USERS, { company: companyQuery.data?.id }],
    getProjectsUsersV2.bind(this, { company: companyQuery.data?.id }),
    { enabled: Boolean(companyQuery.data?.id) },
  );

  const updateRequestCoordinatorMutation = useMutation<Response, Error, UpdateRequestPayload>(
    updateDrawRequestCoordinator,
    {
      onSuccess: () => {
        handleAssignCoordinatorInvalidation({ projectId, drawRequestId: requestId });
        enqueueSnackbar('Coordinator assigned', { variant: 'success' });
        onClose();
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const updateProjectCoordinatorMutation = useMutation<Response, Error, IProjectCoordinator>(
    updateProjectCoordinator,
    {
      onSuccess: async () => {
        await handleAssignCoordinatorInvalidation({ projectId });
        enqueueSnackbar('Coordinator assigned', { variant: 'success' });
        onClose();
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const onAssignCoordinator = useCallback(async () => {
    // project coordinator
    if (!requestId) {
      await updateProjectCoordinatorMutation.mutateAsync({
        projectId,
        coordinatorId: coordinatorField.value?.id,
      });
      return;
    }

    // draw request coordinator
    await updateRequestCoordinatorMutation.mutateAsync({
      project: projectId,
      drawRequest: requestId,
      coordinator: coordinatorField.value?.id,
    });

    if (shouldUpdateProjectCoordinator) {
      await updateProjectCoordinatorMutation.mutateAsync({
        projectId,
        coordinatorId: coordinatorField.value?.id,
      });
    }
  }, [coordinatorField.value?.id, shouldUpdateProjectCoordinator]);

  const onUnassignCoordinator = useCallback(async () => {
    coordinatorField.setValue(null);
    if (!requestId) {
      await updateProjectCoordinatorMutation.mutateAsync({ projectId, coordinatorId: null });
      return;
    }

    await updateRequestCoordinatorMutation.mutateAsync({
      project: projectId,
      drawRequest: requestId,
      coordinator: null,
    });

    if (shouldUpdateProjectCoordinator) {
      await updateProjectCoordinatorMutation.mutateAsync({ projectId, coordinatorId: null });
    }
  }, [shouldUpdateProjectCoordinator, requestId, projectId]);

  const list = useMemo(() => {
    if (projectsUsersByCompanyQuery.data?.results?.length)
      return projectsUsersByCompanyQuery.data.results?.map((x) => ({
        name: x.full_name || getUserFullNameOrEmail(x),
        name_display: x.full_name || getUserFullNameOrEmail(x) || x.email,
        id: x.id,
      }));
    return [];
  }, [projectsUsersByCompanyQuery.data]);

  const isCoordinatorChanged = useMemo(
    () => coordinatorField.isChanged || shouldUpdateProjectCoordinator,

    [shouldUpdateProjectCoordinator, coordinatorField.isChanged],
  );

  return {
    onAssignCoordinator,
    onUnassignCoordinator,
    shouldUpdateProjectCoordinator,
    setShouldUpdateProjectCoordinator,
    isCoordinatorMutating:
      updateRequestCoordinatorMutation.isLoading || updateProjectCoordinatorMutation.isLoading,
    isCoordinatorChanged,
    handleAssignCoordinatorInvalidation,
    list,
    coordinatorField,
    isCoordinatorEditable,
    isCoordinatorsListLoading: projectsUsersByCompanyQuery.isLoading,
  };
};
