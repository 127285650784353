// TODO: delete after ENG_10053_order_service_experience_update
import React from 'react';
import { Stack, Typography } from '@mui/material';
import { UserAvatar } from '@components';
import { useRealTimeUsers } from './controller';

const RealTimeUsers = () => {
  const { usersList } = useRealTimeUsers();

  if (!usersList.length) return null;

  return (
    <Stack direction="row" alignItems="center" sx={{ ml: 4 }}>
      <Typography variant="body1" sx={{ mr: 1 }}>
        Also here
      </Typography>
      <Stack spacing={-1} direction="row">
        {usersList.map((user, index) => (
          <UserAvatar
            user={user}
            size={32}
            key={index}
            withTooltip
            boxShadow="0px 4px 8px 0px rgba(0, 0, 0, 0.15)"
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default RealTimeUsers;
