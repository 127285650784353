import { Stack, Tooltip } from '@mui/material';
import { colors } from '@theme';
import { getDrawIcon, isChangeRequest, ColumnType } from '@utils';
import React from 'react';
import { IDrawRequest } from '@interfaces';

const description: ColumnType<IDrawRequest> = () => ({
  field: 'description',
  graphFields: ['is_lump_sum_request', 'number', 'type', 'source'],
  headerName: 'Type',
  minWidth: 120,
  groupable: false,
  aggregable: false,
  renderCell: ({ row }) => {
    const name = `${isChangeRequest(row) ? 'Change' : 'Draw'}\u00A0#${row.number}`;
    const iconObject = getDrawIcon(row);
    const Icon = iconObject?.component;

    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        spacing={1}
        data-cy="requests_queue__table__body__description"
      >
        <span>{name}</span>
        {Icon && (
          <Tooltip title={iconObject?.tooltip}>
            <Stack alignItems="center" justifyContent="center">
              <Icon size={24} color={colors.icons.gray} />
            </Stack>
          </Tooltip>
        )}
      </Stack>
    );
  },
});

export default description;
