import React, { FC, useContext, useMemo, useState } from 'react';

import {
  ApprovalsSummaryBody,
  IconButtonWithTooltip,
  LabelAndValue,
  LoadingSkeleton,
  SwitchApproverPopup,
} from '@components';
import { EditIcon, InfoIcon } from '@svgAsComponents';
import { IDrawRequest, ITeam, PermissionNamesEnums } from '@interfaces';
import { getTooltipText, isRequestInReview, WithPermission } from '@utils';
import { SettingsContext } from '@context';

const TeamsAndApprovals: FC<{
  request: Pick<
    IDrawRequest,
    | 'id'
    | 'current_reviewer_team'
    | 'status'
    | 'customer_approve_reviews_quantity'
    | 'reviews_required_quantity'
    | 'funding_approve_required'
  >;
  projectId: string;
  teamsList: ITeam[];
}> = ({ request, projectId, teamsList }) => {
  const { reviews_required_quantity, customer_approve_reviews_quantity } = request || {};
  const { isCurrentProjectArchived } = useContext(SettingsContext);
  const [approverPopupOpen, setApproverPopupOpen] = useState<boolean>(false);
  const showTeams = isRequestInReview(request.status);
  const showApprovals = Boolean(reviews_required_quantity);
  const values = useMemo(() => {
    if (showTeams && showApprovals) {
      return {
        label: 'Team & Approvals',
        text: `${request.current_reviewer_team?.name}, ${customer_approve_reviews_quantity}/${reviews_required_quantity}`,
      };
    }
    if (showTeams) {
      return {
        label: 'Team',
        text: request.current_reviewer_team?.name,
      };
    }
    return {
      label: 'Approvals',
      text: `${customer_approve_reviews_quantity}/${reviews_required_quantity}`,
    };
  }, [
    showTeams,
    showApprovals,
    request.current_reviewer_team?.name,
    customer_approve_reviews_quantity,
    reviews_required_quantity,
  ]);

  if (!request || (!showApprovals && !showTeams)) return <LoadingSkeleton />;
  return (
    <WithPermission permissionKey={PermissionNamesEnums.DRAWREQUESTS_REVIEWER_TEAM_VIEW}>
      <LabelAndValue
        withEllipsis={false}
        label={values.label}
        icon={
          showTeams && teamsList?.length > 1 ? (
            <WithPermission permissionKey={PermissionNamesEnums.DRAWREQUESTS_REVIEWER_TEAM_EDIT}>
              <IconButtonWithTooltip
                onClick={() => setApproverPopupOpen(true)}
                tooltipText={getTooltipText({ isCurrentProjectArchived })}
                disabled={isCurrentProjectArchived}
                sx={{ p: 0, pl: 1 }}
                data-cy="requests_tab__summary__reviewer_team__edit__icon"
              >
                <EditIcon size={20} />
              </IconButtonWithTooltip>
            </WithPermission>
          ) : null
        }
        text={values.text}
        valueIcon={
          showApprovals && customer_approve_reviews_quantity ? <InfoIcon size={14} /> : null
        }
        valueIconTooltip={
          showApprovals ? <ApprovalsSummaryBody request={request} projectId={projectId} /> : null
        }
        textDataTestName="requests_tab__summary__reviewer_team__value"
        valueTooltipPlacement="right"
      />
      {approverPopupOpen && (
        <SwitchApproverPopup
          open={approverPopupOpen}
          request={request}
          onClose={() => setApproverPopupOpen(false)}
          projectId={projectId}
          source="requests_tab__summary"
        />
      )}
    </WithPermission>
  );
};

export default TeamsAndApprovals;
