import React, { FC } from 'react';
import { Button, Skeleton, Stack } from '@mui/material';
import { useAssignInvestorPopup } from './controller';
import { LinkIcon } from '@svgAsComponents';
import { Popup, ButtonWithTooltip, CustomAutocomplete } from '@components';

const AssignInvestorPopup: FC<{
  open: boolean;
  onClose: () => void;
  projectId: string[];
}> = ({ open, onClose, projectId }) => {
  const { isLoading, investorField, invetorsList, inviteUserCompany, isMutating } =
    useAssignInvestorPopup({
      projectId: projectId[0],
      onClose,
    });

  return (
    <Popup open={open} maxWidth="md" title="Assign investor" icon={LinkIcon}>
      <Stack sx={{ width: '100%' }}>
        {isLoading ? (
          <Skeleton variant="rectangular" height={40} />
        ) : (
          <CustomAutocomplete
            field={investorField}
            label="Investor"
            options={invetorsList}
            optionsLoading={isLoading}
            inputProps={{
              'data-cy': 'draw_switch_investor_select',
            }}
            clearIcon={false}
          />
        )}

        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
          sx={{ mt: '3.5rem' }}
        >
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          <ButtonWithTooltip
            loading={isMutating}
            disabled={isLoading || !investorField.value}
            onClick={inviteUserCompany}
            dataTestName={'res'}
          >
            Assign
          </ButtonWithTooltip>
        </Stack>
      </Stack>
    </Popup>
  );
};

export default AssignInvestorPopup;
