import React, { Dispatch, useState, useCallback, useContext, useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useSafeSnackbar } from '@hooks';

import { ChangeIcon, InspectionIcon } from '@svgAsComponents';
import {
  SubmissionAutofillEnum,
  BulkDrawRequestListItemUpdateParam,
  ErrorDual,
  IMenuItem,
  MutationKeyEnum,
  QueryNamesEnums,
  PermissionNamesEnums,
} from '@interfaces';
import { isAllowed, parsePathErrorDual } from '@utils';
import { updateDrawRequestMilestones } from '@globalService';
import { PermissionsContext } from '@context';

const AUTOFILL_KEYS = {
  [SubmissionAutofillEnum.REQUESTED_DRAW]: 'inspector_allowance_rate_requested',
  [SubmissionAutofillEnum.RETAINAGE]: 'retainage_release_requested',
};

export const title = {
  [SubmissionAutofillEnum.REQUESTED_DRAW]: 'Autofill requested draw',
  [SubmissionAutofillEnum.RETAINAGE]: 'Autofill approved retainage release',
};

export const popupText = {
  [SubmissionAutofillEnum.REQUESTED_DRAW]:
    'This will autofill the requested draw column with the inspection recommendation for this draw.',
  [SubmissionAutofillEnum.RETAINAGE]:
    'This will autofill the requested retainage release column with the eligible retainage balance.',
};

export type ControllerInterface = {
  autofillMenuItems: IMenuItem[];
  setCurrentMode: Dispatch<React.SetStateAction<SubmissionAutofillEnum>>;
  currentMode: SubmissionAutofillEnum;
  handleSubmissionAutofill: () => void;
  loading: boolean;
};

export const useSubmissionAutofill = ({
  drawRequestId,
  retainageRate,
}: {
  drawRequestId: string;
  retainageRate: number;
}): ControllerInterface => {
  const { projectId } = useParams();
  const { permissions } = useContext(PermissionsContext);
  const { enqueueSnackbar } = useSafeSnackbar();
  const queryClient = useQueryClient();

  const [currentMode, setCurrentMode] = useState<SubmissionAutofillEnum>(null);

  const autofillMenuItems = useMemo(
    () => [
      ...(isAllowed(
        PermissionNamesEnums.DRAWREQUESTS_AUTOFILL_INSPECTOR_ALLOWANCE_REQUESTED,
        permissions,
      )
        ? [
            {
              action: () => {
                setCurrentMode(SubmissionAutofillEnum.REQUESTED_DRAW);
              },
              text: SubmissionAutofillEnum.REQUESTED_DRAW,
              icon: <InspectionIcon size={18} />,
              dataTestName: 'submission__autofill__requested_draw',
            },
          ]
        : []),
      ...(retainageRate
        ? [
            {
              action: () => {
                setCurrentMode(SubmissionAutofillEnum.RETAINAGE);
              },
              text: SubmissionAutofillEnum.RETAINAGE,
              icon: <ChangeIcon size={18} />,
              dataTestName: 'submission__autofill__retainage',
            },
          ]
        : []),
    ],
    [permissions, retainageRate],
  );

  const bulkMilestoneMutation = useMutation<
    Response,
    ErrorDual,
    BulkDrawRequestListItemUpdateParam
  >(updateDrawRequestMilestones, {
    mutationKey: MutationKeyEnum.MILESTONE_PATCH_BULK,
    onSuccess: () => {
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST,
        { projectId, drawRequestId },
      ]);
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST_V2,
        { project_id: projectId, draw_request_id: drawRequestId },
      ]);
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
        { projectId, drawRequestId },
      ]);
    },
    onError: (error) => {
      enqueueSnackbar(parsePathErrorDual(error), { variant: 'error' });
    },
  });

  const handleSubmissionAutofill = useCallback(() => {
    if (currentMode) {
      const json = {
        autofill_key: AUTOFILL_KEYS[currentMode],
      };
      bulkMilestoneMutation.mutate({
        projectId,
        drawRequestId,
        json,
      });
    }
    setCurrentMode(null);
  }, [currentMode]);

  return {
    setCurrentMode,
    autofillMenuItems,
    currentMode,
    handleSubmissionAutofill,
    loading: bulkMilestoneMutation.isLoading,
  };
};
