export const COMPANY_DATA = {
  WEBSITE: 'https://www.trustpoint.ai',
  TERMS_OF_SERVICE_LINK: 'https://www.trustpoint.ai/terms-of-service',
  PRIVACY_POLICY_LINK: 'https://www.trustpoint.ai/privacy-policy',
  DOCSEND_LINK: 'https://trustpoint.docsend.com/view/s/4b9pvpqzexxipqqp',
  HELP_CENTER_LINK: 'https://trustpointinc.zendesk.com/hc/en-us',
  QUICK_GUIDE_LINK:
    'https://trustpointinc.zendesk.com/hc/en-us/articles/5282326591379-Quick-Start-Guide',
  SUBMIT_DRAW_VIDEO_LINK:
    'https://trustpointinc.zendesk.com/hc/en-us/articles/17002756587667-Video-How-to-Submit-a-Draw',
  BUDGET_GUIDE_LINK:
    'https://trustpointinc.zendesk.com/hc/en-us/articles/5456915703827-Budget-Changes-and-Reallocating-line-items',
  DRAW_STATUS_GUIDE_LINK:
    'https://trustpointinc.zendesk.com/hc/en-us/articles/12226292440467-Understanding-Draw-Status',
  KNOWLEDGE_BASE_LINK: 'https://help.trustpoint.ai/kb',
  ADDRESS: '4 W 4th Ave. 6FL San Mateo CA, 94402',
  SUPPORT_EMAIL: 'support@trustpoint.ai',
  NAME: 'TrustPoint',
  FULL_NAME: 'TrustPoint Technologies, Inc.',
  SUPERSET_DOMAIN: 'https://bi.trustpoint.ai',
  CUSTOMER_SUCCESS: 'TrustPoint Customer Success',
};
