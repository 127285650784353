import { QueryNamesEnums, TeamRolesEnum } from '@interfaces';
import { FilterObject } from '../interface';
import { basicFilterSerializer, checkIsLender, getTeamRole } from '@utils';

export const lenderFilter: FilterObject = {
  title: 'Lender',
  filterKey: 'lender',
  needToUpdateUrl: true,
  needToUpdateSetting: true,
  userPermission: (user) => !checkIsLender(getTeamRole(user)),
  getDataParamsPaginated: ({ pagination, q, skip, id }) => ({
    type: QueryNamesEnums.GET_COMPANIES_BY_ROLE,
    keys: ['id', 'name'],
    args: {
      pagination,
      q,
      role: TeamRolesEnum.LENDER,
      id,
    },
    options: {
      skip,
    },
  }),
  strictSerialize: (data) => basicFilterSerializer(data),
  cypressSelector: 'filters__lender',
  withPagination: true,
};
