import * as React from 'react';
import { Button, LinearProgress, Stack } from '@mui/material';
import { DataTableV3, ConfirmationModal } from '@components';
import { useRolesWorkflow, RolesWorkflowInterface } from './controller';
import { AddRoleDialog } from './components/AddRoleDialog';
import { CrudActionEnum, PopupTypeEnum, TableKeyEnum } from '@interfaces';

const RolesWorkflow = ({ roles, disabled, crud, isLoading }: RolesWorkflowInterface) => {
  const {
    itemForEdit,
    actionDialog,
    setActionDialog,
    crudAction,
    closeDialog,
    columns,
    rolesOptionsList,
  } = useRolesWorkflow({
    roles,
    crud,
    disabled,
  });

  return (
    <Stack>
      {isLoading && <LinearProgress sx={{ height: '2px' }} />}
      <DataTableV3
        withBuiltInSearch={false}
        rows={roles}
        columns={columns}
        tableKey={TableKeyEnum.WORKFLOW}
        rowReordering={false}
        headerLeft={[
          {
            Component: disabled ? null : (
              <Button color="primary" onClick={() => setActionDialog(CrudActionEnum.CREATE)}>
                Add role
              </Button>
            ),
          },
        ]}
      />

      <AddRoleDialog
        item={itemForEdit}
        isOpen={actionDialog === CrudActionEnum.UPDATE || actionDialog === CrudActionEnum.CREATE}
        onClose={closeDialog}
        updateHandler={crudAction}
        rolesOptionsList={rolesOptionsList}
      />
      <ConfirmationModal
        open={actionDialog === CrudActionEnum.DELETE}
        title="Delete role"
        text="Are you sure you want to delete this role."
        onClose={closeDialog}
        confirmCallback={crudAction}
        type={PopupTypeEnum.ERROR}
      />
    </Stack>
  );
};

export default RolesWorkflow;
