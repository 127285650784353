import { Stack, Typography } from '@mui/material';
import { colors } from '@theme';

import React from 'react';
import { IconWithProjectPermission } from '@components';
import { IDrawRequest, PermissionNamesEnums } from '@interfaces';
import { isRequestInReview, WithPermission, isInactiveProject, ColumnType } from '@utils';

const current_reviewer_team: ColumnType<IDrawRequest> = ({ updateApproverPopup }) => ({
  field: 'current_reviewer_team',
  graphFields: ['status'],
  graphNested: {
    project: ['status', 'id'],
    current_reviewer_team: ['name'],
  },
  headerName: 'Team',
  minWidth: 150,
  groupable: false,
  aggregable: false,
  renderCell: ({ row }) => {
    const openPopupCallback = () => updateApproverPopup(row.original);
    return (
      <Stack flexDirection="row" alignItems="center">
        <Typography variant="body3SemiBold" sx={{ color: colors.text.link }}>
          {row?.current_reviewer_team?.name}
        </Typography>
        {isRequestInReview(row?.status) && (
          <WithPermission permissionKey={PermissionNamesEnums.DRAWREQUESTS_REVIEWER_TEAM_EDIT}>
            <IconWithProjectPermission
              permissionKey={PermissionNamesEnums.DRAWREQUESTS_REVIEWER_TEAM_EDIT}
              projectId={row.project?.id}
              openPopupCallback={openPopupCallback}
              isCurrentProjectArchived={isInactiveProject(row.project?.status)}
              source="requests_queue__reviewer_team__edit__icon"
            />
          </WithPermission>
        )}
      </Stack>
    );
  },
});

export default current_reviewer_team;
