import React, { FC, PropsWithChildren } from 'react';
import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { ComponentProps, ComponentWithPreloadProps, defaultProps } from './interface';
import { getEllipsisStyle } from '@utils';

const EllipsisStyle = (maxWidth: string | number) => ({
  ...getEllipsisStyle(maxWidth),
});

const IconWrapper = ({
  icon,
  tooltip,
  dataCy,
}: {
  icon: React.ReactNode;
  tooltip?: React.ReactNode;
  dataCy?: string;
}) => (
  <Tooltip title={tooltip || ''}>
    <Stack alignItems="center" pr={0.5} data-cy={dataCy}>
      {icon}
    </Stack>
  </Tooltip>
);

export const LabelAndValue: FC<PropsWithChildren<ComponentProps>> = ({
  size = defaultProps.size,
  children,
  label = defaultProps.label,
  text,
  justifyContent = defaultProps.justifyContent,
  color = defaultProps.color,
  colorLabel = defaultProps.colorLabel,
  icon,
  iconTooltip = defaultProps.iconTooltip,
  withEllipsis,
  tooltipText = defaultProps.tooltipText,
  marginRightAfterLabel = defaultProps.marginRightAfterLabel,
  minHeight = defaultProps.minHeight,
  labelStyle = defaultProps.labelStyle,
  textStyle,
  valueIcon,
  valueIconTooltip,
  noWrap,
  iconPosition = defaultProps.iconPosition,
  textDataTestName,
  valueIconDataTestName,
  iconDataTestName,
  labelDataTestName,
  fullWidth,
  valueIconTooltipDataTestName,
  valueTooltipPlacement = defaultProps.valueTooltipPlacement,
  valueIconPosition = defaultProps.valueIconPosition,
}) => {
  return (
    <Stack
      minHeight={minHeight}
      justifyContent={justifyContent}
      alignItems="center"
      direction="row"
      flexWrap={noWrap ? 'nowrap' : 'wrap'}
      sx={fullWidth ? { width: '100%' } : {}}
    >
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        sx={[withEllipsis && EllipsisStyle('70%'), marginRightAfterLabel && { mr: 3 }]}
      >
        {iconPosition === 'left' && icon && (
          <IconWrapper icon={icon} tooltip={iconTooltip} dataCy={iconDataTestName} />
        )}

        <Tooltip title={tooltipText}>
          <Typography
            variant={size}
            sx={{
              color: colorLabel,
              cursor: tooltipText ? 'pointer' : 'unset',
              whiteSpace: !noWrap ? 'nowrap' : 'wrap',
              ...labelStyle,
            }}
            data-cy={labelDataTestName}
          >
            {label}&nbsp;
          </Typography>
        </Tooltip>

        {iconPosition === 'right' && icon && (
          <IconWrapper icon={icon} tooltip={iconTooltip} dataCy={iconDataTestName} />
        )}
      </Stack>

      <Stack
        alignItems="center"
        sx={{ flexDirection: valueIconPosition === 'right' ? 'row' : 'row-reverse' }}
      >
        {text ? (
          <Stack direction="row" justifyContent="flex-start" alignItems="center">
            <Typography
              variant={`${size}SemiBold`}
              align="right"
              sx={{ color, whiteSpace: 'pre-wrap', wordBreak: 'break-word', ...textStyle }}
              data-cy={textDataTestName}
            >
              {text}
            </Typography>
          </Stack>
        ) : (
          <Box sx={withEllipsis ? EllipsisStyle('30%') : {}}>{children}</Box>
        )}
        {valueIcon && (
          <NoMaxWidthTooltip
            title={
              typeof valueIconTooltip === 'string' ? (
                <Typography
                  data-cy={valueIconTooltipDataTestName}
                  variant="label"
                  dangerouslySetInnerHTML={{ __html: valueIconTooltip }}
                ></Typography>
              ) : (
                valueIconTooltip
              )
            }
            placement={valueTooltipPlacement}
          >
            <Stack
              sx={{ py: 0, px: 1 }}
              data-cy={valueIconTooltipDataTestName || valueIconDataTestName}
            >
              {valueIcon}
            </Stack>
          </NoMaxWidthTooltip>
        )}
      </Stack>
    </Stack>
  );
};

export const LabelAndValueWithPreload: FC<PropsWithChildren<ComponentWithPreloadProps>> = ({
  isLoading,
  ...props
}) => {
  return isLoading ? <Skeleton sx={{ my: 0, minHeight: 24 }} /> : <LabelAndValue {...props} />;
};

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
    padding: 16,
  },
});
