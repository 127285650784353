import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import isEmpty from 'lodash/isEmpty';
import {
  FundingSourceInternalIdEnums,
  HookState,
  IDrawRequestFundingSource,
  IPieChartData,
  IProjectProgressItem,
  QueryNamesEnums,
} from '@interfaces';
import { getDrawRequestSources } from '@globalService';
import {
  calculateFraction,
  currencyFormatter,
  fundingSourceColor,
  getFundingSource,
  getHookState,
  percentFormatter,
} from '@utils';
import { colors } from '@theme';

interface ControllerInterface {
  state: HookState;
  requestProgressItems: { originalScheduledValue: Partial<IProjectProgressItem> };
  getRequestOriginalValuePieChartData: () => IPieChartData[];
  drawRequestFundingSources: IDrawRequestFundingSource[];
  constructionHoldbackFS: IDrawRequestFundingSource;
  borrowerEquityFS: IDrawRequestFundingSource;
}

const defaultProgressItems = {
  originalScheduledValue: { after_value: 0, before_value: 0, total: 0 },
};

export const useRequestPaymentData = (): ControllerInterface => {
  const { projectId, drawRequestId } = useParams();

  const drawRequestFundingSourcesQuery = useQuery<{ results: IDrawRequestFundingSource[] }, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST_FUNDING_SOURCES, { projectId, drawRequestId }],
    getDrawRequestSources.bind(this, projectId, drawRequestId),
    { enabled: Boolean(projectId && drawRequestId) },
  );

  const drawRequestFundingSources = useMemo(
    () =>
      drawRequestFundingSourcesQuery.data?.results
        ?.filter((fundingSource) => fundingSource.is_active)
        .sort((a, b) => a.index - b.index)
        .map((fundingSource) => ({
          // TODO: remove this once the backend is migrated
          ...fundingSource,
          color: fundingSourceColor(fundingSource),
        })),

    [drawRequestFundingSourcesQuery.data],
  );

  const construction_holdback = useMemo(
    () =>
      getFundingSource({
        fundingSources: drawRequestFundingSources,
        internalId: FundingSourceInternalIdEnums.CONSTRUCTION_HOLDBACK,
      }) as IDrawRequestFundingSource,
    [drawRequestFundingSources],
  );

  const borrower_equity = useMemo(
    () =>
      getFundingSource({
        fundingSources: drawRequestFundingSources,
        internalId: FundingSourceInternalIdEnums.BORROWER_EQUITY,
      }) as IDrawRequestFundingSource,
    [drawRequestFundingSources],
  );

  const revisedValueTotal = useMemo(
    () => (construction_holdback?.revised_total || 0) + (borrower_equity?.revised_total || 0),
    [construction_holdback?.revised_total, borrower_equity?.revised_total],
  );

  const createProgressItem = (after_value: number, before_value: number, total: number) => {
    const after = calculateFraction(after_value, total);
    const before = calculateFraction(before_value, total);
    return {
      after_value,
      before_value,
      total,
      after,
      before,
      currentDraw: `${currencyFormatter(after_value - before_value)} / ${percentFormatter({
        value: after - before,
        roundTo: 2,
      })}`,
      contributedText: `${currencyFormatter(before_value)} / ${percentFormatter({
        value: before,
        roundTo: 2,
      })}`,
      remainingText: `${currencyFormatter(total - after_value)} / ${percentFormatter({
        value: 100 - after,
        roundTo: 2,
      })}`,
      barValues: [before_value, after_value - before_value],
    };
  };

  const requestProgressItems = useMemo(() => {
    if (!drawRequestFundingSources || isEmpty(drawRequestFundingSources))
      return defaultProgressItems;

    return {
      originalScheduledValue: createProgressItem(
        construction_holdback.funded_after_current_request +
          borrower_equity.funded_after_current_request,
        construction_holdback.funded_before_current_request +
          borrower_equity.funded_before_current_request,
        revisedValueTotal,
      ),
    };
  }, [drawRequestFundingSources, construction_holdback, borrower_equity, revisedValueTotal]);

  const getRequestOriginalValuePieChartData = useCallback(() => {
    if (!drawRequestFundingSources) return [];
    const totalAmount = drawRequestFundingSources.reduce(
      (acc, fundingSource) => acc + +fundingSource.amount,
      0,
    );
    return drawRequestFundingSources.map((fundingSource) => {
      const fundingSourceFraction = calculateFraction(+fundingSource.amount, totalAmount);
      const fundingSourceValues = `${percentFormatter({ value: fundingSourceFraction })} / ${currencyFormatter(+fundingSource.amount)}`;
      return {
        label: `${fundingSource.name} (${fundingSourceValues})`,
        color: colors.fundingSource[fundingSourceColor(fundingSource)].medium,
        value: +fundingSource.amount,
      };
    });
  }, [drawRequestFundingSources]);

  return {
    state: getHookState(drawRequestFundingSourcesQuery),
    requestProgressItems,
    getRequestOriginalValuePieChartData,
    drawRequestFundingSources,
    constructionHoldbackFS: construction_holdback,
    borrowerEquityFS: borrower_equity,
  };
};
