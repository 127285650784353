import React, { FC } from 'react';
import { Button, ButtonProps, Stack, Typography } from '@mui/material';
import {
  ButtonWithTooltip,
  CustomTextField,
  Popup,
  ButtonWithTooltipOnClick,
  InternationalPhoneField,
} from '@components';
import * as Controller from './controller';
import { AddUserIcon } from '@svgAsComponents';
import { TeamRolesEnum } from '@interfaces';

interface ComponentProps {
  title?: string;
  size?: 'small' | 'medium' | 'large';
  buttonColor?: ButtonProps['color'];
  dataTestName?: string;
  role?: string;
}

const InviteUserCompany: FC<ComponentProps> = ({
  title = 'Invite user',
  size = 'small',
  buttonColor = 'primary',
  dataTestName = 'company_users__invite_user__button',
  role = TeamRolesEnum.INVESTOR,
}) => {
  const {
    firstName,
    lastName,
    phone,
    email,
    inviteUserCompany,
    isSubmitting,
    isOpened,
    setIsOpened,
    onCloseCallback,
    isSearching,
    handleSearchEmailClick,
    foundUser,
    tooltipText,
  } = Controller.useInviteCompanyUser({ role });

  return (
    <>
      <ButtonWithTooltip
        color={buttonColor}
        onClick={() => setIsOpened(true)}
        dataTestName={dataTestName}
        size={size}
      >
        {title}
      </ButtonWithTooltip>

      {isOpened && (
        <Popup open={isOpened} icon={AddUserIcon} maxWidth="sm" title={title}>
          <Stack sx={{ width: '100%', justifyContent: 'space-between', flex: 1 }}>
            <Stack spacing={2}>
              <Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  sx={{ pt: 3 }}
                  spacing={1}
                >
                  <CustomTextField
                    field={email}
                    label="Email"
                    required
                    validationText="Please enter a valid email"
                    sx={{ flex: 1, pt: '2px' }}
                  />
                  <ButtonWithTooltip
                    onClick={handleSearchEmailClick}
                    disabled={!email.isValid}
                    tooltipText={!email.isValid ? 'Please enter a valid email' : ''}
                    sx={{ minWidth: '150px' }}
                    loading={isSearching}
                    dataTestName=""
                  >
                    Find user
                  </ButtonWithTooltip>
                </Stack>
                <Typography variant="label" sx={{ pt: 0.5 }}>
                  {tooltipText}
                </Typography>
              </Stack>
              {foundUser && (
                <>
                  {/** TODO: Keed it as fields for Future add or Invite */}
                  <CustomTextField field={firstName} label="First name" disabled />
                  <CustomTextField field={lastName} label="Last name" disabled />
                  <InternationalPhoneField field={phone} label="Phone" disabled />
                </>
              )}
            </Stack>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              sx={{ mt: 4 }}
              spacing={2}
            >
              <Button variant="text" onClick={onCloseCallback}>
                Cancel
              </Button>
              <ButtonWithTooltipOnClick
                loading={isSubmitting}
                onClick={inviteUserCompany}
                disabled={!foundUser}
              >
                Invite
              </ButtonWithTooltipOnClick>
            </Stack>
          </Stack>
        </Popup>
      )}
    </>
  );
};

export default InviteUserCompany;
