import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSafeSnackbar } from '@hooks';
import { QueryNamesEnums, RequestPayload } from '@interfaces';
import { deleteDrawRequest } from '@globalService';
import { useMutation, useQueryClient } from 'react-query';
import { checkIsResubmit } from '@utils';
import { ConfirmationModalTypeEnums, ICancelRequestController } from '../interfaces';

export const useCancelRequest = (): ICancelRequestController => {
  const { projectId, requestId, action } = useParams();
  const isAdditionalRequirementResubmit = useMemo(() => checkIsResubmit(action), [action]);

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();
  const navigate = useNavigate();
  const [isCancelModalShow, setCancelModalShow] = useState<ConfirmationModalTypeEnums>(null);

  const cancelDrawRequestMutation = useMutation<Response, Error, RequestPayload>(
    deleteDrawRequest,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST,
          { projectId },
        ]);
        enqueueSnackbar('Request canceled', { variant: 'success' });
        navigate(`/projects/${projectId}/overview`);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const requestCancel = useCallback(
    async () =>
      await cancelDrawRequestMutation.mutateAsync({
        project: projectId,
        drawRequest: requestId,
      }),
    [cancelDrawRequestMutation],
  );

  const goBack = () => {
    if (isAdditionalRequirementResubmit) {
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST,
        { projectId, drawRequestId: requestId },
      ]);
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST_V2,
        { project_id: projectId, draw_request_id: requestId },
      ]);
      queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_ITEM_CHECKLIST);
    } else {
      enqueueSnackbar('Draft request is saved', { variant: 'success' });
    }
    navigate(`/projects/${projectId}/draws/draw-requests/${requestId}`);
  };

  return {
    requestCancel,
    goBack,
    setCancelModalShow,
    isCancelModalShow,
    isCanceling: cancelDrawRequestMutation.isLoading,
  };
};
