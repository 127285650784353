import { IDrawRequest, PermissionNamesEnums } from '@interfaces';
import { ColumnType, isAllowed } from '@utils';
import React from 'react';

const inspection_provider: ColumnType<IDrawRequest> = () => ({
  field: 'inspection.provider_status_display',
  renderCell: ({ row }: { row: Partial<IDrawRequest> }) => (
    <span data-cy="requests_queue__table__body__inspection__provider_status_display">
      {row.inspection?.provider_status_display}
    </span>
  ),
  graphFields: [],
  graphNested: {
    inspection: ['provider_status_display'],
  },
  enableByPermissions: (permissions) =>
    isAllowed(PermissionNamesEnums.INSPECTIONS_VIEW, permissions),
  headerName: 'Inspection provider status',
  minWidth: 140,
  groupable: false,
  sortable: false,
  filterable: false,
  aggregable: false,
});

export default inspection_provider;
