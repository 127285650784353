import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useQuery, UseQueryResult } from 'react-query';
import {
  DocumentSectionContextEnum,
  DocumentTabEnums,
  HookState,
  IDocument,
  IDrawRequest,
  IProjectDocument,
  ITablePagination,
  QueryNamesEnums,
  TableKeyEnum,
} from '@interfaces';
import {
  checkIsPHBProject,
  getHookState,
  getSortStringByGridSortModel,
  isChangeRequest,
} from '@utils';
import {
  getDrawRequest,
  getDrawRequestDocumentsList,
  getInspectionDocuments,
  getMilestoneDocumentsV2,
  getProjectDocuments,
  getProjectMilestoneDocumentsV2,
  getProjectMilestoneGroupDocuments,
  getRequestMilestoneGroupDocuments,
  getServiceOrderDocuments,
} from '@globalService';
import { defaultRowsPerPageOptions, excludeCommentsWithTotalsAllQueryFields } from '@constants';
import { useTablePagination, useUserTableSettings } from '@hooks';
import { useGraphQuery } from '@context';

interface DocumentsDataProps {
  projectId: string;
  drawRequestId?: string;
  milestoneId?: string;
  serviceOrderId?: string;
  inspectionId?: string;
  context?: DocumentSectionContextEnum | '';
  isDocumentsPanel?: boolean;
}

interface ControllerInterface {
  state: HookState;
  documents: IDocument[] | IProjectDocument[];
  documentsCount: number;
  isLoading: boolean;
  isPHBProject: boolean;
  projectName: string;
  tablePagination: ITablePagination;
  docSectionHeader: string;
  searchQuery: string;
  setSearchQuery: Dispatch<SetStateAction<string>>;
}

export const useDocumentsData = ({
  projectId,
  drawRequestId,
  milestoneId,
  serviceOrderId,
  inspectionId,
  context = DocumentSectionContextEnum.PRIMARY,
  isDocumentsPanel = false,
}: DocumentsDataProps): ControllerInterface => {
  const { userTableSettings } = useUserTableSettings({ tableKey: TableKeyEnum.DOCUMENTS_LIST });
  const { pathname } = useLocation();
  const match = matchPath('/projects/:projectId/:tab/*', pathname);
  const tabPathname = match?.params['*']?.split('/')[0];
  const tablePagination = useTablePagination({
    initialRowsPerPage: defaultRowsPerPageOptions.documents[isDocumentsPanel ? 0 : 1],
    rowsPerPageOptions: defaultRowsPerPageOptions.documents,
  });

  const tableSortingModel = useMemo(
    () => userTableSettings?.sorting?.sortModel || [],
    [userTableSettings?.sorting?.sortModel],
  );

  const [searchQuery, setSearchQuery] = useState('');

  const sortKeysMap = {
    'draw_request.number': 'draw_request_number',
  };

  const stringQueryParams = useMemo(() => {
    const params = {
      offset: (tablePagination.page * tablePagination.rowsPerPage).toString(),
      limit: tablePagination.rowsPerPage.toString(),
      sorting: getSortStringByGridSortModel(tableSortingModel, sortKeysMap),
      ...(searchQuery ? { q: searchQuery } : {}),
    };
    return new URLSearchParams(params)?.toString();
  }, [tablePagination, tableSortingModel, searchQuery]);

  useEffect(() => {
    tablePagination.setPage(0);
  }, [projectId, drawRequestId, milestoneId, serviceOrderId, inspectionId]);

  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['is_advanced_budget_tracking_enabled', 'status', 'name'],
    args: { project_id: projectId },
  });

  const isPHBProject = useMemo(() => checkIsPHBProject(project.data), [project.data]);

  const drawRequestQuery = useQuery<IDrawRequest, Error>(
    [
      QueryNamesEnums.GET_DRAW_REQUEST,
      { projectId, drawRequestId, query: excludeCommentsWithTotalsAllQueryFields },
    ],
    getDrawRequest.bind(this, {
      projectId,
      drawRequestId,
      query: excludeCommentsWithTotalsAllQueryFields,
    }),
    { enabled: Boolean(drawRequestId) },
  );

  const milestoneDocsQueryParams = useMemo(() => `&context=${context}`, [context]);

  // get documents
  const projectDocsQuery =
    tabPathname === DocumentTabEnums.PROJECT ? '&include_draw_request_documents=false' : '';
  const projectDocumentsQuery = useQuery<{ results: IProjectDocument[]; count: number }, Error>(
    [
      QueryNamesEnums.GET_PROJECT_DOCUMENTS,
      { projectId, stringQueryParams: stringQueryParams + projectDocsQuery },
    ],
    getProjectDocuments.bind(this, {
      projectId,
      stringQueryParams: stringQueryParams + projectDocsQuery,
    }),
    {
      enabled: Boolean(
        projectId && !drawRequestId && !milestoneId && !serviceOrderId && !inspectionId,
      ),
      keepPreviousData: isDocumentsPanel,
    },
  );

  const drawRequestDocumentsQuery = useQuery<{ results: IDocument[]; count: number }, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST_DOCUMENTS, { projectId, drawRequestId, stringQueryParams }],
    getDrawRequestDocumentsList.bind(this, { projectId, drawRequestId, stringQueryParams }),
    {
      enabled: Boolean(
        projectId && drawRequestId && !milestoneId && !serviceOrderId && !inspectionId,
      ),
      keepPreviousData: isDocumentsPanel,
    },
  );

  const requestMilestoneDocumentsQueryV2 = useQuery<{ results: IDocument[]; count: number }, Error>(
    [
      QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE_DOCS,
      {
        projectId,
        milestoneId,
        drawRequestId,
        stringQueryParams: stringQueryParams + milestoneDocsQueryParams,
      },
    ],
    getMilestoneDocumentsV2.bind(this, {
      projectId,
      drawRequestId,
      milestoneId,
      stringQueryParams: stringQueryParams + milestoneDocsQueryParams,
    }),
    {
      enabled: Boolean(milestoneId && drawRequestId && !isPHBProject),
      keepPreviousData: isDocumentsPanel,
    },
  );

  const projectMilestoneDocumentsQueryV2 = useQuery<{ results: IDocument[]; count: number }, Error>(
    [
      QueryNamesEnums.GET_PROJECT_MILESTONE_DOCS,
      { projectId, milestoneId, stringQueryParams: stringQueryParams + milestoneDocsQueryParams },
    ],
    getProjectMilestoneDocumentsV2.bind(this, {
      projectId,
      milestoneId,
      stringQueryParams: stringQueryParams + milestoneDocsQueryParams,
    }),
    {
      enabled: Boolean(milestoneId && !drawRequestId && !isPHBProject),
      keepPreviousData: isDocumentsPanel,
    },
  );

  const projectMilestoneGroupDocumentsQuery = useQuery<
    { results: IDocument[]; count: number },
    Error
  >(
    [
      QueryNamesEnums.GET_PROJECT_MILESTONE_GROUP_DOCS,
      { projectId, milestoneId, stringQueryParams },
    ],
    getProjectMilestoneGroupDocuments.bind(this, {
      projectId,
      milestoneId,
      stringQueryParams,
    }),
    {
      enabled: Boolean(milestoneId && !drawRequestId && isPHBProject),
      keepPreviousData: isDocumentsPanel,
    },
  );

  const requestMilestoneGroupDocumentsQuery = useQuery<
    { results: IDocument[]; count: number },
    Error
  >(
    [
      QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE_GROUP__DOCS,
      { projectId, drawRequestId, milestoneId, stringQueryParams },
    ],
    getRequestMilestoneGroupDocuments.bind(this, {
      projectId,
      drawRequestId,
      milestoneId,
      stringQueryParams,
    }),
    {
      enabled: Boolean(milestoneId && drawRequestId && isPHBProject),
      keepPreviousData: isDocumentsPanel,
    },
  );

  const inspectionDocumentsQuery = useQuery<{ results: IProjectDocument[]; count: number }, Error>(
    [QueryNamesEnums.GET_INSPECTION_DOCUMENTS, { projectId, inspectionId, stringQueryParams }],
    getInspectionDocuments.bind(this, { projectId, inspectionId, stringQueryParams }),
    { enabled: Boolean(projectId && inspectionId) },
  );
  const serviceOrderDocumentsQuery = useQuery<
    { results: IProjectDocument[]; count: number },
    Error
  >(
    [QueryNamesEnums.GET_SERVICE_ORDER_DOCUMENTS, { projectId, serviceOrderId, stringQueryParams }],
    getServiceOrderDocuments.bind(this, { projectId, serviceOrderId, stringQueryParams }),
    { enabled: Boolean(projectId && serviceOrderId), keepPreviousData: isDocumentsPanel },
  );

  const getDocumentDetails = (
    query: UseQueryResult<
      {
        results: IDocument[] | IProjectDocument[];
        count: number;
      },
      Error
    >,
  ) => ({
    state: getHookState(query),
    documents: query.data?.results,
    documentsCount: query.data?.count,
    isLoading: query.isLoading,
  });

  const values = useMemo(() => {
    if (serviceOrderId) {
      return getDocumentDetails(serviceOrderDocumentsQuery);
    }
    if (inspectionId) {
      return getDocumentDetails(inspectionDocumentsQuery);
    }
    if (isPHBProject && milestoneId && drawRequestId) {
      return getDocumentDetails(requestMilestoneGroupDocumentsQuery);
    }
    if (isPHBProject && milestoneId) {
      return getDocumentDetails(projectMilestoneGroupDocumentsQuery);
    }
    if (milestoneId && drawRequestId) {
      return getDocumentDetails(requestMilestoneDocumentsQueryV2);
    }
    if (milestoneId && !drawRequestId) {
      return getDocumentDetails(projectMilestoneDocumentsQueryV2);
    }
    if (drawRequestId) {
      return getDocumentDetails(drawRequestDocumentsQuery);
    }

    return getDocumentDetails(projectDocumentsQuery);
  }, [
    serviceOrderId,
    drawRequestId,
    milestoneId,
    isPHBProject,
    inspectionId,
    projectDocumentsQuery,
    drawRequestDocumentsQuery,
    projectMilestoneDocumentsQueryV2,
    serviceOrderDocumentsQuery,
    inspectionDocumentsQuery,
    projectMilestoneGroupDocumentsQuery,
    requestMilestoneDocumentsQueryV2,
  ]);

  const documents = useMemo(
    () =>
      values.isLoading
        ? Array(3).fill({})
        : values.documents
            ?.map((o) => ({
              ...o,
              rowStyle: o?.deleted ? { textDecoration: 'line-through', opacity: 0.5 } : {},
            }))
            ?.filter((a) => a?.file_representations),
    [values],
  );

  const docSectionHeader = useMemo(() => {
    if (context === DocumentSectionContextEnum.OTHER) return 'Other';

    if (!drawRequestId && !inspectionId) return 'Project';

    if (inspectionId) return 'Inspection';

    return `${isChangeRequest(drawRequestQuery?.data) ? 'Change' : 'Draw'} #${drawRequestQuery?.data?.number}`;
  }, [drawRequestId, drawRequestQuery, milestoneId, inspectionId, context]);

  return {
    state: values.state,
    documentsCount: values.documentsCount,
    isLoading: values.isLoading,
    documents: documents,
    projectName: project.data?.name,
    tablePagination,
    docSectionHeader,
    isPHBProject,
    searchQuery,
    setSearchQuery,
  };
};
