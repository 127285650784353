import React from 'react';
import { Stack, Typography, Switch, FormControlLabel } from '@mui/material';

import {
  StyledBox,
  TabWrapperWithLeaveWarning,
  CustomAutocomplete,
  LoadingSkeleton,
} from '@components';
import * as Controller from './controller';
import { typography } from '@theme';
import { useLaunchDarklyFlags } from '@context';

const OtherSettings = ({ isAllProjectDetailsDisabled }) => {
  const flags = useLaunchDarklyFlags();
  const controller = Controller.useOtherSettings();
  const {
    isBatchSubmissionEnabled,
    handleSubmitClick,
    isSubmitting,
    isUpdated,
    exitPath,
    coordinatorField,
    list,
    isCoordinatorEditable,
    isCoordinatorsListLoading,
    isLoading,
  } = controller;

  if (isLoading) return <LoadingSkeleton type="overviewBlock" />;

  return (
    <TabWrapperWithLeaveWarning
      onSave={handleSubmitClick}
      isUpdated={isUpdated}
      tabValue="general"
      path={exitPath}
      isSubmitting={isSubmitting}
      currentPagePathname={'/projects/*/settings/general'}
    >
      <StyledBox>
        <Stack>
          <Typography variant="h3">Draw settings</Typography>
          <FormControlLabel
            sx={{
              mt: 2,
              '& .MuiTypography-root': {
                ...typography.body3,
              },
            }}
            control={
              <Switch
                sx={{ mr: '15px' }}
                checked={isBatchSubmissionEnabled.value}
                onChange={(event) => isBatchSubmissionEnabled.setValue(event.target.checked)}
                data-cy="project__settings__other__batch_submission__switch"
                disabled={isAllProjectDetailsDisabled}
              />
            }
            label="Enable 'lump sum' submission"
            disabled={isAllProjectDetailsDisabled}
          />
        </Stack>
      </StyledBox>

      {flags?.['ENG_9987_add_coordinator_to_project_settings'] && (
        <StyledBox>
          <Stack>
            <Typography sx={{ mb: 2 }} variant="h3">
              Project coordinator
            </Typography>

            <Stack direction="row" spacing={2} sx={{ maxWidth: '500px' }}>
              <CustomAutocomplete
                field={coordinatorField}
                label="Coordinator"
                options={list}
                optionsLoading={isCoordinatorsListLoading}
                inputProps={{
                  'data-cy': 'project__settings__general__switch_coordinator_select',
                }}
                disabled={!isCoordinatorEditable}
                clearIcon
              />
            </Stack>
          </Stack>
        </StyledBox>
      )}
    </TabWrapperWithLeaveWarning>
  );
};

export default OtherSettings;
