import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { IActivityLogItem, QueryNamesEnums, ITablePagination, HookState } from '@interfaces';
import { getHookState } from '@utils';
import { getMilestoneAuditLog, getProjectAuditLog } from '@globalService';
import { useTablePagination } from '@hooks';
import { useLaunchDarklyFlags } from '@context';
import { defaultRowsPerPageOptions } from '@constants';

interface ControllerInterface {
  state: HookState;
  auditLogData: IActivityLogItem[];
  tablePagination: ITablePagination;
  itemsCount: number;
}

export const useAuditLog = ({ projectId, milestoneId, sorting, filters }): ControllerInterface => {
  const flags = useLaunchDarklyFlags();
  const tablePagination = useTablePagination({
    initialRowsPerPage: defaultRowsPerPageOptions.documents[0],
    rowsPerPageOptions: defaultRowsPerPageOptions.documents,
  });

  const queryURL = useMemo(() => {
    let stringQueryParams = `&offset=${
      tablePagination.page * tablePagination.rowsPerPage
    }&limit=${tablePagination.rowsPerPage}&sorting=${sorting ? '-created_at' : 'created_at'}`;

    if (filters.length) {
      stringQueryParams += `&tags=${filters.join(',')}`;
    }
    return stringQueryParams;
  }, [tablePagination.page, tablePagination.rowsPerPage, filters, flags, sorting]);

  const projectAuditLogQuery = useQuery<{ results: IActivityLogItem[]; count: number }, Error>(
    [QueryNamesEnums.GET_PROJECT_AUDIT_LOG, { projectId, queryURL }],
    getProjectAuditLog.bind(this, projectId, queryURL),
    {
      staleTime: 0,
      enabled: !milestoneId,
    },
  );

  const lineItemAuditLogQuery = useQuery<{ results: IActivityLogItem[]; count: number }, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE_AUDIT_LOG, { milestoneId, queryURL }],
    getMilestoneAuditLog.bind(this, milestoneId, queryURL),
    {
      staleTime: 0,
      enabled: !!milestoneId,
    },
  );

  const auditLogQuery = useMemo(
    () => (!milestoneId ? projectAuditLogQuery : lineItemAuditLogQuery),
    [projectAuditLogQuery, lineItemAuditLogQuery, milestoneId],
  );

  return {
    state: getHookState(auditLogQuery),
    auditLogData: auditLogQuery.data?.results,
    tablePagination,
    itemsCount: auditLogQuery.data?.count,
  };
};
