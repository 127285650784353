import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { useQuery } from 'react-query';

import { ICompanyFull, QueryNamesEnums, DateValidationEnum } from '@interfaces';
import { getMyCompany } from '@globalService';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const yyyymmddRegex = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12]\d|3[01])$/;
const ddmmyyyyRegex = /^(0[1-9]|[12]\d|3[01])-(0[1-9]|1[012])-\d{4}$/;

const isOnlyDateFormat = (date: string) => {
  return ddmmyyyyRegex.test(date) || yyyymmddRegex.test(date);
};

const getOnlyDateFormatted = (date: string) => {
  const [first, second, third] = date.toString().split('-');
  const year = ddmmyyyyRegex.test(date) ? third : first;
  const month = second;
  const day = ddmmyyyyRegex.test(date) ? first : third;
  return `${year}-${month}-${day}`;
};

interface DateFormatterOptions {
  date: string | Date | null;
  withTime?: boolean;
}

export const useDayJsFormatter = () => {
  const { data: company } = useQuery<ICompanyFull>([QueryNamesEnums.GET_MY_COMPANY], getMyCompany);
  const timezone = company?.timezone || 'US/Pacific';

  const dateFormatter = ({ date, withTime = false }: DateFormatterOptions) => {
    if (!date) return null;

    // Check for 'DD-MM-YYYY' and 'YYYY-MM-DD' format
    if (isOnlyDateFormat(date.toString()))
      return dayjs(getOnlyDateFormatted(date as string)).format('MMM DD, YYYY');

    const parsedDate = dayjs(date).tz(timezone);
    return withTime ? parsedDate.format('MMM DD, YYYY hh:mm A') : parsedDate.format('MMM DD, YYYY');
  };

  const getInitialValue = ({
    date,
    addTimestamp = false,
  }: {
    date?: Date | string | null;
    addTimestamp?: boolean;
  }): Date => {
    if (!date)
      return addTimestamp
        ? dayjs().tz(timezone).toDate()
        : dayjs().tz(timezone).startOf('day').toDate();

    // Check for 'DD-MM-YYYY' and 'YYYY-MM-DD' format
    if (isOnlyDateFormat(date.toString()))
      return dayjs
        .tz(getOnlyDateFormatted(date as string), timezone)
        .startOf('day')
        .toDate();

    return dayjs(date).tz(timezone).toDate();
  };

  const getFormattedToTimezoneStringValue = (date: string | Date | null): string | null => {
    if (!date) return null;
    return dayjs(date).tz(timezone).format('YYYY-MM-DD');
  };

  const areEqualDates = ({
    value1,
    value2,
    withTime = false,
  }: {
    value1: Date | string | null;
    value2: Date | string | null;
    withTime?: boolean;
  }): boolean => {
    // Return true if both values are null/undefined
    if (!value1 && !value2) return true;
    // Return false if only one value is null/undefined
    if (!value1 || !value2) return false;

    // Convert both values to dayjs and compare
    const date1 = dayjs(value1);
    const date2 = dayjs(value2);

    // Check if both dates are valid before comparing
    if (!date1.isValid() || !date2.isValid()) return false;

    return date1.isSame(date2, withTime ? 'minute' : 'day');
  };

  const getDateValidationRule = ({
    value,
    rule,
    required = true,
    minDate,
    maxDate,
    dateDescription = '',
  }: {
    value: Date;
    rule: DateValidationEnum;
    required?: boolean;
    minDate?: Date;
    maxDate?: Date;
    dateDescription?: string;
  }) => {
    if (!required && !value) return { value: true, reason: '' };
    if (!value) return { value: false, reason: 'Date is required' };

    const transformedValue = dayjs(value).tz(timezone);
    if (isNaN(transformedValue.valueOf()) || transformedValue.year() <= 2000) {
      return { value: false, reason: 'Please enter a valid date' };
    }

    const transformedMaxDate = maxDate ? dayjs(maxDate).tz(timezone).endOf('day') : undefined;
    const transformedMinDate = minDate ? dayjs(minDate).tz(timezone).startOf('day') : undefined;

    const validationRules = {
      [DateValidationEnum.MORE]: () =>
        transformedMinDate &&
        transformedValue.isSameOrBefore(transformedMinDate) && {
          value: false,
          reason: `Selected date must be more than ${dateDescription || dayjs(transformedMinDate).format('MM/DD/YYYY')}`,
        },
      [DateValidationEnum.LESS]: () =>
        transformedMaxDate &&
        transformedValue.isSameOrAfter(transformedMaxDate) && {
          value: false,
          reason: `Selected date must be less than ${dateDescription || dayjs(transformedMaxDate).format('MM/DD/YYYY')}`,
        },
      [DateValidationEnum.MORE_OR_EQUAL]: () =>
        transformedMinDate &&
        transformedValue.isBefore(transformedMinDate) && {
          value: false,
          reason: `Selected date must be more than or equal to ${
            dateDescription || dayjs(transformedMinDate).format('MM/DD/YYYY')
          }`,
        },
      [DateValidationEnum.LESS_OR_EQUAL]: () =>
        transformedMaxDate &&
        transformedValue.isAfter(transformedMaxDate) && {
          value: false,
          reason: `Selected date must be less than or equal to ${
            dateDescription || dayjs(transformedMaxDate).format('MM/DD/YYYY')
          }`,
        },
      [DateValidationEnum.BETWEEN_INCLUDING]: () =>
        ((transformedMaxDate && transformedValue.isAfter(transformedMaxDate)) ||
          (transformedMinDate && transformedValue.isBefore(transformedMinDate))) && {
          value: false,
          reason: `Selected date must be between ${dayjs(transformedMinDate).format('MM/DD/YYYY')} and ${dayjs(transformedMaxDate).format('MM/DD/YYYY')}`,
        },
    };

    return validationRules[rule]?.() || { value: true, reason: '' };
  };

  return {
    dateFormatter,
    getInitialValue,
    getFormattedToTimezoneStringValue,
    areEqualDates,
    getDateValidationRule,
    timezone,
  };
};
