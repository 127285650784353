import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import {
  ChecklistItemLocal,
  ConfirmOptionEnums,
  DateValidationEnum,
  IWorkflowReason,
  PolicyItemTypesEnum,
  QueryNamesEnums,
  WorkflowReasonEnum,
} from '@interfaces';
import { getWorkflowReasons } from '@globalService';
import {
  getDefaultChecklistItem,
  getReasonsList,
  hasConfirmedCompletionDate,
  isValidDate,
} from '@utils';
import { useDateFieldModel, useStringFieldModel } from '@models';
import { useGraphQuery } from '@context';
import { useQuery } from 'react-query';
import { IFieldsController } from '../interfaces';
import { useInspectionFields, useDayJsFormatter } from '@hooks';

export const useFields = ({
  checklistItems,
  isRejectionResubmit,
}: {
  checklistItems: ChecklistItemLocal[];
  isRejectionResubmit: boolean;
}): IFieldsController => {
  const { getDateValidationRule, getInitialValue, areEqualDates } = useDayJsFormatter();
  const { projectId } = useParams();
  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: [
      'start_date',
      'estimated_start_date',
      'estimated_completion_date',
      'original_completion_date',
    ],
    args: { project_id: projectId },
  });

  const [completionDateReasons, setCompletionDateReasons] = useState([]);

  const metadata = useMemo(
    () =>
      getDefaultChecklistItem(checklistItems, PolicyItemTypesEnum.INSPECTION_ORDERING)?.metadata ??
      {},
    [checklistItems],
  );

  const {
    additionalContactName,
    additionalContactPhone,
    accessCode,
    primaryContactUser,
    primaryContactUserList,
    isBorrowerUsersLoading,
    inspectionRequestedAt,
    isProjectInspectionSettingsUpdated,
    primaryContactPhone,
    isContactListHasUsersWithPhone,
  } = useInspectionFields({
    metadata,
  });

  const reasonsQuery = useQuery<IWorkflowReason[], Error>(
    [QueryNamesEnums.GET_WORKFLOW_REASONS],
    getWorkflowReasons.bind(this),
  );

  const inspectionComment = useStringFieldModel({
    initValue: '',
  });
  const comment = useStringFieldModel({
    initValue: '',
  });
  const completionDateComment = useStringFieldModel({
    initValue: '',
  });
  const [isCompletionDateConfirmed, setCompletionDateConfirmed] = useState('');

  // isInitMetadataUsed is used to prevent the metadata from being used multiple times after each patch
  // and fetching the checklistItems from the server again
  const [isInitMetadataUsed, setIsInitMetadataUsed] = useState(false);

  useEffect(() => {
    if (!isEmpty(checklistItems) && !isInitMetadataUsed) {
      const inspectionChecklistItem = getDefaultChecklistItem(
        checklistItems,
        PolicyItemTypesEnum.INSPECTION_ORDERING,
      );
      const completionDateChecklistItem = getDefaultChecklistItem(
        checklistItems,
        PolicyItemTypesEnum.COMPLETION_DATE_RENEWAL,
      );
      const commentChecklistItem = getDefaultChecklistItem(
        checklistItems,
        PolicyItemTypesEnum.COMMENT,
      );

      if (inspectionChecklistItem?.metadata?.comment)
        inspectionComment.setValue(inspectionChecklistItem.metadata.comment);
      if (commentChecklistItem?.metadata?.comment)
        comment.setValue(commentChecklistItem.metadata.comment);
      if (completionDateChecklistItem?.metadata?.comment)
        completionDateComment.setValue(completionDateChecklistItem.metadata.comment);
      if (completionDateChecklistItem?.metadata?.reasons?.length)
        setCompletionDateReasons(completionDateChecklistItem.metadata.reasons);
      if (completionDateChecklistItem?.metadata?.date_confirmed)
        setCompletionDateConfirmed(completionDateChecklistItem.metadata.date_confirmed);

      setIsInitMetadataUsed(true);
    }
  }, [checklistItems, isInitMetadataUsed]);

  const draftCompletionDate = useMemo(() => {
    const date = getDefaultChecklistItem(
      checklistItems,
      PolicyItemTypesEnum.COMPLETION_DATE_RENEWAL,
    )?.metadata?.date;

    if (date) return getInitialValue({ date });
    return null;
  }, [checklistItems]);

  const startDate = useMemo(() => {
    const date = project.data?.start_date || project.data?.estimated_start_date;
    if (date) return getInitialValue({ date: date as string });
    return null;
  }, [project.data]);

  const currentCompletionDate = useMemo(() => {
    const date = project.data?.estimated_completion_date || project.data?.original_completion_date;
    if (project.isSuccess && !date) {
      setCompletionDateConfirmed(ConfirmOptionEnums.NO);
    }
    if (date) return getInitialValue({ date: date as string });
    return null;
  }, [project.data, project.isSuccess]);

  const completionDate = useDateFieldModel({
    initValue: currentCompletionDate ? getInitialValue({ date: currentCompletionDate }) : null,
    validationRule: (value) =>
      getDateValidationRule({
        value,
        rule: DateValidationEnum.MORE_OR_EQUAL,
        minDate: startDate,
      }),
  });

  useEffect(() => {
    if (draftCompletionDate) completionDate?.setValue(draftCompletionDate);
  }, [draftCompletionDate]);

  useEffect(() => {
    if (draftCompletionDate && isCompletionDateConfirmed === ConfirmOptionEnums.NO)
      completionDate.setValue(draftCompletionDate);
    if (currentCompletionDate && !draftCompletionDate)
      completionDate.setValue(currentCompletionDate);
  }, [currentCompletionDate, draftCompletionDate, isCompletionDateConfirmed]);

  const completionDateReasonsList = useMemo(
    () => getReasonsList(reasonsQuery.data, WorkflowReasonEnum.COMPLETION_DATE_CHANGED),
    [reasonsQuery.data],
  );

  const handleCompletionDateReasonChange = (reasons: string[]) => {
    setCompletionDateReasons(reasons);
  };

  const showCompletionDateReasons = useMemo(
    () =>
      !areEqualDates({ value1: currentCompletionDate, value2: completionDate.value }) &&
      (!draftCompletionDate ||
        !areEqualDates({ value1: draftCompletionDate, value2: currentCompletionDate }) ||
        !areEqualDates({ value1: draftCompletionDate, value2: completionDate.value })),
    [completionDate.value, currentCompletionDate, draftCompletionDate],
  );

  const isCompletionDateChanged = useMemo(
    () => !areEqualDates({ value1: currentCompletionDate, value2: completionDate.value }),
    [completionDate.value, currentCompletionDate],
  );

  useEffect(() => {
    if (
      isInitMetadataUsed &&
      !areEqualDates({
        value1: draftCompletionDate || currentCompletionDate,
        value2: completionDate.value,
      }) &&
      completionDate.value &&
      completionDate.validate()
    )
      setCompletionDateReasons([]);
  }, [completionDate.value, draftCompletionDate, currentCompletionDate, isInitMetadataUsed]);

  const isCompletionDateReasonsValid = useMemo(
    () => !showCompletionDateReasons || Boolean(completionDateReasons.length),
    [completionDateReasons, showCompletionDateReasons],
  );

  const isInspectionValid = useMemo(
    () =>
      (!inspectionRequestedAt.value ||
        (isValidDate(inspectionRequestedAt.value)?.value && inspectionRequestedAt.validate())) &&
      additionalContactPhone?.isValid &&
      Boolean(primaryContactUser?.value?.id) &&
      additionalContactName.isValid,
    [
      inspectionRequestedAt.value,
      additionalContactPhone?.isValid,
      additionalContactName.isValid,
      primaryContactUser?.value,
    ],
  );

  const resetCompletionDate = useCallback(() => {
    completionDate.setValue(currentCompletionDate);
    completionDateComment.setValue('');
    setCompletionDateReasons([]);
  }, [currentCompletionDate]);

  const completionDateValid = useMemo(
    () =>
      (hasConfirmedCompletionDate(isCompletionDateConfirmed) ||
        (isCompletionDateConfirmed === ConfirmOptionEnums.NO &&
          (completionDate.isChanged || isRejectionResubmit))) &&
      (!currentCompletionDate || isCompletionDateReasonsValid) &&
      completionDate.value &&
      completionDate.isValid,
    [
      completionDate.value,
      completionDate.isValid,
      completionDate.isChanged,
      isCompletionDateReasonsValid,
      isCompletionDateConfirmed,
      isRejectionResubmit,
      currentCompletionDate,
    ],
  );

  return {
    inspectionFields: {
      inspectionRequestedAt,
      additionalContactName,
      additionalContactPhone,
      accessCode,
      primaryContactUser,
      primaryContactUserList,
      isBorrowerUsersLoading,
      isProjectInspectionSettingsUpdated,
      primaryContactPhone,
      isContactListHasUsersWithPhone,
    },
    inspectionComment,
    comment,
    completionDate,
    completionDateComment,
    completionDateReasons,
    completionDateReasonsList,
    isCompletionDateConfirmed,
    setCompletionDateConfirmed,
    currentCompletionDate,
    handleCompletionDateReasonChange,
    showCompletionDateReasons,
    isCompletionDateReasonsValid,
    isInspectionValid,
    startDate,
    isCompletionDateChanged,
    isInitMetadataUsed,
    resetCompletionDate,
    completionDateValid,
  };
};
