import { isInspectionService, ColumnType } from '@utils';

const scheduledAt: ColumnType = ({ dateFormatter }) => ({
  field: 'scheduled_at',
  graphFields: ['scheduled_at'],
  headerName: 'Date scheduled',
  minWidth: 80,
  groupable: false,
  aggregable: false,
  renderCell: ({ row }) => {
    if (!isInspectionService(row.service_type)) {
      return null;
    }
    return row.scheduled_at ? dateFormatter({ date: row.scheduled_at }) : '-';
  },
});

export default scheduledAt;
