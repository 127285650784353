import { useMemo } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import { QueryNamesEnums, ErrorDual, ProjectUpdatePayload, EnumTypeForList } from '@interfaces';
import { updateProjectFields } from '@globalService';
import { parsePathErrorDual } from '@utils';
import { useSafeSnackbar, useCoordinator } from '@hooks';
import { useGraphQuery } from '@context';
import { BooleanFieldModel, DropdownFieldModel, useBooleanFieldModel } from '@models';

interface HookInterface {
  isBatchSubmissionEnabled: BooleanFieldModel;
  handleSubmitClick: () => Promise<boolean>;
  isSubmitting: boolean;
  isUpdated: boolean;
  exitPath: string;
  coordinatorField: DropdownFieldModel;
  list: EnumTypeForList[];
  isCoordinatorEditable: boolean;
  isCoordinatorsListLoading: boolean;
  isLoading: boolean;
}

export const useOtherSettings = (): HookInterface => {
  const { enqueueSnackbar } = useSafeSnackbar();
  const { projectId } = useParams();
  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['id', 'is_lump_sum_enabled', 'coordinator'],
    args: { project_id: projectId },
  });

  const {
    isCoordinatorChanged,
    handleAssignCoordinatorInvalidation,
    list,
    coordinatorField,
    isCoordinatorEditable,
    isCoordinatorsListLoading,
  } = useCoordinator({
    projectId,
    initCoordinator: project.data?.coordinator,
  });

  const isBatchSubmissionEnabled = useBooleanFieldModel({
    initValue: project.data?.is_lump_sum_enabled || false,
  });

  // check if project details are updated
  const isOtherSettingsUpdated = useMemo(
    () => isBatchSubmissionEnabled.isChanged || isCoordinatorChanged,
    [isBatchSubmissionEnabled.isChanged, isCoordinatorChanged],
  );

  const projectMutation = useMutation<
    Response,
    ErrorDual,
    { projectId: string; json: ProjectUpdatePayload }
  >(updateProjectFields, {
    onSuccess: () => {
      handleAssignCoordinatorInvalidation({ projectId });
    },
    onError: (error) => {
      enqueueSnackbar(
        parsePathErrorDual(error) || 'Something went wrong while updating project info',
        { variant: 'error' },
      );
    },
  });

  const handleSubmitClick = async () => {
    const projectPayload = {
      ...(isBatchSubmissionEnabled.isChanged
        ? { is_lump_sum_enabled: isBatchSubmissionEnabled.value }
        : {}),
      ...(isCoordinatorChanged ? { coordinator: coordinatorField.value?.id } : {}),
    };
    await projectMutation.mutateAsync({
      projectId,
      json: projectPayload,
    });

    return true;
  };

  const exitPath = useMemo(() => `/projects/${projectId}/overview`, [projectId]);

  return {
    isBatchSubmissionEnabled,
    handleSubmitClick,
    isSubmitting: projectMutation.isLoading,
    isUpdated: isOtherSettingsUpdated,
    exitPath,
    coordinatorField,
    list,
    isCoordinatorEditable,
    isCoordinatorsListLoading,
    isLoading: project.isPostLoading,
  };
};
