import {} from '@constants';
import { IRolePermissions, ITeam, IUser, TeamRolesEnum } from '@interfaces';

export const isCustomer = (user: IUser): boolean => user?.active_team?.company?.is_customer;

export const checkIsLender = (teamRole: string): boolean => teamRole === TeamRolesEnum.LENDER;

export const checkIsOwner = (teamRole: string): boolean => teamRole === TeamRolesEnum.OWNER;

export const checkIsInvestor = (teamRole: string): boolean => teamRole === TeamRolesEnum.INVESTOR;

export const checkIsCustomerSuccess = (teamRole: string): boolean =>
  teamRole === TeamRolesEnum.CUSTOMER_SUCCESS;

export const getBorrower = (teams: ITeam[]) => teams?.find((o) => o.role === TeamRolesEnum.OWNER);

export const getInvestor = (teams: ITeam[]) =>
  teams?.find((o) => o.role === TeamRolesEnum.INVESTOR);

export const getCustomer = (teams: ITeam[]) => teams?.find((o) => o.company?.is_customer);

export const getDisplayRolesList = (roles: IRolePermissions[]) =>
  roles?.map((o) => ({
    id: o.id,
    name_display: o.company_role,
    name: o.company_role,
  })) || [];

export const isCypressUser = (email: string): boolean =>
  email.startsWith('cypress_') && email.endsWith('@trustpoint.ai');
