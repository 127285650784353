import { currencyFormatter, ColumnType } from '@utils';
import { IDrawRequest } from '@interfaces';

const revised_estimate: ColumnType<IDrawRequest> = () => ({
  field: 'revised_estimate',
  graphFields: ['revised_estimate'],
  headerName: 'Revised scheduled value ($)',
  minWidth: 120,
  groupable: false,
  aggregable: false,
  valueFormatter: (value) => currencyFormatter(value, ''),
});

export default revised_estimate;
