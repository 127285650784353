import { useParams } from 'react-router-dom';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  ChangeRequestConfigEnum,
  ChecklistItemLocal,
  IDrawRequest,
  IMilestoneColumn,
  IProjectChecklist,
  PolicyItemTypesEnum,
  QueryNamesEnums,
  TeamRolesEnum,
} from '@interfaces';
import {
  getDrawRequest,
  getDrawRequestItemChecklist,
  getDrawRequestMilestoneListColumns,
} from '@globalService';
import {
  checkIsAllNamesFill,
  checkIsReallocateComplete,
  checkIsResubmit,
  checkIsTableEdit,
  checkRequestedRetainageCorrect,
  emptyHTML,
  getCheckListItemsByRole,
  getDefaultChecklistItem,
  hasModifiedValues,
  hasValidRequestChanges,
  isChangeRequest,
  isDocumentPolicyItem,
  isMilestoneFieldsContainErrors,
  isRequiredDocumentPolicyItem,
  isRequiredPhotoPolicyItem,
  isTextPolicyItem,
  sortAdditionalRequirementsFirst,
} from '@utils';
import isEmpty from 'lodash/isEmpty';
import each from 'lodash/each';
import { useQuery } from 'react-query';
import {
  IChecklistSections,
  IHandleDoneParams,
  ISectionButtonsClick,
  ISections,
  IValidationController,
} from '../interfaces';
import { AuthContext, SettingsContext, useLaunchDarklyFlags } from '@context';
import {
  excludeCommentsWithTotalsAllQueryFields,
  REQUEST_EDITABLE_KEYS,
  SubmissionSectionKeyMap,
  SubmissionSectionTitle,
} from '@constants';
import snakeCase from 'lodash/snakeCase';

export const useValidation = ({
  changeRequestMode,
  completionDateValid,
  isInspectionValid,
  handleDone,
}: {
  changeRequestMode: ChangeRequestConfigEnum;
  completionDateValid: boolean;
  isInspectionValid: boolean;
  handleDone: (params: IHandleDoneParams) => void;
}): IValidationController => {
  const { projectId, requestId, action } = useParams();
  const isAdditionalRequirementResubmit = useMemo(() => checkIsResubmit(action), [action]);
  const isEditTable = useMemo(() => checkIsTableEdit(action), [action]);
  const [isLumpSumTableMode, setIsLumpSumTableMode] = useState(false);
  const flags = useLaunchDarklyFlags();

  const { user } = useContext(AuthContext);
  const { settings } = useContext(SettingsContext);
  const [validatedSection, setValidatedSection] = useState<ISections>(null);
  const [validatedChecklist, setValidatedChecklist] = useState<IChecklistSections>(null);

  const drawRequestData = useQuery<IDrawRequest, Error>(
    [
      QueryNamesEnums.GET_DRAW_REQUEST,
      { projectId, drawRequestId: requestId, query: excludeCommentsWithTotalsAllQueryFields },
    ],
    getDrawRequest.bind(this, {
      projectId,
      drawRequestId: requestId,
      query: excludeCommentsWithTotalsAllQueryFields,
    }),
  );

  const drawRequestMilestonesColumns = useQuery<IMilestoneColumn[], Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES_COLUMNS, { projectId, requestId }],
    getDrawRequestMilestoneListColumns.bind(this, projectId, requestId),
    { enabled: Boolean(projectId && requestId) },
  );

  const checklistQuery = useQuery<IProjectChecklist[], Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST_ITEM_CHECKLIST, { projectId, drawRequestId: requestId }],
    getDrawRequestItemChecklist.bind(this, projectId, requestId),
  );

  const hasDrawRequestErrors = useMemo(() => {
    return (
      isEmpty(drawRequestData.data) ||
      drawRequestMilestonesColumns.data?.some((column) => column.icon_type === 'ERROR')
    );
  }, [drawRequestMilestonesColumns.data, drawRequestData.data]);

  useEffect(() => {
    if (isEditTable && drawRequestData.data?.is_lump_sum_request) setIsLumpSumTableMode(true);
  }, [isEditTable, drawRequestData.data?.is_lump_sum_request]);

  const checklistItems = useMemo(() => {
    if (!checklistQuery.data) return [];
    return getCheckListItemsByRole({
      policies: checklistQuery.data,
      teamRole: TeamRolesEnum.OWNER,
      teamId: user?.active_team?.id,
      itemsCanBeDeleted: false,
    });
  }, [checklistQuery.data]);

  const doneSections = useMemo(() => {
    if (!checklistItems) return;
    const sections = {};
    for (const key in SubmissionSectionKeyMap) {
      sections[SubmissionSectionKeyMap[key]] = getDefaultChecklistItem(
        checklistItems,
        PolicyItemTypesEnum[key],
      )?.checked;
    }
    return sections;
  }, [checklistItems]);

  const isSectionSkipped = (sectionKey) =>
    !doneSections[SubmissionSectionKeyMap[sectionKey]] &&
    Boolean(getDefaultChecklistItem(checklistItems, sectionKey)?.exception_reasons?.length);

  const validateItem = useCallback((item: ChecklistItemLocal) => {
    if (isRequiredDocumentPolicyItem(item.type) || isRequiredPhotoPolicyItem(item.type)) {
      return item.documents?.length > 0;
    }
    if (isTextPolicyItem(item.type)) {
      return item.checked || (Boolean(item.note) && !emptyHTML(item.note));
    }
    return true;
  }, []);

  const updateChecklistItem = useCallback(
    (obj) => {
      const item = checklistItems.find((i) => i.id === obj.checklistItem?.id);
      if (item) {
        obj.checklistItem = item;
        obj.isValid = validateItem(obj.checklistItem);
      }
    },
    [checklistItems],
  );

  useEffect(() => {
    if (isEmpty(validatedChecklist)) {
      const checklistForValidation = checklistItems
        ?.filter((item) => item.is_custom)
        .reduce(
          (acc, item) => ({
            ...acc,
            [snakeCase(item.label)]: {
              checklistItem: item,
              isValid: validateItem(item),
              isDone: item.checked,
              isSkipped: !item.checked && Boolean(item.exception_reasons.length),
              isChecklist: true,
            },
          }),
          {},
        ) as IChecklistSections;
      setValidatedChecklist(checklistForValidation);
    } else {
      setValidatedChecklist((prev) => each(prev, updateChecklistItem));
    }
  }, [checklistItems]);

  const isReallocateComplete = useMemo(
    () => checkIsReallocateComplete(drawRequestData.data),
    [drawRequestData.data],
  );

  useEffect(() => {
    if (isEmpty(validatedSection) && drawRequestData.data && doneSections) {
      const drSections = {
        [SubmissionSectionKeyMap[PolicyItemTypesEnum.DRAW_REQUEST_FORM]]: {
          title: SubmissionSectionTitle[PolicyItemTypesEnum.DRAW_REQUEST_FORM],
          isValid: false,
          isDone:
            isAdditionalRequirementResubmit ||
            doneSections[SubmissionSectionKeyMap[PolicyItemTypesEnum.DRAW_REQUEST_FORM]],
        },
        [SubmissionSectionKeyMap[PolicyItemTypesEnum.INSPECTION_ORDERING]]: {
          title: SubmissionSectionTitle[PolicyItemTypesEnum.INSPECTION_ORDERING],
          isValid: true,
          isDone:
            isAdditionalRequirementResubmit ||
            doneSections[SubmissionSectionKeyMap[PolicyItemTypesEnum.INSPECTION_ORDERING]],
          isSkipped: isSectionSkipped(PolicyItemTypesEnum.INSPECTION_ORDERING),
        },
        [SubmissionSectionKeyMap[PolicyItemTypesEnum.COMPLETION_DATE_RENEWAL]]: {
          title: SubmissionSectionTitle[PolicyItemTypesEnum.COMPLETION_DATE_RENEWAL],
          isValid: completionDateValid,
          isDone:
            isAdditionalRequirementResubmit ||
            doneSections[SubmissionSectionKeyMap[PolicyItemTypesEnum.COMPLETION_DATE_RENEWAL]],
          isSkipped: isSectionSkipped(PolicyItemTypesEnum.COMPLETION_DATE_RENEWAL),
        },
      };
      const crSections = {
        line_items_table: {
          title: SubmissionSectionTitle[PolicyItemTypesEnum.DRAW_REQUEST_FORM],
          isValid: false,
          isDone: false,
        },
      };
      setValidatedSection(() =>
        validateSections(isChangeRequest(drawRequestData.data) ? crSections : drSections),
      );
    }
  }, [drawRequestData.data, settings, doneSections, completionDateValid]);

  useEffect(() => {
    if (isEmpty(validatedSection)) return;
    setValidatedSection((prev) => validateSections(prev));
  }, [
    drawRequestData.data,
    isReallocateComplete,
    completionDateValid,
    isInspectionValid,
    doneSections,
    hasDrawRequestErrors,
    flags,
  ]);

  const validateSections = (sections) => {
    const isDrawValid =
      checkRequestedRetainageCorrect(drawRequestData.data) &&
      checkIsAllNamesFill(drawRequestData.data) &&
      hasModifiedValues(drawRequestData.data) &&
      (!flags?.['ENG_9804_lock_joint_request_based_on_CR_mode'] ||
        hasValidRequestChanges({ drawRequest: drawRequestData.data, changeRequestMode })) &&
      (flags?.['ENG_9511_increase_budget_from_funding_source']
        ? !hasDrawRequestErrors
        : isReallocateComplete) &&
      !isMilestoneFieldsContainErrors(REQUEST_EDITABLE_KEYS, drawRequestData.data);

    return {
      ...sections,
      line_items_table: {
        title: SubmissionSectionTitle[PolicyItemTypesEnum.DRAW_REQUEST_FORM],
        isValid: isDrawValid,
        isDone:
          ((doneSections[SubmissionSectionKeyMap[PolicyItemTypesEnum.DRAW_REQUEST_FORM]] !==
          undefined
            ? doneSections[SubmissionSectionKeyMap[PolicyItemTypesEnum.DRAW_REQUEST_FORM]]
            : sections.line_items_table.isDone) &&
            isDrawValid) ||
          isAdditionalRequirementResubmit,
      },
      ...(sections.inspection && {
        inspection: {
          title: SubmissionSectionTitle[PolicyItemTypesEnum.INSPECTION_ORDERING],
          isValid: isInspectionValid,
          isDone:
            doneSections[SubmissionSectionKeyMap[PolicyItemTypesEnum.INSPECTION_ORDERING]] !==
            undefined
              ? doneSections[SubmissionSectionKeyMap[PolicyItemTypesEnum.INSPECTION_ORDERING]]
              : sections.inspection.isDone && isInspectionValid,
          isSkipped: isSectionSkipped(PolicyItemTypesEnum.INSPECTION_ORDERING),
        },
      }),
      ...(sections.completion && {
        completion: {
          title: SubmissionSectionTitle[PolicyItemTypesEnum.COMPLETION_DATE_RENEWAL],
          isValid: completionDateValid,
          isDone:
            (doneSections[SubmissionSectionKeyMap[PolicyItemTypesEnum.COMPLETION_DATE_RENEWAL]] !==
            undefined
              ? doneSections[SubmissionSectionKeyMap[PolicyItemTypesEnum.COMPLETION_DATE_RENEWAL]]
              : sections.completion.isDone) && completionDateValid,
          isSkipped: isSectionSkipped(PolicyItemTypesEnum.COMPLETION_DATE_RENEWAL),
        },
      }),
    };
  };

  const isAllDone = useMemo(() => {
    if (validatedSection)
      return Object.values({ ...validatedSection, ...validatedChecklist }).every(
        (item) => item?.isDone || item?.isSkipped,
      );
  }, [validatedSection, validatedChecklist]);

  const isAnyItemChanged = useMemo(() => {
    return (
      hasModifiedValues(drawRequestData.data) ||
      checklistItems.some(
        (item) =>
          item.checked ||
          item.documents?.length > 0 ||
          (Boolean(item.note) && !emptyHTML(item.note)),
      ) ||
      Object.values({ ...(validatedSection || []), ...validatedChecklist }).some(
        (item) => item?.isDone || item?.isSkipped,
      )
    );
  }, [validatedSection, validatedChecklist, drawRequestData.data, checklistItems]);

  const undoneSections = useMemo(() => {
    if (!validatedSection || !checklistItems) return [];

    const standardItems = checklistItems.reduce((acc, item) => {
      if (item.is_custom) {
        return acc;
      }
      const section = validatedSection[SubmissionSectionKeyMap[item.type]];
      if (!section?.isDone && !section?.isSkipped) {
        acc.push({
          title: item.label,
          itemRef: item.itemRef,
          id: item.id,
          is_submitted: item.is_submitted,
        });
      }
      return acc;
    }, []);

    const checklist = Object.values(validatedChecklist).reduce((acc, item) => {
      if (item && !item.isDone && !item.isSkipped && item.checklistItem) {
        acc.push({
          title: item.checklistItem.label,
          itemRef: item.checklistItem.itemRef,
          id: item.checklistItem.id,
          is_submitted: item.checklistItem.is_submitted,
        });
      }
      return acc;
    }, []);

    return [...standardItems, ...checklist].sort(sortAdditionalRequirementsFirst);
  }, [validatedSection, validatedChecklist, checklistItems]);

  const onSectionButtonsClick = ({ sectionKey, key, isChecklist }: ISectionButtonsClick) => {
    isChecklist
      ? setValidatedChecklist((prev) => ({
          ...prev,
          [sectionKey]: {
            ...prev[sectionKey],
            [key]: !prev[sectionKey]?.[key],
            ...(!prev[sectionKey]?.isDone && !prev[sectionKey]?.isSkipped ? { isValid: true } : {}),
          },
        }))
      : setValidatedSection((prev) => ({
          ...prev,
          [sectionKey]: { ...prev?.[sectionKey], [key]: !prev?.[sectionKey]?.[key] },
        }));
  };

  const barChartInfo = useMemo(() => {
    const sectionsArr = Object.values({ ...validatedSection, ...validatedChecklist });
    const allSteps = sectionsArr.length;
    let completedSteps = 0;
    sectionsArr.forEach((obj) => {
      if (obj.isSkipped || obj.isDone) {
        completedSteps++;
      }
    });
    const incompletedSteps = allSteps - completedSteps;
    return {
      completedSteps,
      incompletedSteps,
      allSteps,
    };
  }, [validatedSection, validatedChecklist]);

  const handleNextClick = () => {
    setValidatedChecklist((prev) => ({
      ...each(prev, (obj) => {
        obj.highlightBorder = true;
      }),
    }));
    setValidatedSection((prev) => ({
      ...each(prev, (obj) => {
        obj.highlightBorder = true;
      }),
    }));
  };

  const onUploadComplete = (checklistItemId: string) => {
    const checklistItem = checklistItems.find((item) => item.id === checklistItemId);
    if (!checklistItem || !isDocumentPolicyItem(checklistItem?.type)) return;

    const sectionKey = snakeCase(checklistItem.label);
    const validateItem = validatedChecklist[sectionKey];
    if (validateItem.isDone) return;

    if (handleDone) {
      handleDone({
        checklistItemId,
        shouldComplete: true,
      });
    }
    onSectionButtonsClick({ sectionKey, key: 'isDone', isChecklist: true });
  };

  // mark item as undone if all documents are deleted
  const onDocumentDelete = (checklistItemId: string) => {
    const checklistItem = checklistItems.find((item) => item.id === checklistItemId);
    if (!checklistItem || !isDocumentPolicyItem(checklistItem?.type)) return;

    const sectionKey = snakeCase(checklistItem.label);
    const validateItem = validatedChecklist[sectionKey];
    if (checklistItem.documents?.length > 1) return;

    if (validateItem.isDone && handleDone) {
      handleDone({
        checklistItemId,
        shouldComplete: false,
      });
    }
    onSectionButtonsClick({ sectionKey, key: 'isDone', isChecklist: true });
  };

  return {
    validatedSection,
    onSectionButtonsClick,
    isAllDone,
    validatedChecklist,
    barChartInfo,
    isAdditionalRequirementResubmit,
    isEditTable,
    undoneSections,
    handleNextClick,
    onUploadComplete,
    onDocumentDelete,
    isAnyItemChanged,
    isLumpSumTableMode,
    setIsLumpSumTableMode,
    hasDrawRequestErrors,
  };
};
