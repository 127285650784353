import React, { Dispatch, FC, SetStateAction } from 'react';
import { Stack } from '@mui/material';

import {
  IconButtonWithTooltip,
  LabelAndValue,
  LinkRequestToServicePicker,
  StatusChip,
  StyledBox,
  UpdateServiceOrderFieldsPopup,
} from '@components';
import { currencyFormatter, getCostText } from '@utils';
import { useDayJsFormatter } from '@hooks';
import { EditIcon } from '@svgAsComponents';
import { FieldProperties, IServiceOrder, IStatusChip, ServiceTypeEnum } from '@interfaces';

interface ComponentProps {
  serviceOrder: Pick<
    IServiceOrder,
    | 'service_number'
    | 'service_type'
    | 'provider_order_id'
    | 'status'
    | 'cancelled_at'
    | 'cancelled_by'
    | 'ordered_at'
    | 'completed_at'
    | 'provider_status_display'
    | 'provider_service_cost'
    | 'transaction_id'
  > & {
    service_agency: Pick<
      IServiceOrder['service_agency'],
      'display_name' | 'service_provider' | 'id'
    >;
    ordered_by: Pick<IServiceOrder['ordered_by'], 'full_name'>;
    draw_request: Pick<
      IServiceOrder['draw_request'],
      | 'id'
      | 'number'
      | 'submitted_inspection_comment'
      | 'submitted_inspection_requested_at'
      | 'status'
    >;
  };
  fieldsProperties: { [key: string]: FieldProperties };
  statusChipProps: IStatusChip;
  providerStatusChipProps: IStatusChip;
  serviceTypeDisplayName: ServiceTypeEnum;
  editedFieldKey: string | null;
  setEditedFieldKey: Dispatch<SetStateAction<string | null>>;
  handleUpdateServiceOrderField: (fieldKey: string, value: string) => Promise<void>;
  isMutating: boolean;
}

const ServiceSummary: FC<ComponentProps> = ({
  serviceOrder,
  fieldsProperties,
  statusChipProps,
  providerStatusChipProps,
  serviceTypeDisplayName,
  editedFieldKey,
  setEditedFieldKey,
  handleUpdateServiceOrderField,
  isMutating,
}) => {
  const { dateFormatter } = useDayJsFormatter();
  return (
    <Stack spacing={1} direction="row" justifyContent="space-between">
      <StyledBox sx={{ flex: 1 }}>
        <Stack spacing={1}>
          <LabelAndValue label="ID" text={serviceOrder.service_number} />

          <LabelAndValue
            label="Type"
            text={serviceTypeDisplayName}
            textStyle={{ textTransform: 'capitalize' }}
          />
          <LabelAndValue label="Provider" text={serviceOrder.service_agency.display_name} />

          {fieldsProperties['provider_order_id']?.view && (
            <LabelAndValue
              label="External ID"
              text={serviceOrder.provider_order_id || ''}
              icon={
                fieldsProperties['provider_order_id']?.edit && (
                  <IconButtonWithTooltip
                    onClick={() => setEditedFieldKey('provider_order_id')}
                    sx={{ p: 0, pl: 1 }}
                    data-cy="service_results__provider_order_id__edit__icon"
                    tooltipText="Edit"
                  >
                    <EditIcon size={20} />
                  </IconButtonWithTooltip>
                )
              }
              textDataTestName="service_results__provider_order_id__text"
            />
          )}

          <LabelAndValue
            label="Cost"
            text={getCostText(serviceOrder as IServiceOrder)}
            textDataTestName="service_results__cost__text"
          />

          <LabelAndValue
            label="Status"
            icon={
              fieldsProperties['status']?.edit && (
                <IconButtonWithTooltip
                  onClick={() => setEditedFieldKey('status')}
                  sx={{ p: 0, pl: 1 }}
                  data-cy="service_results__status__edit__icon"
                  tooltipText="Edit"
                >
                  <EditIcon size={20} />
                </IconButtonWithTooltip>
              )
            }
          >
            <StatusChip {...statusChipProps} />
          </LabelAndValue>
          {serviceOrder.cancelled_at && (
            <LabelAndValue
              label="Date canceled"
              text={dateFormatter({ date: serviceOrder.cancelled_at })}
            />
          )}
          {serviceOrder.cancelled_by?.full_name && (
            <LabelAndValue label="Canceled by" text={serviceOrder.cancelled_by?.full_name} />
          )}

          <LabelAndValue
            label="Date ordered"
            text={dateFormatter({ date: serviceOrder.ordered_at, withTime: true })}
          />

          {editedFieldKey && (
            <UpdateServiceOrderFieldsPopup
              fieldKey={editedFieldKey}
              setFieldKey={setEditedFieldKey}
              initialValue={
                editedFieldKey === 'provider_status'
                  ? serviceOrder?.provider_status_display
                  : serviceOrder?.[editedFieldKey]
              }
              handleUpdate={handleUpdateServiceOrderField}
              isLoading={isMutating}
              source="service_results__status_edit_modal"
            />
          )}
        </Stack>
      </StyledBox>
      <StyledBox sx={{ flex: 1 }}>
        <Stack spacing={1}>
          {serviceOrder.ordered_by?.full_name && (
            <LabelAndValue label="Ordered by" text={serviceOrder.ordered_by?.full_name} />
          )}
          {serviceOrder.completed_at && (
            <LabelAndValue
              label="Date completed"
              text={dateFormatter({ date: serviceOrder.completed_at, withTime: true })}
            />
          )}

          <LinkRequestToServicePicker
            serviceOrder={serviceOrder as IServiceOrder}
            isEditable={fieldsProperties['draw_request']?.edit}
          />
          <LabelAndValue
            label="Provider status"
            icon={
              fieldsProperties['provider_status']?.edit && (
                <IconButtonWithTooltip
                  onClick={() => setEditedFieldKey('provider_status')}
                  sx={{ p: 0, pl: 1 }}
                  data-cy="service_results__provider_status__edit__icon"
                  tooltipText="Edit"
                >
                  <EditIcon size={20} />
                </IconButtonWithTooltip>
              )
            }
            textDataTestName="service_results__provider_status__text"
          >
            {providerStatusChipProps?.label ? <StatusChip {...providerStatusChipProps} /> : '-'}
          </LabelAndValue>

          {fieldsProperties['provider_service_cost']?.view && (
            <LabelAndValue
              label="Provider cost"
              text={currencyFormatter(serviceOrder.provider_service_cost || 0)}
              icon={
                fieldsProperties['provider_service_cost']?.edit && (
                  <IconButtonWithTooltip
                    onClick={() => setEditedFieldKey('provider_service_cost')}
                    sx={{ p: 0, pl: 1 }}
                    data-cy="service_results__provider_cost__edit__icon"
                    tooltipText="Edit"
                  >
                    <EditIcon size={20} />
                  </IconButtonWithTooltip>
                )
              }
              textDataTestName="service_results__provider_cost__text"
            />
          )}

          {fieldsProperties['transaction_id']?.view && (
            <LabelAndValue
              label="Transaction ID"
              text={serviceOrder.transaction_id || ''}
              icon={
                fieldsProperties['transaction_id']?.edit && (
                  <IconButtonWithTooltip
                    onClick={() => setEditedFieldKey('transaction_id')}
                    sx={{ p: 0, pl: 1 }}
                    data-cy="service_results__transaction_id__edit__icon"
                    tooltipText="Edit"
                  >
                    <EditIcon size={20} />
                  </IconButtonWithTooltip>
                )
              }
              textDataTestName="service_results__transaction_id__text"
            />
          )}
        </Stack>
      </StyledBox>
    </Stack>
  );
};

export default ServiceSummary;
