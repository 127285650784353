import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { IDocument, IProjectDocument, QueryNamesEnums } from '@interfaces';
import { notIncludeChildren, onlyProgressPhotos } from '@constants';
import {
  getMilestoneDocumentsV2,
  getProjectMilestoneDocumentsV2,
  getProjectDocuments,
  getDrawRequestDocumentsList,
} from '@globalService';

export const useDocumentQueries = ({
  projectId,
  drawRequestId,
  item,
  isProjectTab,
  stringQueryParams,
  isRelatedPhotos,
}) => {
  const drawRequestMilestonePhotosQuery = useQuery<{ results: IDocument[]; count: number }, Error>(
    [
      QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE_DOCS,
      {
        projectId,
        drawRequestId,
        milestoneId: item.id,
        query: onlyProgressPhotos,
        stringQueryParams,
      },
    ],
    () =>
      getMilestoneDocumentsV2({
        projectId,
        drawRequestId,
        milestoneId: item.id,
        query: onlyProgressPhotos,
        stringQueryParams,
      }),
    { enabled: false },
  );

  const projectMilestonePhotosQuery = useQuery<{ results: IDocument[]; count: number }, Error>(
    [
      QueryNamesEnums.GET_PROJECT_MILESTONE_DOCS,
      {
        projectId,
        milestoneId: item.id,
        query: onlyProgressPhotos,
        stringQueryParams,
      },
    ],
    getProjectMilestoneDocumentsV2.bind(this, {
      projectId,
      milestoneId: item.id,
      query: onlyProgressPhotos,
      stringQueryParams,
    }),
    { enabled: false },
  );

  const documentQueryParams = useMemo(() => {
    const includeDrawRequestDocuments = isProjectTab ? '&include_draw_request_documents=false' : '';
    return (
      stringQueryParams + onlyProgressPhotos + notIncludeChildren + includeDrawRequestDocuments
    );
  }, [stringQueryParams, isProjectTab]);

  const projectDocumentsQuery = useQuery<{ results: IProjectDocument[]; count: number }, Error>(
    [QueryNamesEnums.GET_PROJECT_DOCUMENTS, { projectId, stringQueryParams: documentQueryParams }],
    getProjectDocuments.bind(this, {
      projectId,
      stringQueryParams: documentQueryParams,
    }),
    { enabled: false },
  );

  const drawRequestDocumentsQuery = useQuery<{ results: IDocument[]; count: number }, Error>(
    [
      QueryNamesEnums.GET_DRAW_REQUEST_DOCUMENTS,
      {
        projectId,
        drawRequestId,
        stringQueryParams: documentQueryParams,
      },
    ],
    getDrawRequestDocumentsList.bind(this, {
      projectId,
      drawRequestId,
      stringQueryParams: documentQueryParams,
    }),
    { enabled: false },
  );

  const currentPhotosQuery = useMemo(() => {
    if (drawRequestId) {
      return isRelatedPhotos ? drawRequestDocumentsQuery : drawRequestMilestonePhotosQuery;
    }
    return isRelatedPhotos ? projectDocumentsQuery : projectMilestonePhotosQuery;
  }, [
    drawRequestMilestonePhotosQuery,
    projectDocumentsQuery,
    projectMilestonePhotosQuery,
    drawRequestDocumentsQuery,
    isRelatedPhotos,
    drawRequestId,
  ]);

  const isLoading = useMemo(() => {
    return isRelatedPhotos
      ? projectDocumentsQuery.isFetching || drawRequestDocumentsQuery.isFetching
      : drawRequestMilestonePhotosQuery.isFetching || projectMilestonePhotosQuery.isFetching;
  }, [
    drawRequestMilestonePhotosQuery.isFetching,
    projectDocumentsQuery.isFetching,
    projectMilestonePhotosQuery.isFetching,
    drawRequestDocumentsQuery.isFetching,
    isRelatedPhotos,
  ]);

  return {
    currentPhotosQuery,
    isLoading,
  };
};
