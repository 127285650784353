import React from 'react';
import { StatusChip } from '@components';
import { ColumnType } from '@utils';
import { statusMap } from '@constants';
import { Tooltip } from '@mui/material';

const projectStatus: ColumnType = ({ settings }) => ({
  field: 'project.status',
  graphNested: {
    project: ['status', 'status_change_reason'],
  },
  headerName: 'Project Status',
  minWidth: 150,
  groupable: false,
  aggregable: false,
  renderCell: ({ row }) => {
    const statusValue = statusMap(row?.project?.status, settings.display, 'project');
    return (
      <Tooltip title={row?.project?.status_change_reason}>
        <span>
          <StatusChip
            color={statusValue.color}
            backgroundColor={statusValue.backgroundColor}
            label={statusValue.text}
          />
        </span>
      </Tooltip>
    );
  },
});

export default projectStatus;
