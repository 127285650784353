import { ErrorDual, ProjectUpdatePayload, QueryNamesEnums, TeamRolesEnum } from '@interfaces';
import { useGraphQuery } from '@context';
import { useDropdownFieldModel } from '@models';
import { getErrorText } from '@utils';
import { updateProjectFields } from '@globalService';
import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useSafeSnackbar } from '@hooks';

export const useAssignInvestorPopup = ({
  projectId,
  onClose,
}: {
  projectId: string;
  onClose: () => void;
}) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();
  const investorField = useDropdownFieldModel({
    initValue: null,
  });

  const { data: investorData, isLoading } = useGraphQuery({
    type: QueryNamesEnums.GET_COMPANIES_BY_ROLE,
    keys: ['id', 'name'],
    args: {
      role: TeamRolesEnum.INVESTOR,
    },
  });

  const invetorsList = (investorData?.results || []).map((item) => ({
    id: item.id,
    value: item.id,
    name: item.name,
    name_display: item.name,
  }));

  const projectMutation = useMutation<
    Response,
    ErrorDual,
    { projectId: string; json: ProjectUpdatePayload }
  >(updateProjectFields, {
    onSuccess: () => {
      enqueueSnackbar('Investor assigned successfully', { variant: 'success' });
      onClose();
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECTS_LIST]);
    },
    onError: (error) => {
      projectMutation.reset();
      const errorText = getErrorText(error as ErrorDual);
      enqueueSnackbar(errorText, { variant: 'error' });
    },
  });

  const inviteUserCompany = useCallback(async () => {
    const projectData = {
      companies: [
        {
          role: TeamRolesEnum.INVESTOR,
          id: investorField.value.id,
          name: investorField.value.name,
        },
      ],
    } as Partial<ProjectUpdatePayload>;

    projectMutation.mutateAsync({
      projectId,
      json: {
        ...projectData,
      },
    });
  }, [projectMutation, investorField]);

  return {
    isLoading,
    investorField,
    invetorsList,
    inviteUserCompany,
    isMutating: projectMutation.isLoading,
  };
};
