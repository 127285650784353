import React, { useContext, useEffect, useMemo } from 'react';
import { useQueries, useQuery } from 'react-query';
import {
  DocumentTypeEnum,
  HookState,
  IDynamicColumns,
  IProjectDocument,
  IProjectProperty,
  MilestoneTagsTypesEnums,
  QueryNamesEnums,
  TableKeyEnum,
} from '@interfaces';
import {
  getProjectBuildings,
  getProjectDocuments,
  getProjectMilestonesList,
  getProjectMilestoneTags,
  getProjectModels,
} from '@globalService';
import { useNavigate, useParams } from 'react-router-dom';
import { getHookState, isCreatedProject } from '@utils';
import { LineItemFilterValues, PHBColumnsForCSV, REGEX } from '@constants';
import { SettingsContext, useGraphQuery } from '@context';

export type ControllerInterface = {
  state: HookState;
  isTableFilled: boolean;
  isChooseNamingModalShown: boolean;
  setIsChooseNamingModalShown: React.Dispatch<React.SetStateAction<boolean>>;
  modelUnitSectionClick: () => void;
  verticalCostCSVUrl: string;
  horizontalMilestonesCount: number;
  verticalMilestonesCount: number;
  showTable: boolean;
  isBudgetEditable: boolean;
  isChooseBudgetViewModalShown: boolean;
  setIsChooseBudgetViewModalShown: React.Dispatch<React.SetStateAction<boolean>>;
  dynamicColumns: IDynamicColumns[];
  isCreatingByModelsFilled: boolean;
  hasUnfilledName: boolean;
  isUploadingVerticalDisabled: boolean;
  isUploadingHorizontalDisabled: boolean;
  showBudgetUploadButtons: boolean;
};

export const useBudgetCreation = (): ControllerInterface => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const { settings } = useContext(SettingsContext);
  const [isChooseNamingModalShown, setIsChooseNamingModalShown] = React.useState(false);
  const [isChooseBudgetViewModalShown, setIsChooseBudgetViewModalShown] = React.useState(false);

  const query = '{}';
  const limit = '1';

  const requestedDataQueries = useQueries([
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_BUILDING, { projectId }],
      queryFn: getProjectBuildings.bind(this, projectId),
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_BUILDING_MODELS, { projectId }],
      queryFn: getProjectModels.bind(this, projectId),
    },
    {
      queryKey: [
        QueryNamesEnums.GET_PROJECT_MILESTONES,
        { projectId, query, filterKey: LineItemFilterValues.HORIZONTAL_SOFT_COST.filterKey, limit },
      ],
      queryFn: getProjectMilestonesList.bind(this, {
        projectId,
        query,
        filterKey: LineItemFilterValues.HORIZONTAL_SOFT_COST.filterKey,
        limit,
      }),
    },
    {
      queryKey: [
        QueryNamesEnums.GET_PROJECT_MILESTONES,
        { projectId, query, filterKey: LineItemFilterValues.VERTICAL_COST.filterKey, limit },
      ],
      queryFn: getProjectMilestonesList.bind(this, {
        projectId,
        query,
        filterKey: LineItemFilterValues.VERTICAL_COST.filterKey,
        limit,
      }),
    },

    {
      queryKey: [QueryNamesEnums.GET_PROJECT_MILESTONE_TAGS, { projectId }],
      queryFn: getProjectMilestoneTags.bind(this, projectId),
    },
  ]);

  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['status'],
    args: { project_id: projectId },
    options: {
      skip: !projectId,
    },
  });

  const stringQueryParams = `&document_type=${DocumentTypeEnum.PROJECT_IMPORT_TEMPLATE}`;
  const projectDocumentsQuery = useQuery<{ results: IProjectDocument[]; count: number }, Error>(
    [QueryNamesEnums.GET_PROJECT_DOCUMENTS, { projectId, stringQueryParams }],
    getProjectDocuments.bind(this, { projectId, stringQueryParams }),
  );

  const projectBuildingsData = requestedDataQueries[0].data;
  const projectModelsData = requestedDataQueries[1].data;
  const horizontalMilestonesCount = requestedDataQueries[2].data?.count;
  const verticalMilestonesCount = requestedDataQueries[3].data?.count;
  const projectDocuments = projectDocumentsQuery.data?.results;
  const projectMilestoneTags = requestedDataQueries[4].data?.results;

  const dynamicColumns = useMemo(() => {
    const arr =
      projectModelsData?.results?.length > 0
        ? projectModelsData?.results
        : projectBuildingsData?.results;
    if (!arr) return [];

    const resultArray = [];
    arr.forEach((model) => {
      PHBColumnsForCSV.forEach((field) => {
        const columnName = `${model.name} / ${field}`;
        resultArray.push({
          column_name: columnName,
          display_label: columnName,
          type: 'regex',
          validators: { expression: REGEX.positiveCurrency },
        });
      });
    });

    return resultArray;
  }, [projectModelsData?.results, projectBuildingsData?.results]);

  const isTableFilled = useMemo(
    () => Boolean(projectBuildingsData?.count) || Boolean(projectModelsData?.count),
    [projectBuildingsData, projectModelsData],
  );

  const hasEmptyName = (data: IProjectProperty[]) => data.find((item) => !item.name);

  const hasUnfilledName = useMemo(
    () =>
      Boolean(projectBuildingsData?.count && hasEmptyName(projectBuildingsData.results)) ||
      Boolean(projectModelsData?.count && hasEmptyName(projectModelsData.results)),
    [projectBuildingsData, projectModelsData],
  );

  const isCreatingByModelsFilled = useMemo(
    () => isTableFilled && Boolean(projectModelsData?.count),
    [isTableFilled, projectModelsData],
  );

  const modelUnitSectionClick = () => {
    if (!isTableFilled) return setIsChooseNamingModalShown(true);
    if (isCreatingByModelsFilled) navigate(`edit-models`);
    else navigate(`edit-units`);
  };

  useEffect(() => {
    if (
      projectMilestoneTags?.length &&
      projectMilestoneTags.find(
        ({ name, type }) => type === MilestoneTagsTypesEnums.LINE_ITEM && !name,
      )
    )
      setIsChooseNamingModalShown(true);
  }, [projectMilestoneTags]);

  const verticalCostCSVUrl = useMemo(() => {
    if (!projectDocuments) return '';
    return projectDocuments?.find((item) => item.name === 'CSVBoxSample.csv')?.link;
  }, [projectDocuments]);

  const showTable = useMemo(
    () => Boolean(isTableFilled && (verticalMilestonesCount || horizontalMilestonesCount)),
    [isTableFilled, verticalMilestonesCount, horizontalMilestonesCount],
  );

  useEffect(() => {
    if (
      showTable &&
      settings.personal_setting?.tables?.[TableKeyEnum.PHB_LINE_ITEMS]?.['groups'] &&
      !settings.personal_setting?.tables?.[TableKeyEnum.PHB_LINE_ITEMS]?.['groups']?.line_items
    ) {
      setIsChooseBudgetViewModalShown(true);
    }
  }, [showTable, settings.personal_setting]);

  const isBudgetEditable = useMemo(
    () => isCreatedProject(project.data?.status),
    [project.data?.status],
  );

  const isUploadingVerticalDisabled = useMemo(
    () =>
      !isBudgetEditable ||
      requestedDataQueries[4].isFetching ||
      !isTableFilled ||
      hasUnfilledName ||
      Boolean(verticalMilestonesCount),
    [
      requestedDataQueries,
      isBudgetEditable,
      isTableFilled,
      hasUnfilledName,
      verticalMilestonesCount,
    ],
  );

  const isUploadingHorizontalDisabled = useMemo(
    () =>
      !isBudgetEditable || !isTableFilled || hasUnfilledName || Boolean(horizontalMilestonesCount),
    [isBudgetEditable, isTableFilled, hasUnfilledName, horizontalMilestonesCount],
  );

  const showBudgetUploadButtons = useMemo(() => isBudgetEditable, [isBudgetEditable]);

  return {
    state: getHookState([...requestedDataQueries, project]),
    isTableFilled,
    isChooseNamingModalShown,
    setIsChooseNamingModalShown,
    modelUnitSectionClick,
    verticalCostCSVUrl,
    horizontalMilestonesCount,
    verticalMilestonesCount,
    showTable,
    isBudgetEditable,
    isChooseBudgetViewModalShown,
    setIsChooseBudgetViewModalShown,
    dynamicColumns,
    isCreatingByModelsFilled,
    hasUnfilledName,
    isUploadingVerticalDisabled,
    isUploadingHorizontalDisabled,
    showBudgetUploadButtons,
  };
};
