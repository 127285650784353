import { useContext, useMemo } from 'react';
import { useMutation } from 'react-query';

import { QueryNamesEnums, IServiceOrderPayload, IServiceOrder } from '@interfaces';
import { patchServiceOrderToProject, deleteServiceOrder } from '@globalService';
import { useGraphQuery, SettingsContext } from '@context';
import { useSafeSnackbar, useServiceOrderStatusInvalidation } from '@hooks';

export const useServiceQueriesAndMutations = ({ projectId, serviceOrderId }) => {
  const { settings } = useContext(SettingsContext);
  const { enqueueSnackbar } = useSafeSnackbar();
  const handleServiceOrderChangeInvalidation = useServiceOrderStatusInvalidation();

  const serviceTypesMap = useMemo(
    () => settings?.display?.service_types,
    [settings?.display?.service_types],
  );

  // const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);

  const deleteServiceOrderMutation = useMutation<
    Response,
    Error,
    { projectId: string; serviceOrderId: string }
  >(deleteServiceOrder, {
    onSuccess: () => {
      enqueueSnackbar('Service order deleted successfully', { variant: 'success' });
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const serviceOrderQuery = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT_SERVICE_ORDER_BY_ID,
    args: { project_id: projectId, service_order_id: serviceOrderId },
    keys: [
      'id',
      'invoiced_amount',
      'status',
      'service_number',
      'provider_order_id',
      'provider_service_cost',
      'ordered_at',
      'completed_at',
      'cancelled_at',
      'provider_status_display',
      'transaction_id',
      'comment',
      'result_documents',
      'comments_preview',
      'service_type',
      'documents_preview',
      'is_cancellable',
      'cancelled_by',
      'totals',
      'is_single_value',
      'inspector_allowance',
    ],
    nested: {
      service_agency: ['display_name', 'service_provider', 'id'],
      // cancelled_by: ['full_name'],
      ordered_by: ['full_name'],
      draw_request: [
        'id',
        'number',
        'submitted_inspection_comment',
        'submitted_inspection_requested_at',
        'status',
      ],
      project: ['id', 'status', 'name'],
    },
  });

  const patchServiceOrder = useMutation<
    IServiceOrder,
    Error,
    { projectId: string; serviceOrderId: string; json: IServiceOrderPayload }
  >(patchServiceOrderToProject, {
    onError: (error, variables) => {
      enqueueSnackbar(error.message, { variant: 'error' });
      handleServiceOrderChangeInvalidation({
        projectId,
        serviceOrderId,
        drawRequestId: variables?.json?.draw_request,
      });
    },
  });

  return {
    patchServiceOrder,
    deleteServiceOrderMutation,
    // isSuccessModalOpen,
    serviceOrder: serviceOrderQuery,
    serviceTypesMap,
  };
};
