import { currencyFormatter, isAllowed, ColumnType } from '@utils';
import { PermissionNamesEnums } from '@interfaces';

const providerCost: ColumnType = () => ({
  field: 'provider_service_cost',
  graphFields: ['provider_service_cost'],
  headerName: 'Provider cost',
  minWidth: 80,
  groupable: false,
  aggregable: false,
  renderCell: ({ row }) => {
    return row.provider_service_cost ? currencyFormatter(row.provider_service_cost) : '-';
  },
  enableByPermissions: (permissions) =>
    isAllowed(PermissionNamesEnums.CUSTOMER_SUCCESS_ACCESS, permissions),
});

export default providerCost;
