import { IDrawRequest } from '@interfaces';
import { ColumnType } from '@utils';
import React from 'react';

const project_type: ColumnType<IDrawRequest> = () => ({
  field: 'project.type',
  renderCell: ({ row }: { row: Partial<IDrawRequest> }) => (
    <span data-cy="requests_queue__table__body__project_type">{row.project?.type}</span>
  ),
  graphFields: [],
  graphNested: {
    project: ['type'],
  },
  headerName: 'Type',
  minWidth: 100,
  groupable: false,
  sortable: false,
  filterable: false,
  aggregable: false,
});

export default project_type;
