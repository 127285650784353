import { useCallback, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext, PermissionsContext, SettingsContext, useLaunchDarklyFlags } from '@context';
import {
  ColumnWidth,
  IDrawRequest,
  IRequestsColumn,
  PermissionNamesEnums,
  TableKeyEnum,
} from '@interfaces';
import {
  checkIsCustomerSuccess,
  checkIsInvestor,
  checkIsLender,
  checkIsOwner,
  getLink,
  getTeamRole,
  isAllowed,
  tableHeaders as headers,
} from '@utils';
import { IRightDrawerParams } from './interface';
import { useCommentsAndDocumentsPreview, useRightMenu, useDayJsFormatter } from '@hooks';
import { excludeRequestColumns, requestColumnToCSVMap } from '@constants';
import { FiltersTypes } from '@components';

export const useDrawChangeRequestTab = ({ isLoading, formattedData, hiddenColumns }) => {
  const { user } = useContext(AuthContext);
  const { permissions } = useContext(PermissionsContext);
  const teamRole = getTeamRole(user);
  const {
    settings: { display: staticStatuses },
  } = useContext(SettingsContext);
  const { timezone } = useDayJsFormatter();
  const navigate = useNavigate();
  const flags = useLaunchDarklyFlags();

  const isLender = useMemo(() => checkIsLender(teamRole), [teamRole]);
  const isInvestor = useMemo(() => checkIsInvestor(teamRole), [teamRole]);
  const isOwner = useMemo(() => checkIsOwner(teamRole), [teamRole]);
  const isCustomerSuccess = useMemo(() => checkIsCustomerSuccess(teamRole), [teamRole]);

  const [coordinatorPopupOpen, setCoordinatorPopupOpen] = useState<boolean>(false);
  const [approverPopupOpen, setApproverPopupOpen] = useState<boolean>(false);
  const [currentRequest, setCurrentRequest] = useState<IDrawRequest>();
  const [isAddRequestPopupOpen, setIsAddRequestPopupOpen] = useState<boolean>(false);

  const [rightDrawerParams, setRightDrawerParams] = useState<IRightDrawerParams>({});
  const { updateCommentsPreviewInfo } = useCommentsAndDocumentsPreview({
    projectId: rightDrawerParams.projectId,
    drawRequestId: rightDrawerParams.requestId,
  });

  const onRightDrawerClose = useCallback(() => {
    updateCommentsPreviewInfo();
    setRightDrawerParams((old) => ({ ...old, activeTab: '' }));
  }, [updateCommentsPreviewInfo]);

  const { handleRightDrawerOpenerClick, ...rightMenu } = useRightMenu({
    onClose: onRightDrawerClose,
  });

  const updateRightDrawer = ({
    title,
    projectId,
    requestId,
    activeTab,
  }: {
    title: string;
    projectId: string;
    requestId: string;
    activeTab: string;
  }) => {
    handleRightDrawerOpenerClick({ title });
    setRightDrawerParams({ projectId, requestId, activeTab });
  };

  const updateAssignCoordinatorPopup = (request: IDrawRequest) => {
    setCurrentRequest(request);
    setCoordinatorPopupOpen(true);
  };
  const updateApproverPopup = (request: IDrawRequest) => {
    setCurrentRequest(request);
    setApproverPopupOpen(true);
  };

  const handleRowClick = (row) => {
    const link = getLink({ row, tableKey: TableKeyEnum.REQUESTS, teamRole });
    link && navigate(link);
  };

  const columns = useMemo(
    () => [
      headers.type({ isLoading }),
      headers.description({
        header: 'Request',
        isLoading,
        source: 'requests_queue__table__body__description',
      }),
      headers.statusWithRole({ header: 'Request status', isLoading }),
      headers.headerTemplate({
        headerName: 'Loan #',
        accessor: 'project.loan.external_id',
        isLoading,
        disableSortBy: true,
        dataTestName: 'requests_queue__table__body__loan_number',
      }),
      headers.projectName({
        isLoading,
        minWidth: ColumnWidth.WIDE_TEXT,
        source: 'requests_queue__table__body__project_name',
      }),
      headers.headerTemplate({
        headerName: 'Address',
        accessor: 'project.address.address_1',
        isLoading,
        disableSortBy: true,
      }),
      headers.headerTemplate({
        headerName: 'City',
        accessor: 'project.address.city',
        isLoading,
        disableSortBy: true,
      }),
      headers.headerTemplate({
        headerName: 'State',
        accessor: 'project.address.state',
        isLoading,
        disableSortBy: true,
      }),
      headers.headerTemplate({
        headerName: 'Project type',
        accessor: 'project.type',
        isLoading,
        disableSortBy: true,
      }),
      ...(!isAllowed(PermissionNamesEnums.DRAWREQUESTS_COORDINATOR_VIEW, permissions)
        ? []
        : [
            headers.coordinator({
              isLoading,
              updateAssignCoordinatorPopup,
              source: 'requests_queue',
            }),
          ]),
      ...(!isAllowed(PermissionNamesEnums.DRAWREQUESTS_REVIEWER_TEAM_VIEW, permissions)
        ? []
        : [headers.approvalTeam({ isLoading, updateApproverPopup })]),
      headers.projectStatus({
        isLoading,
        accessor: 'project.status',
        disableSortBy: true,
      }),
      ...(isAllowed(PermissionNamesEnums.CUSTOMER_SUCCESS_ACCESS, permissions)
        ? [
            headers.headerTemplate({
              headerName: 'Customer',
              accessor: 'customer.name',
              isLoading,
              disableSortBy: true,
            }),
          ]
        : []),
      ...(isAllowed(PermissionNamesEnums.INVESTOR_VIEW, permissions)
        ? [
            headers.headerTemplate({
              headerName: 'Investor',
              accessor: 'project.investor_name',
              isLoading,
              disableSortBy: true,
            }),
          ]
        : []),
      ...(isInvestor || isCustomerSuccess
        ? [
            headers.headerTemplate({
              headerName: 'Lender',
              accessor: 'project.lender_name',
              isLoading,
              disableSortBy: true,
            }),
          ]
        : []),
      ...(isLender || isInvestor || isCustomerSuccess
        ? [
            headers.headerTemplate({
              headerName: 'Borrower',
              accessor: 'project.borrower_name',
              isLoading,
              disableSortBy: true,
            }),
          ]
        : []),
      headers.amount({
        header: 'Requested amount',
        accessor: 'requested_amount',
        isLoading,
        disableSortBy: true,
      }),
      headers.amount({
        header: 'Revised scheduled value ($)',
        accessor: 'revised_estimate',
        isLoading,
        minWidth: ColumnWidth.WIDE_TEXT,
      }),
      headers.date({
        header: 'Submitted date',
        accessor: 'submitted_at',
        isLoading,
        withTime: true,
        timezone,
      }),
      headers.headerTemplate({
        headerName: 'Weekdays since submitted',
        accessor: 'days_since_submitted',
        isLoading,
        minWidth: ColumnWidth.TEXT,
        placeholder: '-',
        tooltipTitle:
          'The time from submittal to disbursement of draw or decision on the change request.',
      }),
      ...(isAllowed(PermissionNamesEnums.INSPECTIONS_VIEW, permissions)
        ? [
            headers.serviceStatus({
              Header: 'Inspection status',
              isLoading,
              accessor: 'inspection.status',
            }),
          ]
        : []),
      ...(isAllowed(PermissionNamesEnums.INSPECTIONS_VIEW, permissions)
        ? [
            headers.headerTemplate({
              headerName: 'Inspection provider status',
              accessor: 'inspection.provider_status_display',
              isLoading,
              disableSortBy: true,
            }),
          ]
        : []),
      ...(isAllowed(PermissionNamesEnums.INSPECTIONS_VIEW, permissions)
        ? [
            headers.headerTemplate({
              headerName: 'Inspection agency',
              accessor: 'inspection.inspection_agency.display_name',
              isLoading,
            }),
            headers.headerTemplate({
              headerName: 'Weekdays waiting for inspection',
              accessor: 'inspection.days_waiting_for_inspection',
              isLoading,
              minWidth: ColumnWidth.TEXT,
              placeholder: '-',
            }),
            headers.percentage({
              header: 'Inspector allowance (%)',
              accessor: 'inspector_allowance_rate',
              isLoading,
              disableSortBy: true,
            }),
          ]
        : []),
      headers.percentage({
        header: 'Lender allowance (%)',
        accessor: 'lender_allowance_rate',
        isLoading,
        disableSortBy: true,
      }),
      headers.amount({
        header: 'Approved amount',
        accessor: 'approved_amount',
        isLoading,
        disableSortBy: true,
      }),
      ...(!isAllowed(PermissionNamesEnums.INSPECTIONS_VIEW, permissions)
        ? []
        : [
            headers.percentage({
              header: 'Variance to lender allowance (%)',
              accessor: 'variance_to_lender_allowance_rate',
              isLoading,
              disableSortBy: true,
            }),
          ]),
      headers.date({
        header: 'Transaction date',
        accessor: 'disbursed_at',
        isLoading,
      }),
      ...(isAllowed(PermissionNamesEnums.SCORES_VIEW, permissions)
        ? [
            headers.projectScore({
              headerName: 'Project health',
              accessor: 'project.overall_score',
              isLoading,
              disableSortBy: false,
            }),
          ]
        : []),
      headers.requestActions({
        isLoading,
        updateRightDrawer,
        source: 'requests_queue__table__body__actions',
        showChecklist: isAllowed(PermissionNamesEnums.POLICIES_VIEW, permissions),
        isCommentsAvailable: isAllowed(PermissionNamesEnums.COMMENTS_VIEW, permissions),
      }),
    ],
    [hiddenColumns, staticStatuses, formattedData, isLoading, permissions, timezone],
  );

  const csvHeaders = useMemo(
    () =>
      columns
        .filter((column: IRequestsColumn) => !excludeRequestColumns.includes(column.accessor))
        .map((column: IRequestsColumn) => ({
          label: column.headerName || column.Header,
          key: requestColumnToCSVMap[column.accessor] || column.accessor,
        })),
    [columns],
  );

  const filters = useMemo<FiltersTypes[]>(
    () =>
      [
        'textSearchFilter',
        'isOnHoldFilter',
        'requestStatusFilter',
        'currentReviewerTeamFilter',
        ...(flags?.['ENG_10222_inactive_projects_visibility']
          ? ['projectStatusFilterV2']
          : ['projectStatusFilter']),
        'inspectionStatusFilter',
        'lenderFilter',
        'borrowerFilter',
        'coordinatorRequestFilter',
        'projectHealthFilter',
        'customerFilter',
        'investorFilter',
      ] as FiltersTypes[],
    [flags?.['ENG_10222_inactive_projects_visibility']],
  );

  return {
    approverPopupOpen,
    columns,
    coordinatorPopupOpen,
    currentRequest,
    handleRowClick,
    setCoordinatorPopupOpen,
    setApproverPopupOpen,
    rightDrawerParams,
    rightMenu,
    csvHeaders,
    isOwner,
    isAddRequestPopupOpen,
    setIsAddRequestPopupOpen,
    filters,
  };
};
