import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  HookState,
  IProjectFundingSource,
  IPieChartData,
  IProjectProgressItem,
  QueryNamesEnums,
  FundingSourceInternalIdEnums,
} from '@interfaces';
import { getProjectFundingSources } from '@globalService';
import {
  calculateFraction,
  currencyFormatter,
  fundingSourceColor,
  getFundingSource,
  getHookState,
  percentFormatter,
} from '@utils';
import { colors } from '@theme';

interface ControllerInterface {
  state: HookState;
  projectProgressItems: {
    originalScheduledValue: Partial<IProjectProgressItem>;
  };
  getOriginalValuePieChartData: () => IPieChartData[];
  projectFundingSources: IProjectFundingSource[];
  borrowerEquityFraction: number;
  constructionHoldbackFraction: number;
}

export const useProjectPaymentData = (): ControllerInterface => {
  const { projectId } = useParams();

  const projectFundingSourcesQuery = useQuery<{ results: IProjectFundingSource[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_FUNDING_SOURCES, { projectId }],
    getProjectFundingSources.bind(this, projectId),
    { enabled: Boolean(projectId) },
  );

  const projectFundingSources = useMemo(
    () =>
      projectFundingSourcesQuery.data?.results
        ?.filter((fundingSource) => fundingSource.is_active)
        .sort((a, b) => a.index - b.index)
        .map((fundingSource) => ({
          // TODO: remove this once the backend is migrated
          ...fundingSource,
          color: fundingSourceColor(fundingSource),
        })),
    [projectFundingSourcesQuery.data],
  );

  const constructionHoldbackFS = useMemo(
    () =>
      getFundingSource({
        fundingSources: projectFundingSources,
        internalId: FundingSourceInternalIdEnums.CONSTRUCTION_HOLDBACK,
      }),
    [projectFundingSources],
  );

  const borrowerEquityFS = useMemo(
    () =>
      getFundingSource({
        fundingSources: projectFundingSources,
        internalId: FundingSourceInternalIdEnums.BORROWER_EQUITY,
      }),
    [projectFundingSources],
  );

  const scheduledValueTotal = useMemo(
    () => borrowerEquityFS?.total + constructionHoldbackFS?.total,
    [constructionHoldbackFS?.total, borrowerEquityFS?.total],
  );

  const projectProgressItems = useMemo(() => {
    const afterValue =
      (borrowerEquityFS?.funded_after_latest_request || 0) +
      (constructionHoldbackFS?.funded_after_latest_request || 0);
    const beforeValue =
      (borrowerEquityFS?.funded_before_latest_request || 0) +
      (constructionHoldbackFS?.funded_before_latest_request || 0);
    const before = calculateFraction(beforeValue, scheduledValueTotal);
    const after = calculateFraction(afterValue, scheduledValueTotal);
    return {
      originalScheduledValue: {
        afterValue,
        after,
        beforeValue,
        before,
        total: scheduledValueTotal,
        contributedText: `${currencyFormatter(afterValue)} / ${percentFormatter({
          value: after,
          roundTo: 2,
        })}`,
        remainingText: `${currencyFormatter(scheduledValueTotal - afterValue)} / ${percentFormatter(
          {
            value: 100 - after,
            roundTo: 2,
          },
        )}`,
        barValues: [afterValue],
      },
    };
  }, [borrowerEquityFS, constructionHoldbackFS, scheduledValueTotal]);

  const borrowerEquityFraction = useMemo(
    () => calculateFraction(borrowerEquityFS?.total, scheduledValueTotal),
    [borrowerEquityFS?.total, scheduledValueTotal],
  );

  const constructionHoldbackFraction = useMemo(
    () => calculateFraction(constructionHoldbackFS?.total, scheduledValueTotal),
    [constructionHoldbackFS?.total, scheduledValueTotal],
  );

  const getOriginalValuePieChartData = useCallback(() => {
    if (!projectFundingSources) return [];
    const totalAmount = projectFundingSources.reduce(
      (acc, fundingSource) => acc + +fundingSource.total,
      0,
    );
    return projectFundingSources.map((fundingSource) => {
      const fundingSourceFraction = calculateFraction(+fundingSource.total, totalAmount);
      const fundingSourceValues = `${percentFormatter({ value: fundingSourceFraction })} / ${currencyFormatter(+fundingSource.total)}`;
      return {
        label: `${fundingSource.name} (${fundingSourceValues})`,
        color: colors.fundingSource[fundingSourceColor(fundingSource)].medium,
        value: +fundingSource.total,
      };
    });
  }, [projectFundingSources]);

  return {
    state: getHookState(projectFundingSourcesQuery),
    projectProgressItems,
    getOriginalValuePieChartData,
    projectFundingSources,
    borrowerEquityFraction,
    constructionHoldbackFraction,
  };
};
