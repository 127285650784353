import { HookState } from '@interfaces';
import { Box } from '@mui/material';
import React, { FC } from 'react';
import { useReport } from './controller';
import { StartPage } from './pages/startPage';
import { ProjectAndDrawInfoV2 } from './pages/projectAndDrawInfo_2';
import { LineItems } from './pages/lineItems';
import { SitePhotos } from './pages/sitePhotos';
import { Documents } from './pages/documents';
import { AuditLog } from './pages/auditLog';
import { InspectionReport } from './pages/inspectionReport';
import { DrawsLog } from './pages/drawsLog';
import { Comments } from './pages/comments';
import { Policies } from './pages/policies';
import { ChangeLog } from './pages/changeLog';
import { LaunchdarklyProvider, PermissionsProvider, SettingsProvider } from '@context';
import { Wrapper } from './common';

const ReportBody: FC = () => {
  const controller = useReport();
  if (controller.state === HookState.LOADING) return null;

  return (
    <Wrapper>
      <Box ref={controller.printRef}>
        <StartPage controller={controller} />
        <ProjectAndDrawInfoV2 controller={controller} />
        <InspectionReport controller={controller} />
        <Comments controller={controller} />
        <Documents controller={controller} />
        <LineItems controller={controller} />
        <DrawsLog controller={controller} />
        <ChangeLog controller={controller} />
        <Policies controller={controller} />
        <SitePhotos controller={controller} />
        <AuditLog controller={controller} />
      </Box>
    </Wrapper>
  );
};

const Report: FC<{ setShowPrint?: React.Dispatch<React.SetStateAction<boolean>> }> = () => (
  <LaunchdarklyProvider>
    <SettingsProvider>
      <PermissionsProvider>
        <ReportBody />
      </PermissionsProvider>
    </SettingsProvider>
  </LaunchdarklyProvider>
);

export default Report;
