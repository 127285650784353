import React, { FC, memo, useCallback, useContext, useMemo } from 'react';
import { Button, Skeleton, Stack, Typography } from '@mui/material';
import {
  approximationEqual,
  calculateRequestedAdjustment,
  checkIsCustomerSuccess,
  currencyFormatter,
  getMilestoneGroupsTagsIds,
  getTeamRole,
  isAllowed,
  useBlockerCell,
  useBlockerFooter,
} from '@utils';
import { ColumnLabel, HeaderText, MilestoneListColumnType } from './common';
import { TableContext } from '../controller';
import { ColumnV2Width, CommonRowType, IMilestoneTotal, PermissionNamesEnums } from '@interfaces';
import { AuthContext, PermissionsContext } from '@context';
import { useDebounceInput, useStringFieldModel } from '@models';
import { TextInputWithTooltip } from '@components';

const RequestedRevisedEstimate: FC<{ row: CommonRowType }> = ({ row }) => {
  const { permissions } = useContext(PermissionsContext);
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const {
    apiUpdate,
    getLineItemError,
    highLightCellByData,
    resetMutation,
    updateRequestedChanges,
  } = useContext(TableContext);
  const error = getLineItemError(row.id, 'requested_revised_estimate');
  const tags = useMemo(() => getMilestoneGroupsTagsIds(row.tags), [row.tags]);

  const requested_revised_estimate = useStringFieldModel({
    initError: error,
    initValue: (row['requested_revised_estimate'] || '').toString(),
    withProgressCheck: true,
    blockNavigationKey: 'requested_revised_estimate',
  });
  const isBlocked = useBlockerCell({
    milestoneId: row.id,
    key: 'requested_revised_estimate',
    tags,
  });

  const isEditable = useMemo(
    () =>
      (row.localIsUserCreator || row.isSubmission) &&
      row.activeToEdit &&
      (isAllowed(PermissionNamesEnums.DRAWREQUESTS_CREATE, permissions) ||
        isAllowed(PermissionNamesEnums.DRAWREQUESTS_MANUAL_CREATE, permissions) ||
        checkIsCustomerSuccess(teamRole)),
    [permissions, row],
  );

  const effect = useCallback(
    (props) =>
      apiUpdate({
        milestoneId: row.id,
        value: requested_revised_estimate.floatValue,
        needToCompare: false,
        isEqualInit: approximationEqual(
          row.requested_revised_estimate,
          requested_revised_estimate.floatValue,
        ),
        name: 'requested_revised_estimate',
        ...props,
      }),
    [requested_revised_estimate.floatValue, row.requested_revised_estimate],
  );

  const { onBlur, onChange } = useDebounceInput({
    effect,
    handleChange: requested_revised_estimate.handleChange,
    resetter: () => resetMutation({ milestone: row.id, json: { requested_revised_estimate: 0 } }),
    afterEffect: () => requested_revised_estimate.changeNavigationBlockContext(false),
  });

  const getTooltipActionButtons = () => (
    <Stack direction="row" justifyContent="flex-end" mt={2} sx={{ width: '100%' }} spacing={2}>
      <Button
        variant="text"
        size="small"
        onClick={() => {
          const clearedValue = row.revised_estimate?.toString() || '';
          requested_revised_estimate.setValue(clearedValue);
          effect({ value: clearedValue });
        }}
      >
        Clear input
      </Button>
      <Button
        variant="new"
        size="small"
        color="secondary"
        onClick={() => {
          const requestedAdjustmentValue = calculateRequestedAdjustment(row, +row.requested_amount);
          effect({
            value: requestedAdjustmentValue,
            name: 'requested_adjustments',
            isEqualInit: false,
          });
          if (updateRequestedChanges) {
            updateRequestedChanges({
              value: requestedAdjustmentValue,
              requestedAmount: row.requested_amount,
              milestoneId: row.id,
              key: 'requested_adjustments',
            });
          }
          highLightCellByData(row.id, 'requested_adjustments');
          highLightCellByData(row.id, 'requested_revised_estimate');
        }}
      >
        Increase change
      </Button>

      <Button
        variant="new"
        size="small"
        color="secondary"
        onClick={() => {
          effect({
            value: +row.balance_to_finish,
            name: 'requested_amount',
            isEqualInit: false,
          });
          highLightCellByData(row.id, 'requested_amount');
        }}
      >
        Adjust draw
      </Button>
    </Stack>
  );

  if (isBlocked) return <Skeleton width={'100%'} />;

  return isEditable ? (
    <TextInputWithTooltip
      onBlur={onBlur}
      value={requested_revised_estimate.value}
      onChange={onChange}
      error={Boolean(error)}
      type="currency"
      tooltipText={row.disabled?.reason}
      disabled={row.disabled?.value}
      errorTip={error}
      hasBorder={requested_revised_estimate.hasBorder}
      tooltipActionButtons={getTooltipActionButtons()}
      tooltipMinWidth="400px"
      textDisabled={row.revised_estimate === +requested_revised_estimate.value}
    />
  ) : (
    <Typography variant="body3">
      {currencyFormatter(row.requested_revised_estimate, '-')}
    </Typography>
  );
};

const Memoized = memo(RequestedRevisedEstimate);

const Footer: FC<{ row: IMilestoneTotal }> = ({ row }) => {
  const isBlocked = useBlockerFooter();

  if (isBlocked) return <Skeleton width={'100%'} />;
  return (
    <Typography variant="body3SemiBold" data-cy="milestone_row_requested_scheduled_value_total">
      {currencyFormatter(row.requested_revised_estimate)}
    </Typography>
  );
};

const requestedRevisedEstimate: MilestoneListColumnType = {
  name: 'requested_revised_estimate',
  columnText: 'Requested scheduled value ($)',
  initDataField: 'requested_revised_estimate',
  isEditable: (permissions) =>
    isAllowed(PermissionNamesEnums.DRAWREQUESTS_CREATE, permissions) ||
    isAllowed(PermissionNamesEnums.DRAWREQUESTS_MANUAL_CREATE, permissions),
  renderCell: ({ row }) => <Memoized row={row} />,
  Footer: (row) => <Footer row={row} />,
  minWidth: (activeToEdit = false) =>
    activeToEdit ? ColumnV2Width.INPUT : ColumnV2Width.NUMBER_MIN,
  maxWidth: (activeToEdit = false) =>
    activeToEdit ? ColumnV2Width.INPUT : ColumnV2Width.NUMBER_MAX,
  Header: (
    <HeaderText tooltipText="Proposed total value for a line item">
      <ColumnLabel>Requested</ColumnLabel>
      <ColumnLabel> scheduled value($)</ColumnLabel>
    </HeaderText>
  ),
};

export default requestedRevisedEstimate;
