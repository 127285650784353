import React, { FC, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';
import {
  MessagePanelTabsEnum,
  PermissionNamesEnums,
  PolicyItemTypesEnum,
  TableKeyEnum,
} from '@interfaces';
import {
  ButtonWithTooltip,
  Filter,
  LineItemEdit,
  LineItemEditV2,
  MessagePanel,
  MessagePanelButton,
  MilestoneListWithPatch,
  ProjectNameLabel,
  RightDrawer,
  StyledBox,
} from '@components';
import SubmissionSummary from '../SubmissionSummary';
import { getDefaultChecklistItem, isAllowed, WithPermission, currencyFormatter } from '@utils';
import {
  IChecklistController,
  IMilestoneList,
  IRequestController,
  IValidationController,
} from '../../main/interfaces';
import { colors } from '@theme';
import SubmissionAutofill from '../SubmissionAutofill';
import { PermissionsContext, SettingsContext, useLaunchDarklyFlags } from '@context';
import { ProductionRequestTableV2 } from '../../../productionBuild';
import { BLOCKED_POLICY_REASON, SubmissionSectionKeyMap } from '@constants';
import { FillTableImage } from '@svgAsComponents';

const MilestoneListModal: FC<{
  controller: IMilestoneList;
  checklistController: IChecklistController;
  validationController: IValidationController;
  requestController: IRequestController;
  isReallocationAllowed: boolean;
}> = ({
  controller,
  checklistController,
  validationController,
  isReallocationAllowed,
  requestController,
}) => {
  const { permissions } = useContext(PermissionsContext);
  const { isPHBProject } = useContext(SettingsContext);
  const navigate = useNavigate();
  const {
    lineItemModal,
    setLineItemModal,
    isReallocateComplete,
    openEditMilestoneModal,
    filterValue,
    handleFiltersChange,
    filterOptions,
    isMilestoneMutatingOrFetching,
  } = controller;
  const { checklistItems, handleDone } = checklistController;
  const {
    onSectionButtonsClick,
    validatedSection,
    isLumpSumTableMode,
    setIsLumpSumTableMode,
    hasDrawRequestErrors,
  } = validationController;
  const {
    project: projectData,
    drawRequest,
    handleRightDrawerOpenerClick,
    rightMenu,
    isLumpSumEnabled,
    setOpenLumpSumModal,
  } = requestController;
  const isWaiting = isMilestoneMutatingOrFetching;
  const run = useCallback(
    () => navigate(`/projects/${projectData?.id}/requests/${drawRequest?.id}/submission/`),
    [navigate, projectData, drawRequest],
  );

  const checklistItem = getDefaultChecklistItem(
    checklistItems,
    PolicyItemTypesEnum.DRAW_REQUEST_FORM,
  );
  const sectionKey = SubmissionSectionKeyMap[PolicyItemTypesEnum.DRAW_REQUEST_FORM];

  const markAsDone = useCallback(() => {
    run();
    if (checklistItem) {
      handleDone({
        checklistItemId: checklistItem.id,
        shouldComplete: !validatedSection?.[sectionKey]?.isDone,
      });
    }
    onSectionButtonsClick({ sectionKey, key: 'isDone' });
  }, [run, checklistItem, validatedSection, sectionKey, onSectionButtonsClick]);

  const disabled = !validatedSection?.[sectionKey]?.isValid;
  const flags = useLaunchDarklyFlags();
  return (
    <Stack sx={{ height: '100%' }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 3 }}>
        <Typography variant="h1">Request amounts</Typography>
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <ProjectNameLabel />
          <MessagePanelButton
            commentsPreview={projectData?.comments_preview}
            handleRightDrawerOpenerClick={handleRightDrawerOpenerClick}
            source="request_submission__edit_request_table"
          />
        </Stack>
        <RightDrawer {...rightMenu}>
          <MessagePanel
            projectId={projectData?.id}
            requestId={drawRequest?.id}
            source={'project__comments'}
            tab={MessagePanelTabsEnum.BORROWER}
          />
        </RightDrawer>
      </Stack>

      <Stack
        sx={{
          backgroundColor: colors.background.gray,
          flex: 1,
          p: 3,
          mb: 11,
        }}
      >
        <Stack spacing={1} width="100%" sx={{ flex: 1 }}>
          <SubmissionSummary
            retainageRate={projectData?.retainage_rate}
            projectLoan={projectData?.loan}
            request={drawRequest}
            isDrawRequest={controller.isDrawRequest}
            isReallocateComplete={isReallocateComplete}
            isReallocationAllowed={isReallocationAllowed}
            hasDrawRequestErrors={hasDrawRequestErrors}
            canBeCollapsed
          />
          {isLumpSumTableMode && (
            <Stack sx={{ flex: 1 }}>
              <StyledBox
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flex: 1,
                }}
              >
                <Stack alignItems="center" justifyContent="center" spacing={5}>
                  <FillTableImage width={318} />
                  <Typography variant="h2">{`Lump sum for this request = ${currencyFormatter(
                    drawRequest.requested_amount,
                  )}.`}</Typography>
                  <Stack direction="row" justifyContent="flex-end" spacing={2}>
                    <Button
                      variant="new"
                      color="secondary"
                      onClick={() => setOpenLumpSumModal(true)}
                      data-cy="request_submission__lump_sum__manage__button"
                    >
                      Manage lump sum
                    </Button>
                    <Button
                      variant="new"
                      color="secondary"
                      onClick={() => setIsLumpSumTableMode(false)}
                      data-cy="request_submission__lump_sum__enter_amounts__button"
                    >
                      Enter amounts
                    </Button>
                  </Stack>
                </Stack>
              </StyledBox>
            </Stack>
          )}
          {!isLumpSumTableMode && !isPHBProject && (
            <StyledBox sx={{ p: 0 }}>
              <MilestoneListWithPatch
                containerSpacing={{
                  top: 60,
                  bottom: 100,
                }}
                requestStatus={drawRequest?.status}
                responsiveColumns
                withColumnIndication
                tableKey={TableKeyEnum.SUBMISSION}
                withProgress
                headerLeftPart={[
                  {
                    Component: (
                      <Filter
                        filterLabel="Show"
                        onChangeCallback={handleFiltersChange}
                        options={filterOptions}
                        filterValue={filterValue}
                        disabled={isMilestoneMutatingOrFetching}
                        source="request_submission__edit_request__line_items_table__filter"
                      />
                    ),
                  },
                ]}
                headerRightPart={[
                  {
                    Component: (
                      <Stack flexDirection="row">
                        {isReallocationAllowed && (
                          <WithPermission
                            permissionKey={PermissionNamesEnums.DRAWREQUESTS_LINE_ITEM_CREATE}
                          >
                            <Button
                              size="small"
                              variant="new"
                              color="secondary"
                              onClick={controller.addLineList}
                              disabled={!controller.isEditable}
                            >
                              Add line item
                            </Button>
                          </WithPermission>
                        )}
                        {isLumpSumEnabled && (
                          <Button
                            size="small"
                            variant="new"
                            color="secondary"
                            sx={{ ml: 1 }}
                            onClick={() => setOpenLumpSumModal(true)}
                          >
                            Enter lump sum
                          </Button>
                        )}
                        {controller.isDrawRequest &&
                          (Boolean(projectData?.retainage_rate) ||
                            isAllowed(
                              PermissionNamesEnums.DRAWREQUESTS_AUTOFILL_INSPECTOR_ALLOWANCE_REQUESTED,
                              permissions,
                            )) && (
                            <SubmissionAutofill
                              drawRequestId={drawRequest?.id}
                              retainageRate={projectData?.retainage_rate}
                            />
                          )}
                      </Stack>
                    ),
                  },
                ]}
                requestId={drawRequest?.id}
                refetch={controller.refetch}
                initColumns={controller.initColumns}
                milestones={controller.filteredMilestones}
                totals={controller.totals}
                contingencyMode={drawRequest?.contingency_mode}
                openEditMilestoneModal={openEditMilestoneModal}
                source="request__submission__line_items_table"
              />
            </StyledBox>
          )}
          {!isLumpSumTableMode && isPHBProject && (
            <ProductionRequestTableV2
              isSubmissionProcess
              lumpSumSuccess={() => setIsLumpSumTableMode(true)}
            />
          )}
        </Stack>
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          position: 'fixed',
          bottom: 0,
          right: 0,
          p: 3,
          width: '100%',
          backgroundColor: colors.background.white,
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
          zIndex: 1100,
        }}
      >
        <Button
          variant="text"
          loading={isWaiting}
          onClick={run}
          data-cy="close_edit_request_table_button"
        >
          Close editing
        </Button>
        <ButtonWithTooltip
          tooltipText={disabled ? BLOCKED_POLICY_REASON[PolicyItemTypesEnum.DRAW_REQUEST_FORM] : ''}
          onClick={markAsDone}
          disabled={disabled}
          dataTestName={`request__submission__${sectionKey}__mark_as_done__button`}
        >
          Mark as done
        </ButtonWithTooltip>
      </Stack>
      {lineItemModal?.open &&
        (flags?.['ENG_9827_table_part_13'] ? (
          <LineItemEditV2
            modalConfig={lineItemModal}
            setLineItemModal={setLineItemModal}
            request={drawRequest}
          />
        ) : (
          <LineItemEdit
            lineItem={lineItemModal?.lineItem}
            open={lineItemModal?.open}
            setLineItemModal={setLineItemModal}
            type={lineItemModal?.type}
            request={drawRequest}
          />
        ))}
    </Stack>
  );
};

export default MilestoneListModal;
