import React, { FC } from 'react';
import { ManualServiceIconProps } from './interface';

const ManualServiceIcon: FC<ManualServiceIconProps> = ({ size = 24, color = 'currentColor' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.85327 3C2.85327 2.44772 3.30099 2 3.85327 2H7.85327C8.40556 2 8.85327 2.44772 8.85327 3C8.85327 3.55228 8.40556 4 7.85327 4H4.85327V7C4.85327 7.55228 4.40556 8 3.85327 8C3.30099 8 2.85327 7.55228 2.85327 7V3ZM12.8533 13H12.7104C11.1325 13 9.85327 14.2792 9.85327 15.8571V17C9.85327 17.5523 9.40556 18 8.85327 18C8.30099 18 7.85327 17.5523 7.85327 17V15.8571C7.85327 14.1725 8.71093 12.6882 10.0134 11.817C9.29628 11.094 8.85327 10.0988 8.85327 9C8.85327 6.79086 10.6441 5 12.8533 5C15.0624 5 16.8533 6.79086 16.8533 9C16.8533 10.0988 16.4103 11.094 15.6931 11.817C16.9956 12.6882 17.8533 14.1725 17.8533 15.8571V17C17.8533 17.5523 17.4056 18 16.8533 18C16.301 18 15.8533 17.5523 15.8533 17V15.8571C15.8533 14.2792 14.5741 13 12.9961 13H12.8533ZM12.8533 11C11.7487 11 10.8533 10.1046 10.8533 9C10.8533 7.89543 11.7487 7 12.8533 7C13.9578 7 14.8533 7.89543 14.8533 9C14.8533 10.1046 13.9578 11 12.8533 11ZM21.8533 2C22.4056 2 22.8533 2.44772 22.8533 3V7C22.8533 7.55228 22.4056 8 21.8533 8C21.301 8 20.8533 7.55228 20.8533 7V4H17.8533C17.301 4 16.8533 3.55228 16.8533 3C16.8533 2.44772 17.301 2 17.8533 2H21.8533ZM2.85327 21C2.85327 21.5523 3.30099 22 3.85327 22H7.85327C8.40556 22 8.85327 21.5523 8.85327 21C8.85327 20.4477 8.40556 20 7.85327 20H4.85327V17C4.85327 16.4477 4.40556 16 3.85327 16C3.30099 16 2.85327 16.4477 2.85327 17V21ZM21.8533 22C22.4056 22 22.8533 21.5523 22.8533 21V17C22.8533 16.4477 22.4056 16 21.8533 16C21.301 16 20.8533 16.4477 20.8533 17V20H17.8533C17.301 20 16.8533 20.4477 16.8533 21C16.8533 21.5523 17.301 22 17.8533 22H21.8533Z"
        fill={color}
      />
    </svg>
  );
};

export default ManualServiceIcon;
