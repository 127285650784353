import { useContext } from 'react';
import find from 'lodash/find';

import { getHookState, Override } from '@utils';
import { QueryNamesEnums, HookState } from '@interfaces';
import { SettingsContext, useGraphQuery } from '@context';

export type ControllerInterface = Override<
  ReturnType<typeof useProject>,
  {
    state: HookState;
    servicingStatus: string;
    loanType: string;
  }
>;

export const useProject = (projectId: string) => {
  const { settings } = useContext(SettingsContext);
  const { loan_servicing_statuses, loan_types, interest_methods } = settings?.display || {};

  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: [
      'estimated_start_date',
      'start_date',
      'original_completion_date',
      'estimated_completion_date',
      'retainage_rate',
    ],
    nested: {
      loan: [
        'external_id',
        'servicing_status',
        'type',
        'interest_method',
        'type',
        'funding_date',
        'loc_commitment',
        'construction_holdback',
        'total_construction_budget',
        'post_funding_construction_budget',
        'borrower_equity',
        'contingency',
        'maturity_date',
        'extended_maturity_date',
      ],
    },
    args: { project_id: projectId },
  });

  return {
    project: project.data,
    state: getHookState(project),
    servicingStatus: find(loan_servicing_statuses, {
      name: project.data?.loan?.servicing_status,
    })?.name_display,
    loanType: find(loan_types, {
      name: project.data?.loan?.type,
    })?.name_display,
    interestMethodDisplay: find(interest_methods, {
      name: project.data?.loan?.interest_method,
    })?.name_display,
  };
};
