import { IDrawRequest, PermissionNamesEnums } from '@interfaces';
import React from 'react';
import { isAllowed, ColumnType } from '@utils';

const customer_name: ColumnType<IDrawRequest> = () => ({
  field: 'customer.name',
  renderCell: ({ row }: { row: Partial<IDrawRequest> }) => (
    <span data-cy="requests_queue__table__body__customer_name">{row.customer?.name}</span>
  ),
  graphFields: [],
  graphNested: {
    customer: ['name'],
  },
  enableByPermissions: (permissions) =>
    isAllowed(PermissionNamesEnums.CUSTOMER_SUCCESS_ACCESS, permissions),
  headerName: 'Customer',
  minWidth: 120,
  groupable: false,
  sortable: false,
  filterable: false,
  aggregable: false,
});

export default customer_name;
