import {
  ChecklistItemLocal,
  FundingSourceInternalIdEnums,
  InitPatchField,
  IProjectChecklist,
  IProjectChecklistHistoryItem,
  IProjectFundingSource,
  ItemStatusesEnum,
  IUser,
  TPolicies,
  TeamRolesEnum,
} from '@interfaces';
import {
  getInspectionName,
  getServiceTag,
  isChangeRequest,
  isHistoryActionCreate,
  isHistoryActionUpdate,
  isLocalhost,
  isPolicyItemCompleted,
  isQAEnvironment,
  isServiceProviderAutomatedByCS,
} from '@utils';
import isEmpty from 'lodash/isEmpty';
import truncate from 'lodash/truncate';
import { currencyFormatter, percentFormatter } from './formators';
import { useDayJsFormatter } from '@hooks';

export const getTruncateText = (text, length, omission = ' ') => {
  return truncate(text, {
    length,
    separator: ' ',
    omission,
  });
};

export const getOptionLabel = (formate) => (option) => {
  if (typeof option === 'string') return option;
  if (typeof formate === 'function') return formate(option);
  return '----';
};

export const getDeepIsEmpty = (data: unknown) =>
  Array.isArray(data) && !isEmpty(data) ? data.every(getDeepIsEmpty) : isEmpty(data);

export const getRoundNumber = (number: number, precision = 0): number => {
  if (!number) return 0;
  const factor = 10 ** precision;
  const tempNumber = number * factor;
  const roundedTempNumber = Math.round(tempNumber);
  return roundedTempNumber / factor;
};

export const getEllipsisStyle = (maxWidth: string | number) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth,
});

export const getTeamRole = (user: IUser): TeamRolesEnum => user?.active_team?.role;

export const getAddress = (address) => {
  if (!address) return null;
  return [
    address?.address_1,
    address?.address_2,
    address?.city,
    address?.state,
    address?.country,
    address?.zip_code,
  ]
    .filter(Boolean)
    .join(', ');
};

export const equalNumbOrString = (a: string, b: string) =>
  isNaN(+a) || isNaN(+b) ? a === b : approximationEqual(+a, +b);

export const approximationEqual = (a: number, b: number) =>
  getRoundNumber(a, 2) === getRoundNumber(b, 2);

export const getTextOrDash = (amount: number, percent?: number) => {
  if (!amount && !percent) return '-';
  return `${currencyFormatter(amount, '')} ${
    percent
      ? `/ ${percentFormatter({
          value: percent,
          roundTo: 0,
        })}`
      : ''
  }`;
};

export const getProtectedPassword = () =>
  isQAEnvironment ||
  isLocalhost ||
  navigator.userAgent.includes('Chrome') ||
  navigator.userAgent.includes('Safari')
    ? {}
    : {
        inputProps: {
          readOnly: true,
          autoComplete: 'off',
          onFocus: (e) => {
            e.target.removeAttribute('readonly');
          },
          onBlur: (e) => {
            e.target.setAttribute('readonly', true);
          },
        },
      };

export const userIsAllowedToLogin = ({ user }): boolean => {
  const { email_is_verified } = user || {};
  return Boolean(email_is_verified);
};

export const getInitFromMilestone = (data: InitPatchField) =>
  data ? (typeof data === 'object' && data?.key ? data.key.toString() : data.toString()) : '';

export const getUserFullName = (user: { first_name?: string; last_name?: string }) =>
  `${user?.first_name || ''} ${user?.last_name || ''}`;

export const getUserFullNameOrEmail = (user: {
  first_name?: string;
  last_name?: string;
  email?: string;
}) => {
  const fullName = getUserFullName(user).trim();
  return fullName || user?.email;
};

interface GetPoliciesFnProps {
  policies: IProjectChecklist[];
  teamRole: TeamRolesEnum;
  teamId?: string;
  itemsCanBeDeleted?: boolean;
}
export const getCheckListItemsByRole = ({
  policies,
  teamRole,
  teamId,
  itemsCanBeDeleted,
}: GetPoliciesFnProps): ChecklistItemLocal[] => {
  const checklist = policies?.find((x) => x.assignee_role === teamRole);
  return (
    checklist?.items.map(
      ({
        id,
        name,
        status,
        history,
        created_by_team,
        documents,
        exception_reasons,
        note,
        is_submitted,
        metadata,
        index,
        type,
        is_custom,
        description,
      }) => ({
        id,
        label: name,
        canBeDeleted: created_by_team && (itemsCanBeDeleted || created_by_team === teamId),
        created_by_team,
        checked: isPolicyItemCompleted(status),
        documents,
        exception_reasons,
        note,
        checkedBy: {
          name: getUserFullName(history?.[0]?.updated_by_user),
          updatedAt: history?.[0]?.updated_at,
        },
        historyEvents: history,
        is_submitted,
        metadata,
        index,
        type,
        is_custom,
        description,
      }),
    ) || []
  );
};

export const getChecklistIdByRole = (
  checklists: IProjectChecklist[],
  teamRole: TeamRolesEnum,
): string => {
  const checklist = checklists?.find((x) => x.assignee_role === teamRole);
  return checklist?.id;
};

export const getPoliciesByRole = ({
  policies,
  teamRole,
  teamId,
  itemsCanBeDeleted,
}: GetPoliciesFnProps): TPolicies => {
  // get policies list for the user by assignee_role
  const checklist = policies?.find((x) => x.assignee_role === teamRole);
  // serialize policies list for policies tab view, return id and items array
  return {
    items:
      checklist?.items?.map(
        ({
          id,
          name,
          status,
          history,
          exception_reasons,
          created_by_team,
          documents,
          note,
          is_submitted,
          metadata,
          type,
          is_custom,
          description,
        }) => ({
          id,
          label: name,
          canBeDeleted: itemsCanBeDeleted || created_by_team === teamId,
          created_by_team,
          checked: isPolicyItemCompleted(status),
          checkedBy: {
            name: getUserFullName(history?.[0]?.updated_by_user),
            updatedAt: history?.[0]?.updated_at,
          },
          historyEvents: history,
          exception_reasons,
          documents,
          note,
          is_submitted,
          metadata,
          type,
          is_custom,
          description,
        }),
      ) || [],
    id: checklist?.id,
    name: checklist?.name,
    is_default: checklist?.is_default,
    template: checklist?.template,
  };
};

export const getPoliciesListByRole = ({ policies, teamRole }: GetPoliciesFnProps): TPolicies[] => {
  // get policies lists for the user by assignee_role
  const templateList = policies?.filter((x) => x.assignee_role === teamRole);
  // serialize policies lists for policies tab view, return id and items array
  return templateList.map((templ) => ({
    ...templ,
    items:
      templ.items?.map(({ id, name, status, metadata, type, is_custom, description }) => ({
        id,
        label: name,
        checked: isPolicyItemCompleted(status),
        metadata,
        type,
        is_custom,
        description,
      })) || [],
  }));
};

export const getTagsList = ({
  request,
  milestone,
  inspection,
  serviceOrder,
  documentId,
  isAutomatedServiceProvider,
  serviceType,
  dateFormatter,
}) => {
  const requestTag = request?.id
    ? `${isChangeRequest(request) ? 'Change' : 'Draw'} #${request?.number}`
    : null;
  const inspectionTag = inspection
    ? getInspectionName({ serviceOrder: inspection, dateFormatter })
    : null;
  const serviceOrderTag = serviceOrder
    ? getServiceTag({ serviceOrder, isTag: true, serviceType, dateFormatter })
    : null;
  const milestoneTag = milestone?.id ? `Line item: ${milestone?.name || ''}` : null;
  const projectTag = request ? null : 'Project';
  const documentTag = documentId ? 'Document' : null;
  const automatedInspectionTag = isAutomatedServiceProvider
    ? inspection?.inspection_agency?.display_name
    : null;
  const automatedByCSServiceOrderTag = isServiceProviderAutomatedByCS(
    serviceOrder?.service_agency?.service_provider,
  )
    ? serviceOrder.service_agency?.display_name
    : null;

  return [
    projectTag,
    requestTag,
    inspectionTag,
    serviceOrderTag,
    automatedInspectionTag,
    automatedByCSServiceOrderTag,
    milestoneTag,
    documentTag,
  ].filter(Boolean);
};

export const getReasonText = (reasons: string[], comment: string) => {
  if (reasons?.length === 0 && !comment) return '';
  if (reasons?.length === 0 && comment) return `Comment: ${comment}`;
  const combinedReasons = ` ${reasons?.join(',')}.`;
  return `${combinedReasons}${comment ? ' Comment: ' + comment : ''}`;
};

export const getChecklistEditedByCompany = (historyEvents: IProjectChecklistHistoryItem[]) => {
  const { dateFormatter } = useDayJsFormatter();
  const editingEvent = historyEvents?.find(
    (event) =>
      event.status === ItemStatusesEnum.NOT_STARTED &&
      (isHistoryActionCreate(event.action) || isHistoryActionUpdate(event.action)),
  );
  return `Policy item ${isHistoryActionCreate(editingEvent?.action) ? 'added' : 'updated'} by ${
    editingEvent?.updated_by_user?.company?.name
  } at ${dateFormatter({
    date: editingEvent?.updated_at,
  })}`;
};

export const fundingSourceColor = (fundingSource: IProjectFundingSource) => {
  if (fundingSource.internal_identifier === FundingSourceInternalIdEnums.PREFUNDING_COST) {
    return 'green';
  }
  if (fundingSource.internal_identifier === FundingSourceInternalIdEnums.CONSTRUCTION_HOLDBACK) {
    return 'blue';
  }
  if (fundingSource.internal_identifier === FundingSourceInternalIdEnums.BORROWER_EQUITY) {
    return 'orange';
  }
  if (fundingSource.is_transactable) {
    return 'violet';
  }
  return 'yellow';
};
