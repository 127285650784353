import { getPaginationString, getParamString } from '@utils';
import { IApiPath } from '@interfaces';
import {
  ArgumentsProjectsCompaniesApi,
  ArgumentsProjectsUserApi,
  ArgumentsProjectsTeamsApi,
  ArgumentsProjectApi,
  ArgumentsServiceAgenciesApi,
} from '../../args';

// TODO think about sorting
export const projectsDrawRequestsApi = ({ pagination, ...params }: IApiPath) =>
  `projects/draw_requests/?${getParamString(params)}${getPaginationString(pagination)}`;

export const projectsCompaniesApi = ({ pagination, ...params }: ArgumentsProjectsCompaniesApi) =>
  `projects/companies/?sorting=name&${getParamString(params)}${getPaginationString(pagination)}`;

export const projectsUsersApi = ({ pagination, ...params }: ArgumentsProjectsUserApi) =>
  `projects/users/?sorting=first_name&${getParamString(params)}${getPaginationString(pagination)}`;

export const projectsTeamsApi = ({ pagination, ...params }: ArgumentsProjectsTeamsApi) =>
  `projects/teams/?sorting=name&${getParamString(params)}${getPaginationString(pagination)}`;

export const projectsInspectionAgenciesApi = ({ pagination, ...params }: IApiPath) =>
  `projects/inspections/agencies/?${getParamString(params)}${getPaginationString(pagination)}`;

export const projectsServiceAgenciesApi = ({
  pagination,
  service_type,
  ...params
}: ArgumentsServiceAgenciesApi) =>
  `projects/service_orders/agencies/?limit=100&service_type=${service_type}&${getParamString(params)}${getPaginationString(pagination)}`;

export const projectsTypesApi = ({ pagination, ...params }: IApiPath) =>
  `projects/types/?${getParamString(params)}${getPaginationString(pagination)}`;

export const projectsDocumentTypesApi = ({ pagination, ...params }: IApiPath) =>
  `documents/project/document_types/?${getParamString(params)}${getPaginationString(pagination)}`;

export const projectsRolesApi = ({ pagination, project_id, ...params }: ArgumentsProjectApi) =>
  `projects/${project_id}/roles/?${getParamString(params)}${getPaginationString(pagination)}`;

export const projectApi = ({ pagination, project_id, ...params }: ArgumentsProjectApi) =>
  `projects/${project_id}/?${getParamString(params)}`;

export const projectsServiceOrdersApi = ({ pagination, ...params }: IApiPath) =>
  `projects/service_orders/?${getParamString(params)}${getPaginationString(pagination)}`;
