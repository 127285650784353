import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext, PermissionsContext } from '@context';
import {
  checkIsOwner,
  getTeamRole,
  isAllowed,
  parsePathErrorDual,
  regexValidation,
  replaceObjectProperties,
} from '@utils';
import {
  PhoneFieldModel,
  StringFieldModel,
  usePhoneFieldModel,
  useStringFieldModel,
} from '@models';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  ErrorDual,
  ICompanyOverview,
  IProject,
  IUser,
  PermissionNamesEnums,
  QueryNamesEnums,
  UpdateUserFields,
} from '@interfaces';
import { getProjectsList, updateCompany, updateUserMe } from '@globalService';
import { useSafeSnackbar } from '@hooks';

export interface ControllerInterface {
  open: boolean;
  onConfirm: () => void;
  firstNameField: StringFieldModel;
  lastNameField: StringFieldModel;
  emailField: StringFieldModel;
  urlField: StringFieldModel;
  companyField: StringFieldModel;
  phoneNumberField: PhoneFieldModel;
  hasCompanyTeamsEditPermission: boolean;
}

export const useVerifyBorrowerModal = (): ControllerInterface => {
  const { projectId } = useParams();

  const { enqueueSnackbar } = useSafeSnackbar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { permissions } = useContext(PermissionsContext);
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const isOwner = useMemo(() => checkIsOwner(teamRole), [teamRole]);

  const hasCompanyTeamsEditPermission = useMemo(
    () => isAllowed(PermissionNamesEnums.COMPANY_EDIT, permissions),
    [permissions],
  );

  const firstNameField = useStringFieldModel({
    initValue: user.first_name,
    validationRule: (value) => Boolean(value?.trim()),
    validateOnChange: true,
  });

  const lastNameField = useStringFieldModel({
    initValue: user.last_name,
    validationRule: (value) => Boolean(value?.trim()),
    validateOnChange: true,
  });

  const phoneNumberField = usePhoneFieldModel({
    initValue: user.phone,
    validationRule: (value) => Boolean(value?.replace('+1', '')?.trim()),
  });

  const emailField = useStringFieldModel({
    initValue: user.email,
  });

  const companyField = useStringFieldModel({
    initValue: user.active_team?.company?.name || '',
    validationRule: (value) => Boolean(value?.trim()),
    validateOnChange: true,
  });

  const urlField = useStringFieldModel({
    initValue: user.active_team?.company?.url || '',
    validationRule: (value) => regexValidation('url', value),
    validateOnChange: true,
  });

  useEffect(() => {
    if (user.phone) {
      phoneNumberField.setValue(user.phone);
    }
  }, [user.phone]);

  const [open, setOpen] = useState(false);

  const url = 'status=ACTIVE&limit=1&query={id}';
  const projectsListQuery = useQuery<{ results: IProject[]; count: number }, Error>(
    [QueryNamesEnums.GET_PROJECTS_LIST, { filterStringQuery: url }],
    getProjectsList.bind(this, { filterStringQuery: url }),
    { enabled: isOwner },
  );

  const activeProject = useMemo(
    () => projectsListQuery.data?.count === 1 && projectsListQuery.data?.results?.[0],
    [projectsListQuery.data],
  );

  const updateCompanyMutation = useMutation<Response, ErrorDual, ICompanyOverview>(updateCompany, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryNamesEnums.GET_MY_COMPANY);
      if (!user?.company_information_first_updated_at && checkIsOwner(teamRole))
        queryClient.invalidateQueries(QueryNamesEnums.GET_USER);
    },
    onError: (error) => {
      enqueueSnackbar(parsePathErrorDual(error), { variant: 'error' });
    },
  });

  useEffect(() => {
    setOpen(
      isOwner &&
        user?.agreed_to_terms &&
        'personal_information_first_updated_at' in user &&
        !user?.personal_information_first_updated_at,
    );
  }, [user, isOwner]);

  const updateUserMutation = useMutation<IUser, Error, UpdateUserFields>(updateUserMe, {
    onSuccess: (newData) => {
      queryClient.setQueryData<IUser>([QueryNamesEnums.GET_USER], (request) =>
        replaceObjectProperties({ data: request, newData }),
      );
      // if we have projectId, it's means we already was redirected from deep link and don't need to navigate to the project overview
      if (!projectId) {
        if (activeProject?.id) navigate(`/projects/${activeProject.id}/overview`);
        if (!activeProject?.id) navigate('/projects');
      }
      setOpen(false);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const updateUserData = () => {
    updateUserMutation.mutateAsync({
      first_name: firstNameField.value,
      last_name: lastNameField.value,
      phone: phoneNumberField.valueToSave,
    });
  };

  const updateCompanyData = () => {
    if (hasCompanyTeamsEditPermission) {
      updateCompanyMutation.mutateAsync({
        company: {
          name: companyField.value,
          url: urlField.value,
        },
      });
    }
  };

  const onConfirm = () => {
    const isFirstNameValid = firstNameField.validate();
    const isLastNameValid = lastNameField.validate();
    const isPhoneNumberValid = phoneNumberField.validate();
    const isCompanyNameValid = companyField.validate();
    const isURLValid = urlField.validate();

    if (
      isFirstNameValid &&
      isLastNameValid &&
      isPhoneNumberValid &&
      isCompanyNameValid &&
      isURLValid
    ) {
      updateCompanyData();
      updateUserData();
    }
  };

  return {
    open,
    onConfirm,
    firstNameField,
    lastNameField,
    emailField,
    companyField,
    phoneNumberField,
    urlField,
    hasCompanyTeamsEditPermission,
  };
};
