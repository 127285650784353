import { useGraphQuery } from '@context';
import { QueryNamesEnums } from '@interfaces';
import { useParams } from 'react-router';

export const useLineItemDataWrapper = () => {
  const { projectId } = useParams();

  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['name'],
    args: { project_id: projectId },
  });

  return {
    name: project.data.name,
  };
};
