import React from 'react';
import {
  Link,
  matchPath,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { Box, IconButton, Stack, Tab, Tabs, Typography } from '@mui/material';
import * as Controller from './controller';
import LoanDetails from './LoanDetails';
import InspectionsSettings from './InspectionsSettings';
import GeneralSettings from './GeneralSettings';
import OtherSettings from './OtherSettings';
import { colors } from '@theme';
import { ProjectNameLabel, RolesPermisssion } from '@components';
import Payments from './Payments';
import { ArrowBackIcon } from '@svgAsComponents';
import { useLaunchDarklyFlags } from '@context';
import ProjectWorkFlow from './ProjectWorkFlow';
import GeneralSettingsV2 from './GeneralSettingsV2';

const TABS = {
  GENERAL: { label: 'General', value: 'general' },
  LOAN: { label: 'Loan', value: 'loan' },
  PAYMENTS: { label: 'Payments', value: 'payments' },
  INSPECTIONS: { label: 'Inspections', value: 'inspections' },
  OTHER: { label: 'Other', value: 'other' },
  WORKFLOW: { label: 'Workflow', value: 'workflow' },
};

const ProjectSettings = () => {
  const flags = useLaunchDarklyFlags();
  const navigate = useNavigate();
  const { pathname, state: locationState } = useLocation();
  const { projectId } = useParams();
  const match = matchPath('/projects/:projectId/settings/:tab/*', pathname);
  const pathBase = `/projects/${projectId}/settings`;
  const controller = Controller.useProjectSettings();

  const {
    isAllProjectDetailsDisabled,
    isLimitedProjectDetailsDisabled,
    hasInspectionsViewPermission,
    hasInspectionsEditPermission,
  } = controller;

  return match?.params?.tab ? (
    <Box sx={{ display: 'flex', flexFlow: 'column nowrap', height: '100%' }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ pt: { md: 3, xs: 2 }, pr: 4 }}
      >
        <Stack direction="row" spacing={2} sx={{ px: { md: 3, xs: 2 } }}>
          <IconButton
            onClick={() =>
              navigate(locationState?.['prevUrl'] || `/projects/${projectId}/overview/`)
            }
            data-cy="project__settings__payments__back__icon"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h1">Project settings</Typography>
        </Stack>
        <ProjectNameLabel showStatusChip />
      </Stack>

      <Box sx={{ mt: { md: 1.5, xs: 0 }, position: 'relative' }}>
        <Tabs
          TabIndicatorProps={{
            style: { transition: 'none' },
            children: <span />,
          }}
          textColor="secondary"
          indicatorColor="secondary"
          value={match?.params.tab || TABS.GENERAL.value}
        >
          <Tab
            label={TABS.GENERAL.label}
            value={TABS.GENERAL.value}
            component={Link}
            to={`${pathBase}/${TABS.GENERAL.value}`}
            sx={{ ml: { md: 3, xs: 2 } }}
            data-cy={`projects_settings__tab__${TABS.GENERAL.value}`}
          />
          <Tab
            label={TABS.LOAN.label}
            value={TABS.LOAN.value}
            component={Link}
            to={`${pathBase}/${TABS.LOAN.value}`}
            sx={{ ml: { md: 3, xs: 2 } }}
            data-cy={`projects_settings__tab__${TABS.LOAN.value}`}
          />

          {hasInspectionsViewPermission && (
            <Tab
              label={TABS.INSPECTIONS.label}
              value={TABS.INSPECTIONS.value}
              component={Link}
              to={`${pathBase}/${TABS.INSPECTIONS.value}`}
              sx={{ ml: { md: 3, xs: 2 } }}
              data-cy={`projects_settings__tab__${TABS.INSPECTIONS.value}`}
            />
          )}

          <Tab
            label={TABS.PAYMENTS.label}
            value={TABS.PAYMENTS.value}
            component={Link}
            to={`${pathBase}/${TABS.PAYMENTS.value}`}
            sx={{ ml: { md: 3, xs: 2 } }}
            data-cy={`projects_settings__tab__${TABS.PAYMENTS.value}`}
          />
          {flags?.['ENG_8495_new_approval_flow'] && (
            <Tab
              label={TABS.WORKFLOW.label}
              value={TABS.WORKFLOW.value}
              component={Link}
              to={`${pathBase}/${TABS.WORKFLOW.value}`}
              sx={{ ml: { md: 3, xs: 2 } }}
              data-cy={`projects_settings__tab__${TABS.WORKFLOW.value}`}
            />
          )}

          <Tab
            label={TABS.OTHER.label}
            value={TABS.OTHER.value}
            component={Link}
            to={`${pathBase}/${TABS.OTHER.value}`}
            sx={{ ml: { md: 3, xs: 2 } }}
            data-cy={`projects_settings__tab__${TABS.OTHER.value}`}
          />
        </Tabs>
      </Box>
      <Stack sx={{ flex: 1, backgroundColor: colors.background.gray }}>
        <Routes>
          <Route
            path={TABS.GENERAL.value}
            element={
              flags?.['ENG_9420_number_of_units_for_all_projects'] ? (
                <GeneralSettingsV2
                  isAllProjectDetailsDisabled={isAllProjectDetailsDisabled}
                  isLimitedProjectDetailsDisabled={isLimitedProjectDetailsDisabled}
                />
              ) : (
                <GeneralSettings
                  isAllProjectDetailsDisabled={isAllProjectDetailsDisabled}
                  isLimitedProjectDetailsDisabled={isLimitedProjectDetailsDisabled}
                />
              )
            }
          />
          <Route
            path={TABS.LOAN.value}
            element={
              <LoanDetails
                isAllProjectDetailsDisabled={isAllProjectDetailsDisabled}
                isLimitedProjectDetailsDisabled={isLimitedProjectDetailsDisabled}
              />
            }
          />
          <Route
            path={TABS.INSPECTIONS.value}
            element={
              <InspectionsSettings hasInspectionsEditPermission={hasInspectionsEditPermission} />
            }
          />
          <Route path={TABS.PAYMENTS.value} element={<Payments />} />
          <Route
            path={TABS.OTHER.value}
            element={<OtherSettings isAllProjectDetailsDisabled={isAllProjectDetailsDisabled} />}
          />
          {flags?.['ENG_8495_new_approval_flow'] && (
            <Route path={TABS.WORKFLOW.value} element={<ProjectWorkFlow />} />
          )}
          {flags?.['ENG_8495_new_approval_flow'] && (
            <Route path={'roles/:roleId/permissions'} element={<RolesPermisssion />} />
          )}
        </Routes>
      </Stack>
    </Box>
  ) : (
    <Navigate to={`${pathBase}/${TABS.GENERAL.value}`} replace />
  );
};

export default ProjectSettings;
