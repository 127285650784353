import { GridColDef } from '@mui/x-data-grid';
import { getRoundNumber } from './decorators';
import isNumber from 'lodash/isNumber';
import { ILoanError, PatchListItem } from '@interfaces';
import { colors } from '@theme';

interface PercentFormatterProp {
  value: number;
  roundTo?: number;
  returnValue?: string;
}

export const percentFormatter = ({
  value,
  roundTo = 2,
  returnValue = '-',
}: PercentFormatterProp) => {
  if (value === null && returnValue) return returnValue;
  const result = getRoundNumber(value, roundTo);
  return result >= 0 ? `${result}%` : `-${Math.abs(result)}%`;
};

export const currencyFormatter = (value: string | number, returnValue: string | null = null) => {
  if (!isNumber(value) || isNaN(+value)) return returnValue;

  const result = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(Math.abs(+value));
  return +value >= 0 ? result : `-${result}`;
};

export const currencyToNumber = (value: string | number, supportNegative = false) => {
  if (!value) return 0;

  const stringValue = value.toString();
  const isNegative = stringValue.startsWith('(');

  const cleanValue = stringValue.replace(/[$,()]/g, '');
  const number = parseFloat(cleanValue);
  const result = isNegative ? (supportNegative ? -number : 0) : number;
  if (result < 0 && !supportNegative) return 0;
  return result;
};

export const getBasicUrl = ({
  requestType,
  projectId,
  requestId,
  milestoneId,
  inspectionId,
  serviceOrderId,
  documentId,
  isPHBProject = false,
}: {
  requestType?: string;
  projectId: string;
  requestId?: string;
  milestoneId?: string;
  inspectionId?: string;
  serviceOrderId?: string;
  documentId?: string;
  isPHBProject?: boolean;
}) => {
  const basicUrl = `projects/${projectId}`;

  if (isPHBProject && milestoneId) return `${basicUrl}/milestone_groups/${milestoneId}/comments/`;

  if (requestType === 'post' && milestoneId && inspectionId)
    return `${basicUrl}/inspections/${inspectionId}/milestones/${milestoneId}/comments/`;

  if (requestType === 'post' && requestId && milestoneId)
    return `${basicUrl}/draw_requests/${requestId}/milestones/${milestoneId}/comments/`;

  if (milestoneId) return `${basicUrl}/milestones/${milestoneId}/comments/`;

  if (inspectionId) return `${basicUrl}/inspections/${inspectionId}/comments/`;

  if (serviceOrderId) return `${basicUrl}/service_orders/${serviceOrderId}/comments/`;

  if (documentId) return `${basicUrl}/documents/${documentId}/comments/`;

  if (requestId) return `${basicUrl}/draw_requests/${requestId}/comments/`;

  return `${basicUrl}/comments/`;
};

export const getDrawCellKey = (vars: PatchListItem): string =>
  `${vars.milestone}-${Object.keys(vars.json).join('')}`;

export const formatError = (error: string) => {
  return `<span style='color: ${colors.status.information.medium}'><b>${error}</b></span>`;
};

export const phoneFormatter = (phoneNumber) => {
  // Ensure the phone number starts with +1 and has 11 digits
  if (phoneNumber?.startsWith('+1') && phoneNumber?.length === 12) {
    const areaCode = phoneNumber.slice(2, 5);
    const centralOfficeCode = phoneNumber.slice(5, 8);
    const lineNumber = phoneNumber.slice(8, 12);
    return `(${areaCode}) ${centralOfficeCode}-${lineNumber}`;
  } else {
    console.log('Invalid phone number format. Expected +1XXXXXXXXXX');
    return phoneNumber;
  }
};
export const convertReactTableColumnsToMuiDataGridColumns = (oldColumns): GridColDef[] => {
  return oldColumns.map((oldCol) => {
    const { Header, accessor, disableFilters, disableSortBy, Cell, minWidth, ...rest } = oldCol;

    // Convert minWidth from string to number if needed
    let convertedMinWidth: number | undefined;
    if (typeof minWidth === 'string' && minWidth.endsWith('px')) {
      convertedMinWidth = parseInt(minWidth, 10);
    } else if (typeof minWidth === 'number') {
      convertedMinWidth = minWidth;
    }

    // Ensure the field is defined
    const field = accessor || oldCol.field || oldCol.Header || 'undefined_field';

    // Create the new column definition
    const newCol: GridColDef = {
      headerName: typeof Header === 'string' ? Header : '',
      renderHeader: typeof Header !== 'string' ? () => Header : undefined,
      sortable: !disableSortBy,
      filterable: !disableFilters,
      groupable: false,
      aggregable: false,
      minWidth: convertedMinWidth ?? 120,
      field,
      flex: 1,
      ...rest,
    };

    if (typeof accessor === 'string') {
      // Check if the accessor contains dot notation
      if (accessor.includes('.')) {
        // Use valueGetter for nested fields
        newCol.field = accessor;
        newCol.valueGetter = (_value, row) => {
          return accessor.split('.').reduce((obj, key) => obj?.[key], row);
        };
      } else {
        // Direct field access for non-nested fields
        newCol.field = accessor;
        newCol.valueGetter = (_value, row) => row[accessor];
      }
    }

    // Render custom cell if Cell is provided
    if (Cell) {
      newCol.renderCell = (params) => {
        const row = { original: params.row };
        const value = params.value;
        return Cell({ row, cell: { value } });
      };
    }

    return newCol;
  });
};

export const transformObjectToFalseKeysArray = (obj) => {
  return Object.entries(obj)
    .filter(([_key, value]) => !value)
    .map(([key]) => key);
};

export const parseLoanErrors = (message) => {
  const errorValuesMap = {
    prefunding_cost: 'Prefunding cost',
    construction_holdback: 'Construction holdback',
    borrower_equity: 'Borrower equity',
    schedule_of_values: 'Schedule of values',
  };

  const getErrorText = (name: string, error: ILoanError) => ({
    message: error.message,
    loan: `${errorValuesMap[name]} = ${error.loan}`,
    budget: `${errorValuesMap[name]} = ${error.budget}`,
  });

  const errorsToSet = ['budget', 'borrower']
    .filter((field) => message?.[field])
    .map((field) => ({ message: message[field] }));

  const loanErrorFields = [
    'prefunding_cost',
    'construction_holdback',
    'borrower_equity',
    'schedule_of_values',
  ];
  const loanErrorsToSet = message?.budget
    ? []
    : loanErrorFields
        .filter((field) => message?.[field])
        .map((field) => getErrorText(field, message[field]));
  const loanFieldsErrors = message?.loan_fields?.message
    ? [
        {
          message: message.loan_fields.message,
          loan_fields: message.loan_fields.fields?.join(', '),
        },
      ]
    : [];
  const loanTypeErrors = message?.loan_type ? [{ message: message.loan_type }] : [];

  return [...errorsToSet, ...loanErrorsToSet, ...loanFieldsErrors, ...loanTypeErrors];
};
