import React from 'react';
import { Stack } from '@mui/material';

import { HookState } from '@interfaces';
import { LoadingSkeleton, ServiceMessage, StyledBox } from '@components';
import * as Controller from './controller';
import { MFASettings, PasswordAuthSettings } from './components';

const CompanySecurity = () => {
  const controller = Controller.useCompanySecurity();
  const { state, data } = controller;

  const company = data;
  switch (state) {
    case HookState.LOADING: {
      return (
        <StyledBox sx={{ flex: 1 }}>
          <LoadingSkeleton type="overviewBlock" />
        </StyledBox>
      );
    }

    case HookState.ERROR: {
      return <ServiceMessage text="Company Users" />;
    }

    case HookState.SUCCESS: {
      return (
        <Stack direction="row" spacing={2} alignItems="flex-start" sx={{ flex: 1 }}>
          <StyledBox sx={{ width: '50%' }}>
            <MFASettings company={company} />
          </StyledBox>
          <StyledBox sx={{ width: '50%' }}>
            <PasswordAuthSettings company={company} />
          </StyledBox>
        </Stack>
      );
    }

    default:
      return null;
  }
};

export default CompanySecurity;
