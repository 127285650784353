import React from 'react';

import { CompanyInfoBlock, InfoCard } from '@components';
import * as Controller from './controller';
import { HookState } from '@interfaces';
import { Skeleton, Stack } from '@mui/material';

const CompanyModal = ({ open, handleClose, companyId, source }) => {
  const { state, company } = Controller.useCompanyModal(companyId);

  return (
    <InfoCard open={open} handleClose={handleClose} title="Company Info">
      {state === HookState.LOADING && (
        <Stack spacing={2} direction="row">
          <Skeleton width={200} height={72} />
          <Skeleton width={200} height={72} />
        </Stack>
      )}
      {state === HookState.SUCCESS && (
        <CompanyInfoBlock company={company} isEditAllowed={false} source={source} />
      )}
    </InfoCard>
  );
};

export default CompanyModal;
