import React from 'react';
import { Button, Stack } from '@mui/material';
import findIndex from 'lodash/findIndex';
import { ConfirmationModal, StyledBox, ImageSlickCarousel, MediaDetails } from '@components';
import { IPhoto, PopupTypeEnum } from '@interfaces';
import { colors } from '@theme';

const PhotosDetailView = ({ controller }) => {
  const {
    closeCallback,
    photos,
    activePhoto,
    callbackOnPhotoChange,
    handlePhotoDelete,
    isPhotoDeleting,
    userId,
    handleProjectImageUpdate,
    confirmUpdateProjectCoverModal,
  } = controller;
  const startIndex = findIndex(photos, (o) => (o as IPhoto).id === activePhoto?.id);

  return (
    <StyledBox
      sx={{
        display: 'flex',
        width: '100%',
        maxWidth: '100%',
        height: '100%',
        flexDirection: { sm: 'column', lg: 'row' },
        alignItems: { sm: 'center', lg: 'flex-start' },
      }}
    >
      <Stack
        sx={{
          flex: 1,
          width: {
            sm: '90vh',
            lg: 'calc(100vw - 600px)',
          },
          mt: 4.5,
          mx: { sm: 0, lg: 4 },
          my: { sm: 4, lg: 0 },
          borderRight: {
            xs: 'none',
            lg: `1px solid ${colors.neutral.light}`,
          },
          borderBottom: {
            xs: `1px solid ${colors.neutral.light}`,
            lg: 'none',
          },
          pb: { xs: 4, lg: 0 },
        }}
      >
        <Stack justifyContent="center" alignItems="center" sx={{ flex: 1 }}>
          <ImageSlickCarousel
            slides={photos}
            startIndex={startIndex}
            callbackOnPhotoChange={callbackOnPhotoChange}
            activePhoto={activePhoto}
          />
        </Stack>
      </Stack>

      <Stack justifyContent="space-between" sx={{ minWidth: 450, maxWidth: 450, height: '100%' }}>
        <MediaDetails
          media={activePhoto}
          size="medium"
          closeCallback={closeCallback}
          mediaType={activePhoto.file_representations?.webm ? 'Video' : 'Photo'}
        />
        <Stack direction="row" justifyContent="flex-end" spacing={1} mt={6} mb={2}>
          <Button
            color="error"
            variant="new"
            loading={isPhotoDeleting}
            onClick={() => handlePhotoDelete(activePhoto.id)}
            disabled={userId !== activePhoto.created_by?.id && userId !== activePhoto.loaded_by?.id}
            data-cy="photos_tab__card__delete__button"
          >
            Delete
          </Button>
          <Button
            variant="new"
            color="secondary"
            component="a"
            href={activePhoto.link}
            download
            data-cy="photos_tab__card__download__button"
          >
            Download
          </Button>
          <Button
            onClick={confirmUpdateProjectCoverModal.askConfirm}
            variant="new"
            color="secondary"
            data-cy="photos_tab__card__make_as_cover__button"
            disabled={!!activePhoto?.file_representations?.webm}
          >
            Make as cover
          </Button>
        </Stack>
      </Stack>
      {confirmUpdateProjectCoverModal.isConfirmModalOpened && (
        <ConfirmationModal
          open={confirmUpdateProjectCoverModal.isConfirmModalOpened}
          title="Confirmation"
          text="This photo will be set as project cover photo."
          onClose={confirmUpdateProjectCoverModal.closeConfirmModal}
          confirmCallback={() => {
            handleProjectImageUpdate();
            confirmUpdateProjectCoverModal.closeConfirmModal();
          }}
          type={PopupTypeEnum.CONFIRMATION}
          source="photos_tab__card__make_as_cover"
        />
      )}
    </StyledBox>
  );
};

export default PhotosDetailView;
