import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { IServiceOrder } from '@interfaces';
import { CollapsedCard } from '@components';

type ServiceInstructionsProps = {
  serviceOrder: Pick<IServiceOrder, 'draw_request' | 'comment'>;
};

const ServiceInstructions: FC<ServiceInstructionsProps> = ({ serviceOrder }) => {
  if (!serviceOrder?.draw_request?.submitted_inspection_comment && !serviceOrder?.comment) {
    return null;
  }

  return (
    <CollapsedCard header={<Typography variant="h3">Special instructions</Typography>}>
      {serviceOrder?.draw_request?.submitted_inspection_comment && (
        <Stack sx={{ mx: 2 }}>
          <Typography variant="label">Comment from borrower (Internal)</Typography>
          <Typography
            variant="body2"
            dangerouslySetInnerHTML={{
              __html: serviceOrder?.draw_request?.submitted_inspection_comment,
            }}
          />
        </Stack>
      )}

      {serviceOrder?.comment && (
        <Stack sx={{ mx: 2 }}>
          <Typography variant="label">Comment</Typography>
          <Typography variant="body2" dangerouslySetInnerHTML={{ __html: serviceOrder.comment }} />
        </Stack>
      )}
    </CollapsedCard>
  );
};

export default ServiceInstructions;
