import { IDrawRequest } from '@interfaces';
import { ColumnType } from '@utils';
import React from 'react';

const project_address: ColumnType<IDrawRequest> = () => ({
  field: 'project.address.address_1',
  renderCell: ({ row }: { row: Partial<IDrawRequest> }) => (
    <span data-cy="requests_queue__table__body__project_address">
      {row.project?.address?.address_1}
    </span>
  ),
  graphFields: [],
  graphNested: {
    'project.address': ['address_1'],
  },
  headerName: 'Address',
  minWidth: 160,
  groupable: false,
  sortable: false,
  filterable: false,
  aggregable: false,
});

export default project_address;
