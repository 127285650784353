import { currencyFormatter, ColumnType } from '@utils';
import { IDrawRequest } from '@interfaces';

const approved_amount: ColumnType<IDrawRequest> = () => ({
  field: 'approved_amount',
  graphFields: ['approved_amount'],
  headerName: 'Approved amount ($)',
  minWidth: 120,
  groupable: false,
  aggregable: false,
  valueFormatter: (value) => currencyFormatter(value, ''),
});

export default approved_amount;
