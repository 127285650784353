import { IDrawRequest, PermissionNamesEnums } from '@interfaces';
import { ColumnType, isAllowed } from '@utils';
import React from 'react';

const inspection_agency: ColumnType<IDrawRequest> = () => ({
  field: 'inspection.inspection_agency.display_name',
  renderCell: ({ row }: { row: Partial<IDrawRequest> }) => (
    <span data-cy="requests_queue__table__body__inspection_agency">
      {row.inspection?.inspection_agency?.display_name}
    </span>
  ),
  graphFields: [],
  graphNested: {
    'inspection.inspection_agency': ['display_name'],
  },
  enableByPermissions: (permissions) =>
    isAllowed(PermissionNamesEnums.INSPECTIONS_VIEW, permissions),
  headerName: 'Inspection agency',
  minWidth: 140,
  groupable: false,
  sortable: false,
  filterable: false,
  aggregable: false,
});

export default inspection_agency;
