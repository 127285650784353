import { QueryNamesEnums, TeamRolesEnum } from '@interfaces';
import { FilterObject } from '../interface';
import { basicFilterSerializer, checkIsOwner, getTeamRole } from '@utils';

export const borrowerFilter: FilterObject = {
  title: 'Borrower',
  filterKey: 'borrower',
  userPermission: (user) => !checkIsOwner(getTeamRole(user)),
  getDataParamsPaginated: ({ pagination, q, skip, id }) => ({
    type: QueryNamesEnums.GET_COMPANIES_BY_ROLE,
    keys: ['id', 'name'],
    args: {
      role: TeamRolesEnum.OWNER,
      pagination,
      q,
      id,
    },
    options: {
      skip,
    },
  }),
  strictSerialize: (data) => basicFilterSerializer(data),
  cypressSelector: 'filters__borrower',
  needToUpdateUrl: true,
  needToUpdateSetting: true,
  withPagination: true,
};
