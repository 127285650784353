import status from './status';
import description from './description';
import loan_number from './loan_number';
import project_name from './project_name';
import project_address from './project_address';
import project_city from './project_city';
import project_state from './project_state';
import project_type from './project_type';
import coordinator from './coordinator';
import current_reviewer_team from './current_reviewer_team';
import project_status from './project_status';
import customer_name from './customer_name';
import project_investor from './project_investor';
import project_lender from './project_lender';
import project_borrower from './project_borrower';
import requested_amount from './requested_amount';
import revised_estimate from './revised_estimate';
import submitted_at from './submitted_at';
import days_since_submitted from './days_since_submitted';
import inspection_status from './inspection_status';
import inspection_agency from './inspection_agency';
import inspection_provider from './inspection_provider';
import days_waiting_for_inspection from './days_waiting_for_inspection';
import inspector_allowance_rate from './inspector_allowance_rate';
import lender_allowance_rate from './lender_allowance_rate';
import approved_amount from './approved_amount';
import variance_to_lender_allowance_rate from './variance_to_lender_allowance_rate';
import disbursed_at from './disbursed_at';
import project_overall_score from './project_overall_score';
import action from './actions';

export default [
  description,
  loan_number,
  status,
  project_name,
  project_address,
  project_city,
  project_type,
  project_state,
  coordinator,
  current_reviewer_team,
  project_status,
  customer_name,
  project_investor,
  project_lender,
  project_borrower,
  requested_amount,
  revised_estimate,
  submitted_at,
  days_since_submitted,
  inspection_provider,
  inspection_status,
  inspection_agency,
  days_waiting_for_inspection,
  inspector_allowance_rate,
  lender_allowance_rate,
  approved_amount,
  variance_to_lender_allowance_rate,
  disbursed_at,
  project_overall_score,
  action,
];
