import { useContext, useState, useEffect, useCallback } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useFormik } from 'formik';
import { useSearchParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { useMsal } from '@azure/msal-react';

import { EnumTypeForList, ErrorDual, IdentityServiceTypeEnum } from '@interfaces';
import { useLogin, useNavigateToOriginalUrl } from '@hooks';
import { getIntegrationDataByEmail } from '@globalService';
import { emailValidationSchema, getErrorText } from '@utils';
import { SSOContext } from '@context';
import { DropdownFieldModel, useDropdownFieldModel } from '@models';

export interface LoginPayload {
  sso_access_token?: string;
  auth_connector?: string;
  sso_domain_name?: string;
}
export interface ControllerInterface {
  formik: any;
  inProgress: boolean;
  sso_access_token: string;
  fromRedirect: boolean;
  connectorsList: EnumTypeForList[];
  connector: DropdownFieldModel;
  ssoType: IdentityServiceTypeEnum;
}

export const useSSOIntegration = (): ControllerInterface => {
  const { ssoType, setSSOConfig, setSSOType, clearSSOData } = useContext(SSOContext);
  const { ssoAccessToken, fromRedirect, from } = useNavigateToOriginalUrl();

  const cleanupSSOData = () => {
    localStorage.clear();
    sessionStorage.clear();
    if (clearSSOData) clearSSOData();
  };

  const { postLoginData } = useLogin({
    errorCallback: (error) => {
      formik.setFieldError('email', getErrorText(error as ErrorDual));
    },
    onSettledCallback: () => {
      setInProgress(false);
    },
    successCallback: () => {
      cleanupSSOData();
    },
  });
  const { instance } = useMsal();

  const okta = useOktaAuth();
  const { authState, oktaAuth } = okta || {};
  const connector = useDropdownFieldModel({
    initValue: null,
    validationRule: (value) => Boolean(value?.id),
  });

  const [connectorsList, setConnectorsList] = useState([]);
  const [inProgress, setInProgress] = useState(false);
  const authConnector = JSON.parse(sessionStorage.getItem('ssoConfig'))?.state;

  const [searchParams] = useSearchParams();
  const iss = searchParams.get('iss')?.split('//').pop();
  const sso_domain_name = searchParams.get('sso_domain_name');
  const sso_access_token = searchParams.get('sso_access_token') || ssoAccessToken;

  const getConnectorData = (email) => {
    cleanupSSOData();
    getIntegrationDataByEmail(email)
      .then(({ results }) => {
        if (!results?.length) {
          formik.setFieldError('email', 'No auth connector found for this email');
          formik.setSubmitting(false);
          setInProgress(false);
          return;
        }
        const processedResults = results
          .filter((connector) => connector.access_type === 'INTERNAL')
          .map((item) => ({
            name: item.domain,
            name_display: item.domain,
            ...item,
          })) as EnumTypeForList[];

        if (processedResults?.length === 1) {
          handleRedirectToAuthConnector(processedResults[0]);
          return;
        }
        if (processedResults?.length > 1) {
          // if we have multiple internal connectors we need to ask user to choose one
          setConnectorsList(processedResults);
          formik.setSubmitting(false);
          setInProgress(false);
        }
      })
      .catch((error) => {
        formik.setFieldError('email', error?.message);
        formik.setSubmitting(false);
        setInProgress(false);
      });
  };

  const handleRedirectToAuthConnector = (connector) => {
    if (!connector) return;
    let config = {};
    if (connector.identity_service_type === IdentityServiceTypeEnum.OKTA) {
      config = {
        issuer: `https://${connector.domain}`,
        clientId: connector.client_id,
        redirectUri: `${window.location.origin}/login-with-sso/callback`,
        state: connector.id,
      };
    }

    if (connector.identity_service_type === IdentityServiceTypeEnum.MICROSOFT) {
      config = {
        authority: `https://${connector.domain}`,
        clientId: connector.client_id,
        redirectUri: `${window.location.origin}/login-with-sso/callback`,
        state: connector.id,
        responseType: ['token'],
        scopes: ['openid'],
        pkce: false,
      };
    }

    // when redirect from identity provider will be done we need to initiate the app with this provider instance
    // as we don't ask for config connector on redirect we'll use them from SS
    sessionStorage.setItem('ssoConfig', JSON.stringify(config));
    sessionStorage.setItem('ssoType', JSON.stringify(connector.identity_service_type));
    // location.state - 'from' flag is used to redirect user to original url if he was logged out due to access token expiration
    if (from) sessionStorage.setItem('originalUrl', JSON.stringify(from));
    setSSOConfig(config);
    setSSOType(connector.identity_service_type);
  };

  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema: emailValidationSchema,
    onSubmit: (values) => {
      if (connectorsList?.length && connector.value) {
        setInProgress(true);
        handleRedirectToAuthConnector(connector.value);
        return;
      }
      getConnectorData(values.email);
    },
  });

  useEffect(() => {
    if (formik.values?.email && formik.isValid) {
      connector.setValue(null);
    }
  }, [formik.values.email, formik.isValid]);

  useEffect(() => {
    if (!authConnector && iss) getConnectorData(iss);

    // check if it initial page and we re-init app with okta provider -> initiate okta auth
    if (authConnector && !fromRedirect && ssoType === IdentityServiceTypeEnum.OKTA)
      initiateOktaAuth();

    // check if it initial page and we re-init app with microsoft provider -> initiate microsoft auth
    if (authConnector && !sso_access_token && ssoType === IdentityServiceTypeEnum.MICROSOFT) {
      setInProgress(true);
      // timeout is needed to let msal instance to be initialized
      setTimeout(() => initiateMSalAuth(), 1000);
    }

    if ((authConnector && fromRedirect) || sso_access_token) handleLogin();
  }, [fromRedirect, authConnector, iss, sso_domain_name, sso_access_token, ssoType]);

  const initiateOktaAuth = async () => {
    try {
      setInProgress(true);
      await oktaAuth.signInWithRedirect();
    } catch (err: any) {
      console.log('Okta login error:', err);

      const error =
        typeof err === 'object'
          ? err?.errorSummary
          : typeof err === 'string'
            ? err
            : 'Unknown Okta login error';
      formik.setFieldError('domainName', error);
    } finally {
      setInProgress(false);
    }
  };

  const initiateMSalAuth = useCallback(async () => {
    const request = {
      scopes: ['openid'],
    };
    try {
      await instance.loginRedirect(request);
    } catch (err) {
      console.log('Microsoft login error:', err);

      const error =
        typeof err === 'object'
          ? JSON.stringify(err)
          : typeof err === 'string'
            ? err
            : 'Unknown Microsoft login error';
      formik.setFieldError('domainName', error);
    } finally {
      setInProgress(false);
    }
  }, [instance]);

  const handleLogin = useCallback(async () => {
    let params = {};
    if (sso_domain_name && sso_access_token)
      params = {
        sso_domain_name,
        sso_access_token,
      };

    // Okta SSO
    if (authState?.accessToken?.accessToken && authConnector)
      params = {
        sso_access_token: authState.accessToken.accessToken,
        auth_connector: authConnector,
      };

    // Microsoft SSO
    if (ssoType === IdentityServiceTypeEnum.MICROSOFT) {
      params = {
        sso_access_token,
        auth_connector: authConnector,
      };
    }

    if (isEmpty(params)) return;
    setInProgress(true);
    await postLoginData.mutateAsync(params);
  }, [authConnector, sso_access_token, sso_domain_name, ssoType, authState]);

  return {
    formik,
    inProgress,
    sso_access_token,
    fromRedirect,
    connectorsList,
    connector,
    ssoType,
  };
};
