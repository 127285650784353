import React from 'react';
import { CellRenderWithError, MilestoneListColumnTypeV2 } from './common';
import {
  currencyFormatter,
  currencyToNumber,
  isAllowed,
  notAllowNegative,
  isCostTypeContingency,
} from '@utils';
import { PermissionNamesEnums } from '@interfaces';
import { InputBaseComponentProps } from '@mui/material';
import { GridEditInputCell } from '@mui/x-data-grid';
import { CurrencyInput } from 'src/components/NumberFormatInputs';

const prefundingCost: MilestoneListColumnTypeV2 = {
  field: 'prefunding_cost',
  valueFormatter: (value) => currencyFormatter(value, ''),
  valueParser: (value) => currencyToNumber(value),
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Prefunding cost ($)',
  description: 'Project costs spent prior to loan closing',
  renderEditCell: (props) => (
    <GridEditInputCell
      {...props}
      inputProps={{
        isAllowed: notAllowNegative,
      }}
      inputComponent={CurrencyInput as unknown as React.ElementType<InputBaseComponentProps>}
    />
  ),

  renderCell: (params) => <CellRenderWithError params={params} />,
  editableByMilestone: (row) =>
    !isCostTypeContingency(row.cost_type) &&
    ((row.activeToEdit && row.localNew) || row.isCreatedProjectMode),
  editableByPermissions: (permissions) =>
    isAllowed(PermissionNamesEnums.DRAWREQUESTS_LINE_ITEM_CREATE, permissions),
  minWidth: 120,
};

export default prefundingCost;
