import React, { FC, ReactElement, useContext, useMemo, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Box, ButtonProps, Stack, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import {
  CommonRowType,
  ExtendGridStatePremium,
  IDocument,
  IMilestoneTotal,
  IProofpoint,
  ITransloaditSignature,
  PermissionNamesEnums,
  TableKeyEnum,
} from '@interfaces';
import { colors } from '@theme';
import {
  currencyFormatter,
  openFullScreenPanorama,
  roundToTwoDigits,
  useDidValueChange,
} from '@utils';
import {
  CustomErrorTooltip,
  Gallery,
  IconButtonWithTooltip,
  PanoramaViewer,
  PDFViewerNew,
} from '@components';
import { AddPhotoIcon, PhotoGalleryIconWithIndicator, WarningIcon } from '@svgAsComponents';
import { useImagePicker } from '@hooks';
import { ViewerAPI } from 'react-photo-sphere-viewer';
import { TableContext } from '../controller';
import { GridColDef, GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid-premium';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';

const StyledArrow = styled(Box)({
  display: 'inline-block',
  paddingRight: 5,
  color: colors.text.dark,
});

export const ProgressionSum: FC<{
  sum?: number;
  progress: number;
  isError?: boolean;
  dataTestName?: string;
}> = ({ sum, progress, isError, dataTestName }) => {
  if (!progress) return null;

  const newBalance = roundToTwoDigits(+sum) + roundToTwoDigits(+progress);
  return (
    <Typography
      sx={{ position: 'relative' }}
      color={
        isError || newBalance < 0 ? colors.status.error.medium : colors.status.information.medium
      }
      variant="body3"
      data-cy={dataTestName}
    >
      {newBalance !== sum && <StyledArrow>➝</StyledArrow>}
      {currencyFormatter(newBalance)}
    </Typography>
  );
};

export type MilestoneListColumnTypeV2 = GridColDef & {
  editableByMilestone?: (row: CommonRowType, tableKey?: TableKeyEnum) => boolean;
  editableByPermissions?: (permissions: any) => boolean;
  permissionKey?: PermissionNamesEnums;
  totalFormater?: (totals: IMilestoneTotal) => React.ReactNode;
};

export const isFooterRow = (row) => row.id === 'totals';

export const MilestoneImages: FC<{
  images: (IProofpoint | IDocument)[];
  canAddPhotos: boolean;
  disabled: {
    value: boolean;
    reason: string;
  };
  uploadClick: () => void;
  isFilesUploaderOpened: boolean;
  transloaditSignature: ITransloaditSignature;
  uploader: () => ReactElement;
  generalRowDataSource?: string;
}> = ({
  images,
  canAddPhotos,
  disabled,
  uploadClick,
  isFilesUploaderOpened,
  transloaditSignature,
  uploader,
  generalRowDataSource,
}) => {
  const { pdf, gallery, open, close, startIndex } = useImagePicker();
  const { source } = useContext(TableContext);
  const [showPanorama, setShowPanorama] = React.useState(false);
  const ref = useRef<ViewerAPI>();

  const canOpenPanorama = useMemo(
    () => images?.length === 1 && images[0]?.is_panorama,
    [images?.length, images?.[0]?.is_panorama],
  );

  return (
    <>
      {showPanorama && <PanoramaViewer ref={ref} />}
      <Stack direction="row" justifyContent="flex-end" flexWrap="nowrap" alignItems="center">
        {Boolean(images?.length) && (
          <IconButtonWithTooltip
            onClick={() => {
              if (canOpenPanorama) {
                openFullScreenPanorama(setShowPanorama, ref, images[0]);
              } else {
                open(images, 0);
              }
            }}
            data-cy={
              source
                ? `${source}__body__photos__gallery__icon`
                : `${generalRowDataSource}__photos__gallery__icon}`
            }
          >
            <PhotoGalleryIconWithIndicator hasUnseenImages={false} />
          </IconButtonWithTooltip>
        )}
        {canAddPhotos && (
          <IconButtonWithTooltip
            tooltipText={disabled?.reason}
            disabled={disabled?.value}
            onClick={uploadClick}
            data-cy={
              source
                ? `${source}__body__photos__upload__icon`
                : `${generalRowDataSource}__photos__upload__icon}`
            }
          >
            <AddPhotoIcon />
          </IconButtonWithTooltip>
        )}
      </Stack>
      {gallery && <Gallery startIndex={startIndex} close={close} files={gallery} />}
      {pdf && <PDFViewerNew pdfFile={pdf[0]} close={close} />}
      {isFilesUploaderOpened &&
        transloaditSignature &&
        ReactDOM.createPortal(uploader(), document.body)}
    </>
  );
};

export interface ActionButton extends ButtonProps {
  label: string;
  onClickCustom: (api: React.MutableRefObject<GridApiPremium>, row: any) => void;
  hideButton?: (row: any) => boolean;
}

export const gridCellSet = async (api, row, field, value) => {
  await api.current.startCellEditMode({ id: row.id, field });
  await api.current.setEditCellValue({
    id: row.id,
    field,
    value,
  });
  await api.current.stopCellEditMode({ id: row.id, field });
};

export const GridCellErrorRenderer: FC<{
  params: GridRenderCellParams;
  actionButtons?: Array<ActionButton>;
  persistTooltip?: boolean;
  errorForCurrentRow?: string;
  api: React.MutableRefObject<GridApiPremium>;
  hasChanged: boolean;
  setHasChanged: (hasChanged: boolean) => void;
}> = ({
  params,
  actionButtons,
  persistTooltip,
  errorForCurrentRow,
  api,
  hasChanged,
  setHasChanged,
}) => {
  return (
    <Stack width="100%" flex={1} alignItems="center" justifyContent="space-between" direction="row">
      {persistTooltip || !!actionButtons?.length ? (
        <CustomErrorTooltip
          params={params}
          actionButtons={actionButtons}
          persistTooltip={persistTooltip}
          errorForCurrentRow={errorForCurrentRow}
          api={api}
          hasChanged={hasChanged}
          setHasChanged={setHasChanged}
        />
      ) : (
        <Tooltip
          title={
            <Stack>
              <Typography
                variant="label"
                dangerouslySetInnerHTML={{ __html: errorForCurrentRow }}
                sx={{ whiteSpace: 'pre-line' }}
              ></Typography>
            </Stack>
          }
        >
          <Stack sx={{ cursor: 'pointer', p: 0.5 }}>
            <WarningIcon size={14} />
          </Stack>
        </Tooltip>
      )}
      {params.formattedValue}
    </Stack>
  );
};

export const CellRenderWithError: FC<{
  params: GridRenderCellParams;
  actionButtons?: Array<ActionButton>;
  persistTooltip?: boolean;
}> = ({ params, actionButtons, persistTooltip }) => {
  const api = useGridApiContext();
  const state = api.current.state as ExtendGridStatePremium;
  const errorForCurrentRow = state.additionalData?.rowErrors?.[params.field]?.[params.row.id];
  const { hasChanged, setHasChanged } = useDidValueChange(errorForCurrentRow);

  if (errorForCurrentRow) {
    return (
      <GridCellErrorRenderer
        params={params}
        persistTooltip={persistTooltip}
        actionButtons={actionButtons}
        errorForCurrentRow={errorForCurrentRow}
        api={api}
        hasChanged={hasChanged}
        setHasChanged={setHasChanged}
      />
    );
  }

  return params.formattedValue;
};

export const CellRenderWithStaticError: FC<{
  params: GridRenderCellParams;
  errorText: string;
  blockNavigationOnError?: boolean;
}> = ({ params, errorText }) => {
  const errorForCurrentRow = !params.value;
  const { setHasChanged } = useDidValueChange(errorForCurrentRow);

  if (errorForCurrentRow) {
    return (
      <Stack
        width="100%"
        flex={1}
        alignItems="center"
        justifyContent="space-between"
        direction="row"
      >
        <Tooltip
          onClose={() => setHasChanged(false)}
          title={
            <Stack>
              <Typography variant="label" sx={{ whiteSpace: 'pre-line' }}>
                {errorText}
              </Typography>
            </Stack>
          }
        >
          <Stack onClick={() => setHasChanged(true)} sx={{ cursor: 'pointer', p: 0.5 }}>
            <WarningIcon size={14} />
          </Stack>
        </Tooltip>
        {params.formattedValue}
      </Stack>
    );
  }

  return params.formattedValue;
};
