import React, { FC } from 'react';
import { Button, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import { Fees, Popup } from '@components';
import { useFeesEditPopup } from './controller';
import { useFees } from '@hooks';

const FeesEditPopup: FC<{ onClose: () => void }> = ({ onClose }) => {
  const { isActiveDrawRequest, onSubmit, isSubmitting, addFeeToProject, setAddFeeToProject } =
    useFeesEditPopup(onClose);

  const { addFee, fees, deleteFee, updateFee, isFeesUpdated } = useFees();

  return (
    <Popup open maxWidth="md" title="Edit fees">
      <Stack sx={{ width: '100%' }}>
        <Typography variant="body2" sx={{ textAlign: 'center' }}>
          Payment model only affects the original scheduled value amount and not the additional
          equity.
        </Typography>
        <Stack spacing={2} sx={{ mt: 3 }}>
          <Fees
            fees={fees}
            deleteFee={deleteFee}
            updateFee={updateFee}
            canEdit={isActiveDrawRequest}
            source="payments_tab"
          />
        </Stack>
        <FormControlLabel
          control={
            <Checkbox
              checked={addFeeToProject}
              onChange={(_e, value) => {
                setAddFeeToProject(value);
              }}
              disabled={isSubmitting}
              data-cy="payments_tab__fee_popup__add_to_project__checkbox"
            />
          }
          label={
            <Typography variant="body2">
              Update these fees for the next requests as well.
            </Typography>
          }
          sx={{ mt: 3 }}
        />
        <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ mt: 7.5 }}>
          <Button
            variant="text"
            onClick={onClose}
            loading={isSubmitting}
            data-cy="payments_tab__fee_popup__cancel_button"
          >
            Cancel
          </Button>
          <Button
            onClick={addFee}
            variant="new"
            color="secondary"
            sx={{ ml: '0.5rem' }}
            loading={isSubmitting}
            data-cy="payments_tab__fee_popup__add_fee_button"
          >
            Add fee
          </Button>
          <Button
            onClick={() => onSubmit(fees)}
            sx={{ ml: '0.5rem' }}
            loading={isSubmitting}
            data-cy="payments_tab__fee_popup__save_button"
            disabled={!isFeesUpdated}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </Popup>
  );
};

export default FeesEditPopup;
