import React, { useCallback } from 'react';
import { Button, Stack } from '@mui/material';
import { useIsMutating } from 'react-query';
import { Popup } from '@components';
import { ComponentProps } from './interface';
import { MutationKeyEnum, PopupTypeEnum } from '@interfaces';
import snakeCase from 'lodash/snakeCase';
import { useSafeSnackbar } from '@hooks';

const ConfirmationModal = ({
  title = '',
  text,
  hideConfirmButton,
  confirmCallback,
  onClose,
  type = PopupTypeEnum.CONFIRMATION,
  open,
  children,
  maxWidth = 'sm',
  cancelButtonLabel = 'Cancel',
  isLoading,
  confirmButtonLabel = 'Confirm',
  icon,
  source = '',
}: ComponentProps) => {
  const { enqueueSnackbar } = useSafeSnackbar();
  const isMutating = useIsMutating({
    predicate: ({ options }) =>
      options.mutationKey === MutationKeyEnum.MILESTONE_PATCH ||
      options.mutationKey === MutationKeyEnum.DRAW_REQUEST_PATCH,
  });

  const handleConfirm = useCallback(async () => {
    try {
      await confirmCallback();
    } catch (error) {
      if (error instanceof Error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      } else {
        enqueueSnackbar('An unknown error occurred', { variant: 'error' });
      }
    }
  }, [confirmCallback, enqueueSnackbar]);

  return (
    <Popup open={open} type={type} title={title} text={text} icon={icon} maxWidth={maxWidth}>
      <Stack sx={{ width: '100%' }}>
        {children}
        <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{ mt: 7.5 }}>
          <Button
            variant="text"
            onClick={onClose}
            data-cy={`${source || snakeCase(title)}__confirmation_dialog__cancel__button`}
          >
            {cancelButtonLabel}
          </Button>
          {!hideConfirmButton && (
            <Button
              loading={!!isMutating || isLoading}
              disabled={!!isMutating || isLoading}
              onClick={handleConfirm}
              data-cy={`${source || snakeCase(title)}__confirmation_dialog__confirm__button`}
              color={type === PopupTypeEnum.ERROR ? 'error' : 'primary'}
            >
              {confirmButtonLabel}
            </Button>
          )}
        </Stack>
      </Stack>
    </Popup>
  );
};

export default ConfirmationModal;
