import React from 'react';
import { Stack, Typography } from '@mui/material';
import {
  CenteredStyledBox,
  CustomAutocomplete,
  CustomCurrencyTextField,
  DateTimePickerWithTimezone,
  CustomPercentTextField,
  CustomTextField,
  FundingSourcesContainer,
} from '@components';
import { getNextDay, getPrevDay } from '@utils';

const LoanDetails = ({
  loanDetailsFields,
  borrowerEquityLocal,
  editPaymentConfigurationData,
  loan_servicing_statuses,
  loan_types,
  projectFundingSources,
  handleSaveFundingSource,
}) => {
  const {
    external_id,
    maturity_date,
    extended_maturity_date,
    funding_date,
    loc_commitment,
    prefunding_cost,
    construction_holdback,
    contribution_fraction,
    retainage_rate,
    borrower_equity,
    post_funding_construction_budget,
    estimated_start_date,
    original_completion_date,
    estimated_completion_date,
    loan_status,
    loan_type,
  } = loanDetailsFields;

  return (
    <Stack alignItems="center" spacing={2}>
      <CenteredStyledBox>
        <Stack spacing={3}>
          <Stack direction="row" spacing={2}>
            <CustomTextField
              field={external_id}
              label="Loan ID (optional)"
              inputProps={{
                'data-cy': 'project__create__loan_id__input',
              }}
            />
            <Stack sx={{ width: '100%' }}>
              <CustomCurrencyTextField
                label="Total loan commitment (optional)"
                field={loc_commitment}
                inputProps={{
                  'data-cy': 'project__create__total_loan_commitment__input',
                }}
              />
              <Typography variant="label" sx={{ display: 'inline-block', ml: 1 }}>
                Costs towards land acquisition, construction, associated expenses
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={2}>
            <CustomAutocomplete
              label="Loan type (optional)"
              field={loan_type}
              options={loan_types}
              inputProps={{
                'data-cy': 'project__settings__loan__type__input',
              }}
            />
            <CustomAutocomplete
              label="Loan servicing status (optional)"
              field={loan_status}
              options={loan_servicing_statuses}
              inputProps={{
                'data-cy': 'project__settings__loan__servicing_status__input',
              }}
            />
          </Stack>
        </Stack>
      </CenteredStyledBox>
      <CenteredStyledBox>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2}>
            <DateTimePickerWithTimezone
              label="Loan funding date (optional)"
              field={funding_date}
              maxDate={getPrevDay(maturity_date.value)}
              inputProps={{
                'data-cy': 'project__create__funding_date__input',
              }}
              sx={{ width: '100%' }}
            />
            <DateTimePickerWithTimezone
              label="Estimated start date  (optional)"
              field={estimated_start_date}
              maxDate={getPrevDay(original_completion_date.value)}
              inputProps={{
                'data-cy': 'project__create__estimated_start_date__input',
              }}
              sx={{ width: '100%' }}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <DateTimePickerWithTimezone
              label="Original maturity date (optional)"
              field={maturity_date}
              minDate={getNextDay(funding_date.value)}
              maxDate={extended_maturity_date.value}
              inputProps={{
                'data-cy': 'project__create__maturity_date__input',
              }}
              sx={{ width: '100%' }}
            />
            <DateTimePickerWithTimezone
              label="Extended maturity date (optional)"
              field={extended_maturity_date}
              minDate={maturity_date.value}
              inputProps={{
                'data-cy': 'project__create__extended_maturity_date__input',
              }}
              sx={{ width: '100%' }}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <DateTimePickerWithTimezone
              label="Estimated completion date (optional)"
              field={estimated_completion_date}
              minDate={getNextDay(estimated_start_date.value)}
              inputProps={{
                'data-cy': 'project__create__estimated_completion_date__input',
              }}
              sx={{ width: '100%' }}
            />
          </Stack>
        </Stack>
      </CenteredStyledBox>
      <CenteredStyledBox>
        <FundingSourcesContainer
          prefunding_cost={prefunding_cost}
          construction_holdback={construction_holdback}
          constructionHoldbackFraction={contribution_fraction}
          borrower_equity={borrower_equity}
          borrowerEquityFraction={borrowerEquityLocal}
          configurationType={editPaymentConfigurationData.configurationType}
          setConfigurationType={editPaymentConfigurationData.setConfigurationType}
          constructionBudget={post_funding_construction_budget}
          source="project__create"
          configurationTypeOptions={editPaymentConfigurationData.configurationTypeOptions}
          projectFundingSources={projectFundingSources}
          handleSaveFundingSource={handleSaveFundingSource}
        />
      </CenteredStyledBox>
      <CenteredStyledBox>
        <Stack data-cy={'project__create__loan_retainage_block__block'}>
          <Typography variant="h3">Retainage</Typography>
          <Typography variant="body1" sx={{ mt: '8px', display: 'inline-block' }}>
            If retainage is a fixed rate, please enter the value below. If it is variable, you can
            enter the value per line item when creating the budget.
          </Typography>
          <Stack direction="row" justifyContent="space-between" spacing={3} mt={3}>
            <CustomPercentTextField
              label="Fixed retainage (%) (optional)"
              field={retainage_rate}
              inputProps={{
                'data-cy': 'project__create__loan_retainage__input',
              }}
            />
          </Stack>
        </Stack>
      </CenteredStyledBox>
    </Stack>
  );
};

export default LoanDetails;
