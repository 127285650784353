import React from 'react';
import { Stack, Typography, Box, FormControlLabel, Checkbox, Tooltip } from '@mui/material';
import { LoadingSkeleton, RolesWorkflow, ServiceMessage, StyledBox } from '@components';
import * as Controller from './controller';
import { HookState } from '@interfaces';
import { colors } from '@theme';

const CompanyWorkflow = () => {
  const {
    state,
    rolesInApprovalFlow,
    reviewsQuantity,
    fundingApproveRequired,
    isSettingsMutating,
    handleQuantityClick,
    requeredReviewsValuesArray,
    handleFundingReviewClick,
    hasCompanyTeamsEditPermission,
    crud,
    isUpdating,
    editAvailable,
  } = Controller.useCompanyWorkflow();

  if (state === HookState.LOADING) {
    return <LoadingSkeleton type="overviewBlock" />;
  }

  if (state === HookState.ERROR) {
    return <ServiceMessage text="company teams" />;
  }

  const disabledTooltipText = hasCompanyTeamsEditPermission
    ? ''
    : "You don't have permission to edit company workflow";

  return (
    <Stack spacing={2}>
      <StyledBox>
        <Stack>
          <Typography variant="h3" mb={2}>
            Approval flow
          </Typography>
          <Typography variant="body1">
            Here you can set required user approvals for approval flow, sort & remove teams from
            approval flow.
          </Typography>
        </Stack>

        <Stack direction="row" alignItems="center" mt={3} mb={5.5}>
          <Typography variant="body1SemiBold" mr={2.5}>
            Required number of approvals
          </Typography>
          <Tooltip title={disabledTooltipText} placement="right">
            <Stack direction="row" alignItems="center" spacing={1}>
              {requeredReviewsValuesArray.map((item) => (
                <Box
                  key={item}
                  sx={{
                    width: '32px',
                    height: '32px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor:
                      item === reviewsQuantity ? colors.main.primary.main : colors.neutral.lightest,

                    opacity: isSettingsMutating ? 0.5 : 1,
                    cursor: hasCompanyTeamsEditPermission ? 'pointer' : 'unset',
                  }}
                  onClick={hasCompanyTeamsEditPermission ? handleQuantityClick(item) : null}
                  data-cy={`company_teams__workflow__required_reviews__${item}_button_${
                    item === reviewsQuantity ? 'active' : 'inactive'
                  }}`}
                >
                  <Typography
                    variant="body3SemiBold"
                    sx={{
                      color: item === reviewsQuantity ? colors.neutral.white : colors.text.medium,
                    }}
                  >
                    {item}
                  </Typography>
                </Box>
              ))}
            </Stack>
          </Tooltip>
          <Tooltip title={disabledTooltipText} placement="right">
            <FormControlLabel
              sx={{ ml: 12 }}
              control={
                <Checkbox
                  checked={fundingApproveRequired}
                  onChange={(_e, value) => {
                    handleFundingReviewClick(value);
                  }}
                  disabled={isSettingsMutating || !hasCompanyTeamsEditPermission}
                  data-cy="company_teams__workflow__funding_approve__checkbox"
                />
              }
              label={<Typography variant="body2">Funding approve is required</Typography>}
            />
          </Tooltip>
        </Stack>
      </StyledBox>
      <RolesWorkflow
        isLoading={isUpdating}
        disabled={isUpdating || !editAvailable}
        crud={crud}
        roles={rolesInApprovalFlow}
      />
    </Stack>
  );
};

export default CompanyWorkflow;
