import React, { useCallback, useMemo, useState } from 'react';
import { Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-premium';

import { EditIcon, DeleteIcon } from '@svgAsComponents';
import { CrudActionEnum, IRoleNew, TeamRolesEnum } from '@interfaces';
import RoleIconWithLink from '../RoleIconWithLink';
import IconButtonWithTooltip from '../withTooltips/IconButtonWithTooltip';

export interface RolesWorkflowInterface {
  roles: Pick<IRoleNew, 'id' | 'review_level' | 'permissions' | 'company_role' | 'display_name'>[];
  disabled?: boolean;
  isLoading?: boolean;
  crud?: (data: Partial<IRoleNew>, action: CrudActionEnum) => void;
}

export const useRolesWorkflow = ({ crud, disabled }: RolesWorkflowInterface) => {
  const [actionDialog, setActionDialog] = useState<CrudActionEnum | null>(null);
  const [itemForEdit, setItemsForEdit] = useState<Partial<IRoleNew> | null>(null);

  const crudAction = useCallback(
    (data?: Partial<IRoleNew>, instantAction?: CrudActionEnum) => {
      crud &&
        crud(
          { ...(data || {}), ...(itemForEdit ? { id: itemForEdit?.id } : {}) },
          instantAction || actionDialog,
        );
      closeDialog();
    },
    [crud, itemForEdit, actionDialog],
  );

  const selectItemForDelete = useCallback(
    (data: Partial<IRoleNew>) => {
      if (disabled) return;
      setItemsForEdit(data);
      setActionDialog(CrudActionEnum.DELETE);
    },
    [disabled],
  );

  const selectItemForEdit = useCallback(
    (data: Partial<IRoleNew>) => {
      if (disabled) return;
      setItemsForEdit(data);
      setActionDialog(CrudActionEnum.UPDATE);
    },
    [disabled],
  );

  const closeDialog = useCallback(() => {
    setItemsForEdit(null);
    setActionDialog(null);
  }, []);

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'review_level',
        headerName: 'Review Level',
        sortable: false,
        filterable: false,
        groupable: false,
        aggregable: false,
        hideable: false,
        resizable: false,
        width: 100,
      },
      {
        field: 'display_name',
        headerName: 'Role Name',
        sortable: false,
        filterable: false,
        groupable: false,
        aggregable: false,
        hideable: false,
        resizable: false,
        flex: 1,
      },
      {
        field: 'id',
        headerName: 'Actions',
        sortable: false,
        filterable: false,
        groupable: false,
        aggregable: false,
        hideable: false,
        resizable: false,
        width: 150,
        renderCell: ({ row }) => (
          <Stack direction="row" spacing={1} justifyContent="flex-end">
            <IconButtonWithTooltip
              disabled={disabled}
              tooltipText="Edit item"
              onClick={() => selectItemForEdit?.(row)}
            >
              <EditIcon />
            </IconButtonWithTooltip>
            <IconButtonWithTooltip
              tooltipText={row.teams_count > 0 ? 'Cannot be delete' : ''}
              disabled={row.teams_count > 0 || disabled}
              onClick={() => selectItemForDelete?.(row)}
            >
              <DeleteIcon />
            </IconButtonWithTooltip>
            <RoleIconWithLink roleId={row.id} />
          </Stack>
        ),
      },
    ],
    [selectItemForEdit, disabled],
  );

  const rolesOptionsList = [TeamRolesEnum.LENDER, TeamRolesEnum.OWNER, TeamRolesEnum.INVESTOR];

  return {
    itemForEdit,
    selectItemForEdit,
    actionDialog,
    setActionDialog,
    closeDialog,
    crudAction,
    selectItemForDelete,
    columns,
    rolesOptionsList,
  };
};
