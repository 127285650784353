import { isInspectionService, percentFormatter, ColumnType } from '@utils';

const inspectorAllowance: ColumnType = () => ({
  field: 'inspector_allowance',
  graphFields: ['inspector_allowance'],
  headerName: 'Inspector allowance',
  minWidth: 80,
  groupable: false,
  aggregable: false,
  renderCell: ({ row }) => {
    if (!isInspectionService(row.service_type)) {
      return null;
    }
    const rate = row.inspector_allowance?.rate;
    return rate ? percentFormatter(rate) : '-';
  },
});

export default inspectorAllowance;
