export default {
  isDev: process.env.NODE_ENV === 'development',
  isProd: process.env.NODE_ENV === 'production',
  url: process.env.PUBLIC_URL,
  mockApi: 'https://de4e52d0-9e9a-44e1-bddf-e82e66c0e8cc.mock.pstmn.io',
  sentryDsn: 'https://bdf1096da4594c0a80fa8fb2e0aa1963@o406777.ingest.sentry.io/5649910',
  plaidRedirectUri: process.env.REACT_APP_PLAID_REDIRECT_URI,
  sentryEnv: process.env.REACT_APP_SENTRY_ENV,
  dataGridKey: process.env.REACT_APP_DATA_GRID_KEY,
  gtmId: process.env.REACT_APP_GTM_ID,
  launchDarklyClientSideID: process.env.REACT_APP_LD_CLIENT_SIDE_ID,
  datadogClientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
  datadogApplicationID: process.env.REACT_APP_DATADOG_APP_ID,
  datadogEnv: process.env.REACT_APP_DATADOG_ENV,
  datadogSite: 'us3.datadoghq.com',
  oauthOidcUrl: process.env.REACT_APP_OAUTH_OIDC_URL,
  api: `${process.env.REACT_APP_API_ROOT_URL}/v1`,
  apiV2: `${process.env.REACT_APP_API_ROOT_URL}/v2`,
  googleMapApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  wssUrl: process.env.REACT_APP_WSS_URL,
};
