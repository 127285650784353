import { IDrawRequest } from '@interfaces';
import { ColumnType, isInactiveProject } from '@utils';
import React, { useContext } from 'react';
import { statusMap } from '@constants';
import { SettingsContext } from '@context';

const project_name: ColumnType<IDrawRequest> = () => ({
  field: 'project.name',
  renderCell: ({ row }: { row: Partial<IDrawRequest> }) => {
    const { settings } = useContext(SettingsContext);
    const { name, status } = row.project;
    const projectStatusValue = statusMap(status, settings.display, 'project');

    return (
      <span data-cy={'requests_queue__table__body__project_name'}>
        {name}
        {isInactiveProject(status) ? ` (${projectStatusValue?.text})` : ''}
      </span>
    );
  },
  graphFields: [],
  graphNested: {
    project: ['name', 'status'],
  },
  headerName: 'Project name',
  minWidth: 190,
  groupable: false,
  sortable: false,
  filterable: false,
  aggregable: false,
});

export default project_name;
