import { PermissionNamesEnums, MessagePanelTabsEnum, TeamRolesEnum } from '@interfaces';
import { colors } from '@theme';
import { isAllowed } from '@utils';

export const TABS = {
  ALL: {
    label: 'All',
    value: MessagePanelTabsEnum.ALL,
    permissionKey: PermissionNamesEnums.COMMENTS_ALL_VIEW,
    dataTestName: 'tabs__all',
    teamRole: null,
  },
  INTERNAL: {
    label: 'Internal',
    value: MessagePanelTabsEnum.INTERNAL,
    permissionKey: PermissionNamesEnums.COMMENTS_ALL_VIEW,
    dataTestName: 'tabs__internal',
    teamRole: null,
  },
  INVESTOR: {
    label: 'Investor',
    value: MessagePanelTabsEnum.INVESTOR,
    permissionKey: PermissionNamesEnums.COMMENTS_ALL_VIEW,
    dataTestName: 'tabs__investor',
    teamRole: TeamRolesEnum.INVESTOR,
  },
  BORROWER: {
    label: 'Borrower',
    value: MessagePanelTabsEnum.BORROWER,
    permissionKey: PermissionNamesEnums.COMMENTS_ALL_VIEW,
    dataTestName: 'tabs__borrower',
    teamRole: TeamRolesEnum.OWNER,
  },
  SERVICES: {
    label: 'Services',
    value: MessagePanelTabsEnum.SERVICES,
    permissionKey: PermissionNamesEnums.INSPECTIONS_EDIT,
    dataTestName: 'tabs__services',
    teamRole: null,
  },
};

export const COMMENT_CONFIG = {
  [MessagePanelTabsEnum.BORROWER]: {
    color: colors.status.orange.medium,
    isExternalComment: true,
  },
  [MessagePanelTabsEnum.INVESTOR]: {
    color: colors.status.violet.medium,
    isExternalComment: true,
  },
  default: {
    isExternalComment: false,
    color: colors.main.primary.main,
  },
} as const;

export const getFilterParams = ({
  activeTab,
  received_by_company_id,
  isInspectionMilestoneComments,
}: {
  activeTab: string;
  received_by_company_id: string;
  isInspectionMilestoneComments: boolean;
}) => {
  switch (activeTab) {
    case TABS.BORROWER.value:
    case TABS.INVESTOR.value:
    case TABS.INTERNAL.value:
      return { received_by_company_id };
    case TABS.SERVICES.value:
      return isInspectionMilestoneComments
        ? {}
        : {
            services_only: true,
          };
    default:
      return {};
  }
};

export const getReceivedByCompanyId = ({
  activeTab,
  borrowerCompanyId,
  userCompanyId,
  investorCompanyId,
}: {
  activeTab: string;
  borrowerCompanyId: string;
  userCompanyId: string;
  investorCompanyId: string;
}) => {
  switch (activeTab) {
    case TABS.BORROWER.value:
      return borrowerCompanyId;
    case TABS.INVESTOR.value:
      return investorCompanyId;
    case TABS.INTERNAL.value:
      return userCompanyId;
    default:
      return null;
  }
};

export const getCommentProps = ({
  activeTab,
  permissions,
  borrowerTeamId,
  investorCompanyId,
  received_by_company_id,
  parentCommentReceivedById,
}: {
  activeTab: MessagePanelTabsEnum;
  permissions: any[];
  borrowerTeamId?: string;
  investorCompanyId?: string;
  received_by_company_id?: string;
  parentCommentReceivedById?: string;
}) => {
  if (!isAllowed(PermissionNamesEnums.COMMENTS_ALL_VIEW, permissions)) {
    return null;
  }

  if (activeTab !== MessagePanelTabsEnum.ALL) {
    return COMMENT_CONFIG[activeTab] || COMMENT_CONFIG.default;
  }

  const isReceivedByBorrower =
    received_by_company_id === borrowerTeamId || parentCommentReceivedById === borrowerTeamId;

  const isReceivedByInvestor =
    received_by_company_id === investorCompanyId || parentCommentReceivedById === investorCompanyId;

  if (isReceivedByBorrower) {
    return COMMENT_CONFIG[MessagePanelTabsEnum.BORROWER];
  }

  if (isReceivedByInvestor) {
    return COMMENT_CONFIG[MessagePanelTabsEnum.INVESTOR];
  }

  return COMMENT_CONFIG.default;
};
