import React, { CSSProperties, FC, SyntheticEvent } from 'react';
import { styled } from '@mui/material/styles';
import { Tab, Tabs, TabsList } from '@mui/base';
import { Stack, Tooltip } from '@mui/material';
import { tabClasses } from '@mui/base/Tab';
import snakeCase from 'lodash/snakeCase';

import { colors, fonts } from '@theme';
import { MessagePanelTabsEnum, TabSwitcherType } from '@interfaces';

const StyledTab = styled(Tab)(() => ({
  fontSize: 16,
  fontFamily: fonts.primary,
  fontWeight: 600,
  height: 40,
  cursor: 'pointer',
  border: 'none',
  borderRight: `1px solid ${colors.background.white}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [`&.${tabClasses.selected}`]: {
    backgroundColor: colors.status.information.medium,
    color: colors.neutral.white,
  },
  '&:first-of-type': {
    borderRadius: '2px 0px 0px 2px',
  },
  '&:last-child': {
    borderRadius: '0px 2px 2px 0px',
  },
  minWidth: 105,
  whiteSpace: 'nowrap',
  flexShrink: 0,
  padding: '0.625rem 1.5rem',
}));

const StryledTabsList = styled(TabsList)(() => ({
  minWidth: '320px',
  borderRadius: 0.5,
  padding: '0 1rem 1rem',
  marginTop: 16,
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'nowrap',
  alignContent: 'space-between',
  overflowX: 'auto',
  maxWidth: '100%',
}));

const TabSwitcher: FC<{
  tabs: TabSwitcherType[];
  activeTab: string;
  onTabChange: (id: string) => void;
  tabStyle?: CSSProperties;
  isSmall?: boolean;
  source: string;
}> = ({ tabs, activeTab, onTabChange, tabStyle, isSmall, source }) => {
  const handleChange = (
    _event: SyntheticEvent<Element, Event>,
    value: MessagePanelTabsEnum | string | number,
  ) => {
    if (value) onTabChange(value.toString());
  };

  return (
    <Tabs defaultValue={activeTab} value={activeTab} onChange={handleChange} data-cy={source}>
      <StryledTabsList style={{ ...tabStyle }}>
        {tabs.map(({ label, value, isActive, dataTestName, disabled = false, icon }) => {
          const Icon = icon?.component;
          return (
            <StyledTab
              key={label}
              value={value?.toString()}
              sx={{
                textTransform: 'capitalize',
                ...(isActive ? { flexShrink: 0 } : {}),
                ...(isSmall && { fontSize: 12, height: 32 }),
                backgroundColor: disabled
                  ? colors.neutral.lighter
                  : colors.status.information.lighter,
                '&:hover': {
                  backgroundColor: disabled
                    ? colors.neutral.lighter
                    : colors.status.information.light,
                },
                color: disabled ? colors.neutral.medium : colors.status.information.medium,
              }}
              data-cy={`${source}__${dataTestName}`}
              disabled={disabled}
            >
              {label}
              {icon && (
                <Tooltip title={icon.tooltip}>
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      ml: 1,
                    }}
                    data-cy={`${source}__${dataTestName}__${snakeCase(icon.tooltip)}__icon`}
                  >
                    <Icon
                      color={isActive ? colors.neutral.white : colors.status.information.medium}
                    />
                  </Stack>
                </Tooltip>
              )}
            </StyledTab>
          );
        })}
      </StryledTabsList>
    </Tabs>
  );
};
export default TabSwitcher;
