import React, { FC } from 'react';
import { Box, Button, Stack } from '@mui/material';
import { ButtonWithTooltip, CustomRadioGroup, Popup, VerticalMenu } from '@components';
import { getTooltipText } from '@utils';
import { AutoFillModeEnum, IDrawRequest } from '@interfaces';
import { popupText, title, useAutofillAllowance } from './controller';
import { DocumentsIconWithIndicator } from '@svgAsComponents';

const AutofillDialog: FC<{
  drawRequest: IDrawRequest;
  handleAutofillLender: (autofillValue: string) => void;
  loading: boolean;
}> = ({ handleAutofillLender, drawRequest, loading }) => {
  const {
    isCurrentProjectArchived,
    setAutofillDialogOpened,
    isAutofillDialogOpened,
    autofillValue,
    setAutofillValue,
    autofillMenuItems,
    autofillOptions,
    currentMode,
  } = useAutofillAllowance({ drawRequest });

  return (
    <>
      <VerticalMenu
        menuItems={autofillMenuItems}
        disabled={isCurrentProjectArchived}
        disabledTooltipText={getTooltipText({ isCurrentProjectArchived })}
      >
        <ButtonWithTooltip
          size="small"
          loading={loading}
          variant="new"
          color="secondary"
          dataTestName="draw__autofill__button"
        >
          Autofill
        </ButtonWithTooltip>
      </VerticalMenu>

      <Popup
        open={isAutofillDialogOpened && Boolean(autofillOptions?.length)}
        title={title[currentMode]}
        text={popupText[currentMode]}
        maxWidth="sm"
        icon={DocumentsIconWithIndicator}
      >
        <Stack sx={{ width: '100%' }}>
          {currentMode === AutoFillModeEnum.LENDER_ALLOWANCE && (
            <Stack justifyContent="flex-start" sx={{ mt: 1 }}>
              <CustomRadioGroup
                dataTestName="draw__autofill__radio__group"
                value={autofillValue}
                onChange={(e) => setAutofillValue(e.target.value)}
                options={autofillOptions}
                direction="column"
              />
            </Stack>
          )}

          <Box sx={{ mt: 7 }} />
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button
              variant="text"
              onClick={() => setAutofillDialogOpened(false)}
              data-cy="draw__autofill__cancel__button"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                currentMode !== AutoFillModeEnum.CREDIT_REVIEW &&
                  handleAutofillLender(autofillValue);
                setAutofillDialogOpened(false);
              }}
              data-cy="draw__autofill__confirm__button"
            >
              {currentMode === AutoFillModeEnum.CREDIT_REVIEW ? 'Review' : 'Confirm'}
            </Button>
          </Stack>
        </Stack>
      </Popup>
    </>
  );
};

export default AutofillDialog;
