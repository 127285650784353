import React, { FC } from 'react';
import { StyledBox } from '@components';
import { Typography } from '@mui/material';
import { colors } from '@theme';
import NoImages from '@assets/no_images.svg';
import UploadPhotosButton from './UploadPhotosButton';

const EmptyPhotos: FC<{
  isAddPhotoAvailable: boolean;
  projectId: string;
  drawRequestId: string;
}> = ({ isAddPhotoAvailable, projectId, drawRequestId }) => {
  return (
    <StyledBox
      justifyContent="center"
      alignItems="center"
      sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', gap: 3, py: 10 }}
    >
      <img src={NoImages} style={{ maxWidth: '100%', width: '182px' }} alt="no photos" />
      <Typography variant="h2" sx={{ color: colors.text.heading }}>
        No photos
      </Typography>
      {isAddPhotoAvailable && (
        <UploadPhotosButton projectId={projectId} drawRequestId={drawRequestId} size="large" />
      )}
    </StyledBox>
  );
};

export default EmptyPhotos;
