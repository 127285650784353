import {
  FilterArumentsPaginated,
  IPermission,
  IUISettings,
  IUser,
  PermissionNamesEnums,
  TableKeyEnum,
  TeamRolesEnum,
} from '@interfaces';
import * as FiltersItems from './filters';
import { FunctionArguments } from '../../context/dataLayer/types';
import { dataProviders } from '../../context/dataLayer/provider';

export type FiltersTypes = keyof typeof FiltersItems;

export interface ComponentProps {
  source: string;
  padding?: number;
  filters: FiltersTypes[];
  LeftComponent?: React.FunctionComponent<any>;
  setFilterStringQuery: React.Dispatch<React.SetStateAction<string>>;
  width?: string;
  tableKey?: TableKeyEnum;
  onReady?: () => void;
  onPageChange?: (event, newPage) => void;
  skipToggle?: boolean;
}

export type GetFilterParamsPaginated = (args: FilterArumentsPaginated) => {
  type: keyof typeof dataProviders;
  keys?: Array<string>;
  args: FunctionArguments<(typeof dataProviders)[keyof typeof dataProviders]>;
  options?: { skip?: boolean };
};

export type FilterObject = {
  noNeedToRender?: boolean;
  title: string;
  filterKey: string;
  userPermission?: (user: IUser) => boolean; // TODO: remove that when migrate to permissions
  permissionKey?: PermissionNamesEnums | PermissionNamesEnums[];
  defaultFilters?: (role?: TeamRolesEnum) => Array<string>;
  needToUpdateUrl?: boolean;
  needToUpdateSetting?: boolean;
  getDataParamsPaginated?: GetFilterParamsPaginated;
  strictSerialize?: (data, permissions?: IPermission[], user?: IUser) => Array<any>;
  willChangeUrl?: boolean;
  getStaticValues?: ({
    permissions,
    settings,
  }: {
    permissions?: IPermission[];
    settings?: IUISettings;
  }) => Array<{ label: string; value: string }>;
  cypressSelector?: string;
  withPagination?: boolean;
};

export const DEFAULT_FILTERS_COUNT = 4;
export const DEFAULT_SET_TO_URL = false;
export const DEFAULT_SET_TO_SETTINGS = false;
