import { PermissionNamesEnums, QueryNamesEnums } from '@interfaces';
import { FilterObject } from '../interface';
import { basicFilterSerializer } from '@utils';

export const customerFilter: FilterObject = {
  title: 'Customer',
  filterKey: 'customer',
  permissionKey: PermissionNamesEnums.CUSTOMER_SUCCESS_ACCESS,
  needToUpdateUrl: true,
  needToUpdateSetting: true,
  getDataParamsPaginated: ({ pagination, q, skip, id }) => ({
    type: QueryNamesEnums.GET_COMPANIES,
    keys: ['id', 'name'],
    args: {
      is_customer: true,
      pagination,
      q,
      id,
    },
    options: {
      skip,
    },
  }),
  strictSerialize: (data) => basicFilterSerializer(data),
  cypressSelector: 'filters__customer',
  withPagination: true,
};
