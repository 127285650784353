import React, { ChangeEvent, FC, useMemo, useState } from 'react';
import { ConfirmationModal, LabelAndValue, TextInputWithTooltip } from '@components';
import { StringFieldModel } from '@models';
import { Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import { percentFormatter, isPercentAllowed } from '@utils';
import { PopupTypeEnum } from '@interfaces';

const TotalInspectionAllowance: FC<{
  isEditable: boolean;
  field: StringFieldModel;
  isTotalInspectionAllowanceEnabled: boolean;
  updateIsTotalInspectionAllowanceEnabled: (value: boolean) => void;
}> = ({
  isEditable,
  field,
  isTotalInspectionAllowanceEnabled,
  updateIsTotalInspectionAllowanceEnabled,
}) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [checked, setChecked] = useState(!!isTotalInspectionAllowanceEnabled);

  const showInspectionAllowance = useMemo(() => {
    return checked || !!isTotalInspectionAllowanceEnabled;
  }, [checked, isTotalInspectionAllowanceEnabled]);

  const onCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newCheckedValue = e.target.checked;

    if (newCheckedValue) {
      setIsConfirmationModalOpen(true);
    } else {
      updateIsTotalInspectionAllowanceEnabled(false);
      setChecked(false);
    }
  };

  return (
    <Stack direction="row" spacing={2.5}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={onCheckboxChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        }
        label={<Typography variant="body3">Total inspection allowance</Typography>}
      />
      {showInspectionAllowance && (
        <>
          {isEditable ? (
            <TextInputWithTooltip
              inputProps={{
                isAllowed: isPercentAllowed,
              }}
              value={field.value}
              onChange={field.handleChange}
              error={!field.isValid}
              type="percent"
              dataTestName="inspection_results__total_inspection_allowance__input"
              label="Total inspection allowance:"
            />
          ) : (
            <LabelAndValue
              textDataTestName="inspection_results__total_inspection_allowance"
              label="Total inspection allowance (%)"
              text={percentFormatter({ value: +field.value })}
            />
          )}
        </>
      )}
      <ConfirmationModal
        title="Enable total inspection allowance"
        text={`Are you sure you want to enable the total inspection allowance? \n It will remove the inspector allowance for line items.`}
        open={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        type={PopupTypeEnum.CONFIRMATION}
        confirmCallback={() => {
          setChecked(true);
          updateIsTotalInspectionAllowanceEnabled(true);
          setIsConfirmationModalOpen(false);
        }}
      />
    </Stack>
  );
};

export default TotalInspectionAllowance;
