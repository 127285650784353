import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { MilestonePhotosItem, EmptyPhotos } from './';
import { IDocument, IProjectDocument, DocumentArray } from '@interfaces';
import { IPhoto } from '@interfaces';

const PhotosList: FC<{
  relatedPhotos: {
    results: IProjectDocument[] | IDocument[];
    count: number;
  };
  milestonesWithPhotos: {
    name: string;
    photos: IPhoto[];
    photos_count: number;
    id: string;
  }[];
  handlePhotoClick: (id: string, photos: DocumentArray) => void;
  showEmptyPhotos: boolean;
  isAddPhotoAvailable: boolean;
  projectId: string;
  drawRequestId: string;
  isProjectTab: boolean;
}> = ({
  relatedPhotos,
  milestonesWithPhotos,
  handlePhotoClick,
  showEmptyPhotos,
  isAddPhotoAvailable,
  projectId,
  drawRequestId,
  isProjectTab,
}) => {
  if (showEmptyPhotos)
    return (
      <EmptyPhotos
        isAddPhotoAvailable={isAddPhotoAvailable}
        projectId={projectId}
        drawRequestId={drawRequestId}
      />
    );

  return (
    <Stack spacing={1}>
      {relatedPhotos.results.length > 0 && (
        <MilestonePhotosItem
          item={{
            name: 'Related photos',
            photos: relatedPhotos.results,
            photos_count: relatedPhotos.count,
          }}
          index={0}
          handlePhotoClick={handlePhotoClick}
          projectId={projectId}
          drawRequestId={drawRequestId}
          isProjectTab={isProjectTab}
        />
      )}
      {milestonesWithPhotos?.map((item, index) => (
        <MilestonePhotosItem
          item={item}
          key={item.id}
          index={index + 1}
          handlePhotoClick={handlePhotoClick}
          projectId={projectId}
          drawRequestId={drawRequestId}
          isProjectTab={isProjectTab}
        />
      ))}
    </Stack>
  );
};

export default PhotosList;
