import {
  ArgumentsProjectPostMilestone,
  deleteProjectDrawRequestMilestoneByIdV2,
  patchProjectDrawRequestMilestoneByIdV2,
  postProjectDrawRequestMilestonesV2,
  postProjectMilestoneV2,
} from '@globalService';
import { QueryNamesEnums } from '@interfaces';

const postProjectMilestoneMutation = (projectId) => ({
  api: postProjectMilestoneV2,
  validationRule: (values: Partial<ArgumentsProjectPostMilestone>) =>
    Boolean(values?.name?.trim()) && (values?.original_estimate > 0 || values?.prefunding_cost > 0),
  invalidation: {
    [QueryNamesEnums.GET_PROJECT_MILESTONES]: { projectId },
    [QueryNamesEnums.GET_PROJECT]: { project_id: projectId },
  },
});

const addToRequest = (projectId, requestId) => ({
  api: postProjectDrawRequestMilestonesV2,
  validationRule: (values: Partial<ArgumentsProjectPostMilestone>) => Boolean(values?.name?.trim()),
  invalidation: {
    [QueryNamesEnums.GET_DRAW_REQUEST]: { projectId, drawRequestId: requestId },
    [QueryNamesEnums.GET_DRAW_REQUEST_V2]: { project_id: projectId, draw_request_id: requestId },
    [QueryNamesEnums.GET_PROJECT_MILESTONES]: { projectId },
    [QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES]: { projectId, drawRequestId: requestId },
    [QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES_COLUMNS]: { projectId, requestId },
  },
});

const editInRequest = (projectId, requestId) => ({
  api: patchProjectDrawRequestMilestoneByIdV2,
  validationRule: (values: Partial<ArgumentsProjectPostMilestone>) => Boolean(values?.name?.trim()),
  invalidation: {
    [QueryNamesEnums.GET_DRAW_REQUEST]: { projectId, drawRequestId: requestId },
    [QueryNamesEnums.GET_DRAW_REQUEST_V2]: { project_id: projectId, draw_request_id: requestId },
    [QueryNamesEnums.GET_PROJECT_MILESTONES]: { projectId },
    [QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES]: { projectId, drawRequestId: requestId },
    [QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES_COLUMNS]: { projectId, requestId },
  },
});

const deleteInRequest = (projectId, requestId) => ({
  api: deleteProjectDrawRequestMilestoneByIdV2,
  invalidation: {
    [QueryNamesEnums.GET_DRAW_REQUEST]: { projectId, drawRequestId: requestId },
    [QueryNamesEnums.GET_DRAW_REQUEST_V2]: { project_id: projectId, draw_request_id: requestId },
    [QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES]: { projectId, drawRequestId: requestId },
    [QueryNamesEnums.GET_PROJECT_MILESTONES]: { projectId },
  },
});

export default {
  addToRequest,
  editInRequest,
  deleteInRequest,
  postProjectMilestoneMutation,
};
