import {
  useMutation,
  UseMutationResult,
  useQueries,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import {
  BulkDrawRequestListItemUpdateParam,
  ErrorDual,
  IDrawRequest,
  IMilestone,
  LineItemFilterEnum,
  MutationKeyEnum,
  PatchDrawRequestMSGroupParam,
  QueryNamesEnums,
  RequestPayload,
} from '@interfaces';
import {
  deleteDrawRequest,
  getDrawRequest,
  getDrawRequestMilestoneGroups,
  patchDrawRequestMilestoneGroup,
  updateDrawRequestMilestones,
  getProjectDrawRequestsList,
} from '@globalService';
import { useNavigate, useParams } from 'react-router-dom';
import { useRequestTotals, useSafeSnackbar } from '@hooks';
import { useCallback, useMemo } from 'react';
import {
  isLineItemView,
  isModelView,
  isUnitView,
  parsePathErrorDual,
  replaceMilestoneData,
  usePHBGrouping,
} from '@utils';
import {
  defaultDrawRequestQueryDetails,
  excludeCommentsQueryFields,
  LineItemFilterValues,
} from '@constants';
import { useGraphQuery } from '@context';

interface UseGetQueriesInterface {
  requestedDataQueries: [any, UseQueryResult<IDrawRequest>];
  deleteDrawRequestMutation: UseMutationResult<Response, Error, RequestPayload>;
  unitsQuery: UseQueryResult<{ results: IMilestone[] }, Error>;
  modelsQuery: UseQueryResult<{ results: IMilestone[] }, Error>;
  lineItemsQuery: UseQueryResult<{ results: IMilestone[] }, Error>;
  drawRequestsQuery: UseQueryResult<{ results: IDrawRequest[] }, Error>;
  bulkMilestoneMutation: UseMutationResult<Response, ErrorDual, BulkDrawRequestListItemUpdateParam>;
  onMilestoneUpdate: (data: IMilestone, isLineItemPatch?: boolean) => void;
  patchMSGroupMutation: UseMutationResult<IMilestone, ErrorDual, PatchDrawRequestMSGroupParam>;
  invalidationAfterBulkUpdate: () => void;
}

export const useGetQueries = ({
  filterTotalKey,
  filterKey,
  updateUnitsListItemsWithMsList,
  updateUnitsListItemsWithParentGroup,
  updateLineItemsListItemsWithParentGroup,
  updateLineItemsListItemsWithMsList,
  isSubmissionProcess,
  typeFilterValue,
  setUnitsTableItems,
  setLineItemsTableItems,
  setModelsTableItems,
  activeView,
}): UseGetQueriesInterface => {
  const { projectId, requestId: drawRequestId } = useParams();
  const { enqueueSnackbar } = useSafeSnackbar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const query = excludeCommentsQueryFields;
  const { unitLineItemGrouping, lineItemUnitGrouping, modelUnitsGrouping } = usePHBGrouping();
  const isLineItemsView = useMemo(() => isLineItemView(activeView), [activeView]);
  const isModelsView = useMemo(() => isModelView(activeView), [activeView]);
  const isUnitsView = useMemo(() => isUnitView(activeView), [activeView]);

  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['is_reallocation_enabled', 'is_lump_sum_enabled', 'retainage_rate'],
    args: { project_id: projectId },
    options: {
      skip: !projectId,
    },
  });

  const requestedDataQueries = useQueries([
    {
      queryKey: [
        QueryNamesEnums.GET_DRAW_REQUEST,
        {
          projectId,
          drawRequestId,
          query: `{-comments_preview,${defaultDrawRequestQueryDetails},totals{${LineItemFilterValues[LineItemFilterEnum.VERTICAL_COST].totalKey}}}`,
        },
      ],
      queryFn: getDrawRequest.bind(this, {
        projectId,
        drawRequestId,
        query: `{-comments_preview,${defaultDrawRequestQueryDetails},totals{${LineItemFilterValues[LineItemFilterEnum.VERTICAL_COST].totalKey}}}`,
      }),
      enabled: Boolean(drawRequestId),
    },
  ]);

  const drawRequestsQuery = useQuery<{ results: IDrawRequest[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }],
    getProjectDrawRequestsList.bind(this, projectId),
    { enabled: !!projectId },
  );

  const deleteDrawRequestMutation = useMutation<Response, Error, RequestPayload>(
    deleteDrawRequest,
    {
      mutationKey: MutationKeyEnum.MILESTONE_DELETE,
      onSuccess: async () => {
        enqueueSnackbar('Request deleted', { variant: 'success' });
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST_FOR_APPROVAL,
          { projectId },
        ]);
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_PROGRESS, { projectId }]);
        //TODO remove GET_PROJECT_PHOTOS with ENG_9658_new_photo_tab
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_PHOTOS, { projectId }]);
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_PROJECT_MILESTONES_WITH_PHOTOS,
          { projectId },
        ]);
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST,
          { projectId },
        ]);
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_PROJECT_MILESTONES,
          { projectId, query },
        ]);
        navigate(`/projects/${projectId}/overview`);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const { refetchAndReplaceTotals } = useRequestTotals({
    projectId,
    drawRequestId,
    totalKey: filterTotalKey,
    filterKey,
    groupByKeys: unitLineItemGrouping,
  });

  const modelsQuery = useQuery<{ results: IMilestone[] }, Error>(
    [
      QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
      {
        projectId,
        drawRequestId,
        groupBy: modelUnitsGrouping,
        filterKey,
      },
    ],
    getDrawRequestMilestoneGroups.bind(this, {
      projectId,
      drawRequestId,
      groupBy: modelUnitsGrouping,
      filterKey,
    }),
    { enabled: Boolean(drawRequestId && projectId && modelUnitsGrouping) },
  );

  const unitsQuery = useQuery<{ results: IMilestone[] }, Error>(
    [
      QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
      {
        projectId,
        drawRequestId,
        groupBy: unitLineItemGrouping,
        filterKey,
      },
    ],
    getDrawRequestMilestoneGroups.bind(this, {
      projectId,
      drawRequestId,
      groupBy: unitLineItemGrouping,
      filterKey,
    }),
    { enabled: Boolean(drawRequestId && projectId && unitLineItemGrouping) },
  );

  const lineItemsQuery = useQuery<{ results: IMilestone[] }, Error>(
    [
      QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
      {
        projectId,
        drawRequestId,
        groupBy: lineItemUnitGrouping,
        typeKeys: typeFilterValue,
      },
    ],
    getDrawRequestMilestoneGroups.bind(this, {
      projectId,
      drawRequestId,
      groupBy: lineItemUnitGrouping,
      typeKeys: typeFilterValue,
    }),
    {
      enabled: Boolean(drawRequestId && projectId && lineItemUnitGrouping),
    },
  );

  // bulk milestones update on autofill
  const bulkMilestoneMutation = useMutation<
    Response,
    ErrorDual,
    BulkDrawRequestListItemUpdateParam
  >(updateDrawRequestMilestones, {
    mutationKey: MutationKeyEnum.MILESTONE_PATCH_BULK,
    onSuccess: () => invalidationAfterBulkUpdate(),
    onError: (error) => {
      enqueueSnackbar(parsePathErrorDual(error), { variant: 'error' });
    },
  });

  const invalidationAfterBulkUpdate = async () => {
    unitsQuery.refetch().then((res) => {
      updateUnitsListItemsWithMsList(res.data?.results);
    });
    lineItemsQuery.refetch().then((res) => {
      updateLineItemsListItemsWithMsList(res.data?.results);
    });
    queryClient.invalidateQueries([
      QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
      { projectId, drawRequestId },
    ]);
    queryClient.invalidateQueries([QueryNamesEnums.GET_DRAW_REQUEST, { projectId, drawRequestId }]);
    queryClient.invalidateQueries([
      QueryNamesEnums.GET_DRAW_REQUEST_V2,
      { project_id: projectId, draw_request_id: drawRequestId },
    ]);
    queryClient.invalidateQueries([
      QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES_COLUMNS,
      { projectId, requestId: drawRequestId },
    ]);
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_PROGRESS, { projectId }]);
    queryClient.invalidateQueries([
      QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE,
      { projectId, drawRequestId },
    ]);
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_MILESTONE, { projectId }]);
  };

  const patchMSGroupMutation = useMutation<IMilestone, ErrorDual, PatchDrawRequestMSGroupParam>(
    patchDrawRequestMilestoneGroup,
    {
      mutationKey: MutationKeyEnum.MILESTONE_PATCH,
      onSuccess: (data) => {
        onMilestoneUpdate(data);
      },
    },
  );

  const invalidateAfterLineItemPatch = async (data) => {
    queryClient.setQueriesData<{ results: IMilestone[] }>(
      {
        queryKey: [
          QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
          {
            projectId,
            drawRequestId,
            groupBy: lineItemUnitGrouping,
            typeKeys: typeFilterValue,
          },
        ],
      },
      (old) =>
        replaceMilestoneData({
          milestones: old,
          milestoneId: data.id,
          json: data,
        }),
    );
    await queryClient.invalidateQueries([
      QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
      { projectId, drawRequestId, groupBy: unitLineItemGrouping, filterKey },
    ]);
    await queryClient.invalidateQueries([
      QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
      {
        projectId,
        drawRequestId,
        groupBy: modelUnitsGrouping,
        filterKey,
      },
    ]);
  };

  const invalidateAfterUnitPatch = async (data) => {
    queryClient.setQueriesData<{ results: IMilestone[] }>(
      {
        queryKey: [
          QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
          {
            projectId,
            drawRequestId,
            groupBy: unitLineItemGrouping,
            filterKey,
          },
        ],
      },
      (old) =>
        replaceMilestoneData({
          milestones: old,
          milestoneId: data.id,
          json: data,
        }),
    );
    await queryClient.invalidateQueries([
      QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
      {
        projectId,
        drawRequestId,
        groupBy: lineItemUnitGrouping,
        typeKeys: typeFilterValue,
      },
    ]);
    await queryClient.invalidateQueries([
      QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
      {
        projectId,
        drawRequestId,
        groupBy: modelUnitsGrouping,
        filterKey,
      },
    ]);
  };

  const invalidateAfterModelPatch = async (data) => {
    queryClient.setQueriesData<{ results: IMilestone[] }>(
      {
        queryKey: [
          QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
          {
            projectId,
            drawRequestId,
            groupBy: modelUnitsGrouping,
            filterKey,
          },
        ],
      },
      (old) =>
        replaceMilestoneData({
          milestones: old,
          milestoneId: data.id,
          json: data,
        }),
    );
    await queryClient.invalidateQueries([
      QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
      {
        projectId,
        drawRequestId,
        groupBy: lineItemUnitGrouping,
        typeKeys: typeFilterValue,
      },
    ]);
    await queryClient.invalidateQueries([
      QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
      { projectId, drawRequestId, groupBy: unitLineItemGrouping, filterKey },
    ]);
  };

  const onMilestoneUpdate = useCallback(
    async (data) => {
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES_COLUMNS,
        { projectId, requestId: drawRequestId },
      ]);
      if (isLineItemsView) {
        await invalidateAfterLineItemPatch(data);
        updateLineItemsListItemsWithParentGroup(data);
        setUnitsTableItems(null);
        setModelsTableItems(null);
      }
      if (isUnitsView) {
        await invalidateAfterUnitPatch(data);
        updateUnitsListItemsWithParentGroup(data);
        setLineItemsTableItems(null);
        setModelsTableItems(null);
      }
      if (isModelsView) {
        await invalidateAfterModelPatch(data);
        setModelsTableItems(
          (old) =>
            replaceMilestoneData({
              milestones: { results: old },
              milestoneId: data.id,
              json: data,
            })?.results,
        );
        setLineItemsTableItems(null);
        setUnitsTableItems(null);
      }

      refetchAndReplaceTotals({ isMilestonesUpdate: false });
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST,
        { projectId, drawRequestId },
      ]);
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST_V2,
        { project_id: projectId, draw_request_id: drawRequestId },
      ]);
      if (isSubmissionProcess) {
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
          {
            projectId,
            drawRequestId,
            filterKey: LineItemFilterValues.CURRENT_REQUEST_ONLY.filterKey,
            restQlParams: '{}',
            limit: '1',
          },
        ]);
      }
    },
    [
      projectId,
      drawRequestId,
      unitLineItemGrouping,
      lineItemUnitGrouping,
      modelUnitsGrouping,
      query,
      filterKey,
      refetchAndReplaceTotals,
      isLineItemsView,
      isUnitsView,
      isModelsView,
    ],
  );

  return {
    requestedDataQueries: [project, ...requestedDataQueries],
    deleteDrawRequestMutation,
    unitsQuery,
    modelsQuery,
    lineItemsQuery,
    bulkMilestoneMutation,
    onMilestoneUpdate,
    patchMSGroupMutation,
    drawRequestsQuery,
    invalidationAfterBulkUpdate,
  };
};
