import { currencyFormatter, ColumnType } from '@utils';
import { IDrawRequest } from '@interfaces';

const requested_amount: ColumnType<IDrawRequest> = () => ({
  field: 'requested_amount',
  graphFields: ['requested_amount'],
  headerName: 'Requested amount ($)',
  minWidth: 120,
  groupable: false,
  aggregable: false,
  valueFormatter: (value) => currencyFormatter(value, ''),
});

export default requested_amount;
