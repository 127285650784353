import React, { FC } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import {
  CustomRadioGroup,
  CustomTextField,
  LabelAndValueWithPreload,
  ProgressWithArrow,
  WysiwygEditor,
} from '@components';
import { RequestTableTabTypesEnum } from '@interfaces';
import { currencyFormatter } from '@utils';
import { colors, typography } from '@theme';
import { useLineItemBreakdownPanel } from './controller';
import FundingSourceField from './FundingSourceField';

const LineItemBreakdownPanel: FC<{
  milestoneId?: string;
  requestId?: string;
  setOpen?: CallableFunction;
  type?: RequestTableTabTypesEnum;
  source?: string;
}> = ({ milestoneId, requestId, setOpen, type, source }) => {
  const {
    itemName,
    revisedScheduledValue,
    costType,
    handleCostTypeChange,
    costTypeOptions,
    submit,
    isSubmiting,
    deleteLineItem,
    isEditable,
    comment,
    customSources,
    isUpdating,
    handleFundingSourceChange,
    isSubmitDisabled,
  } = useLineItemBreakdownPanel({
    drawRequestId: requestId,
    milestoneId,
    setOpen,
    type,
  });

  return (
    <Stack flex={1}>
      <Stack mt={1} flex={1} spacing={2.5}>
        <LabelAndValueWithPreload
          isLoading={isUpdating}
          label="Revised scheduled value ($)"
          labelStyle={{ ...typography.body2SemiBold }}
        >
          <ProgressWithArrow
            prevValue={currencyFormatter(revisedScheduledValue.prevValue, '-')}
            nextValue={currencyFormatter(revisedScheduledValue.nextValue, '-')}
            showProgress
            nextValueDataTestName={`${source}__line_item_modal__revised_estimate__value`}
            getTextStyleForActive={true}
          />
        </LabelAndValueWithPreload>
        <CustomTextField
          field={itemName}
          label="Line item name"
          required
          inputProps={{ 'data-cy': `${source}__line_item_modal__name_input` }}
        />
        <CustomRadioGroup
          disabled={!isEditable}
          dataTestName={`${source}__line_item_modal__cost_type__radio_group`}
          value={costType}
          onChange={handleCostTypeChange}
          options={costTypeOptions}
        />
        <Stack spacing={3}>
          <Typography variant="h4" color={colors.text.heading}>
            Available funds
          </Typography>
          {customSources.map((fundingSource) => (
            <FundingSourceField
              key={fundingSource.id}
              fundingSource={fundingSource}
              isUpdating={isUpdating}
              source={source}
              handleFundingSourceChange={handleFundingSourceChange}
            />
          ))}
        </Stack>
        <WysiwygEditor editField={comment} source={`${source}__line_item_modal__comment`} />
      </Stack>
      <Stack spacing={2} justifyContent="flex-end" direction="row" mt={3}>
        {isEditable && (
          <Button
            loading={!!isSubmiting}
            onClick={deleteLineItem}
            color="error"
            variant="outlined"
            data-cy={`${source}__line_item_modal__delete__button`}
          >
            Delete
          </Button>
        )}
        <Button
          loading={!!isSubmiting}
          onClick={submit}
          variant="contained"
          data-cy={`${source}__line_item_modal__submit__button`}
          disabled={isSubmitDisabled}
        >
          Submit
        </Button>
      </Stack>
    </Stack>
  );
};

export default LineItemBreakdownPanel;
