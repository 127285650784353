import { ArgumentsDrawRequestApi } from '@globalService';
import { getParamString } from '@utils';

export const drawRequestApi = ({
  pagination,
  project_id,
  draw_request_id,
  ...params
}: ArgumentsDrawRequestApi) =>
  `projects/${project_id}/draw_requests/${draw_request_id}/?${getParamString(params)}`;
