import React from 'react';
import { Stack } from '@mui/material';

import { ButtonWithTooltip, VerticalMenu } from '@components';
import { TOOLTIP_TEXTS } from '@constants';

import { useServicesButton } from './controller';

const OrderServiceButton = () => {
  const { menuItems, isLoading, isCurrentProjectArchived } = useServicesButton();

  return (
    Boolean(menuItems.length) && (
      <Stack direction="row" spacing={2}>
        <VerticalMenu menuItems={menuItems} disabled={isCurrentProjectArchived || isLoading}>
          <ButtonWithTooltip
            tooltipText={TOOLTIP_TEXTS.isCurrentProjectArchived}
            fullWidth={true}
            loading={isLoading}
            disabled={isCurrentProjectArchived}
            dataTestName="project__add_request__button"
          >
            Order service
          </ButtonWithTooltip>
        </VerticalMenu>
      </Stack>
    )
  );
};

export default OrderServiceButton;
