import React, { FC, useCallback, useMemo, useState } from 'react';
import { Button, Stack } from '@mui/material';
import { Popup } from '@components';
import { EnumTypeForList } from '@interfaces';
import { SummaryEditableDataEnum, TChangedData } from '../interface';
import {
  DateInput,
  NumberInput,
  RateInput,
  ReasonInput,
  StatusInput,
  TextInput,
} from './components';
import { useDayJsFormatter } from '@hooks';
import { useLaunchDarklyFlags } from '@context';

interface ChangeDataPopupProps {
  onClose: () => void;
  onSave: (value: TChangedData, reasons?: string[]) => void;
  initData?: TChangedData;
  title: string;
  type: SummaryEditableDataEnum;
  options?: EnumTypeForList[];
  source: string;
  validationRule?: (value: Date) => { value: boolean; reason: string };
  minDate?: Date;
  maxDate?: Date;
}

// All date fields with their timestamp requirements
const DATE_FIELDS = {
  [SummaryEditableDataEnum.SUBMITTED]: true,
  [SummaryEditableDataEnum.APPROVED]: true,
  [SummaryEditableDataEnum.DISBURSED_AT]: false,
  [SummaryEditableDataEnum.ESTIMATED_DISBURSEMENT_DATE]: false,
  [SummaryEditableDataEnum.EXTENDED_MATURITY_DATE]: false,
  [SummaryEditableDataEnum.FUNDING_DATE]: false,
} as const;

const STATUS_FIELDS = [
  SummaryEditableDataEnum.SERVICING_STATUS,
  SummaryEditableDataEnum.INTEREST_METHOD,
  SummaryEditableDataEnum.LOAN_TYPE,
];

const ChangeDataPopup: FC<ChangeDataPopupProps> = ({
  onClose,
  onSave,
  initData,
  title,
  type,
  options = [],
  source,
  validationRule,
  minDate,
  maxDate,
}) => {
  const [isValid, setIsValid] = useState(false);
  const [value, setValue] = useState<TChangedData>(initData);
  const [reasons, setReasons] = useState<string[]>([]);
  const { areEqualDates } = useDayJsFormatter();
  const flags = useLaunchDarklyFlags();

  const renderDateInput = useCallback(
    ({ showReasonInput = false }: { showReasonInput?: boolean }) => (
      <Stack spacing={3}>
        <DateInput
          value={initData as Date}
          type={type}
          title={title}
          source={source}
          onValidChange={setIsValid}
          onChange={setValue}
          validationRule={validationRule}
          minDate={minDate}
          maxDate={maxDate}
          addTimestamp={type in DATE_FIELDS ? DATE_FIELDS[type] : false}
        />
        {showReasonInput && flags?.['ENG_9779_estimated_completion_date_reason_on_overview'] && (
          <ReasonInput
            reasons={reasons}
            type={type}
            source={source}
            required={!areEqualDates({ value1: value as Date, value2: initData as Date })}
            onChange={setReasons}
          />
        )}
      </Stack>
    ),
    [
      type,
      title,
      source,
      validationRule,
      minDate,
      maxDate,
      initData,
      flags,
      reasons,
      value,
      areEqualDates,
    ],
  );

  const renderInput = useCallback(() => {
    if (type in DATE_FIELDS) {
      return renderDateInput({});
    }

    switch (type) {
      case SummaryEditableDataEnum.ESTIMATED_COMPLETION_DATE:
        return renderDateInput({ showReasonInput: true });

      case SummaryEditableDataEnum.NUMBER:
        return (
          <NumberInput
            type={type}
            initValue={initData as string | number}
            onValidChange={setIsValid}
            onChange={setValue}
            source={source}
          />
        );

      case SummaryEditableDataEnum.RATE:
        return (
          <RateInput
            type={type}
            initValue={initData as string | number}
            onValidChange={setIsValid}
            onChange={setValue}
            source={source}
          />
        );

      case SummaryEditableDataEnum.SCOPE_OF_WORK:
        return (
          <TextInput
            type={type}
            initValue={initData as string}
            onValidChange={setIsValid}
            onChange={setValue}
            source={source}
          />
        );

      default:
        if (STATUS_FIELDS.includes(type)) {
          return (
            <StatusInput
              value={options.find((option) => option.name === initData) as EnumTypeForList}
              type={type}
              title={title}
              options={options}
              onValidChange={setIsValid}
              onChange={setValue}
            />
          );
        }
        return null;
    }
  }, [type, initData, title, options, source, renderDateInput]);

  const disableSave = useMemo(() => {
    if (
      type === SummaryEditableDataEnum.ESTIMATED_COMPLETION_DATE &&
      flags?.['ENG_9779_estimated_completion_date_reason_on_overview']
    ) {
      return (
        !isValid ||
        areEqualDates({ value1: value as Date, value2: initData as Date }) ||
        !reasons.length
      );
    }
    return !isValid;
  }, [isValid, reasons, type, initData, value, flags, areEqualDates]);

  const handleSave = () => {
    if (disableSave) return;

    if (type === SummaryEditableDataEnum.NUMBER || type === SummaryEditableDataEnum.RATE) {
      onSave(+value);
    } else {
      onSave(value, reasons);
    }
  };

  return (
    <Popup open maxWidth="md" title={title}>
      <Stack sx={{ width: '100%' }}>
        {renderInput()}
        <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ mt: 7 }}>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          <Button
            sx={{ ml: 1 }}
            disabled={disableSave}
            onClick={handleSave}
            data-cy={`draw_change_${type}_save_button`}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </Popup>
  );
};

export default ChangeDataPopup;
