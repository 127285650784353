import { DrawRequestTypeEnum, TableKeyEnum } from '@interfaces';
import { createColumnVisibleModel } from '@utils';
import React, { useState, useCallback, useRef } from 'react';
import { useUserTableSettings } from '@hooks';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { defaultPersonalSetting } from '@constants';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { GridColDef, GridColumnDimensions, GridSortModel } from '@mui/x-data-grid-premium';

const createInitState = ({
  memoColumns,
  withColumnIndex,
  tableKey,
  dimensions,
  userSettings,
}: {
  memoColumns: GridColDef[];
  withColumnIndex: boolean;
  tableKey: TableKeyEnum | DrawRequestTypeEnum;
  dimensions?: Record<string, GridColumnDimensions>;
  userSettings?: GridInitialStatePremium;
}) => {
  const defaultColumnOrder = memoColumns
    .map((item) => item.field)
    .filter((item) => item !== '__reorder__' && item !== 'name' && item !== 'id');

  const orderedFields = withColumnIndex
    ? ['id', '__reorder__', 'name', ...defaultColumnOrder]
    : ['__reorder__', 'name', ...defaultColumnOrder];

  if (userSettings?.columns) {
    if (
      !userSettings.columns.orderedFields ||
      orderedFields.length > userSettings.columns.orderedFields.length
    ) {
      return {
        ...userSettings,
        columns: {
          ...userSettings.columns,
          orderedFields,
        },
      };
    }
    return userSettings;
  }

  const clearState: GridInitialStatePremium = {
    columns: {
      columnVisibilityModel: {
        ...createColumnVisibleModel(
          memoColumns.map((column) => column.field),
          true,
        ),
        ...createColumnVisibleModel(defaultPersonalSetting.tables[tableKey]?.hidden_columns || []),
      },
      dimensions: dimensions || {},
      orderedFields,
    },
    filter: {
      filterModel: {
        items: [],
      },
    },
    pinnedColumns: {
      left: [...(withColumnIndex ? ['id', '__reorder__', 'name'] : ['__reorder__', 'name'])],
      right: [],
    },
    density: 'standard',
    rowGrouping: {
      model: [],
    },
    sorting: {
      sortModel: [],
    },
  };
  return clearState;
};

const useTableSettings = (
  tableKey: TableKeyEnum | DrawRequestTypeEnum,
  apiRef: React.MutableRefObject<GridApiPremium>,
  setTableLoading: React.Dispatch<React.SetStateAction<boolean>>,
  memoColumns: GridColDef[],
  rowReordering?: boolean,
  withColumnIndex?: boolean,
) => {
  const needToUpdateSettings = useRef<boolean>(true);
  const { userTableSettings, updateUserTableSettings } = useUserTableSettings({ tableKey });

  const [canRowsReorder, setCanRowsReorder] = useState(
    userTableSettings?.sorting ? userTableSettings.sorting.sortModel.length : rowReordering,
  );

  const [initialSettings, setInitState] = useState<GridInitialStatePremium>(() =>
    createInitState({
      memoColumns,
      withColumnIndex,
      tableKey,
      userSettings: userTableSettings,
    }),
  );

  const onSortModelChangeLocal = useCallback((model: GridSortModel) => {
    setCanRowsReorder(!model.length);
    saveCurrentSet();
  }, []);

  const saveCurrentSet = useCallback(
    async (keepOrder?: boolean) => {
      if (!needToUpdateSettings.current) return;
      apiRef.current.resetRowHeights();
      const { preferencePanel, ...currentState } = apiRef.current.exportState();
      if (currentState?.filter?.filterModel?.quickFilterValues?.length)
        currentState.filter.filterModel.quickFilterValues = [];
      if (!keepOrder) currentState.columns.orderedFields = [];
      setTableLoading(true);
      await updateUserTableSettings(currentState);
      setTableLoading(false);
    },
    [updateUserTableSettings, apiRef.current],
  );

  const clearSettings = useCallback(async () => {
    const currentState = apiRef.current.exportState();
    const dimensions = memoColumns.reduce((sum, column) => {
      const key = column.field;
      const { width, flex, ...value } = currentState.columns.dimensions[key];
      sum[key] = {
        ...value,
        ...(column.flex ? { flex: 1 } : {}),
        ...(column.minWidth ? { minWidth: column.minWidth, width: column.minWidth } : {}),
        ...(column.maxWidth ? { maxWidth: column.maxWidth } : {}),
      };
      return sum;
    }, {});

    const clearState: GridInitialStatePremium = createInitState({
      memoColumns,
      withColumnIndex,
      tableKey,
      dimensions,
    });
    setTableLoading(true);
    needToUpdateSettings.current = false;
    apiRef.current.restoreState(clearState);
    setInitState(clearState);

    await updateUserTableSettings(clearState);
    setTableLoading(false);
    needToUpdateSettings.current = true;
  }, [apiRef.current, tableKey, memoColumns, withColumnIndex, updateUserTableSettings]);

  return {
    initialSettings,
    clearSettings,
    saveCurrentSet,
    canRowsReorder,
    onSortModelChangeLocal,
  };
};

export default useTableSettings;
