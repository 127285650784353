import { percentFormatter, ColumnType } from '@utils';
import { IDrawRequest } from '@interfaces';

const lender_allowance_rate: ColumnType<IDrawRequest> = () => ({
  field: 'lender_allowance_rate',
  graphFields: ['lender_allowance_rate'],
  headerName: 'Lender allowance (%)',
  minWidth: 120,
  groupable: false,
  aggregable: false,
  valueFormatter: (value) => percentFormatter({ returnValue: ' ', value }),
});

export default lender_allowance_rate;
