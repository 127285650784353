import { useCallback, useRef, useEffect, useState, useMemo } from 'react';
import { TRIGGERHEIGHT } from '../styled';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import debounce from 'lodash/debounce';

const ROW_HEIGHT_BASIC = 36;

const useTableScrollPin = (
  apiRef: React.MutableRefObject<GridApiPremium>,
  withoutAdaptiveScroll: boolean,
  rowsLength: number,
) => {
  const containerRef = useRef<HTMLElement>();
  const [onFullScreen, setOnFullScreen] = useState(false);

  const rowBasicHeight = useMemo(() => {
    return apiRef.current?.state?.dimensions?.rowHeight || ROW_HEIGHT_BASIC;
  }, [apiRef.current]);

  const maxRowCount = useMemo(() => {
    if (onFullScreen || withoutAdaptiveScroll) return rowsLength;
    const maxRowsOnScreen = Math.round((window.innerHeight - TRIGGERHEIGHT) / rowBasicHeight);
    return Math.min(rowsLength, maxRowsOnScreen);
  }, [rowsLength, rowBasicHeight, onFullScreen, withoutAdaptiveScroll]);

  const fullScreenAvailable = useMemo(() => {
    const isOverLap = maxRowCount < rowsLength;
    return onFullScreen || (!withoutAdaptiveScroll && isOverLap);
  }, [withoutAdaptiveScroll, rowsLength, maxRowCount, onFullScreen]);

  const containerHeight = useMemo(() => {
    if (withoutAdaptiveScroll) {
      if (!containerRef.current) return undefined;
      return window.innerHeight - (containerRef.current.getBoundingClientRect().top + 20);
    }
    return fullScreenAvailable
      ? TRIGGERHEIGHT + maxRowCount * rowBasicHeight + 3 * rowBasicHeight
      : null;
  }, [
    withoutAdaptiveScroll,
    containerRef.current,
    maxRowCount,
    rowBasicHeight,
    fullScreenAvailable,
  ]);

  const checkPosition = useCallback(
    debounce(() => {
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();
        const isPinned = rect.top <= TRIGGERHEIGHT;
        if (isPinned) {
          setOnFullScreen(true);
        }
      }
    }, 50),
    [containerRef],
  );

  const checkBackPosition = useCallback(
    debounce(() => {
      const { top } = apiRef.current.getScrollPosition();
      if (top < 1) {
        setOnFullScreen(false);
      }
    }, 50),
    [apiRef.current],
  );

  const scrollToLastIndex = useCallback(() => {
    if (fullScreenAvailable) {
      setOnFullScreen(true);
    }
    apiRef.current.scrollToIndexes({
      rowIndex: rowsLength,
    });
  }, [fullScreenAvailable, apiRef.current]);

  useEffect(() => {
    if (withoutAdaptiveScroll || !fullScreenAvailable) return;
    if (onFullScreen) {
      window.removeEventListener('scroll', checkPosition);
      document
        .querySelector('.MuiDataGrid-virtualScroller')
        .addEventListener('scroll', checkBackPosition);
    } else {
      window.addEventListener('scroll', checkPosition);
      document
        .querySelector('.MuiDataGrid-virtualScroller')
        .removeEventListener('scroll', checkBackPosition);
    }

    return () => {
      window.removeEventListener('scroll', checkPosition);
      document
        .querySelector('.MuiDataGrid-virtualScroller')
        ?.removeEventListener('scroll', checkBackPosition);
      checkPosition.cancel();
      checkBackPosition.cancel();
    };
  }, [withoutAdaptiveScroll, fullScreenAvailable, onFullScreen]);

  return {
    checkPosition,
    containerRef,
    containerHeight,
    maxRowCount,
    onFullScreen,
    scrollToLastIndex,
    setOnFullScreen: fullScreenAvailable ? setOnFullScreen : null,
  };
};

export default useTableScrollPin;
