import React, { useContext, useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import {
  IDrawRequest,
  IInspection,
  IMenuItem,
  IServiceOrder,
  PermissionNamesEnums,
  QueryNamesEnums,
  ServiceTypeEnum,
} from '@interfaces';
import {
  getProjectDrawRequestsList,
  getProjectInspectionsList,
  postServiceOrderToProject,
} from '@globalService';
import { PermissionsContext, SettingsContext } from '@context';
import {
  getCurrentInspection,
  getServiceTypeDisplayName,
  isCancelRequestedService,
  isAllowed,
} from '@utils';
import {
  AppraisalIcon,
  BuilderReportIcon,
  FeasibilityIcon,
  InspectionIcon,
  PermitIcon,
  ServicePriceIcon,
  TitleIcon,
} from '@svgAsComponents';
import { TOOLTIP_TEXTS } from '@constants';
import { enqueueSnackbar } from 'notistack';

export interface ControllerInterface {
  isLoading: boolean;
  menuItems: IMenuItem[];
  isCurrentProjectArchived: boolean;
}

export const useServicesButton = (): ControllerInterface => {
  const { permissions } = useContext(PermissionsContext);
  const { isCurrentProjectArchived, isCurrentProjectActive, settings } =
    useContext(SettingsContext);

  const navigate = useNavigate();
  const { projectId } = useParams();

  const serviceTypesMap = useMemo(
    () => settings?.display?.service_types,
    [settings?.display?.service_types],
  );
  const drawRequestsQuery = useQuery<{ results: IDrawRequest[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }],
    getProjectDrawRequestsList.bind(this, projectId),
  );

  // TODO: switch this to service orders, discuss with Petro
  const { data: { results: inspectionData = [] } = {} } = useQuery<
    { results: IInspection[] },
    Error
  >(
    [QueryNamesEnums.GET_PROJECT_INSPECTIONS, { projectId }],
    getProjectInspectionsList.bind(this, { projectId }),
    {
      enabled: isAllowed(PermissionNamesEnums.INSPECTIONS_VIEW, permissions),
    },
  );

  const inProgressInspection = useMemo(
    () => getCurrentInspection(inspectionData),
    [inspectionData],
  );
  const isInspectionCancelRequested = useMemo(
    () => isCancelRequestedService(inProgressInspection?.status),
    [inProgressInspection],
  );

  const createServiceOrder = useMutation<
    IServiceOrder,
    Error,
    { projectId: string; serviceType: ServiceTypeEnum }
  >(postServiceOrderToProject, {
    onSuccess: async (data, { serviceType }) => {
      navigate(`/projects/${projectId}/service/${data.id}`, {
        state: { serviceType },
      });
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const handleOrderService = async (serviceType: ServiceTypeEnum) => {
    await createServiceOrder.mutateAsync({
      projectId,
      serviceType,
    });
  };

  const menuItems = useMemo(() => {
    return [
      ...(isAllowed(PermissionNamesEnums.INSPECTIONS_EDIT, permissions)
        ? [
            {
              action: () => handleOrderService(ServiceTypeEnum.INSPECTION),
              text: 'Inspection',
              icon: <InspectionIcon size={24} />,
              dataTestName: 'project__add_request_menu__order_service__inspection__button',
              disabled: Boolean(inProgressInspection),
              disabledTooltipText: isInspectionCancelRequested
                ? TOOLTIP_TEXTS.inspectionCancelRequested
                : TOOLTIP_TEXTS.inspectionOrdered,
            },
            {
              action: () => handleOrderService(ServiceTypeEnum.TITLE),
              text: getServiceTypeDisplayName({
                serviceTypesMap,
                serviceType: ServiceTypeEnum.TITLE,
              }),
              icon: <TitleIcon />,
              dataTestName: 'project__add_request_menu__order_service__title__button',
            },
            {
              action: () => handleOrderService(ServiceTypeEnum.FEASIBILITY),
              text: getServiceTypeDisplayName({
                serviceTypesMap,
                serviceType: ServiceTypeEnum.FEASIBILITY,
              }),
              icon: <FeasibilityIcon />,
              dataTestName: 'project__add_request_menu__order_service__feasibility__button',
            },
            {
              action: () => handleOrderService(ServiceTypeEnum.APPRAISAL),
              text: getServiceTypeDisplayName({
                serviceTypesMap,
                serviceType: ServiceTypeEnum.APPRAISAL,
              }),
              icon: <AppraisalIcon />,
              dataTestName: 'project__add_request_menu__order_service__appraisal__button',
            },
            {
              action: () => handleOrderService(ServiceTypeEnum.PERMIT_VERIFICATION_REPORT),
              text: getServiceTypeDisplayName({
                serviceTypesMap,
                serviceType: ServiceTypeEnum.PERMIT_VERIFICATION_REPORT,
              }),
              icon: <PermitIcon />,
              dataTestName:
                'project__add_request_menu__order_service__permit_verification_report__button',
            },
            {
              action: () => handleOrderService(ServiceTypeEnum.BUILDER_INFORMATION_REPORT),
              text: getServiceTypeDisplayName({
                serviceTypesMap,
                serviceType: ServiceTypeEnum.BUILDER_INFORMATION_REPORT,
              }),
              icon: <BuilderReportIcon />,
              dataTestName:
                'project__add_request_menu__order_service__builder_information_report__button',
            },
            {
              action: () => handleOrderService(ServiceTypeEnum.BROKER_PRICE_OPINION),
              text: getServiceTypeDisplayName({
                serviceTypesMap,
                serviceType: ServiceTypeEnum.BROKER_PRICE_OPINION,
              }),
              icon: <ServicePriceIcon />,
              dataTestName:
                'project__add_request_menu__order_service__broker_price_opinion__button',
            },
          ]
        : []),
    ];
  }, [permissions, inProgressInspection, isCurrentProjectActive, serviceTypesMap]);

  return {
    menuItems,
    isLoading: drawRequestsQuery.isLoading || createServiceOrder.isLoading,
    isCurrentProjectArchived,
  };
};
