import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  IInspection,
  IInspectionAgency,
  IInspectionClosePopupEnum,
  ServiceProviderEnum,
} from '@interfaces';
import { useStringFieldModel } from '@models';
import {
  debounceFunction,
  hasApprovedReallocation,
  hasRequestedReallocation,
  isAutomatedServiceProvider,
  isServiceProviderManual,
  isServiceProviderAutomatedByCS,
  isTruePicInspection,
  isValidDate,
} from '@utils';
import { useConfirmationModal, useInspectionFields, useDayJsFormatter } from '@hooks';
import { useInspectionQueriesAndMutations } from './utils';

export const useInspectionDialog = (
  projectId: string,
  onClose: (popupType?: IInspectionClosePopupEnum) => void,
  drawRequestId?: string,
) => {
  const { getInitialValue } = useDayJsFormatter();
  const navigate = useNavigate();
  const [isNewlyCreatedInspection, setIsNewlyCreatedInspection] = useState<boolean>(false);
  const [inspectionAgencySelected, setInspectionAgencySelected] = useState<IInspectionAgency>(null);
  const [otherInspectionName, setOtherInspectionName] = useState<IInspectionAgency>(null);
  const inspectionComment = useStringFieldModel({
    initValue: '',
  });
  const orderInspectionWithoutAppraisalModal = useConfirmationModal();
  const requestHasReallocationModal = useConfirmationModal();
  const [popup, setPopup] = useState<IInspectionClosePopupEnum>(null);

  const {
    myCompanyQuery,
    drawRequestQuery,
    projectQuery,
    projectDocumentsQuery,
    inspectionAgenciesData,
    deleteInspectionMutation,
    updateInfoProject,
    createInspection,
    orderInspection,
    createInspectionAgencyMutation,
    createdInspection,
    scheduleInspection,
    drawRequestInspectionsQuery,
    projectInspectionsQuery,
    getCreatedInspection,
  } = useInspectionQueriesAndMutations({
    projectId,
    drawRequestId,
    onClose,
    setPopup,
    isNewlyCreatedInspection,
    setIsNewlyCreatedInspection,
  });

  const {
    additionalContactName,
    additionalContactPhone,
    accessCode,
    primaryContactUser,
    primaryContactUserList,
    isBorrowerUsersLoading,
    inspectionRequestedAt,
    isProjectInspectionSettingsUpdated,
    primaryContactPhone,
    isContactListHasUsersWithPhone,
  } = useInspectionFields({});

  useEffect(() => {
    if (hasRequestedReallocation(drawRequestQuery.data)) requestHasReallocationModal.askConfirm();
  }, [drawRequestQuery.data]);

  const reallocationWarningText = useMemo(
    () =>
      hasApprovedReallocation(drawRequestQuery.data)
        ? {
            text: 'The inspection will be ordered with the updated schedule of values based on the approved reallocation.',
            title: 'Order with approved reallocations',
          }
        : {
            text: 'The inspection will be ordered without updated budgets as no reallocation requests were approved.\n\nYou can return to the Request tab to approve the request or proceed with the inspection order.',
            title: 'Order without reallocations',
          },
    [drawRequestQuery.data],
  );

  const getInitialRequestedDate = (inspection?: IInspection) => {
    const currentDate = getInitialValue({});
    if (!inspection?.gc_requested_at) return currentDate;
    const savedDate = getInitialValue({ date: inspection?.gc_requested_at });
    return savedDate > currentDate ? savedDate : currentDate;
  };

  const isOtherInspectionSelected = useMemo(
    () => inspectionAgencySelected?.display_name === 'Other',
    [inspectionAgencySelected],
  );

  const isAutomatedServiceProviderSelected = useMemo(
    () => isAutomatedServiceProvider(inspectionAgencySelected?.service),
    [inspectionAgencySelected],
  );
  const isPseudoAutomatedInspectionSelected = useMemo(
    () => isServiceProviderAutomatedByCS(inspectionAgencySelected?.service),
    [inspectionAgencySelected],
  );

  const showDisclaimer = useMemo(() => {
    return !isServiceProviderManual(inspectionAgencySelected?.service);
  }, [inspectionAgencySelected?.service]);

  const handleInspectionRequest = async () => {
    let inspectionAgencyId = '';

    if (!isOtherInspectionSelected) {
      inspectionAgencyId = inspectionAgencySelected?.id;
    } else if (!otherInspectionName?.id) {
      // Create new inspection agency if it's newly created input without id
      const data = await createInspectionAgencyMutation.mutateAsync({
        company: myCompanyQuery.data?.id,
        display_name: otherInspectionName?.display_name,
        service: ServiceProviderEnum.MANUAL,
      });
      inspectionAgencyId = data?.id;
    } else {
      inspectionAgencyId = otherInspectionName.id;
    }

    const inspectionValues = {
      comment: inspectionComment.value,
      inspection_agency: inspectionAgencyId,
      gc_requested_at:
        inspectionRequestedAt?.value && isValidDate(inspectionRequestedAt.value)?.value
          ? inspectionRequestedAt.value
          : new Date(),
    };

    if (isProjectInspectionSettingsUpdated) {
      await updateInfoProject.mutateAsync({
        projectId,
        json: {
          inspection_additional_contact_name: additionalContactName.value,
          inspection_additional_contact_phone: additionalContactPhone.valueToSave,
          inspection_entry_access_code: accessCode.value,
          inspection_primary_contact_user_id: primaryContactUser.value?.id || null,
        },
      });
    }

    const payload = {
      projectId,
      inspectionData: {
        ...inspectionValues,
        ...(createdInspection?.id && { inspectionId: createdInspection.id }),
      },
    };

    await orderInspection.mutateAsync(payload);
  };

  const cancelInputsValues = () => {
    inspectionComment.setValue(createdInspection?.gc_comment || '');
    inspectionRequestedAt.setValue(getInitialRequestedDate(createdInspection));
    if (isNewlyCreatedInspection) {
      deleteInspectionMutation.mutateAsync({ projectId, inspectionId: createdInspection?.id });
    }
  };

  const inspectionAgenciesList = useMemo(
    () => [
      ...(inspectionAgenciesData.data?.results?.filter(
        (o) => !isServiceProviderManual(o.service),
      ) || []),
      { display_name: 'Other', service: ServiceProviderEnum.MANUAL, id: null },
    ],
    [inspectionAgenciesData.data],
  );

  const manualInspectionAgenciesList = useMemo(
    () =>
      inspectionAgenciesData.data?.results?.filter(
        (o) => isServiceProviderManual(o.service) && o.display_name !== 'Other',
      ) || [],
    [inspectionAgenciesData.data],
  );

  useEffect(() => {
    if (inspectionAgenciesList.length && !projectQuery.isPostLoading) {
      const defaultAgency =
        createdInspection?.inspection_agency || projectQuery.data?.inspection_agency;

      if (inspectionAgencySelected?.id) return; // set default value from project settings only if it's not set from the list manually
      if (isServiceProviderManual(defaultAgency?.service)) {
        setInspectionAgencySelected({
          display_name: 'Other',
          service: ServiceProviderEnum.MANUAL,
          id: null,
        });
        setOtherInspectionName(defaultAgency);
      } else {
        const serviceFromList = inspectionAgenciesList?.find(
          (item) => item.id === defaultAgency?.id,
        );
        setInspectionAgencySelected(serviceFromList || inspectionAgenciesList[0]);
      }
    }
  }, [
    inspectionAgenciesList,
    createdInspection?.inspection_agency,
    projectQuery.data?.inspection_agency,
    projectQuery.isPostLoading,
  ]);

  useEffect(() => {
    // drawRequestId here is request in progress
    // the concept is that we use draft inspection only if inspection draft was created for active request
    // if there is no active request or no draft for it, we create new draft inspection and ignore the previous one
    if (!drawRequestId) {
      createInspection.mutateAsync({
        projectId,
        inspectionData: { ...(drawRequestId ? { draw_request: drawRequestId } : {}) },
      });
      return;
    }
    if (drawRequestId && !drawRequestInspectionsQuery.isSuccess) return;

    const createdInspection = getCreatedInspection(drawRequestInspectionsQuery.data?.results);

    if (!createdInspection) {
      createInspection.mutateAsync({
        projectId,
        inspectionData: { ...(drawRequestId ? { draw_request: drawRequestId } : {}) },
      });
    } else {
      inspectionComment.setValue(createdInspection.gc_comment || '');

      if (createdInspection?.gc_requested_at) {
        inspectionRequestedAt.setValue(getInitialRequestedDate(createdInspection));
      }
    }
  }, [drawRequestInspectionsQuery.isSuccess, projectInspectionsQuery.isSuccess, drawRequestId]);

  const submitDisabledTooltipText = useMemo(() => {
    if (!createdInspection?.id) return 'Please wait for the inspection to be created';
    if (isOtherInspectionSelected && !otherInspectionName?.display_name)
      return 'Please enter the name of the inspection agency.';
    return '';
  }, [createdInspection, isOtherInspectionSelected, otherInspectionName]);

  const handleCheckAppraisalBeforeOrderingInspection = useCallback(
    debounceFunction(async () => {
      const additionalContactNameValid = additionalContactName.validate();
      const additionalContactPhoneValid = additionalContactPhone.validate();
      const inspectionRequestedAtValid = inspectionRequestedAt.validate();
      const accessCodeValid = accessCode.validate();

      const canProceed =
        inspectionRequestedAtValid &&
        accessCodeValid &&
        (primaryContactUser?.value || (additionalContactNameValid && additionalContactPhoneValid));

      if (!canProceed || submitDisabledTooltipText) {
        return;
      }

      if (isAutomatedServiceProviderSelected && !projectDocumentsQuery.data?.results?.length) {
        orderInspectionWithoutAppraisalModal.askConfirm();
      } else {
        handleInspectionRequest();
      }
    }, 300),
    [
      isAutomatedServiceProviderSelected,
      projectDocumentsQuery.data?.results,
      handleInspectionRequest,
      additionalContactName,
      additionalContactPhone,
      inspectionRequestedAt,
      accessCode,
      primaryContactUser,
      submitDisabledTooltipText,
    ],
  );

  const tooltipText = useMemo(() => {
    if (isNewlyCreatedInspection || !createdInspection) return '';
    const hasComment = Boolean(createdInspection.gc_comment);
    const hasDate = Boolean(createdInspection.gc_requested_at);

    if (hasComment && hasDate) {
      return 'Borrower has already supplied the earliest availability date and additional information with the Draw request submission.';
    }
    if (hasComment) {
      return 'Borrower has already supplied additional information with the Draw request submission.';
    }
    if (hasDate) {
      return 'Borrower has already supplied the earliest availability date with the Draw request submission.';
    }
    return '';
  }, [createdInspection, isNewlyCreatedInspection]);

  const goToRequest = useCallback(() => {
    navigate(`/projects/${projectId}/draws/draw-requests/${drawRequestId}/`);
  }, [projectId, drawRequestId]);

  const isLoadingState = useMemo(() => projectQuery.isPostLoading, [projectQuery.isPostLoading]);

  return {
    inspectionComment,
    borrowerComment: createdInspection?.gc_comment || '',
    drNumber: drawRequestQuery.data?.number,
    project: projectQuery.data,
    cancelInputsValues,
    handleInspectionRequest,
    isSubmitting:
      orderInspection.isLoading || createInspection.isLoading || scheduleInspection.isLoading,
    inspectionAgencySelected,
    setInspectionAgencySelected,
    inspectionAgenciesList,
    manualInspectionAgenciesList,
    otherInspectionName,
    setOtherInspectionName,
    isOtherInspectionSelected,
    isAutomatedServiceProviderSelected,
    isSubmitDisabled: Boolean(submitDisabledTooltipText),
    orderInspectionWithoutAppraisalModal,
    handleCheckAppraisalBeforeOrderingInspection,
    inspectionRequestedAt,
    inspectionFields: {
      additionalContactName,
      additionalContactPhone,
      accessCode,
      primaryContactUserList,
      isBorrowerUsersLoading,
      inspectionRequestedAt,
      primaryContactUser,
      primaryContactPhone,
      isContactListHasUsersWithPhone,
    },
    tooltipText,
    submitDisabledTooltipText,
    createdInspection,
    requestHasReallocationModal,
    reallocationWarningText,
    goToRequest,
    isPseudoAutomatedInspectionSelected,
    popup,
    setPopup,
    showDisclaimer,
    isTruePicInspection: isTruePicInspection(inspectionAgencySelected?.service),
    isLoadingState,
  };
};
