import React, { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';
import { Box, Button, Divider, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import snakeCase from 'lodash/snakeCase';

import { HookState, PermissionNamesEnums } from '@interfaces';
import NodeCard from '../NodeCard';
import {
  ButtonWithTooltip,
  DrawRequestStatusChip,
  LabelAndValue,
  LoadingSkeleton,
  ProgressWithArrow,
  ServiceMessage,
  StatusChip,
  StyledLink,
  ToggleButtonGroup,
  ValueAndLabelWithPreload,
  WidgetPaperCard,
} from '@components';
import { AdditionalRequirements, NoDataImage } from '@svgAsComponents';
import { currencyFormatter, getTooltipText, isChangeRequest, WithPermission } from '@utils';
import { colors } from '@theme';
import * as Controller from './controller';
import { UserAndCompanyLabel } from '../RequestSummary/subcomponents';
import { ControllerInterface } from './interface';

const DrawRequestCard: FC = () => {
  const controller = Controller.useDrawRequestCard();

  if (controller.isEmptyDrawRequestsList) return <NoDrawRequest />;

  switch (controller.state) {
    case HookState.LOADING: {
      return (
        <NodeCard style={{ minHeight: '100%' }} title={`Request`}>
          <LoadingSkeleton type="overviewBlock" />
        </NodeCard>
      );
    }
    case HookState.ERROR: {
      return <ServiceMessage text="active request" />;
    }

    case HookState.SUCCESS: {
      if (!controller.drawRequest)
        return (
          <NodeCard style={{ minHeight: '100%' }} title={`Request`}>
            <LoadingSkeleton type="overviewBlock" />
          </NodeCard>
        );
      return isChangeRequest(controller.drawRequest) ? (
        <ChangeRequestCardBody controller={controller} />
      ) : (
        <DrawRequestCardBody controller={controller} />
      );
    }

    default:
      return null;
  }
};

export default DrawRequestCard;

const NoDrawRequest = () => {
  if (isMobileOnly) return null;
  return (
    <WidgetPaperCard style={{ minHeight: '100%' }} title="Request information">
      <Stack
        sx={{ py: '60px', flexDirection: 'column' }}
        justifyContent="center"
        alignItems="center"
      >
        <NoDataImage size={145} />

        <Typography sx={{ mt: 3, mb: 0.5 }} variant="body2SemiBold" align="center">
          No created requests yet
        </Typography>

        <Typography variant="body3" align="center">
          Please wait until the first request will be
          <br />
          submitted.
        </Typography>
      </Stack>
    </WidgetPaperCard>
  );
};

const DrawRequestCardBody: FC<{ controller: ControllerInterface }> = ({ controller }) => {
  const navigate = useNavigate();
  const { projectId } = useParams();

  const {
    drawRequest,
    inReview,
    isDraft,
    hasBeenReviewed,
    inspectorAllowanceProgress,
    gapProgress,
    progressType,
    handleProgressTypeChange,
    requestedAmount,
    approvedAmount,
    drawRequestDate,
    currentReviewer,
    isLoading,
    requestedScheduledValues,
    revisedScheduledValues,
    requestLink,
    lenderAllowanceProgress,
    showAdditionalRequirements,
    isReallocationAllowed,
    openDraft,
    isCurrentProjectActive,
    continueDraftButtonLabel,
  } = controller;

  return (
    <CardHeader isDrawRequest requestLink={requestLink} request={drawRequest}>
      <Stack spacing={1} sx={{ flexGrow: 1, mt: 1 }}>
        {Boolean(showAdditionalRequirements) && (
          <>
            <Box sx={{ py: 2 }}>
              <Divider />
            </Box>
            <StatusChip
              color={colors.status.orange.medium}
              backgroundColor={colors.status.orange.lighter}
              size="large"
              label={
                <Stack direction="row" alignItems="center">
                  <AdditionalRequirements size={24} />
                  <Typography
                    variant="body2SemiBold"
                    sx={{ color: colors.status.orange.medium, pl: 1 }}
                    data-cy="request__request_card__additional_requirements__value"
                  >
                    {`${drawRequest?.submission_checklist_incomplete_items_total} additional requirements`}
                  </Typography>
                </Stack>
              }
            />
            <Button
              onClick={() =>
                navigate(`/projects/${projectId}/requests/${drawRequest?.id}/resubmit`)
              }
              data-cy="request__request_card__fulfill_requirements__button"
            >
              Fulfill requirements
            </Button>
            <Box sx={{ py: 2 }}>
              <Divider />
            </Box>
          </>
        )}
        {(inReview || isDraft) && (
          <>
            <ValueAndLabelWithPreload
              value={requestedAmount}
              label="requested draw"
              isLoading={isLoading}
            />
            {!isDraft && (
              <ValueAndLabelWithPreload
                value={approvedAmount}
                label="approved amount"
                color={colors.status.information.medium}
                isLoading={isLoading}
              />
            )}
            <Box sx={{ py: 1.5 }}>
              {continueDraftButtonLabel ? (
                <ButtonWithTooltip
                  disabled={!isCurrentProjectActive}
                  tooltipText={getTooltipText({ nonActiveProject: !isCurrentProjectActive })}
                  fullWidth
                  onClick={openDraft}
                  dataTestName="request__request_card__continue_draft__button"
                >
                  {continueDraftButtonLabel}
                </ButtonWithTooltip>
              ) : (
                <Divider />
              )}
            </Box>
          </>
        )}

        {hasBeenReviewed && (
          <>
            <LabelAndValue label="Approved draw" text={currencyFormatter(approvedAmount || 0)} />
            <LabelAndValue
              label="Approved total reallocations"
              text={currencyFormatter(drawRequest?.approved_reallocation || 0)}
            />
            <LabelAndValue
              label="Approved total budget change"
              text={currencyFormatter(drawRequest?.totals?.all?.approved_budget_change || 0)}
            />
            <LabelAndValue
              label="Revised scheduled value"
              text={currencyFormatter(revisedScheduledValues)}
            />
          </>
        )}

        {(inReview || isDraft) && (
          <>
            {isReallocationAllowed && (
              <LabelAndValue
                label="Requested total reallocations"
                text={currencyFormatter(drawRequest?.requested_reallocation || 0)}
              />
            )}
            <LabelAndValue
              label="Requested total budget change"
              text={currencyFormatter(drawRequest?.totals?.all?.requested_budget_change || 0)}
            />
            <LabelAndValue
              label="Requested scheduled value"
              text={currencyFormatter(requestedScheduledValues)}
            />
            <LabelAndValue
              label={'Current scheduled value'}
              text={currencyFormatter(revisedScheduledValues)}
            />
          </>
        )}
        <Box sx={{ py: 1.5 }}>
          <Divider />
        </Box>

        {drawRequestDate?.value && (
          <LabelAndValue label={drawRequestDate.label} text={drawRequestDate.value} />
        )}

        <LabelAndValue label="Draw status">
          <DrawRequestStatusChip drawRequest={drawRequest} />
        </LabelAndValue>

        <UserAndCompanyLabel
          actionUser={isDraft ? drawRequest.created_by : drawRequest.submitted_by}
          isUpdating={isLoading}
          label={isDraft ? 'Created by' : 'Submitted by'}
        />

        {inReview && currentReviewer && (
          <WithPermission permissionKey={PermissionNamesEnums.DRAWREQUESTS_REVIEWER_TEAM_VIEW}>
            <LabelAndValue label="Team" text={currentReviewer} />
          </WithPermission>
        )}

        <Box sx={{ py: 1.5 }}>
          <Divider />
        </Box>

        {inReview && (
          <Stack direction="row" justifyContent="flex-end">
            <Typography variant="labelSemiBold" sx={{ color: colors.text.medium }}>
              Pre ➝ Post draw (%)
            </Typography>
          </Stack>
        )}

        <LabelAndValue label={lenderAllowanceProgress.title}>
          <ProgressWithArrow
            prevValue={lenderAllowanceProgress.currentShare}
            nextValue={lenderAllowanceProgress.nextShare}
            showProgress={lenderAllowanceProgress.showProgress}
            getTextStyleForActive={lenderAllowanceProgress.getTextStyleForActive}
          />
        </LabelAndValue>

        <WithPermission permissionKey={PermissionNamesEnums.INSPECTIONS_VIEW}>
          <LabelAndValue label={inspectorAllowanceProgress.title}>
            <ProgressWithArrow
              prevValue={inspectorAllowanceProgress.currentShare}
              nextValue={inspectorAllowanceProgress.nextShare}
              showProgress={inspectorAllowanceProgress.showProgress}
            />
          </LabelAndValue>
        </WithPermission>
        <WithPermission permissionKey={PermissionNamesEnums.INSPECTIONS_VIEW}>
          <LabelAndValue
            label={gapProgress.title}
            icon={
              <Tooltip title="Total Inspection Allowance (%) - Total Lender Allowance (%)">
                <InfoOutlinedIcon
                  sx={{
                    fontSize: '1rem',
                    color: colors.status.information.medium,
                    mx: 1,
                    '&:hover': { cursor: 'pointer' },
                  }}
                />
              </Tooltip>
            }
          >
            <ProgressWithArrow
              prevValue={gapProgress.currentShare}
              nextValue={gapProgress.nextShare}
              getTextStyleForActive={gapProgress.getTextStyleForActive}
              showProgress={gapProgress.showProgress}
            />
          </LabelAndValue>
        </WithPermission>
        <Box sx={{ mb: 1.5 }} />

        <ToggleButtonGroup
          value={progressType}
          handleChange={handleProgressTypeChange}
          typesList={Object.values(Controller.PROGRESS_TYPES).map((item) => ({
            label: item,
            value: item,
          }))}
        />
        <ProgressBlock controller={controller} />
        <Box sx={{ mb: 2 }} />
      </Stack>
    </CardHeader>
  );
};

const ChangeRequestCardBody: FC<{ controller: ControllerInterface }> = ({ controller }) => {
  const {
    drawRequest,
    hasBeenReviewed,
    progressType,
    handleProgressTypeChange,
    drawRequestDate,
    requestedReallocation,
    revisedScheduledValues,
    requestedScheduledValues,
    approvedReallocation,
    inReview,
    requestLink,
    isLoading,
    isDraft,
  } = controller;

  return (
    <CardHeader isDrawRequest={false} requestLink={requestLink} request={drawRequest}>
      <Stack spacing={1} sx={{ flexGrow: 1, mt: 1 }}>
        {hasBeenReviewed && (
          <LabelAndValue
            label="Approved total reallocations"
            text={currencyFormatter(approvedReallocation)}
          />
        )}
        <LabelAndValue
          label="Requested total reallocations"
          text={currencyFormatter(requestedReallocation)}
        />
        {hasBeenReviewed && (
          <LabelAndValue
            label="Approved total budget change"
            text={currencyFormatter(drawRequest?.totals?.all?.approved_budget_change || 0)}
          />
        )}
        <LabelAndValue
          label="Requested total budget change"
          text={currencyFormatter(drawRequest?.totals?.all?.requested_budget_change || 0)}
        />
        {inReview && (
          <LabelAndValue
            label="Requested scheduled value"
            text={currencyFormatter(requestedScheduledValues)}
          />
        )}
        <LabelAndValue
          label="Revised scheduled value"
          text={currencyFormatter(revisedScheduledValues)}
        />
        <Box sx={{ py: 1.5 }}>
          <Divider />
        </Box>
        {drawRequestDate?.value && (
          <LabelAndValue label={drawRequestDate.label} text={drawRequestDate.value} />
        )}

        <LabelAndValue label="Draw status">
          <DrawRequestStatusChip drawRequest={drawRequest} />
        </LabelAndValue>
        <UserAndCompanyLabel
          actionUser={isDraft ? drawRequest.created_by : drawRequest.submitted_by}
          isUpdating={isLoading}
          label={isDraft ? 'Created by' : 'Submitted by'}
        />
        <Box sx={{ py: 1.5 }}>
          <Divider />
        </Box>
        <ToggleButtonGroup
          value={progressType}
          handleChange={handleProgressTypeChange}
          typesList={Object.values(Controller.PROGRESS_TYPES).map((item) => ({
            label: item,
            value: item,
          }))}
        />
        <ProgressBlock controller={controller} />
        <Box sx={{ mb: 2 }} />
      </Stack>
    </CardHeader>
  );
};

const ProgressBlock: FC<{ controller: ControllerInterface }> = ({ controller }) => {
  const {
    constuctionBudgetProgress,
    borrowerEquityProgress,
    constuctionHoldbackProgress,
    isLoading,
    progressType,
  } = controller;
  const dataTestPrefix = `request__request_card__${snakeCase(progressType)}`;
  return (
    <>
      <ProgressBlockWithLabel
        {...constuctionBudgetProgress}
        source={`${dataTestPrefix}__construction_budget_progress`}
        isLoading={isLoading}
      />
      <ProgressBlockWithLabel
        {...constuctionHoldbackProgress}
        source={`${dataTestPrefix}__construction_holdback_progress`}
        isLoading={isLoading}
      />
      <ProgressBlockWithLabel
        {...borrowerEquityProgress}
        source={`${dataTestPrefix}__borrower_equity_progress`}
        isLoading={isLoading}
      />
    </>
  );
};

const ProgressBlockWithLabel = ({
  title,
  currentShare,
  nextShare,
  currentAmount,
  nextAmount,
  showProgress = true,
  getTextStyleForActive = false,
  isLoading,
  source,
}) => {
  if (!title && isLoading) return <Skeleton sx={{ my: 1 }} />;

  return (
    <Box>
      <Typography variant="label" component="span" color={colors.text.medium}>
        {title}
      </Typography>
      {isLoading ? (
        <Skeleton sx={{ my: 1 }} />
      ) : (
        <Stack
          direction={!showProgress ? 'row' : 'column'}
          alignItems={!showProgress ? 'center' : 'flex-start'}
          justifyContent="space-between"
          spacing={0.5}
          sx={{ mt: 0.5 }}
        >
          <ProgressWithArrow
            prevValue={currencyFormatter(currentAmount, '-')}
            nextValue={currencyFormatter(nextAmount, '-')}
            showProgress={showProgress}
            getTextStyleForActive={getTextStyleForActive}
            prevValueDataTestName={`${source}__current_value`}
            nextValueDataTestName={`${source}__next_value`}
          />
          <ProgressWithArrow
            prevValue={currentShare}
            nextValue={nextShare}
            showProgress={showProgress}
            getTextStyleForActive={getTextStyleForActive}
            prevValueDataTestName={`${source}__current_rate`}
            nextValueDataTestName={`${source}__next_rate`}
          />
        </Stack>
      )}
    </Box>
  );
};

const CardHeader = ({ children, isDrawRequest, requestLink, request }) => {
  return (
    <WidgetPaperCard
      style={{ minHeight: '100%' }}
      title={
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: '100%' }}
        >
          <Typography variant="h4">{`${isDrawRequest ? 'Draw' : 'Change'} request #${
            request?.counter_per_request_type
          }`}</Typography>

          <StyledLink variant="labelSemiBold" to={requestLink}>
            See details
          </StyledLink>
        </Stack>
      }
    >
      {children}
    </WidgetPaperCard>
  );
};
