import { QueryNamesEnums } from '@interfaces';
import { FilterObject } from '../interface';
import { basicFilterSerializer } from '@utils';

export const unitsFilter: FilterObject = {
  title: 'Units',
  filterKey: 'units',
  needToUpdateUrl: false,
  needToUpdateSetting: false,
  getDataParamsPaginated: ({ pagination, q, skip, id }) => ({
    type: QueryNamesEnums.GET_PROJECT_MILESTONES,
    keys: ['tags', 'name'],
    args: { pagination, q, id },
    options: {
      skip,
    },
  }),
  strictSerialize: (data) => basicFilterSerializer(data),
  cypressSelector: 'filters__units',
  withPagination: true,
};
