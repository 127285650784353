import { useCallback, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
// import find from 'lodash/find';

import {
  IRightDrawerParams,
  QueryNamesEnums,
  RequestTableTabsEnum,
  TableKeyEnum,
} from '@interfaces';

import {
  useCommentsAndDocumentsPreview,
  useLineItemsFilter,
  useRightMenu,
  useServiceOrderFieldsProperties,
} from '@hooks';
import { useGraphQuery } from '@context';
import { LineItemFilterValues, onlyProgressPhotos } from '@constants';
import {
  getServiceOrderMilestoneTotals,
  getInitColumns,
  getFilterOptions,
  updateAllQueries,
} from './utils';

export const useServiceOrderMilestonesTable = () => {
  const { serviceOrderId, projectId } = useParams();
  const queryClient = useQueryClient();

  const [rightDrawerParams, setRightDrawerParams] = useState<IRightDrawerParams>({
    projectId,
    serviceOrderId,
  });

  const {
    filterValue,
    handleFilterClick,
    defaultOptions,
    isMilestoneMutatingOrFetching,
    // filterKey,
  } = useLineItemsFilter({
    defaultState: LineItemFilterValues.ALL.filterValue,
    tableKey: TableKeyEnum.INSPECTION_RESULTS,
  });

  const { updateCommentsPreviewInfo } = useCommentsAndDocumentsPreview({
    projectId,
    serviceOrderId,
  });

  const { handleRightDrawerOpenerClick, ...rightMenu } = useRightMenu({
    onClose: updateCommentsPreviewInfo,
  });

  const updateRightDrawer = useCallback(
    ({ tab }: { tab?: RequestTableTabsEnum }) =>
      () => {
        handleRightDrawerOpenerClick({
          title: tab ? 'Inspection' : 'Comments',
        });
        setRightDrawerParams({
          tab,
          projectId,
          serviceOrderId,
        });
      },
    [projectId, serviceOrderId, handleRightDrawerOpenerClick],
  );

  // const key = find(defaultOptions, { filterValue })?.totalKey;

  const serviceOrderQuery = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT_SERVICE_ORDER_BY_ID,
    args: { project_id: projectId, service_order_id: serviceOrderId },
    keys: [
      'id',
      'is_single_value',
      'draw_request',
      'totals',
      'project',
      'service_type',
      'status',
      'result_documents',
      'comments_preview',
      'documents_preview',
      'is_editable',
    ],
  });

  const serviceOrderPhotos = useGraphQuery({
    type: QueryNamesEnums.GET_SERVICE_ORDER_DOCUMENTS,
    args: { project_id: projectId, service_order_id: serviceOrderId, query: onlyProgressPhotos },
    keys: ['milestone_ids', 'original_filename'],
  });

  const milestonesQuery = useGraphQuery({
    type: QueryNamesEnums.GET_SERVICE_ORDER_MILESTONES,
    args: { project_id: projectId, service_order_id: serviceOrderId },
    keys: [
      'id',
      'name',
      'previous_inspector_allowance_rate',
      'inspector_allowance_rate',
      'revised_estimate',
      'requested_amount',
      'documents_preview',
      'comments_preview',
    ],
  });

  const fieldsProperties = useServiceOrderFieldsProperties({
    serviceOrder: serviceOrderQuery.data,
  });

  const updateQueries = useCallback(() => {
    updateAllQueries(queryClient, {
      projectId,
      serviceOrderId,
      drawRequestId: serviceOrderQuery.data?.draw_request?.id,
    });
  }, [queryClient, projectId, serviceOrderId, serviceOrderQuery.data?.draw_request?.id]);

  const isServiceOrderLinkedToDR = useMemo(
    () => Boolean(serviceOrderQuery.data?.draw_request?.id),
    [serviceOrderQuery.data],
  );

  const initColumns = useMemo(
    () =>
      getInitColumns({
        isEditable: serviceOrderQuery.data?.is_editable,
        isServiceOrderLinkedToDR,
        showInspectorRate:
          !fieldsProperties['inspector_allowance_rate']?.view ||
          !serviceOrderQuery.data?.is_single_value,
      }),
    [
      serviceOrderQuery.data?.is_editable,
      isServiceOrderLinkedToDR,
      serviceOrderQuery.data?.is_single_value,
      fieldsProperties['inspector_allowance_rate']?.view,
    ],
  );

  const totals = useMemo(
    () => getServiceOrderMilestoneTotals(serviceOrderQuery.data, filterValue, defaultOptions),
    [serviceOrderQuery.data, filterValue, defaultOptions],
  );

  return {
    serviceOrder: serviceOrderQuery.data,
    serviceOrderRefetch: updateQueries,
    initColumns,
    milestones: milestonesQuery.data?.results || [],
    serviceOrderPhotos: serviceOrderPhotos.data?.results,
    rightMenu,
    rightDrawerParams,
    updateRightDrawer,
    totals,
    filterValue,
    handleFiltersChange: handleFilterClick,
    filterOptions: getFilterOptions(defaultOptions),
    isMilestoneMutatingOrFetching,
    isServiceOrderLinkedToDR,
    fieldsProperties,
  };
};
