import { styled } from '@mui/material';
import React from 'react';
import { ColumnType, isAllowed } from '@utils';
import { scoreColorMap } from '@constants';
import { PopoverContentList, PopoverTooltip } from '@components';
import { PermissionNamesEnums, IDrawRequest } from '@interfaces';

const StyledScoreButton = styled('span')(({ color }) => ({
  width: 24,
  height: 24,
  borderRadius: 2,
  backgroundColor: color,
  display: 'block',
}));

const project_overall_score: ColumnType<IDrawRequest> = () => ({
  field: 'project.overall_score',
  graphFields: [],
  graphNested: {
    project: ['overall_score'],
  },
  headerName: 'Project health',
  minWidth: 120,
  groupable: false,
  aggregable: false,
  enableByPermissions: (permissions) => isAllowed(PermissionNamesEnums.SCORES_VIEW, permissions),
  renderCell: ({ row }) => {
    const value = row?.project?.overall_score;
    const isHasComment = value?.comments?.length;
    const comments = value?.flags?.join(', ');
    const color = scoreColorMap[value?.color || value];

    return (
      <span onClick={(e) => e.stopPropagation()}>
        {isHasComment ? (
          <PopoverTooltip popoverContent={<PopoverContentList title="Comments" data={comments} />}>
            <StyledScoreButton color={color} />
          </PopoverTooltip>
        ) : (
          <StyledScoreButton color={color} style={{ cursor: 'auto' }} />
        )}
      </span>
    );
  },
});

export default project_overall_score;
