import { Box, Typography } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { tableHeaders as headers, isChangeRequest } from '@utils';
import { ControllerInterface } from '../controller';
import { ReactTable } from '@components';

export const ChangeLog: FC<{ controller: ControllerInterface }> = ({ controller }) => {
  const { previousDrawRequest } = controller;

  const columns = useMemo(
    () => [
      headers.requestWithNumber({
        accessor: 'counter_per_request_type',
      }),
      headers.amount({
        header: 'Approved reallocations ($)',
        accessor: 'approved_reallocation',
        isLoading: false,
        typoprops: {
          variant: 'printBody1',
        },
      }),
      headers.amount({
        header: 'Approved budget change',
        accessor: 'approved_budget_change',
        isLoading: false,
        typoprops: {
          variant: 'printBody1',
        },
      }),
      headers.amount({
        header: 'Revised scheduled value',
        accessor: 'revised_estimate',
        isLoading: false,
        typoprops: {
          variant: 'printBody1',
        },
      }),
      headers.date({
        header: 'Approval date',
        accessor: 'submitted_at',
        isLoading: false,
        typoprops: {
          variant: 'printBody1',
        },
      }),
    ],
    [],
  );

  const data = previousDrawRequest.filter(isChangeRequest);

  return data.length ? (
    <Box sx={{ breakInside: 'avoid' }}>
      <Typography pr="15px" pl="15px" variant="printH2" data-cy="report_reallocation_section_title">
        Previous reallocations
      </Typography>
      <ReactTable data={data} columns={columns} footer={false} source="report__change_log" />
    </Box>
  ) : null;
};
