import { getParamString } from '@utils';
import {
  ArgumentsServiceOrderByIdApi,
  ArgumentsProjectServiceOrdersApi,
  ArgumentsDrawRequestServiceOrdersApi,
  ArgumentsServiceOrderDocumentsApi,
} from '../../args';

export const serviceOrderByIdApi = ({
  pagination,
  project_id,
  service_order_id,
  ...params
}: ArgumentsServiceOrderByIdApi) =>
  `projects/${project_id}/service_orders/${service_order_id}/?${getParamString(params)}`;

export const serviceOrderMilestonesApi = ({
  pagination,
  project_id,
  service_order_id,
  ...params
}: ArgumentsServiceOrderByIdApi) =>
  `projects/${project_id}/service_orders/${service_order_id}/milestones/?${getParamString(params)}`;

export const projectServiceOrdersApi = ({
  pagination,
  project_id,
  excluded_status,
  ...params
}: ArgumentsProjectServiceOrdersApi) =>
  `projects/${project_id}/service_orders/?excluded_status=${excluded_status}&${getParamString(params)}`;

export const drawRequestServiceOrdersApi = ({
  pagination,
  draw_request_id,
  project_id,
  ...params
}: ArgumentsDrawRequestServiceOrdersApi) =>
  `projects/${project_id}/draw_requests/${draw_request_id}/service_orders/?${getParamString(params)}`;

export const serviceOrderDocumentsApi = ({
  pagination,
  project_id,
  service_order_id,
  ...params
}: ArgumentsServiceOrderDocumentsApi) =>
  `projects/${project_id}/service_orders/${service_order_id}/documents/?${getParamString(params)}`;
