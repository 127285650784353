// TODO: contact to single hook
import { EngineContext } from '@context';
import { approximationEqual, isValidDate } from '@utils';
import {
  ChangeEvent,
  Dispatch,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { EnumTypeForList } from '@interfaces';
import isEqual from 'lodash/isEqual';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { useDayJsFormatter } from '@hooks';
export interface StringFieldModel {
  value: string;
  setValue: Dispatch<React.SetStateAction<string>>;
  handleChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  isValid: boolean;
  validate: (eventValue?: string) => boolean;
  floatValue: number;
  setAsFloat: (value: number) => void;
  changeNavigationBlockContext: (value: boolean) => void;
  validationRule: (value: string) => boolean;
  errorTip: string;
  setErrorTip: Dispatch<React.SetStateAction<string>>;
  hasBorder: boolean;
  setBorder: Dispatch<React.SetStateAction<boolean>>;
  setValid: Dispatch<React.SetStateAction<boolean>>;
  isChanged: boolean;
  initValue: string;
}

interface StringFieldProp {
  blockNavigationKey?: string;
  initValue: string;
  validationRule?: (value: string) => boolean;
  validateOnChange?: boolean;
  initError?: string;
  withProgressCheck?: boolean;
}

// TODO: provide useNumberFields for logic with blocking and change

export const useStringFieldModel = (props?: StringFieldProp): StringFieldModel => {
  const { setBlockedBy } = useContext(EngineContext);

  const [value, setValue] = useState<string>(props.initValue);
  const [initValue, setInitValue] = useState<string>(props.initValue);
  const [isValid, setValid] = useState<boolean>(true);
  const [errorTip, setErrorTip] = useState<string>(props.initError || '');

  useEffect(() => {
    if (value !== props.initValue && props.validateOnChange) validate();
  }, [value]);

  useEffect(() => {
    if (props?.withProgressCheck && !isEqual(props.initValue, initValue)) {
      setValue(props.initValue || '');
      setInitValue(props.initValue || '');
    }
  }, [props?.withProgressCheck, props.initValue, initValue]);

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue(e.target.value);
    setValid(props?.validationRule ? props.validationRule(e.target.value) : true);
    if (!approximationEqual(+props.initValue, +e.target.value)) {
      changeNavigationBlockContext(true);
    }
  };

  const validate = useCallback(
    (eventValue = '') => {
      const isValid = !props?.validationRule || props.validationRule(eventValue || value);
      setValid(isValid);
      return isValid;
    },
    [value, props.validationRule],
  );

  const changeNavigationBlockContext = useCallback(
    (isBlock: boolean) => {
      if (!props?.blockNavigationKey) return null;

      setBlockedBy((blocked) => ({
        ...blocked,
        [props?.blockNavigationKey]: isBlock,
      }));
    },
    [props?.blockNavigationKey],
  );

  const [hasBorder, setBorder] = useState(false);

  const isChanged = useMemo(
    () => getIsChanged({ value, initValue, propsInitValue: props.initValue }),
    [value, initValue, props.initValue],
  );

  return {
    value,
    setValue,
    handleChange,
    isValid,
    validate,
    floatValue: parseFloat(value || '0'),
    setAsFloat: (value: number) => setValue(value.toString()),
    changeNavigationBlockContext,
    validationRule: props.validationRule,
    errorTip,
    setErrorTip,
    hasBorder,
    setBorder,
    setValid,
    isChanged,
    initValue: props.initValue,
  };
};

export interface DateFieldModel {
  value: Date;
  setValue: Dispatch<React.SetStateAction<Date>>;
  handleChangePicker: (e: Date | null) => void;
  isValid: boolean;
  validate: () => boolean;
  changeNavigationBlockContext: (value: boolean) => void;
  validationRule?: (value: Date | null) => {
    value: boolean;
    reason: string;
  };
  validationError?: string;
  isChanged: boolean;
  initValue: Date | null;
  setValid: Dispatch<React.SetStateAction<boolean>>;
  setInitValue: Dispatch<React.SetStateAction<Date | null>>;
}

interface DateFieldProp {
  blockNavigationKey?: string;
  initValue?: Date | null;
  validationRule?: (value: Date | null) => {
    value: boolean;
    reason: string;
  };
  validateOnChange?: boolean;
}

export const useDateFieldModel = ({
  validationRule,
  blockNavigationKey,
  validateOnChange = true,
  ...props
}: DateFieldProp): DateFieldModel => {
  const { setBlockedBy } = useContext(EngineContext);
  const [initValue, setInitValue] = useState<Date | null>(props.initValue || null);
  const [value, setValue] = useState<Date | null>(props.initValue || null);
  const [isValid, setValid] = useState<boolean>(true);
  const [validationError, setValidationError] = useState<string>('');

  const { areEqualDates } = useDayJsFormatter();
  useEffect(() => {
    if (!areEqualDates({ value1: props.initValue, value2: initValue })) {
      setValue(props.initValue);
      setInitValue(props.initValue);
    }
  }, [props.initValue, initValue]);
  const handleChangePicker = (date: Date | null) => {
    setValue(date);
  };

  const changeNavigationBlockContext = useCallback(
    (isBlock: boolean) => {
      if (!blockNavigationKey) return null;

      setBlockedBy((blocked) => ({
        ...blocked,
        [blockNavigationKey]: isBlock,
      }));
    },
    [blockNavigationKey],
  );

  const validate = useCallback(() => {
    const isValid = validationRule ? validationRule(value)?.value : isValidDate(value).value;
    setValid(isValid);
    setValidationError(validationRule ? validationRule(value)?.reason : isValidDate(value).reason);
    return isValid;
  }, [value, validationRule]);

  useEffect(() => {
    if (value !== initValue) {
      if (validateOnChange) validate();
      changeNavigationBlockContext(true);
    } else {
      setValid(true);
    }
    if (!value) {
      changeNavigationBlockContext(true);
    }
  }, [value]);

  const isChanged = useMemo(
    () => getIsChanged({ value, initValue, propsInitValue: props.initValue }),
    [value, initValue, props.initValue],
  );

  return {
    value,
    setValue,
    handleChangePicker,
    isValid,
    validate,
    changeNavigationBlockContext,
    validationRule,
    validationError,
    isChanged,
    initValue,
    setValid,
    setInitValue,
  };
};

// for dropdowns with predefined list of object values
export interface DropdownFieldModel {
  value: EnumTypeForList;
  setValue: Dispatch<React.SetStateAction<EnumTypeForList>>;
  isValid: boolean;
  validate: () => boolean;
  validationRule: (value: EnumTypeForList | null) => boolean;
  setValid: Dispatch<React.SetStateAction<boolean>>;
  initValue: EnumTypeForList | null;
  isChanged: boolean;
}
interface DropdownFieldProp {
  initValue: EnumTypeForList | null;
  validateOnChange?: boolean;
  validationRule?: (value: EnumTypeForList | null) => boolean;
}

export const useDropdownFieldModel = ({
  validateOnChange = true,
  validationRule,
  ...props
}: DropdownFieldProp): DropdownFieldModel => {
  const [initValue, setInitValue] = useState<EnumTypeForList | null>(props.initValue);
  const [value, setValue] = useState<EnumTypeForList>(props.initValue);
  const [isValid, setValid] = useState<boolean>(true);

  useEffect(() => {
    if (!initValue && !props.initValue) return;
    if (!isEqual(props.initValue, initValue)) {
      setValue(props.initValue);
      setInitValue(props.initValue);
    }
  }, [props.initValue, initValue]);

  useEffect(() => {
    if (!isEqual(value, initValue) && validateOnChange) validate();
  }, [value]);

  const validate = useCallback(() => {
    const isValid = validationRule ? validationRule(value) : true;
    setValid(isValid);
    return isValid;
  }, [value, validationRule]);

  const isChanged = useMemo(
    () => getIsChanged({ value, initValue, propsInitValue: props.initValue }),
    [value, initValue, props.initValue],
  );

  return {
    value,
    setValue,
    isValid,
    validate,
    validationRule,
    setValid,
    initValue,
    isChanged,
  };
};

// Phone input section
type PhoneValue = string | undefined;

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone: string) => {
  try {
    const number = phoneUtil.parseAndKeepRawInput(phone, 'US');
    return phoneUtil.isValidNumberForRegion(number, 'US');
  } catch (error) {
    return false;
  }
};

export interface PhoneFieldModel {
  value: PhoneValue;
  setValue: (value: PhoneValue) => void;
  handleChange: (newValue: PhoneValue) => void;
  isValid: boolean;
  validate: (eventValue?: string) => boolean;
  setValid: Dispatch<React.SetStateAction<boolean>>;
  isChanged: boolean;
  valueToSave: string | null;
  initValue: PhoneValue;
}

interface PhoneFieldProps {
  initValue?: PhoneValue;
  withProgressCheck?: boolean;
  validationRule?: (value: string) => boolean;
}

export const usePhoneFieldModel = (props: PhoneFieldProps = {}): PhoneFieldModel => {
  const [value, setValue] = useState<PhoneValue>(props.initValue || '');
  const [initValue, setInitValue] = useState<PhoneValue>(props.initValue || '');
  const [isValid, setIsValid] = useState<boolean>(true);
  const [hasValidated, setHasValidated] = useState<boolean>(false);

  const handleChange = (newValue) => {
    setValue(newValue);
    if (hasValidated) {
      validate(newValue);
    }
  };

  useEffect(() => {
    if (!initValue && !props.initValue) return;
    if (!isEqual(props.initValue, initValue)) {
      setValue(props.initValue || '');
      setInitValue(props.initValue || '');
    }
  }, [props.initValue, initValue]);

  const validate = useCallback(
    (valueToValidate: string = value) => {
      setHasValidated(true); // Mark that validation has been called

      const isValidRule = !props.validationRule || props.validationRule(valueToValidate);
      const isValidPhone = valueToValidate?.replace('+1', '')?.trim()
        ? isPhoneValid(valueToValidate)
        : true;

      const isValid = isValidPhone && isValidRule;

      setIsValid(isValid);
      return isValid;
    },
    [props.validationRule],
  );

  const isChanged = useMemo(
    () => getIsChanged({ value, initValue, propsInitValue: props.initValue }),
    [value, initValue, props.initValue],
  );

  const valueToSave = useMemo(() => {
    return value?.replace('+1', '') ? value : null;
  }, [value]);

  return {
    value,
    setValue,
    handleChange,
    isValid,
    validate,
    setValid: setIsValid,
    isChanged,
    valueToSave,
    initValue,
  };
};

export interface BooleanFieldModel {
  value: boolean;
  setValue: Dispatch<React.SetStateAction<boolean>>;
  isChanged: boolean;
  handleChange: (newValue: boolean) => void;
}
interface BooleanFieldProp {
  initValue: boolean | null;
}

export const useBooleanFieldModel = (props?: BooleanFieldProp): BooleanFieldModel => {
  const [value, setValue] = useState<boolean>(props.initValue);
  const [initValue, setInitValue] = useState<boolean>(props.initValue);

  useEffect(() => {
    if (props.initValue !== initValue) {
      setValue(props.initValue);
      setInitValue(props.initValue);
    }
  }, [props.initValue, initValue]);

  const handleChange = (newValue: boolean) => {
    setValue(newValue);
  };

  const isChanged = useMemo(
    () => getIsChanged({ value, initValue, propsInitValue: props.initValue }),
    [value, initValue, props.initValue],
  );

  return {
    value,
    setValue,
    handleChange,
    isChanged,
  };
};

interface IsChangedArgs<T> {
  value: T;
  initValue: T;
  propsInitValue: T;
}

const getIsChanged = <T>({ value, initValue, propsInitValue }: IsChangedArgs<T>): boolean => {
  // If props.initValue !== initValue, data was refetched
  if (!isEqual(propsInitValue, initValue)) {
    return false;
  }

  // If both values are null/undefined, return false
  if (!value && !initValue) {
    return false;
  }

  return !isEqual(value, initValue);
};
