import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Grid2 } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { colors } from '@theme';
import { CompanyLink, ServiceMessage } from '@components';
import { HookState, PermissionNamesEnums } from '@interfaces';
import * as Controller from './controller';
import { isAllowed } from '@utils';
import { PermissionsContext } from '@context';

const Bullet = styled('span')({
  margin: '8px',
  fontSize: '1.25rem',
  color: colors.text.link,
});

const ProjectHeader = () => {
  const { permissions } = useContext(PermissionsContext);
  const {
    state,

    projectBorrowerCompany,
    projectInvestorCompany,
    projectCustomerCompany,

    teamsIsLoading,
  } = Controller.useProjectHeader();

  switch (state) {
    case HookState.LOADING: {
      return (
        <Box pt={2}>
          <Skeleton width="40%" variant="rectangular" height={40} />
          <Skeleton width="20%" />
          <Skeleton width="20%" />
        </Box>
      );
    }
    case HookState.ERROR: {
      return <ServiceMessage text="project" />;
    }

    case HookState.SUCCESS: {
      return (
        <Box>
          {!teamsIsLoading && (
            <Grid2 container alignItems="baseline">
              {projectBorrowerCompany?.name && (
                <>
                  <CompanyLink
                    name={projectBorrowerCompany?.name}
                    companyId={projectBorrowerCompany?.id}
                    source="project__overview__borrower"
                  />

                  <Bullet>&#8226;</Bullet>
                </>
              )}
              <CompanyLink
                name={projectCustomerCompany?.name}
                companyId={projectCustomerCompany?.id}
                source="project__overview__lender"
              />
              {isAllowed(PermissionNamesEnums.INVESTOR_VIEW, permissions) &&
                projectInvestorCompany?.id && (
                  <>
                    <Bullet>&#8226;</Bullet>
                    <CompanyLink
                      name={projectInvestorCompany?.name}
                      companyId={projectInvestorCompany?.id}
                      source="project__overview__investor"
                    />
                  </>
                )}
            </Grid2>
          )}
        </Box>
      );
    }

    default:
      return null;
  }
};

export default ProjectHeader;
