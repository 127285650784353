import React, { ChangeEvent } from 'react';
import {
  Chip,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
  Box,
  SxProps,
  IconButton,
} from '@mui/material';
import { colors } from '@theme';
import { InfoIcon, InspectionIcon, ManualServiceIcon } from '@svgAsComponents';
import { isServiceProviderManual } from '@utils';
import { LoadingSkeleton } from '@components';

interface CustomRadioGroupProps {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  options: {
    value: string;
    label: string;
    logo?: string;
    isPremium?: boolean;
    description?: React.ReactNode;
    content?: React.ReactNode;
    service_provider?: string;
  }[];
  dataTestName?: string;
  disabled?: boolean;
  disabledTooltipText?: string;
  radioButtonStyle?: SxProps;
  columnsCount?: number;
  handleInfoIconClick?: (item: {
    value: string;
    label: string;
    description?: React.ReactNode;
    content?: React.ReactNode;
  }) => void;
  isLoading?: boolean;
}

const renderLabel = (option, disabled, disabledTooltipText) => {
  if (option.logo) {
    return (
      <img
        alt="service agency logo"
        src={option.logo}
        style={{ minHeight: '34px', maxHeight: '34px' }}
      />
    );
  }

  if (isServiceProviderManual(option.service_provider)) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          backgroundColor: colors.main.primary.lightest,
          minHeight: '40px',
          padding: 1,
          borderRadius: '2px',
        }}
      >
        <ManualServiceIcon
          size={24}
          color={disabled ? colors.neutral.dark : colors.neutral.darker}
        />
        <Typography variant="labelSemiBold">{option.label}</Typography>
      </Box>
    );
  }

  return (
    <Tooltip title={disabled ? disabledTooltipText : ''}>
      <Typography variant="body2" sx={disabled ? { color: colors.neutral.dark } : {}}>
        {option.label}
      </Typography>
    </Tooltip>
  );
};

const CustomRadioGroupGridLayout = ({
  value,
  onChange,
  options,
  dataTestName,
  disabled,
  disabledTooltipText,
  radioButtonStyle = {},
  columnsCount = 3, // Default to 3 columns
  handleInfoIconClick,
  isLoading,
}: CustomRadioGroupProps) => {
  if (isLoading) {
    return <LoadingSkeleton type="overviewBlock" />;
  }

  return (
    <RadioGroup
      aria-label="inputType"
      name="inputType"
      value={value}
      onChange={onChange}
      data-cy={dataTestName}
    >
      {/* CSS Grid Container */}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: `repeat(${columnsCount}, 1fr)`, // Dynamic column count
          gap: 2, // Adjusts spacing
          alignItems: 'center',
        }}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            data-cy={`${dataTestName}_${option.value.toLowerCase()}`}
            control={<Radio disabled={disabled} />}
            label={
              <Box display="flex" alignItems="center" gap={1}>
                {renderLabel(option, disabled, disabledTooltipText)}

                {option.isPremium && (
                  <Chip
                    sx={{
                      backgroundColor: colors.status.orange.lighter,
                      borderRadius: '2px',
                      height: '24px',
                      padding: '4px 2px',
                      '& .MuiChip-label': { padding: 0 },
                    }}
                    label={
                      <Typography
                        variant="labelSemiBold"
                        sx={{
                          color: colors.status.orange.medium,
                          letterSpacing: '0.3px',
                          padding: '0 4px',
                        }}
                      >
                        Premium
                      </Typography>
                    }
                    icon={<InspectionIcon size={16} color={colors.status.orange.medium} />}
                  />
                )}
                {option.description && (
                  <IconButton
                    onClick={() => handleInfoIconClick?.(option)}
                    sx={{
                      backgroundColor: 'transparent',
                      border: 'none',
                    }}
                  >
                    <InfoIcon size={20} color={colors.status.information.medium} />
                  </IconButton>
                )}
              </Box>
            }
            sx={{
              m: 0,
              ml: 1,
              '& .MuiFormControlLabel-label': { display: 'flex' },
              ...radioButtonStyle,
            }}
          />
        ))}
      </Box>
    </RadioGroup>
  );
};

export default CustomRadioGroupGridLayout;
