import React, { FC } from 'react';
import { Stack, Button } from '@mui/material';
import { colors } from '@theme';
import { MilestoneListWithPatch } from '@components';
import { PermissionNamesEnums, TableKeyEnum } from '@interfaces';
import { WithPermission } from '@utils';
import { useModelsTable } from './controller';

const CreateByModelsTable: FC<{ isEditable: boolean }> = ({ isEditable }) => {
  const { initColumns, projectModels, addLineList, patchModel, update, deleteItem, onExpandClick } =
    useModelsTable({ isEditable });

  return (
    <Stack
      sx={{ backgroundColor: colors.background.white, pt: 1, borderRadius: '4px', flexGrow: 1 }}
    >
      <MilestoneListWithPatch
        deleteMilestone={deleteItem}
        refetch={update}
        patchMilestone={patchModel}
        withColumnIndication
        tableKey={TableKeyEnum.PHB_LINE_ITEMS}
        initColumns={initColumns}
        milestones={projectModels}
        key="listWithEditModels"
        onExpandTable={onExpandClick}
        headerRightPart={[
          {
            Component: (
              <WithPermission permissionKey={PermissionNamesEnums.PROJECT_ONBOARDING}>
                <Stack spacing={1} direction="row">
                  <Button
                    loading={false}
                    size="small"
                    onClick={addLineList}
                    data-cy="project__budget__create__structure_table__add_item__button"
                    disabled={!isEditable}
                  >
                    Add property
                  </Button>
                </Stack>
              </WithPermission>
            ),
          },
        ]}
        source="project__budget__create__structure_table"
      />
    </Stack>
  );
};
export default CreateByModelsTable;
