/* eslint-disable no-unsafe-optional-chaining */
import { PermissionNamesEnums, QueryNamesEnums } from '@interfaces';
import { FilterObject } from '../interface';
import { getUserFullName } from '@utils';

export const coordinatorProjectFilter: FilterObject = {
  title: 'Coordinator',
  filterKey: 'coordinator',
  permissionKey: PermissionNamesEnums.DRAWREQUESTS_COORDINATOR_VIEW,
  getDataParamsPaginated: ({ pagination, q, skip, id }) => ({
    type: QueryNamesEnums.GET_COORDINATORS,
    keys: ['id', 'first_name', 'last_name'],
    args: {
      is_project_coordinator: 'true',
      pagination,
      q,
      id,
    },
    options: {
      skip,
    },
  }),
  strictSerialize: (data) => {
    if (!data?.pages)
      return [
        {
          value: 'null',
          label: 'Unassigned',
        },
      ];

    return [
      {
        value: 'null',
        label: 'Unassigned',
      },
      ...data.pages?.flatMap((page) =>
        page.results?.map((item) => ({
          value: item.id,
          label: getUserFullName(item),
        })),
      ),
    ];
  },
  cypressSelector: 'filters__coordinator',
  needToUpdateUrl: true,
  needToUpdateSetting: true,
  withPagination: true,
};
