import { ColumnType } from '@utils';

const completedAt: ColumnType = ({ dateFormatter }) => ({
  field: 'completed_at',
  graphFields: ['completed_at'],
  headerName: 'Completed date',
  minWidth: 180,
  groupable: false,
  aggregable: false,
  renderCell: ({ row }) => {
    return row.completed_at ? dateFormatter({ date: row.completed_at, withTime: true }) : '-';
  },
});

export default completedAt;
