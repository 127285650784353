import React, { useState, useEffect, useContext } from 'react';
import { Typography, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from '@context';
import { AnimatedRedirect } from '../components';
import { useUser } from '@hooks';

const LoginOnBehalfCallback = () => {
  const { user } = useContext(AuthContext);
  const { refetch } = useUser();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      if (!user?.isAllowedToLogin) {
        await refetch();
      }
      setLoading(false);
      navigate('/');
    };

    fetchUser();
  }, [user?.isAllowedToLogin]);

  return (
    <>
      {loading && (
        <Stack alignItems="center" sx={{ minHeight: '100vh' }}>
          <Stack sx={{ flex: 1, alignItems: 'center', justifyContent: 'center' }} spacing={6}>
            <AnimatedRedirect />

            <Typography variant="h2">Redirecting...</Typography>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default LoginOnBehalfCallback;
