import React, { useCallback, useContext, useMemo, useState } from 'react';
import { GridEventListener, GridSortModel } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';

import {
  getHookState,
  getSortStringByGridSortModel,
  createColumnVisibleModel,
  ColumnConfig,
  generateGraphKeys,
} from '@utils';
import {
  IServiceOrder,
  QueryNamesEnums,
  TableKeyEnum,
  IRightDrawerParams,
  ServiceOrderStatusEnum,
} from '@interfaces';
import { SettingsContext, PermissionsContext, useGraphQuery } from '@context';
import {
  useCommentsAndDocumentsPreview,
  useImagePicker,
  useRightMenu,
  useTablePagination,
  useUserTableSettings,
  useDayJsFormatter,
} from '@hooks';
import { ControllerInterface } from './interface';
import { ProjectServicesColumnsConfig } from '@components';
import { defaultPersonalSetting } from '@constants';
const TABS = {
  documents: { label: 'Documents', value: 'documents' },
  comments: { label: 'Comments', value: 'comments' },
};

export const useServices = (projectId: string, drawRequestId: string): ControllerInterface => {
  const navigate = useNavigate();
  const { dateFormatter } = useDayJsFormatter();
  const { settings } = useContext(SettingsContext);
  const { permissions } = useContext(PermissionsContext);
  const { userTableSettings } = useUserTableSettings({ tableKey: TableKeyEnum.PROJECT_SERVICES });

  const [filtersReady, setFiltersReady] = useState<boolean>(true); // TODO: should be false initially
  const [tableSortingModel, setTableSortingModel] = useState<GridSortModel>([]);
  const [filterStringQuery, setFilterStringQuery] = useState<string>('');

  const serviceTypesMap = useMemo(
    () => settings?.display?.service_types,
    [settings?.display?.service_types],
  );

  const { page, rowsPerPage, rowsPerPageOptions, onPageChange, onRowsPerPageChange } =
    useTablePagination({});

  const [tab, setTab] = useState(TABS.documents.value);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  const [rightDrawerParams, setRightDrawerParams] = useState<IRightDrawerParams>({
    serviceOrderId: '',
    requestId: drawRequestId,
    projectId,
  });

  const { updateCommentsPreviewInfo } = useCommentsAndDocumentsPreview({
    projectId,
    ...(drawRequestId ? { drawRequestId } : {}),
    ...(rightDrawerParams.serviceOrderId
      ? { serviceOrderId: rightDrawerParams.serviceOrderId }
      : {}),
  });

  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['inspection_cadence'],
    args: { project_id: projectId },
  });

  const { pdf, gallery, close } = useImagePicker();

  const { handleRightDrawerOpenerClick, ...rightMenu } = useRightMenu({
    onClose: updateCommentsPreviewInfo,
  });

  const updateRightDrawer = ({ serviceOrderId, activeTab, title }) => {
    handleRightDrawerOpenerClick({
      title,
    });

    setRightDrawerParams((old) => ({ ...old, serviceOrderId, activeTab }));
  };

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    navigate(`/projects/${params.row?.project?.id}/service/${params.row?.id}`);
  };

  const columnsToFetch = useMemo(() => {
    const columnsModel = userTableSettings?.columns.columnVisibilityModel || {
      ...createColumnVisibleModel(
        ProjectServicesColumnsConfig.map((column) => column({}).field),
        true,
      ),
      ...createColumnVisibleModel(
        defaultPersonalSetting.tables[TableKeyEnum.PROJECT_SERVICES]?.hidden_columns || [],
      ),
    };
    if (columnsModel) return Object.keys(columnsModel).filter((key) => columnsModel[key]);
    return [];
  }, [userTableSettings?.columns.columnVisibilityModel]);

  const queryTest = useMemo(
    () =>
      generateGraphKeys<IServiceOrder>(
        columnsToFetch?.map((column) =>
          ProjectServicesColumnsConfig.map((column) => column({})).find(
            (config) => config.field === column,
          ),
        ),
      ),
    [columnsToFetch],
  );

  const servicesListCustomRules = useCallback(
    (column: ColumnConfig<IServiceOrder>) => {
      return column.enableByPermissions ? column.enableByPermissions(permissions) : true;
    },
    [permissions],
  );

  const projectServicesQuery = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT_SERVICE_ORDERS,
    keys: queryTest.keys as (keyof IServiceOrder)[],
    nested: queryTest.nested,
    args: {
      project_id: projectId,
      excluded_status: ServiceOrderStatusEnum.CREATED,
      pagination: {
        page,
        rowsPerPage,
      },
      sorting: getSortStringByGridSortModel(tableSortingModel),
      filter: filterStringQuery,
    },
    options: {
      skip: !!drawRequestId || !columnsToFetch?.length || !filtersReady,
    },
  });

  const requestServicesQuery = useGraphQuery({
    type: QueryNamesEnums.GET_DRAW_REQUEST_SERVICE_ORDERS,
    keys: queryTest.keys as (keyof IServiceOrder)[],
    nested: queryTest.nested,
    args: {
      project_id: projectId,
      draw_request_id: drawRequestId,
      excluded_status: ServiceOrderStatusEnum.CREATED,
      pagination: {
        page,
        rowsPerPage,
      },
      sorting: getSortStringByGridSortModel(tableSortingModel),
      filter: filterStringQuery,
    },
    options: {
      skip: !drawRequestId || !columnsToFetch?.length || !filtersReady,
    },
  });

  const servicesQuery = useMemo(() => {
    return drawRequestId ? requestServicesQuery : projectServicesQuery;
  }, [drawRequestId, projectServicesQuery, requestServicesQuery]);

  const columns = useMemo(
    () =>
      ProjectServicesColumnsConfig.map((column) =>
        column({ updateRightDrawer, serviceTypesMap, settings, dateFormatter, permissions }),
      ).filter(servicesListCustomRules),
    [
      servicesListCustomRules,
      updateRightDrawer,
      serviceTypesMap,
      settings,
      dateFormatter,
      permissions,
    ],
  );

  return {
    state: getHookState(servicesQuery),
    rows: servicesQuery?.data?.results,
    columns,
    onFiltersReady: () => setFiltersReady(true),
    onSortModelChange: setTableSortingModel,
    setFilterStringQuery,
    pdf,
    gallery,
    close,
    rightDrawerParams: { ...rightDrawerParams, projectId },
    rightMenu,
    isUpdating: servicesQuery.isFetching,
    inspectionCadence: project.data?.inspection_cadence,
    TABS,
    tab,
    handleTabChange,
    paginationProps: {
      page,
      rowsPerPage,
      rowsPerPageOptions,
      onPageChange,
      onRowsPerPageChange,
      itemsCount: servicesQuery?.data?.results?.length,
    },
    noFiltersApplied: !filterStringQuery,
    handleRowClick,
  };
};
