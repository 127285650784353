import React from 'react';
import { DrawRequestStatusChip } from '@components';
import { ColumnType } from '@utils';
import { IDrawRequest } from '@interfaces';

const status: ColumnType<IDrawRequest> = () => ({
  field: 'status',
  graphFields: [
    'status',
    'is_resubmit',
    'is_resubmit_change_reason',
    'is_on_hold',
    'is_on_hold_change_reason',
  ],
  graphNested: {
    project: ['status'],
  },
  headerName: 'Request status',
  minWidth: 120,
  groupable: false,
  aggregable: false,
  renderCell: ({ row }) => <DrawRequestStatusChip drawRequest={row} />,
});

export default status;
