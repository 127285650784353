import React from 'react';
import { Box, FormControlLabel, Grid2, Stack, Switch, Typography } from '@mui/material';

import {
  CustomTextField,
  InspectionInputsBlock,
  LoadingSkeleton,
  StyledBox,
  TabWrapperWithLeaveWarning,
} from '@components';
import { typography } from '@theme';
import * as Controller from './controller';

const InspectionsSettings = ({ hasInspectionsEditPermission }) => {
  const controller = Controller.useInspectionSettings();
  const {
    inspectionFields,
    handleSubmitClick,
    isSubmitting,
    isUpdated,
    exitPath,
    isSubmitDisabled,
    isCurrentProjectArchived,
    isLoading,
  } = controller;

  if (isLoading) return <LoadingSkeleton type="overviewBlock" />;

  return (
    <TabWrapperWithLeaveWarning
      onSave={handleSubmitClick}
      isUpdated={isUpdated}
      isDisabled={isSubmitDisabled}
      tabValue="inspections"
      path={exitPath}
      isSubmitting={isSubmitting}
      currentPagePathname={'/projects/*/settings/inspections'}
    >
      <StyledBox>
        <Typography variant="h3">General</Typography>
        <Box sx={{ mt: 4 }}>
          <Typography variant="body2" sx={{ mb: 3 }}>
            {inspectionFields?.isContactListHasUsersWithPhone
              ? 'At least one contact with phone number (primary or additional) is required.'
              : 'No borrower users with phone number available. Please, enter additional contact user.'}
          </Typography>
          <InspectionInputsBlock
            source="project__settings__inspections"
            disabled={!hasInspectionsEditPermission || isCurrentProjectArchived}
            {...inspectionFields}
            inputWidth={4}
            showContactsTooltip={false}
          />
        </Box>
      </StyledBox>
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <StyledBox sx={{ minHeight: '100%' }}>
            <Stack>
              <Typography variant="h3">Ordering on submission</Typography>
              <FormControlLabel
                sx={{
                  mt: 2,
                  '& .MuiTypography-root': {
                    ...typography.body3,
                  },
                }}
                control={
                  <Switch
                    sx={{ mr: '15px' }}
                    checked={inspectionFields.inspectionIsOrderAutomatically.value}
                    onChange={(event) =>
                      inspectionFields.inspectionIsOrderAutomatically.handleChange(
                        event.target.checked,
                      )
                    }
                    data-cy="project__settings__inspections__is_order_automatic__switch"
                    disabled={!hasInspectionsEditPermission || isCurrentProjectArchived}
                  />
                }
                label="Automatically order inspection on draw request submission"
              />
            </Stack>
          </StyledBox>
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <StyledBox>
            <Stack>
              <Typography variant="h3">Scheduled ordering</Typography>
              <Typography variant="body3" sx={{ mt: 2, mb: 1 }}>
                Set the interval to automatically order inspections based on schedule and prompt
                borrower when complete.
              </Typography>
              <CustomTextField
                label="Inspection interval (in days)"
                field={inspectionFields.inspectionCadence}
                inputProps={{
                  'data-cy': `project__settings__inspections__inspection_cadence__input`,
                }}
                disabled={!hasInspectionsEditPermission || isCurrentProjectArchived}
              />
            </Stack>
          </StyledBox>
        </Grid2>
      </Grid2>
    </TabWrapperWithLeaveWarning>
  );
};

export default InspectionsSettings;
