import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import dayjs from 'dayjs';
import {
  getDrawRequestForApproval,
  isActiveProject,
  isRequestApproved,
  isRequestInReview,
} from '@utils';
import {
  EnumTypeForList,
  IDrawRequest,
  IProjectSettings,
  QueryNamesEnums,
  ProjectUpdatePayload,
} from '@interfaces';
import {
  getProjectDrawRequestsList,
  getProjectSettings,
  updateProjectFields,
} from '@globalService';
import { IChangedData, TChangedData } from '../interface';
import { useSafeSnackbar } from '@hooks';
import { useGraphQuery } from '@context';

const requiredSettingsKeys = ['should_display_create_draw_request'] as const;
type ProjectSettings = Pick<IProjectSettings, (typeof requiredSettingsKeys)[number]>;

export interface ControllerInterface {
  showCreateButton: boolean;
  disabledCreateButton: boolean;
  waitingForApproval: boolean;
  changedData: IChangedData | null;
  setChangedData: Dispatch<SetStateAction<IChangedData | null>>;
  updateProjectData: (data: { key: string; value: TChangedData }[]) => void;
}

export const useOverviewTab = (projectId: string): ControllerInterface => {
  const [changedData, setChangedData] = useState<IChangedData | null>(null);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();

  const settingsQuery = useQuery<ProjectSettings, Error>(
    [QueryNamesEnums.GET_PROJECT_SETTINGS, { projectId }],
    getProjectSettings.bind(this, projectId),
  );

  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['status', 'name', 'comments_preview'],
    args: { project_id: projectId },
    nested: {
      loan: ['type', 'id'],
    },
  });

  const drawRequestsQuery = useQuery<{ results: IDrawRequest[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }],
    getProjectDrawRequestsList.bind(this, projectId),
  );

  const showCreateButton = useMemo(
    () =>
      settingsQuery.data?.should_display_create_draw_request &&
      isActiveProject(project.data?.status),
    [settingsQuery, project],
  );

  const disabledCreateButton = useMemo(() => {
    return drawRequestsQuery.data?.results?.some(
      (dr) => isRequestApproved(dr.status) || isRequestInReview(dr.status),
    );
  }, [drawRequestsQuery.data]);

  const waitingForApproval = useMemo(() => {
    return !!getDrawRequestForApproval(drawRequestsQuery.data?.results);
  }, [drawRequestsQuery.data]);

  const updateProjectMutation = useMutation<
    Response,
    Error,
    { projectId: string; json: ProjectUpdatePayload }
  >(updateProjectFields, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT, { project_id: projectId }]);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const updateProjectData = (data: { key: string; value: TChangedData }[]) => {
    const projectData = (key: string, value: TChangedData) => {
      if (key === 'extended_maturity_date') {
        return {
          loan: {
            extended_maturity_date: dayjs(value as Date).format('YYYY-MM-DD'),
            id: project.data?.loan?.id,
          },
        };
      }
      if (key === 'servicing_status') {
        return {
          loan: {
            servicing_status: (value as EnumTypeForList)?.name || null,
            id: project.data?.loan?.id,
          },
        };
      }
      if (key === 'interest_method') {
        return {
          loan: {
            interest_method: (value as EnumTypeForList)?.name || null,
            id: project.data?.loan?.id,
          },
        };
      }
      if (key === 'type') {
        return {
          loan: {
            type: (value as EnumTypeForList)?.name || null,
            id: project.data?.loan?.id,
          },
        };
      }
      if (key === 'funding_date') {
        return {
          loan: {
            funding_date: dayjs(value as Date).format('YYYY-MM-DD'),
            id: project.data?.loan?.id,
          },
        };
      }
      if (key === 'estimated_completion_date') {
        return {
          estimated_completion_date: dayjs(value as Date).format('YYYY-MM-DD'),
        };
      }
      return { [key]: value };
    };
    const json = data.reduce((acc, { key, value }) => {
      const loanData = projectData(key, value);
      return { ...acc, ...loanData };
    }, {});

    updateProjectMutation.mutate({
      projectId,
      json: json as ProjectUpdatePayload,
    });
  };

  return {
    showCreateButton,
    disabledCreateButton,
    waitingForApproval,
    changedData,
    setChangedData,
    updateProjectData,
  };
};
