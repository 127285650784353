import { ColumnType } from '@utils';
import { IDrawRequest } from '@interfaces';
const days_since_submitted: ColumnType<IDrawRequest> = () => ({
  field: 'days_since_submitted',
  description: 'The time from submittal to disbursement of draw or decision on the change request.',
  graphFields: ['days_since_submitted'],
  headerName: 'Weekdays since submitted',
  minWidth: 100,
  groupable: false,
  aggregable: false,
});

export default days_since_submitted;
