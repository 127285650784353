import { PermissionNamesEnums, QueryNamesEnums } from '@interfaces';
import { FilterObject } from '../interface';
import { basicFilterSerializer } from '@utils';
import { IUser } from '@interfaces';

export const currentReviewerTeamFilter: FilterObject = {
  title: 'Current reviewer team',
  filterKey: 'current_reviewer_team',
  needToUpdateUrl: true,
  needToUpdateSetting: true,
  permissionKey: PermissionNamesEnums.DRAWREQUESTS_REVIEWER_TEAM_VIEW,
  getDataParamsPaginated: ({ id, pagination, q, skip }) => ({
    type: QueryNamesEnums.GET_PROJECTS_TEAMS,
    keys: ['id', 'name'],
    nested: {
      company: ['id', 'name'],
    },
    args: { pagination, q, exclude_approval_level: '0,1', sorting: 'company_name', id },
    options: {
      skip,
    },
  }),
  strictSerialize: (data, _, user: IUser) => {
    if (!data?.pages?.length || !data?.pages[0]) return [];

    const userCompanyId = user?.company_id;

    const transformedData = {
      ...data,
      pages: data.pages.map((page) => ({
        ...page,
        results: page.results.map((item) => ({
          id: item.id,
          name:
            item.company.id === userCompanyId ? item.name : `${item.company.name} / ${item.name}`,
        })),
      })),
    };

    return basicFilterSerializer(transformedData);
  },
  cypressSelector: 'filters__current_reviewer_team',
  withPagination: true,
};
