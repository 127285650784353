import React, { useContext } from 'react';
import { ColumnType, isAllowed, isFailedService } from '@utils';
import { PermissionNamesEnums, ServiceOrderStatusEnum, IDrawRequest } from '@interfaces';
import { statusMap } from '@constants';
import { SettingsContext } from '@context';
import { StatusChip, StatusChipWithIconAndTooltip } from '@components';
import { WarningIcon } from '@svgAsComponents';

const inspection_status: ColumnType<IDrawRequest> = () => ({
  field: 'inspection.status',
  graphNested: {
    inspection: ['status', 'id', 'error_msg'],
  },
  headerName: 'Inspection status',
  minWidth: 150,
  groupable: false,
  aggregable: false,
  enableByPermissions: (permissions) =>
    isAllowed(PermissionNamesEnums.INSPECTIONS_VIEW, permissions),
  renderCell: ({ row }) => {
    const { settings } = useContext(SettingsContext);
    if (!row?.inspection?.status || row.inspection.status === ServiceOrderStatusEnum.CREATED)
      return <span>-</span>;
    const inspectionStatusValue = statusMap(
      row.inspection.status,
      settings.display,
      'service_order',
    );

    const chipProps = {
      color: inspectionStatusValue.color,
      backgroundColor: inspectionStatusValue.backgroundColor,
      label: inspectionStatusValue.text || row.inspection.status,
      dataTestName: 'project__services_tab__list__status_chip',
    };

    if (isFailedService(row.inspection.status))
      return (
        <StatusChipWithIconAndTooltip
          {...chipProps}
          tooltipText={row.inspection.error_msg}
          icon={<WarningIcon size={14} />}
        />
      );

    return <StatusChip {...chipProps} />;
  },
});

export default inspection_status;
