import React from 'react';
import { HookState, TableKeyEnum } from '@interfaces';
import { DataTableV3, LoadingSkeleton, ReactTable, ServiceMessage, StyledBox } from '@components';
import { useRequestsList } from './controller';
import { Typography } from '@mui/material';
import { colors } from '@theme';
import { useLaunchDarklyFlags } from '@context';

const ProjectPaymentRequestList = () => {
  const { state, requests, newColumns, columns } = useRequestsList();
  const flags = useLaunchDarklyFlags();
  switch (state) {
    case HookState.LOADING: {
      return <LoadingSkeleton type="twoBlocks" />;
    }
    case HookState.ERROR: {
      return <ServiceMessage text="requests list" />;
    }

    case HookState.SUCCESS: {
      return (
        <StyledBox sx={{ mt: 2, px: 0 }}>
          {flags?.[`ENG_7895_table_v3__${TableKeyEnum.PROJECT_PAYMENTS}`] ? (
            <DataTableV3
              withBuiltInSearch={false}
              tableKey={TableKeyEnum.PROJECT_PAYMENTS}
              rows={requests}
              columns={newColumns}
              headerLeft={[
                {
                  Component: <Typography variant="h3">Draws to date</Typography>,
                },
              ]}
            />
          ) : (
            <>
              <Typography variant="h3" sx={{ p: 2, pt: 0 }}>
                Draws to date
              </Typography>
              <ReactTable
                data={requests}
                columns={columns}
                headerBgColor={colors.neutral.lightest}
                showVerticalBorders
                source="project__payment_configuration__table"
              />
            </>
          )}
        </StyledBox>
      );
    }
    default:
      return null;
  }
};

export default ProjectPaymentRequestList;
