import { currencyFormatter, isAllowed, ColumnType } from '@utils';
import { PermissionNamesEnums } from '@interfaces';

const serviceCost: ColumnType = () => ({
  field: 'invoiced_amount',
  graphFields: ['invoiced_amount'],
  headerName: 'Cost',
  minWidth: 80,
  groupable: false,
  aggregable: false,
  renderCell: ({ row }) => {
    return row.invoiced_amount ? currencyFormatter(row.invoiced_amount) : '-';
  },
  enableByPermissions: (permissions) =>
    isAllowed(PermissionNamesEnums.INSPECTIONS_EDIT, permissions),
});

export default serviceCost;
