import React, { FC } from 'react';
import { Stack, Button } from '@mui/material';

import { ButtonWithTooltip } from '@components';
import { CompleteDRButton } from '../index';
import { ControllerInterface } from '../../controller';
import { useRequestInfo } from './controller';
import { isChangeRequest, WithPermission } from '@utils';
import { PermissionNamesEnums, ApproveActionEnum } from '@interfaces';

const ApprovalButtonsNested: FC<{ controller: ControllerInterface; projectId: string }> = ({
  controller,
  projectId,
}) => {
  const {
    handleActionButtonClick,
    isApproveDisabled,
    disableButtonTooltip,
    drawRequest,
    approveActionType,
    dataIsLoading,
    showRejectModal,
    showRejectButton,
    showPutOnHoldModal,
    showReleaseFromHoldModal,
    isFinalApprove,
    isUserHasPermissionForAction,
    showMarkAsDisbursedModal,
    projectStatus,
    showBothApproveAndFundButtons,
  } = controller;

  const {
    isRequestOnHold,
    isHistoricalRequest,
    disableActionButtonTooltip,
    isActionButtonDisabled,
    isRequestApproved,
  } = useRequestInfo({
    drawRequest,
    projectId,
    projectStatus,
  });

  if (dataIsLoading) {
    return (
      <Button disabled loading>
        Processing...
      </Button>
    );
  }

  if (isHistoricalRequest) {
    return (
      <CompleteDRButton
        drawRequest={drawRequest}
        isApproveDisabled={isApproveDisabled}
        projectId={projectId}
        tooltipText={disableButtonTooltip}
      />
    );
  }

  if (isRequestOnHold) {
    return (
      <ButtonWithTooltip
        onClick={showReleaseFromHoldModal}
        tooltipText={disableActionButtonTooltip}
        disabled={isActionButtonDisabled || dataIsLoading}
        dataTestName="release_from_hold_button"
      >
        Release from hold
      </ButtonWithTooltip>
    );
  }

  if (isRequestApproved) {
    return (
      <WithPermission permissionKey={PermissionNamesEnums.PAYMENTS_MARK_AS_PAID}>
        <ButtonWithTooltip
          onClick={showMarkAsDisbursedModal}
          tooltipText={disableActionButtonTooltip}
          disabled={isActionButtonDisabled || dataIsLoading}
          dataTestName="draw_request_approval_buttons_mark_as_disbursed"
        >
          Mark as disbursed
        </ButtonWithTooltip>
      </WithPermission>
    );
  }

  return (
    <>
      {showRejectButton && (
        <ButtonWithTooltip
          tooltipText={disableActionButtonTooltip}
          disabled={isActionButtonDisabled || dataIsLoading}
          variant="text"
          color="error"
          onClick={showRejectModal}
          dataTestName="draw_request_approval_buttons_return_request"
        >
          Return request
        </ButtonWithTooltip>
      )}
      <WithPermission
        permissionKey={[
          PermissionNamesEnums.DRAWREQUESTS_APPROVE,
          PermissionNamesEnums.DRAWREQUESTS_REVIEWER_TEAM_EDIT,
        ]}
      >
        <ButtonWithTooltip
          variant="new"
          color="secondary"
          onClick={showPutOnHoldModal}
          tooltipText={disableActionButtonTooltip}
          disabled={isActionButtonDisabled || dataIsLoading}
          dataTestName="draw_request_approval_buttons_put_on_hold"
        >
          Put on hold
        </ButtonWithTooltip>
      </WithPermission>
      {showBothApproveAndFundButtons ? (
        <>
          <Button
            onClick={() => handleActionButtonClick(ApproveActionEnum.APPROVE)}
            loading={dataIsLoading}
            data-cy="draw_request_approval_buttons_approve"
            variant="new"
            color="secondary"
          >
            Approve
          </Button>
          <Button
            onClick={() => handleActionButtonClick(ApproveActionEnum.FUND)}
            loading={dataIsLoading}
            data-cy="draw_request_approval_buttons_fund"
          >
            Fund
          </Button>
        </>
      ) : (
        <ButtonWithTooltip
          onClick={() => handleActionButtonClick(approveActionType)}
          tooltipText={
            !isUserHasPermissionForAction
              ? "You don't have permission to perform this action"
              : disableButtonTooltip
          }
          disabled={isApproveDisabled || !isUserHasPermissionForAction}
          dataTestName={`draw_request_approval_buttons_${approveActionType
            ?.replace(/\s+/g, '_')
            ?.toLowerCase()}`}
        >
          {isFinalApprove && isChangeRequest(drawRequest)
            ? 'Approve and finalize'
            : approveActionType}
        </ButtonWithTooltip>
      )}
    </>
  );
};

const ApprovalButtons = ({
  controller,
  projectId,
}: {
  controller: ControllerInterface;
  projectId: string;
}) => (
  <Stack
    direction={{ xs: 'column', sm: 'row' }}
    sx={{ justifyContent: 'flex-end', pt: 2 }}
    spacing={1}
  >
    <ApprovalButtonsNested controller={controller} projectId={projectId} />
  </Stack>
);

export default ApprovalButtons;
