import { PermissionNamesEnums } from '@interfaces';
import { isAllowed, ColumnType } from '@utils';

const transactionId: ColumnType = () => ({
  field: 'transaction_id',
  graphFields: ['transaction_id'],
  headerName: 'Transaction ID',
  minWidth: 80,
  groupable: false,
  aggregable: false,
  renderCell: ({ row }) => {
    return row.transaction_id || '-';
  },
  enableByPermissions: (permissions) =>
    isAllowed(PermissionNamesEnums.CUSTOMER_SUCCESS_ACCESS, permissions),
});

export default transactionId;
