import React, { useState, useEffect, useMemo } from 'react';
import { Typography, Button } from '@mui/material';
import { useFormik } from 'formik';

import { initialValues, initialErrors, validatePassword, getErrorText } from '@utils';
import { LoginWireframe, CreatePasswordInputs, LoadingSkeleton } from '@components';
import { BackToLoginButton, ThirdPartyLoginButtons } from '../components';
import * as Controller from './controller';
import { ErrorDual } from '@interfaces';

const SetPasswordForm = () => {
  const { handleCreatePassword, handleLogin, showProgress, accountToken } =
    Controller.useSetupPassword();
  // this error is used only for server response errors
  // input field validation errors are handled by formik
  const [linkError, setLinkError] = useState('');
  const [email, setEmail] = useState('');
  const [validationErrors, setValidationErrors] = useState(initialErrors);

  useEffect(() => {
    const loginWithToken = async () => {
      try {
        const res = await handleLogin({ account_token: accountToken });
        setEmail(res?.user?.email);
      } catch (error: any) {
        const err = getErrorText(error as ErrorDual);
        setLinkError(
          typeof err === 'string' ? err : 'This link is invalid, expired or has been already used.',
        );
      }
    };
    loginWithToken();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validate: (values) => validatePassword(values, setValidationErrors),
    onSubmit: async (values) => {
      try {
        await handleCreatePassword(values.password.trim());
      } catch (err: any) {
        formik.setSubmitting(false);
        formik.resetForm();
        formik.setFieldError('password', err?.toString());
      }
    },
  });

  const getTitle = useMemo(() => {
    if (linkError)
      return (
        <>
          <Typography variant="h2" sx={{ mb: '24px', textTransform: 'capitalize' }}>
            {linkError}
          </Typography>
          <Typography variant="body2" sx={{ mb: { md: '120px', xs: 'auto' } }}>
            Ask you account manager for the new link or sign in if you already set up the password.
          </Typography>
        </>
      );

    return (
      <>
        <Typography variant="h1">Let's get an account</Typography>
        {Boolean(email) && (
          <Typography variant="body2" sx={{ mt: 0.5 }}>
            {`for email - ${email}`}
          </Typography>
        )}
      </>
    );
  }, [accountToken, linkError, email]);

  const getFormContent = useMemo(() => {
    if (linkError) return null;

    return <CreatePasswordInputs validationErrors={validationErrors} />;
  }, [linkError, validationErrors]);

  const getFormButtons = useMemo(() => {
    return (
      <>
        {(linkError && <BackToLoginButton variant="contained" />) || (
          <Button
            fullWidth
            type="submit"
            loading={formik.isSubmitting}
            disabled={Boolean(validationErrors.length)}
          >
            Continue
          </Button>
        )}
        <ThirdPartyLoginButtons />
        {!linkError && <BackToLoginButton />}
      </>
    );
  }, [linkError, formik]);

  if (showProgress) return <LoadingSkeleton type="overviewBlock" />;

  return (
    <LoginWireframe
      formik={formik}
      getTitle={getTitle}
      getFormContent={getFormContent}
      getFormButtons={getFormButtons}
    />
  );
};

export default React.memo(SetPasswordForm);
