import React from 'react';
import { Stack, Typography } from '@mui/material';
import { Navigate } from 'react-router-dom';

import {
  LoadingSkeleton,
  ProjectNameLabel,
  IconButtonWithTooltip,
  CenteredStyledBox,
} from '@components';
import { ArrowBackIcon } from '@svgAsComponents';
import { colors } from '@theme';
import * as Controller from './controller';
import { OtherServiceOrder, InspectionServiceOrder } from './components';
import { ServiceTypeEnum } from '@interfaces';

const OrderService = () => {
  const controller = Controller.useOrderService();
  const {
    goBack,
    project,
    drawRequestInProgress,
    isRequestsListLoaded,
    inspectionDisabledTooltipText,
    serviceTypeDisplayName,
    service_type,
  } = controller;

  if (!service_type) return <Navigate to={`/projects/${project?.id}/overview`} />;

  return (
    <Stack sx={{ height: '100%' }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ pr: 2 }}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{ px: { md: 3, xs: 2 }, height: 84 }}
        >
          <IconButtonWithTooltip
            tooltipText="Go back"
            onClick={goBack}
            data-cy="add_borrower_page__back__icon"
          >
            <ArrowBackIcon />
          </IconButtonWithTooltip>
          <Typography variant="h1">{`Order ${serviceTypeDisplayName}`}</Typography>
        </Stack>
        <ProjectNameLabel showStatusChip />
      </Stack>
      <Stack
        sx={{
          backgroundColor: colors.background.gray,
          pt: 3,
          flex: 1,
          alignItems: 'center',
        }}
        spacing={2}
      >
        {!isRequestsListLoaded && (
          <CenteredStyledBox>
            <LoadingSkeleton type="overviewBlock" />
          </CenteredStyledBox>
        )}
        {isRequestsListLoaded &&
          (service_type === ServiceTypeEnum.INSPECTION ? (
            <InspectionServiceOrder
              onClose={goBack}
              drawRequestId={drawRequestInProgress?.id}
              inspectionDisabledTooltipText={inspectionDisabledTooltipText}
            />
          ) : (
            <OtherServiceOrder onClose={goBack} drawRequestId={drawRequestInProgress?.id} />
          ))}
      </Stack>
    </Stack>
  );
};

export default OrderService;
