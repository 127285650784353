import React, { FC, useEffect } from 'react';
import { Box, Stack } from '@mui/material';
import { IDrawRequestFundingSource } from '@interfaces';
import { CustomCurrencyTextField, LabelAndValueWithPreload, ProgressWithArrow } from '@components';
import { colors, typography } from '@theme';
import { currencyFormatter } from '@utils';
import { useStringFieldModel } from '@models';

const FundingSourceField: FC<{
  fundingSource: IDrawRequestFundingSource;
  isUpdating: boolean;
  source: string;
  handleFundingSourceChange: (
    source: IDrawRequestFundingSource,
    value: string,
    isValid: boolean,
  ) => void;
}> = ({ fundingSource, isUpdating, source, handleFundingSourceChange }) => {
  const field = useStringFieldModel({
    initValue: fundingSource.used.toString(),
    validationRule: (value) =>
      +value <= +fundingSource.previous_available && +value >= +fundingSource.used,
    validateOnChange: true,
    withProgressCheck: true,
    initError: 'Amount must be between current used amount and total available funds',
  });

  useEffect(() => {
    handleFundingSourceChange(fundingSource, field.value, field.isValid);
  }, [field.isValid]);

  return (
    <Stack spacing={3} key={fundingSource.id}>
      <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
        <Box
          sx={{
            width: 16,
            height: 16,
            borderRadius: '2px',
            backgroundColor: colors.fundingSource[fundingSource.color || 'green'].medium,
          }}
        />
        <Stack sx={{ flex: 1 }}>
          <LabelAndValueWithPreload
            isLoading={isUpdating}
            label={fundingSource.name}
            labelStyle={{ ...typography.body2SemiBold }}
          >
            <ProgressWithArrow
              prevValue={currencyFormatter(fundingSource.previous_available, '-')}
              nextValue={currencyFormatter(fundingSource.previous_available - +field.value, '-')}
              showProgress
              nextValueDataTestName={`${source}__line_item_modal__${fundingSource.name}__value`}
              getTextStyleForActive={true}
            />
          </LabelAndValueWithPreload>
        </Stack>
      </Stack>
      <CustomCurrencyTextField
        field={field}
        label={fundingSource.name}
        onChange={(e) => {
          field.handleChange(e);
          handleFundingSourceChange(fundingSource, e.target.value, field.isValid);
        }}
        inputProps={{ 'data-cy': `${source}__line_item_modal__${fundingSource.name}__value` }}
      />
    </Stack>
  );
};

export default FundingSourceField;
