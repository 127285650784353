import React from 'react';
import { Stack } from '@mui/material';
import { isOrderedService, isServiceReadyForReview, ColumnType } from '@utils';

import { ButtonWithTooltip, ServiceCancelButton } from '@components';
import { TOOLTIP_TEXTS } from '@constants';

const actions: ColumnType = ({ navigate, isCurrentProjectArchived, source }) => ({
  field: 'actions',
  graphFields: ['is_editable', 'is_cancellable'],
  graphNested: {
    service_agency: ['service_provider'],
  },
  headerName: 'Actions',
  minWidth: 250,
  groupable: false,
  aggregable: false,
  renderCell: ({ row }) => {
    const { status, project } = row || {};
    const navigateToServiceOrder = () => {
      navigate(`/projects/${project?.id}/service/${row?.id}`);
    };
    const showEnterResultButton = isServiceReadyForReview(status) || isOrderedService(status);

    return (
      <Stack spacing={1} direction="row">
        {row?.is_editable && (
          <>
            {showEnterResultButton ? (
              <ButtonWithTooltip
                size="small"
                variant="new"
                color="secondary"
                onClick={() => navigateToServiceOrder()}
                disabled={isCurrentProjectArchived}
                tooltipText={TOOLTIP_TEXTS.isCurrentProjectArchived}
                dataTestName={`${source}__enter_results__button`}
                sx={{ minWidth: '110px' }}
              >
                Enter results
              </ButtonWithTooltip>
            ) : (
              <ButtonWithTooltip
                size="small"
                variant="new"
                color="secondary"
                onClick={() => navigateToServiceOrder()}
                disabled={isCurrentProjectArchived}
                tooltipText={TOOLTIP_TEXTS.isCurrentProjectArchived}
                dataTestName={`${source}__edit_results__button`}
              >
                Edit
              </ButtonWithTooltip>
            )}
          </>
        )}
        <ServiceCancelButton serviceOrder={row} size="small" source={source} />
      </Stack>
    );
  },
});

export default actions;
