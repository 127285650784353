import { dataProvider } from '@api';
import { IMilestone, IProjectDocument, IServiceOrder } from '@interfaces';
import {
  ArgumentsProjectServiceOrdersApi,
  ArgumentsDrawRequestServiceOrdersApi,
  ArgumentsServiceOrderByIdApi,
  ArgumentsServiceOrderDocumentsApi,
} from '../../args';
import {
  drawRequestServiceOrdersApi,
  projectServiceOrdersApi,
  serviceOrderByIdApi,
  serviceOrderDocumentsApi,
  serviceOrderMilestonesApi,
} from '../../paths';

export const getServiceOrderByIdV2 = async (
  args: ArgumentsServiceOrderByIdApi,
): Promise<IServiceOrder> => dataProvider.get(serviceOrderByIdApi(args)).json();

export const getProjectServiceOrdersV2 = async (
  args: ArgumentsProjectServiceOrdersApi,
): Promise<{ results: IServiceOrder[]; count: number }> =>
  dataProvider.get(projectServiceOrdersApi(args)).json();

export const getDrawRequestServiceOrdersV2 = async (
  args: ArgumentsDrawRequestServiceOrdersApi,
): Promise<{ results: IServiceOrder[]; count: number }> =>
  dataProvider.get(drawRequestServiceOrdersApi(args)).json();

export const getServiceOrderMilestonesV2 = async (
  args: ArgumentsServiceOrderByIdApi,
): Promise<{ results: IMilestone[]; count: number }> =>
  dataProvider.get(serviceOrderMilestonesApi(args)).json();

export const getServiceOrderDocumentsV2 = async (
  args: ArgumentsServiceOrderDocumentsApi,
): Promise<{ results: IProjectDocument[]; count: number }> =>
  dataProvider.get(serviceOrderDocumentsApi(args)).json();
