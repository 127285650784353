import React, { FC, ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';
import { CSVBoxSheetsEnum, HookState, PermissionNamesEnums } from '@interfaces';
import { colors } from '@theme';
import {
  CheckboxIconRoundedChecked,
  CheckboxIconRoundedDefault,
  ChevronRightIcon,
  FillTableImage,
  UnitImage,
} from '@svgAsComponents';
import {
  CSVUploader,
  IsProductionBuildCheckbox,
  LoadingSkeleton,
  ServiceMessage,
  StyledLink,
} from '@components';
import { useBudgetCreation } from './controller';
import { CSVSampleLinks, CSVSampleLinksNames } from '@constants';
import { WithPermission } from '@utils';
import { ChooseNamingModal } from '../Modals/ChooseNamingModal';
import { ChooseBudgetViewModal } from '../Modals/ChooseBudgetViewModal/';
import BudgetTableV2 from '../BudgetTableV2';
import { useLaunchDarklyFlags } from '@context';

const ProductionBuildBudget: FC = () => {
  const { projectId } = useParams();

  const {
    isTableFilled,
    isChooseNamingModalShown,
    setIsChooseNamingModalShown,
    modelUnitSectionClick,
    verticalCostCSVUrl,
    horizontalMilestonesCount,
    verticalMilestonesCount,
    showTable,
    isBudgetEditable,
    isChooseBudgetViewModalShown,
    setIsChooseBudgetViewModalShown,
    dynamicColumns,
    isCreatingByModelsFilled,
    state,
    hasUnfilledName,
    isUploadingVerticalDisabled,
    isUploadingHorizontalDisabled,
    showBudgetUploadButtons,
  } = useBudgetCreation();
  const flags = useLaunchDarklyFlags();

  switch (state) {
    case HookState.ERROR: {
      return <ServiceMessage text="Budget creation" />;
    }

    case HookState.LOADING: {
      return <LoadingSkeleton type="twoBlocks" />;
    }

    case HookState.SUCCESS: {
      return (
        <>
          <WithPermission permissionKey={PermissionNamesEnums.PROJECT_ONBOARDING}>
            {flags?.['ENG_9420_number_of_units_for_all_projects'] && showBudgetUploadButtons ? (
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  flexGrow: 1,
                  backgroundColor: colors.background.white,
                  borderRadius: 0.5,
                  p: 2,
                  mb: 2,
                }}
              >
                <Stack direction="row" spacing={1}>
                  <CSVUploader
                    sheetKey={CSVBoxSheetsEnum.IMPORT_PROJECT_BUDGET}
                    templateUrl={CSVSampleLinks.BUDGET_UPLOAD}
                    templateName={CSVSampleLinksNames.PRODUCTION__HORIZONTAL_BUDGET}
                    renderItem={(launch) => (
                      <Button
                        data-cy="project__budget__create__horizontal_costs__upload_budget_button"
                        onClick={launch}
                        variant="new"
                        color="secondary"
                        disabled={
                          Boolean(horizontalMilestonesCount) || isUploadingHorizontalDisabled
                        }
                      >
                        Upload Horizontal & Soft costs (Optional)
                      </Button>
                    )}
                  />
                  <CSVUploader
                    key={verticalCostCSVUrl}
                    sheetKey={CSVBoxSheetsEnum.IMPORT_BUDGET_PHB}
                    templateUrl={verticalCostCSVUrl}
                    templateName={CSVSampleLinksNames.PRODUCTION__VERTICAL_BUDGET}
                    dynamicColumns={dynamicColumns}
                    phbTagKey={isCreatingByModelsFilled ? 'model_name' : 'unit_name'}
                    renderItem={(launch) => (
                      <Button
                        data-cy="project__budget__create__vertical_costs__upload_budget_button"
                        onClick={launch}
                        variant="new"
                        color="secondary"
                        disabled={Boolean(verticalMilestonesCount) || isUploadingVerticalDisabled}
                      >
                        Upload Vertical costs
                      </Button>
                    )}
                  />
                </Stack>
                {isBudgetEditable && !isTableFilled && (
                  <IsProductionBuildCheckbox checked wrapperPadding={0} justifyContent="flex-end" />
                )}
              </Stack>
            ) : (
              <>
                {isBudgetEditable && !isTableFilled && (
                  <Stack mb={2}>
                    <IsProductionBuildCheckbox checked />
                  </Stack>
                )}
                {showBudgetUploadButtons && (
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                      width: '100%',
                      ' > div': { width: '100%', height: '80px' },
                    }}
                    mb={2}
                  >
                    <FillingTableItems
                      name="Budget naming & Structure"
                      isCompleted={isTableFilled && !hasUnfilledName}
                      image={<UnitImage />}
                      onClick={modelUnitSectionClick}
                      dataCy="project__budget__create__budget_structure__button"
                    />
                    <CSVUploader
                      sheetKey={CSVBoxSheetsEnum.IMPORT_PROJECT_BUDGET}
                      templateUrl={CSVSampleLinks.BUDGET_UPLOAD}
                      templateName={CSVSampleLinksNames.PRODUCTION__HORIZONTAL_BUDGET}
                      renderItem={(launch) => (
                        <FillingTableItems
                          name="Horizontal & Soft costs (Optional)"
                          isCompleted={Boolean(horizontalMilestonesCount)}
                          image={<FillTableImage width={55} height={35} />}
                          onClick={launch}
                          isDisabled={isUploadingHorizontalDisabled}
                          dataCy="project__budget__create__horizontal_costs__upload_budget_button"
                        />
                      )}
                    />
                    <CSVUploader
                      key={verticalCostCSVUrl}
                      sheetKey={CSVBoxSheetsEnum.IMPORT_BUDGET_PHB}
                      templateUrl={verticalCostCSVUrl}
                      templateName={CSVSampleLinksNames.PRODUCTION__VERTICAL_BUDGET}
                      dynamicColumns={dynamicColumns}
                      phbTagKey={isCreatingByModelsFilled ? 'model_name' : 'unit_name'}
                      renderItem={(launch) => (
                        <FillingTableItems
                          name="Vertical costs"
                          isCompleted={Boolean(verticalMilestonesCount)}
                          image={<FillTableImage width={55} height={35} />}
                          onClick={launch}
                          isDisabled={isUploadingVerticalDisabled}
                          dataCy="project__budget__create__vertical_costs__upload_budget_button"
                        />
                      )}
                    />
                  </Stack>
                )}
              </>
            )}
            <ChooseNamingModal
              open={isChooseNamingModalShown}
              onClose={() => setIsChooseNamingModalShown(false)}
            />
            <ChooseBudgetViewModal
              open={isChooseBudgetViewModalShown}
              onClose={() => setIsChooseBudgetViewModalShown(false)}
              isCreatedByModels={isCreatingByModelsFilled}
            />
          </WithPermission>
          {showTable ? (
            <BudgetTableV2 />
          ) : (
            <Stack
              justifyContent="center"
              alignItems="center"
              padding={8}
              sx={{ flexGrow: 1, backgroundColor: colors.background.white }}
            >
              <FillTableImage />
              <Typography
                variant="h2"
                mt={8}
                textAlign="center"
                data-cy="project__budget__create__fill_info__label"
              >
                To see the whole budget, please fill the information <br /> above and add budget
                structure in{' '}
              </Typography>

              <StyledLink variant="h2" to={`/projects/${projectId}/settings/general/`}>
                project settings.
              </StyledLink>
            </Stack>
          )}
        </>
      );
    }

    default:
      return null;
  }
};

const FillingTableItems: FC<{
  name: string;
  isCompleted: boolean;
  image: ReactElement;
  onClick: () => void;
  isDisabled?: boolean;
  dataCy?: string;
}> = ({ name, isCompleted, image, onClick, isDisabled, dataCy }) => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    paddingX={1}
    paddingY={2.5}
    sx={{
      flexGrow: 1,
      backgroundColor: colors.background.white,
      cursor: !isDisabled && 'pointer',
      height: '100%',
    }}
    onClick={!isDisabled ? onClick : null}
    data-cy={dataCy}
  >
    {image}
    <Typography
      variant="h4"
      sx={{ color: isDisabled ? colors.neutral.medium : colors.text.heading }}
    >
      {name}
    </Typography>
    {isCompleted ? (
      <CheckboxIconRoundedChecked
        color={isDisabled ? colors.neutral.medium : colors.status.success.medium}
      />
    ) : (
      <CheckboxIconRoundedDefault
        color={isDisabled ? colors.neutral.medium : colors.neutral.lighter}
      />
    )}
    <ChevronRightIcon color={isDisabled ? colors.neutral.medium : colors.icons.gray} />
  </Stack>
);
export default ProductionBuildBudget;
