import React from 'react';
import { Button } from '@mui/material';

import { ConfirmationModal } from '@components';
import { PopupTypeEnum, IServiceOrder } from '@interfaces';
import { useLaunchDarklyFlags } from '@context';
import * as Controller from './controller';

const ServiceCancelButton = ({
  serviceOrder,
  size = 'medium',
  source,
}: {
  serviceOrder: Pick<
    IServiceOrder,
    'is_cancellable' | 'id' | 'status' | 'draw_request' | 'service_type'
  > & {
    project: Pick<IServiceOrder['project'], 'id' | 'name' | 'status'>;
  };
  size?: 'medium' | 'small' | 'large';
  source?: string;
}) => {
  const flags = useLaunchDarklyFlags();

  const controller = Controller.useServiceOrderDocuments(serviceOrder);

  const { showCancelButton, confirmModal, isServiceManual, handleCancelService, isSubmitting } =
    controller;

  if (
    (flags?.['ENG_9657_show_inspections_in_services'] && !serviceOrder?.is_cancellable) ||
    !showCancelButton
  )
    return null;
  return (
    <>
      <Button
        size={size}
        color="error"
        onClick={(e) => {
          e.stopPropagation();
          confirmModal.askConfirm();
        }}
        data-cy={`${source}__cancel_service__button`}
        sx={{ minWidth: isServiceManual ? 'auto' : '155px' }}
      >
        {isServiceManual ? 'Cancel' : 'Request cancellation'}
      </Button>
      <ConfirmationModal
        open={confirmModal.isConfirmModalOpened}
        title={isServiceManual ? 'Cancel service' : 'Request service cancellation'}
        text={
          isServiceManual
            ? 'Are you sure you want to cancel this service?'
            : 'Are you sure you want to request the cancellation of this service?'
        }
        onClose={confirmModal.closeConfirmModal}
        confirmCallback={handleCancelService}
        type={PopupTypeEnum.CONFIRMATION}
        cancelButtonLabel="Close"
        isLoading={isSubmitting}
      />
    </>
  );
};

export default ServiceCancelButton;
