import React, { Dispatch, FC, SetStateAction } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { ActiveFundingSource } from '@interfaces';
import { EditIcon } from '@svgAsComponents';
import { colors } from '@theme';
import { currencyFormatter } from '@utils';
import { IconButtonWithTooltip } from '@components';

interface FundingSourceCardProps {
  fundingSource: ActiveFundingSource;
  setActiveFundingSource: Dispatch<SetStateAction<ActiveFundingSource>>;
  source: string;
  isEditable?: boolean;
}

const FundingSourceCard: FC<FundingSourceCardProps> = ({
  fundingSource,
  setActiveFundingSource,
  source,
  isEditable = true,
}) => {
  if (!fundingSource) return null;

  const { colorKey, total, fractionValue, name, internal_identifier, field } = fundingSource;
  const dataCyPrefix = `${source}__funding_source__${internal_identifier?.toLowerCase()}`;
  const sourceTotal = internal_identifier ? field?.floatValue : total;
  const backgroundColor = colors.fundingSource[colorKey]?.lighter;
  const textColor = colors.fundingSource[colorKey]?.medium;

  if (sourceTotal) {
    return (
      <Stack
        sx={{
          paddingX: '40px',
          paddingY: '24px',
          backgroundColor,
          minWidth: '200px',
          minHeight: '146px',
        }}
        spacing={1}
      >
        <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography
            color={colors.text.heading}
            variant="labelSemiBold"
            data-cy={`${dataCyPrefix}__name__value`}
          >
            {name}
          </Typography>
          {isEditable && (
            <IconButtonWithTooltip
              onClick={() => setActiveFundingSource({ ...fundingSource })}
              tooltipText="Edit"
              data-cy={`${dataCyPrefix}__edit__button`}
            >
              <EditIcon color={colors.icons.green} size={24} />
            </IconButtonWithTooltip>
          )}
        </Stack>
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Typography variant="h3" color={textColor} data-cy={`${dataCyPrefix}__amount__value`}>
            {`${currencyFormatter(sourceTotal)} ${fractionValue ? '/' : ''}`}
          </Typography>
          {Boolean(fractionValue) && (
            <Typography
              variant="body3SemiBold"
              color={textColor}
              data-cy={`${dataCyPrefix}__fraction__value`}
            >
              {fractionValue}
            </Typography>
          )}
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack
      sx={{
        paddingX: '40px',
        paddingY: '29px',
        backgroundColor,
        minWidth: '200px',
        minHeight: '146px',
      }}
      justifyContent="center"
      spacing={1}
    >
      <Typography variant="h3">{name}</Typography>
      {isEditable && (
        <Button
          sx={{ width: 56 }}
          onClick={() => setActiveFundingSource({ ...fundingSource })}
          data-cy={`${dataCyPrefix}__add__button`}
          size="small"
        >
          Add
        </Button>
      )}
    </Stack>
  );
};

export default FundingSourceCard;
