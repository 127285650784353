import React, { FC } from 'react';
import { Button, Grid2, Stack, Typography, Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import {
  ButtonWithTooltipOnClick,
  CenteredStyledBox,
  FullScreenDialog,
  LabelAndValue,
  ServiceRelatedDocuments,
  StyledBox,
  SuccessModal,
  WysiwygEditor,
  CustomRadioGroupGridLayout,
  ProjectNameLabel,
  IconButtonWithTooltip,
  InspectionInputsBlock,
  CollapsedCard,
} from '@components';
import { colors } from '@theme';
import * as Controller from './controller';
import { ServiceTypeEnum } from '@interfaces';
import { TutorialIcon, ArrowBackIcon } from '@svgAsComponents';
import { ServiceTypeSelection } from './components/ServiceTypeSelection';
import { getServiceTypeDisplayName } from '@utils';

const OrderServiceComponent = () => {
  const { projectId, serviceOrderId } = useParams();

  const {
    serviceAgenciesList,
    serviceAgenciesListIsLoading,
    agencySelected,
    setAgencySelected,
    serviceOrder,
    handleServiceOrderRequest,
    isSubmitting,
    isSubmitDisabled,
    isSuccessModalOpen,
    commentField,
    detailsPopup,
    setDetailsPopup,
    handleInfoIconClick,
    handleWhatIsTheBestOptionClick,
    serviceType,
    setServiceType,
    inspectionFields,
    isTruePicInspection,
    inspectionDisabledTooltipText,
    serviceTypesMap,
  } = Controller.useService({
    projectId,
    serviceOrderId,
  });

  return (
    <Stack sx={{ height: '100%' }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ pr: 2 }}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{ px: { md: 3, xs: 2 }, height: 84 }}
        >
          <IconButtonWithTooltip
            tooltipText="Go back"
            // onClick={goBack}
            data-cy="add_borrower_page__back__icon"
          >
            <ArrowBackIcon />
          </IconButtonWithTooltip>
          <Typography variant="h1">Order service</Typography>
        </Stack>
        <ProjectNameLabel showStatusChip />
      </Stack>
      <Stack
        sx={{
          backgroundColor: colors.background.gray,
          pt: 3,
          flex: 1,
          alignItems: 'center',
        }}
        spacing={2}
      >
        <Stack sx={{ flex: 1, width: '100%', alignItems: 'center' }}>
          <Stack justifyContent="space-between" sx={{ flex: 1 }}>
            <Stack spacing={2}>
              <CollapsedCard
                isExpanded={true}
                title="Service type"
                value={getServiceTypeDisplayName({
                  serviceTypesMap,
                  serviceType,
                })}
              >
                <CenteredStyledBox>
                  <Box sx={{ mt: 3, width: '100%' }}>
                    <ServiceTypeSelection
                      selectedType={serviceType}
                      onTypeSelect={(type) => {
                        setServiceType(type);
                      }}
                      inspectionDisabledTooltipText={inspectionDisabledTooltipText}
                    />
                  </Box>
                </CenteredStyledBox>
              </CollapsedCard>

              <CenteredStyledBox>
                <Stack spacing={2} direction="row" alignItems="center" mb={4}>
                  <Typography variant="h3">Service provider</Typography>

                  {!!serviceAgenciesList?.length && (
                    <Stack
                      onClick={handleWhatIsTheBestOptionClick}
                      direction="row"
                      alignItems="center"
                      sx={{
                        backgroundColor: colors.status.information.lighter,
                        borderRadius: 0.5,
                        minHeight: 24,
                        py: 0.5,
                        px: 1,
                        cursor: 'pointer',
                      }}
                      spacing={0.5}
                    >
                      <TutorialIcon color={colors.status.information.medium} size={16} />
                      <Typography
                        variant="label"
                        sx={{ color: colors.status.information.medium, fontWeight: 600 }}
                      >
                        What is the best option?
                      </Typography>
                    </Stack>
                  )}
                </Stack>

                <CustomRadioGroupGridLayout
                  dataTestName="order_service__service_agencies__radio__group"
                  value={agencySelected || ''}
                  onChange={(e) => setAgencySelected(e.target.value)}
                  options={serviceAgenciesList}
                  handleInfoIconClick={handleInfoIconClick}
                  isLoading={serviceAgenciesListIsLoading}
                />
              </CenteredStyledBox>

              <Grid2 container spacing={1} justifyContent="space-between">
                <Grid2 size={{ xs: 12, md: 6 }}>
                  <StyledBox flex={1}>
                    {serviceOrder?.draw_request?.id && (
                      <LabelAndValue
                        label="Draw request"
                        text={`#${serviceOrder?.draw_request?.number}`}
                      />
                    )}
                  </StyledBox>
                </Grid2>
                <Grid2 size={{ xs: 12, md: 6 }}>
                  <StyledBox flex={1}>
                    <LabelAndValue
                      label="Address"
                      text={serviceOrder?.project?.address?.full_address}
                    />
                  </StyledBox>
                </Grid2>
              </Grid2>

              {serviceType === ServiceTypeEnum.INSPECTION && (
                <CenteredStyledBox>
                  {/* {tooltipText && (
                    <Box>
                      <ToolTipLine
                      typographyVariant="body2"
                      text={tooltipText}
                      sx={{ justifyContent: 'flex-start' }}
                    />
                  </Box>
                )} */}

                  <Box sx={{ mt: 2 }}>
                    <InspectionInputsBlock
                      source="order_inspection_dialog"
                      inputWidth={6}
                      showRequestedDate
                      isTruePicInspection={isTruePicInspection}
                      {...inspectionFields}
                      disabled={!!inspectionDisabledTooltipText}
                    />
                  </Box>
                </CenteredStyledBox>
              )}

              {serviceOrder?.id && agencySelected && (
                <ServiceRelatedDocuments
                  drawRequestId={serviceOrder?.draw_request?.id}
                  serviceOrderId={serviceOrder?.id}
                  serviceType={serviceType}
                  source="order_service__related_documents"
                />
              )}

              <CenteredStyledBox>
                <Stack spacing={2}>
                  <Typography variant="h3">Special instructions</Typography>

                  <WysiwygEditor editField={commentField} source="order_service__comments" />
                </Stack>
              </CenteredStyledBox>

              <CenteredStyledBox>
                <Typography variant="body3">
                  <span style={{ color: colors.status.information.medium, fontWeight: 700 }}>
                    Disclaimer.{' '}
                  </span>
                  By clicking "Order" below, I agree and acknowledge that I am ordering a product or
                  service from a third-party provider ("Provider"), not TrustPoint. I understand and
                  agree that this order is subject to the Provider's, and not TrustPoint's, Terms
                  and Conditions, which can be found{' '}
                  <a
                    href="https://www.trustpoint.ai/third-party-terms-of-service"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </a>
                  . I certify that I have read and understood the Provider's Terms and Conditions,
                  and I agree to abide by, and be bound by, the Provider's Terms and Conditions.
                </Typography>
              </CenteredStyledBox>

              {isSuccessModalOpen && (
                <SuccessModal
                  text="Service has been ordered."
                  open
                  // onClose={() => onClose()}
                  buttons={<ModalBackToProjectServicesButtons projectId={projectId} />}
                />
              )}
            </Stack>
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            sx={{
              backgroundColor: colors.background.white,
              mt: 2,
              padding: '1.5rem',
              width: '100%',
            }}
          >
            <Button
              variant="new"
              sx={{ mr: '0.5rem' }}
              // onClick={() => onClose()}
              data-cy="order_service__cancel__button"
            >
              Cancel
            </Button>
            <ButtonWithTooltipOnClick
              onClick={handleServiceOrderRequest}
              loading={isSubmitting}
              disabled={isSubmitting || !serviceOrder?.id || !agencySelected}
              conditionallyDisabled={isSubmitDisabled}
              dataTestName="order_service__submit__button"
            >
              {isSubmitting ? 'Ordering...' : 'Order'}
            </ButtonWithTooltipOnClick>
          </Stack>

          <FullScreenDialog
            open={detailsPopup.open}
            title={detailsPopup.title}
            actionButtons={null}
            onClose={() => setDetailsPopup({ open: false, title: '', content: null })}
            content={detailsPopup.content}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default OrderServiceComponent;

export const OptionsListDescription = ({ list }) => {
  return (
    <Grid2 container spacing={2} flexWrap="wrap">
      {list?.map((item) => (
        <Grid2 size={{ xs: 6 }} key={item.id}>
          <StyledBox>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="h3">{item.label}</Typography>
              {item.logo && (
                <img
                  alt="service agency logo"
                  src={item.logo}
                  style={{ minHeight: '50px', maxHeight: '50px' }}
                />
              )}
            </Stack>

            <Typography
              variant="body1"
              sx={{ mt: 1, fontSize: '14px' }}
              dangerouslySetInnerHTML={{ __html: item.description }}
            />
          </StyledBox>
        </Grid2>
      ))}
    </Grid2>
  );
};

export const OptionDescription = ({ item }) => {
  return (
    <StyledBox>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h3">{item.label}</Typography>
        {item.logo && (
          <img
            alt="service agency logo"
            src={item.logo}
            style={{ minHeight: '50px', maxHeight: '50px' }}
          />
        )}
      </Stack>

      <Typography
        variant="body1"
        sx={{ mt: 1, fontSize: '14px' }}
        dangerouslySetInnerHTML={{ __html: item.description }}
      />
    </StyledBox>
  );
};

const ModalBackToProjectServicesButtons: FC<{
  projectId: string;
}> = ({ projectId }) => {
  const navigate = useNavigate();
  return (
    <Stack direction="row">
      <Button
        variant="new"
        color="secondary"
        onClick={() => navigate(`/projects/${projectId}/services/`)}
        data-cy="order_service_modal_back_to_project_button"
      >
        Go to project
      </Button>
      <Button
        variant="new"
        color="secondary"
        onClick={() => navigate(`/services/`)}
        sx={{ ml: '8px' }}
        data-cy="order_service_modal_back_to_services_button"
      >
        Go to services list
      </Button>
    </Stack>
  );
};
