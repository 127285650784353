import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { isTruePicInspection, Override } from '@utils';
import { ServiceTypeEnum, IServiceOrder } from '@interfaces';
import { useStringFieldModel, StringFieldModel } from '@models';
import { useLocation } from 'react-router-dom';
import { useServiceQueriesAndMutations } from './utils';
import { OptionDescription, OptionsListDescription } from './index';
import { useInspectionFields } from '@hooks';

export type ControllerInterface = Override<
  ReturnType<typeof useService>,
  {
    serviceAgenciesList: { value: string; label: string }[];
    serviceAgenciesListIsLoading: boolean;
    agencySelected: string | null;
    setAgencySelected: (value: string) => void;
    setServiceType: (value: ServiceTypeEnum) => void;
    serviceOrder: IServiceOrder;
    handleServiceOrderRequest: () => void;
    isSubmitting: boolean;
    isSubmitDisabled: boolean;
    isSuccessModalOpen: boolean;
    commentField: StringFieldModel;
    setDetailsPopup: Dispatch<
      SetStateAction<{
        open: boolean;
        title: string;
        content: React.ReactNode;
      }>
    >;
    detailsPopup: {
      open: boolean;
      title: string;
      content: React.ReactNode;
    };
    handleInfoIconClick?: (item: {
      value: string;
      label: string;
      description?: React.ReactNode;
      content?: React.ReactNode;
    }) => void;
    handleWhatIsTheBestOptionClick: () => void;
  }
>;

interface CustomizedState {
  serviceType: ServiceTypeEnum;
}

export const useService = ({
  projectId,
  serviceOrderId,
}: {
  projectId: string;
  serviceOrderId: string;
}) => {
  const commentField = useStringFieldModel({
    initValue: '',
  });

  const {
    additionalContactName,
    additionalContactPhone,
    accessCode,
    primaryContactUser,
    primaryContactUserList,
    isBorrowerUsersLoading,
    inspectionRequestedAt,
    primaryContactPhone,
    isContactListHasUsersWithPhone,
  } = useInspectionFields({});

  const location = useLocation();
  const state = location?.state as CustomizedState;
  console.log({ state });
  const [serviceType, setServiceType] = useState<ServiceTypeEnum>(
    state?.serviceType || ServiceTypeEnum.INSPECTION,
  );

  const {
    serviceAgenciesList,
    serviceAgenciesListIsLoading,
    patchServiceOrder,
    serviceOrder,
    isSuccessModalOpen,
    inspectionDisabledTooltipText,
    serviceTypesMap,
  } = useServiceQueriesAndMutations({
    projectId,
    serviceOrderId,
    serviceType,
  });

  const [agencySelected, setAgencySelected] = useState(null);

  const [detailsPopup, setDetailsPopup] = useState({
    open: false,
    title: '',
    content: null,
  });

  useEffect(() => {
    if (serviceAgenciesList?.length) setAgencySelected(serviceAgenciesList[0]?.value);
  }, [serviceAgenciesList]);

  const handleServiceOrderRequest = async () => {
    const json = {
      service_type: serviceType,
      service_agency: agencySelected,
      ...(commentField.value ? { comment: commentField.value } : {}),
    };

    await patchServiceOrder.mutateAsync({ projectId, serviceOrderId, json });
  };

  const handleInfoIconClick = (item) => {
    setDetailsPopup({
      open: true,
      title: 'Service provider',
      content: <OptionDescription item={item} />,
    });
  };

  const handleWhatIsTheBestOptionClick = () => {
    setDetailsPopup({
      open: true,
      title: 'What is the best option?',
      content: <OptionsListDescription list={serviceAgenciesList} />,
    });
  };

  return {
    serviceAgenciesList,
    serviceAgenciesListIsLoading,
    agencySelected,
    setAgencySelected,
    serviceType,
    setServiceType,
    serviceOrder: serviceOrder.data,
    handleServiceOrderRequest,
    isSubmitting: patchServiceOrder.isLoading,
    isSubmitDisabled: !agencySelected || patchServiceOrder.isLoading,
    isSuccessModalOpen,
    commentField,
    detailsPopup,
    setDetailsPopup,
    handleInfoIconClick,
    handleWhatIsTheBestOptionClick,
    inspectionFields: {
      additionalContactName,
      additionalContactPhone,
      accessCode,
      primaryContactUserList,
      isBorrowerUsersLoading,
      inspectionRequestedAt,
      primaryContactUser,
      primaryContactPhone,
      isContactListHasUsersWithPhone,
    },
    isTruePicInspection: isTruePicInspection(agencySelected?.service),
    inspectionDisabledTooltipText,
    serviceTypesMap,
  };
};
