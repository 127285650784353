import React from 'react';
import { Grid2, Stack, Typography } from '@mui/material';

import {
  CustomAutocomplete,
  CustomCurrencyTextField,
  DateTimePickerWithTimezone,
  CustomSelect,
  CustomTextField,
  FundingSourcesContainer,
  StyledBox,
  TabWrapperWithLeaveWarning,
  LoadingSkeleton,
} from '@components';
import { getNextDay, getPrevDay } from '@utils';
import * as Controller from './controller';
import { useLaunchDarklyFlags } from '@context';
const LoanDetails = ({ isAllProjectDetailsDisabled, isLimitedProjectDetailsDisabled }) => {
  const controller = Controller.useLoanDetails({ isAllProjectDetailsDisabled });
  const {
    external_id,
    loc_commitment,
    prefunding_cost,
    post_funding_construction_budget,
    construction_holdback,
    construction_holdback_fraction,
    borrower_equity,
    borrower_equity_fraction,
    handleSubmitClick,
    isSubmitting,
    isEditable,
    isUpdated,
    exitPath,
    isDisabled,
    completionDateReasonsList,
    projectDates,
    handleCompletionDateReasonChange,
    isCompletionDateReasonsValid,
    completionDateReasons,
    loan_status,
    loanStatusesList,
    loan_type,
    loanTypesList,
    legal_entity,
    configurationType,
    setConfigurationType,
    configurationTypeOptions,
    project,
    interest_method,
    interestMethodsList,
    projectFundingSources,
    isLoading,
    customSources,
    handleSaveFundingSource,
  } = controller;
  const {
    funding_date,
    estimated_start_date,
    original_completion_date,
    estimated_completion_date,
    maturity_date,
    extended_maturity_date,
  } = projectDates;
  const flags = useLaunchDarklyFlags();

  if (isLoading) return <LoadingSkeleton type="overviewBlock" />;

  return (
    <TabWrapperWithLeaveWarning
      onSave={handleSubmitClick}
      isUpdated={isUpdated}
      isDisabled={isDisabled}
      tabValue="loan"
      path={exitPath}
      isSubmitting={isSubmitting}
      currentPagePathname={'/projects/*/settings/loan'}
    >
      <StyledBox>
        <Typography variant="h3">General</Typography>

        <Grid2 container spacing={2} sx={{ mt: 3 }}>
          <Grid2 size={{ xs: 12, lg: 4 }}>
            <CustomTextField
              field={external_id}
              label="Loan ID (External identifier)"
              required={false}
              inputProps={{
                'data-cy': 'project__settings__loan_id__input',
              }}
              disabled={isAllProjectDetailsDisabled || !isEditable}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, lg: 4 }}>
            <Stack sx={{ width: '100%' }}>
              <CustomCurrencyTextField
                label="Total loan commitment"
                field={loc_commitment}
                disabled={isAllProjectDetailsDisabled || !isEditable}
              />
              <Typography variant="label" sx={{ display: 'inline-block' }}>
                Costs towards land acquisition, construction, associated expenses
              </Typography>
            </Stack>
          </Grid2>
          {flags?.['ENG_10024_add_interest_method_to_loan'] && (
            <Grid2 size={{ xs: 12, lg: 4 }}>
              <CustomAutocomplete
                label="Interest method"
                field={interest_method}
                options={interestMethodsList}
                disabled={isAllProjectDetailsDisabled || !isEditable}
                handleTextFieldChange={(value: string) => {
                  if (!value) {
                    interest_method.setValue(null);
                  }
                }}
                inputProps={{
                  'data-cy': 'project__settings__loan__interest_method__input',
                }}
              />
            </Grid2>
          )}
        </Grid2>
        <Grid2 container spacing={2} sx={{ mt: 2 }}>
          <Grid2 size={{ xs: 12, lg: 4 }}>
            <CustomAutocomplete
              label="Loan type"
              field={loan_type}
              options={loanTypesList}
              inputProps={{
                'data-cy': 'project__settings__loan__type__input',
              }}
              disabled={isAllProjectDetailsDisabled || !isEditable}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, lg: 4 }}>
            <CustomAutocomplete
              label="Servicing status"
              field={loan_status}
              options={loanStatusesList}
              disabled={isAllProjectDetailsDisabled}
              required={false}
              handleTextFieldChange={(value: string) => {
                if (!value) {
                  loan_status.setValue(null);
                }
              }}
              inputProps={{
                'data-cy': 'project__settings__loan__servicing_status__input',
              }}
            />
          </Grid2>

          <Grid2 size={{ xs: 12, lg: 4 }}>
            <DateTimePickerWithTimezone
              label="Loan funding date"
              field={funding_date}
              inputProps={{
                'data-cy': 'project__settings__loan__funding_date__input',
              }}
              disabled={isAllProjectDetailsDisabled || !isEditable}
              maxDate={getPrevDay(maturity_date.value)}
            />
          </Grid2>
        </Grid2>
        <Grid2 container spacing={2} sx={{ mt: 2 }}>
          <Grid2 size={{ xs: 12, lg: 4 }}>
            <DateTimePickerWithTimezone
              label="Original maturity date"
              field={maturity_date}
              minDate={getNextDay(funding_date.value)}
              maxDate={extended_maturity_date.value}
              inputProps={{
                'data-cy': 'project__settings__loan__maturity_date__input',
              }}
              disabled={isAllProjectDetailsDisabled || !isEditable}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, lg: 4 }}>
            <DateTimePickerWithTimezone
              label="Extended maturity date"
              field={extended_maturity_date}
              minDate={maturity_date.value}
              inputProps={{
                'data-cy': 'project__settings__loan__extended_maturity_date__input',
              }}
              disabled={isAllProjectDetailsDisabled}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, lg: 4 }}>
            <DateTimePickerWithTimezone
              label="Start date"
              field={estimated_start_date}
              inputProps={{
                'data-cy': 'project__settings__loan__estimated_start_date__input',
              }}
              disabled={isAllProjectDetailsDisabled || !isEditable}
              maxDate={getPrevDay(original_completion_date.value)}
            />
          </Grid2>
        </Grid2>
        <Grid2 container spacing={2} sx={{ mt: 2 }}>
          <Grid2 size={{ xs: 12, lg: 4 }}>
            <DateTimePickerWithTimezone
              label="Original completion date"
              field={original_completion_date}
              minDate={getNextDay(estimated_start_date.value)}
              inputProps={{
                'data-cy': 'project__settings__loan__original_completion_date__input',
              }}
              disabled={isAllProjectDetailsDisabled || !isEditable}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, lg: 4 }}>
            <Stack>
              <DateTimePickerWithTimezone
                label="Estimated completion date"
                field={estimated_completion_date}
                minDate={getNextDay(estimated_start_date.value)}
                inputProps={{
                  'data-cy': 'project__settings__loan__estimated_completion_date__input',
                }}
                disabled={isLimitedProjectDetailsDisabled && isAllProjectDetailsDisabled}
              />
              {project.estimated_completion_date_change_reason && (
                <Typography variant="label">
                  Reason:{' '}
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: project.estimated_completion_date_change_reason,
                    }}
                    variant="labelSemiBold"
                    data-cy="project__overview__estimated_completion_date__change_reason__value"
                  />
                </Typography>
              )}
            </Stack>
          </Grid2>
          <Grid2 size={{ xs: 12, lg: 4 }}>
            <CustomSelect
              label="Reason for updating estimated completion date"
              optionsList={completionDateReasonsList}
              selectedOptions={completionDateReasons}
              handleSelectChange={handleCompletionDateReasonChange}
              required={estimated_completion_date.isChanged}
              error={!isCompletionDateReasonsValid}
              dataTestName="project__overview__completion_date__reasons__select"
              disabled={isLimitedProjectDetailsDisabled && isAllProjectDetailsDisabled}
              errorText="A reason is required when updating the estimated completion date"
            />
          </Grid2>
        </Grid2>
      </StyledBox>

      <StyledBox>
        <FundingSourcesContainer
          prefunding_cost={prefunding_cost}
          construction_holdback={construction_holdback}
          constructionHoldbackFraction={construction_holdback_fraction}
          borrower_equity={borrower_equity}
          borrowerEquityFraction={borrower_equity_fraction}
          configurationType={configurationType}
          setConfigurationType={setConfigurationType}
          constructionBudget={post_funding_construction_budget}
          source="project__settings"
          isEditable={!isAllProjectDetailsDisabled && isEditable}
          configurationTypeOptions={configurationTypeOptions}
          budgetPrefundingCost={project?.loan?.budget_prefunding_cost?.toString()}
          budgetScheduleOfValues={project?.loan?.budget_post_funding_construction_budget?.toString()}
          projectFundingSources={projectFundingSources}
          customSources={customSources}
          handleSaveFundingSource={handleSaveFundingSource}
        />
      </StyledBox>

      <StyledBox>
        <Stack direction="row" alignItems="flex-end" spacing={0.5} sx={{ mb: 1 }}>
          <Typography variant="h3">Legal entity</Typography>
          <Typography variant="labelSemiBold" sx={{ pb: 0.2 }}>
            (Optional)
          </Typography>
        </Stack>
        <Typography variant="body1" sx={{ mb: 3 }}>
          Legal entity responsible for the loan. It may differ from the company name.
        </Typography>
        <CustomTextField
          field={legal_entity}
          label="Company name"
          inputProps={{
            'data-cy': 'project__settings__loan__legal_entity__input',
          }}
          disabled={isAllProjectDetailsDisabled || !isEditable}
        />
      </StyledBox>
    </TabWrapperWithLeaveWarning>
  );
};

export default LoanDetails;
