import { createContext, useCallback, useContext, useMemo } from 'react';
import MilestoneColumns from './columnsV2';
import { PermissionsContext, useGraphQuery, AuthContext } from '@context';
import { HookInterface, TableContextInterface } from './interface';
import {
  getSortedByIndexMilestones,
  isCreditInRequest,
  isDrawRequest,
  isAllowed,
  checkIsCustomerSuccess,
  getTeamRole,
  canBeEdited,
  checkIsLender,
  checkIsInvestor,
} from '@utils';
import {
  IDrawRequest,
  IMilestoneColumn,
  CommonRowType,
  PermissionNamesEnums,
  ProductionBuildCommonRowType,
  QueryNamesEnums,
} from '@interfaces';
import { useQuery } from 'react-query';

import { useParams } from 'react-router-dom';
import {
  getDrawRequest,
  getDrawRequestMilestoneListColumns,
  getProjectMilestoneListColumns,
} from '@globalService';
import { MilestoneListColumnTypeV2 } from './columnsV2/common';
import { GridColDef } from '@mui/x-data-grid';
import React from 'react';
import HeaderComponent from './HeaderComponent';
export const TableContext = createContext<TableContextInterface>({ getLineItemError: () => '' });

export type ApiUpdatePayloadV2 = {
  milestoneId: string;
  isModelBuilding?: boolean;
};

export const useMilestoneList = ({
  milestones,
  initColumns,
  patchMilestone,
  withColumnIndication,
  contingencyMode,
  withCredit,
  groupByFilter,
  totals,
  source,
}: HookInterface) => {
  const { user } = useContext(AuthContext);
  const { projectId, requestId } = useParams();
  const { permissions } = useContext(PermissionsContext);

  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['status', 'retainage_rate'],
    args: { project_id: projectId },
  });
  const teamRole = getTeamRole(user);

  // MOVE to UsegetData when GraphStructure will be merged
  const drawRequestErrorsQuery = useQuery<IDrawRequest, Error>(
    [
      QueryNamesEnums.GET_DRAW_REQUEST,
      {
        projectId,
        drawRequestId: requestId,
        ...(groupByFilter ? { groupByKeys: groupByFilter } : {}),
        query: '{errors}',
      },
    ],
    getDrawRequest.bind(this, {
      projectId,
      drawRequestId: requestId,
      ...(groupByFilter ? { groupByKeys: groupByFilter } : {}),
      query: '{errors,type,credit_amount}',
    }),
    {
      enabled: Boolean(projectId && requestId),
    },
  );

  const milestoneColumnsQuery = requestId
    ? useQuery<IMilestoneColumn[], Error>(
        [QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES_COLUMNS, { projectId, requestId }],
        getDrawRequestMilestoneListColumns.bind(this, projectId, requestId),
        { enabled: withColumnIndication },
      )
    : useQuery<IMilestoneColumn[], Error>(
        [QueryNamesEnums.GET_PROJECT_MILESTONES_COLUMNS, { projectId }],
        getProjectMilestoneListColumns.bind(this, projectId),
        { enabled: withColumnIndication },
      );

  const permittedColumns = useMemo(
    () =>
      initColumns.filter((item: string) => {
        const column = MilestoneColumns[item];
        if (!column) return false;
        if (!column.permissionKey) return true;
        return isAllowed(column.permissionKey, permissions);
      }),
    [permissions, initColumns],
  );

  const columnsConfig = useMemo<Array<MilestoneListColumnTypeV2>>(
    () => permittedColumns?.map((item: string) => MilestoneColumns[item]),
    [permittedColumns],
  );

  const apiUpdateV2 = useCallback(async ({ id, json }) => {
    await patchMilestone({
      milestone: id,
      json,
    });
  }, []);

  const sortedMilestones: CommonRowType[] | ProductionBuildCommonRowType[] = useMemo(
    () =>
      getSortedByIndexMilestones(
        milestones?.map((ml) => ({
          contingencyMode,
          nonBorrower:
            checkIsLender(teamRole) ||
            checkIsInvestor(teamRole) ||
            checkIsCustomerSuccess(teamRole),
          isEditableV2:
            ml.activeToEdit &&
            canBeEdited({
              row: ml,
              contingencyMode,
              adjustments_field: 'approved_adjustments',
              error: '',
            }),
          ...ml,
        })),
      ),
    [milestones, contingencyMode, teamRole],
  );

  const drawRequestErrors = useMemo(() => {
    const transformedObject = {};
    if (!drawRequestErrorsQuery.data?.errors) return transformedObject;

    Object.keys(drawRequestErrorsQuery.data.errors).forEach((key) => {
      transformedObject[key] = {};
      drawRequestErrorsQuery.data.errors[key]?.forEach((item) => {
        transformedObject[key][item.id] = item.error;
      });
    });

    return transformedObject;
  }, [drawRequestErrorsQuery.data]);

  const showCreditButton = useMemo(
    () =>
      isAllowed(PermissionNamesEnums.DRAWREQUESTS_CREDIT_APPROVE, permissions) &&
      withCredit &&
      isDrawRequest(drawRequestErrorsQuery.data) &&
      isCreditInRequest(sortedMilestones),
    [withCredit, drawRequestErrorsQuery, sortedMilestones, permissions],
  );

  const isColumnEditableWithPermission = useCallback(
    (column: MilestoneListColumnTypeV2) => {
      if ('editable' in column) {
        return { editable: column.editable };
      }

      if ('editableByPermissions' in column) {
        if (checkIsCustomerSuccess(teamRole)) return { editable: true };
        return { editable: column.editableByPermissions(permissions) };
      }

      return { editable: true };
    },
    [permissions, teamRole],
  );

  const columns = React.useMemo<GridColDef[]>(
    () =>
      (columnsConfig as unknown as MilestoneListColumnTypeV2[]).map((item) => ({
        ...item,
        ...isColumnEditableWithPermission(item),
        groupable: false,
        aggregable: false,
        renderHeader: HeaderComponent,
      })),
    [columnsConfig, isColumnEditableWithPermission, source],
  );

  const columnsToCSV = React.useMemo(
    () => columnsConfig.map(({ field, headerName }) => ({ name: field, columnText: headerName })),
    [columns],
  );

  const totalCalculation = useMemo(() => {
    return {
      id: 'totals',
      name: 'Totals',
      ...totals,
    };
  }, [totals]);

  return {
    columnsToCSV,
    columns,
    sortedMilestones,
    milestoneColumns: milestoneColumnsQuery.data || [],
    project: project.data,
    showCreditButton,
    apiUpdateV2,
    totalCalculation,
    drawRequestErrors,
  };
};
