import React, { useContext } from 'react';
import { Box, useTheme, useMediaQuery, Stack, Button, Tooltip } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';

import {
  ProjectWarning,
  ProjectNameLabel,
  WatchProject, //move to local components
  MessagePanelButton,
} from '@components';
import * as Controller from './controller';
import { PermissionNamesEnums, ToolTipLineVariantEnum } from '@interfaces';
import {
  ProjectScores,
  LenderChecklist,
  ProjectCompanies,
  RealTimeUsers,
  AddRequestButton,
  OrderServiceButton,
} from '..';
import { checkIsInvestor, checkIsLender, isAllowed, WithPermission } from '@utils';
import { PermissionsContext, SettingsContext } from '@context';
import { WIDGET_TYPE } from '@constants';
import { ProjectSettingsIcon } from '@svgAsComponents';

const getGridTemplateAreas = (isSm: boolean, isMd: boolean) => {
  if (isSm) {
    return `"name"
            "companies"
            "watchers"
            "actions"
            "approval"`;
  }
  if (isMd) {
    return `"name"
            "companies"
            "actions"
            "approval"`;
  }
  return `"name companies"
          "actions actions"`;
};

const ProjectMainInfo = ({ handleRightDrawerOpenerClick }) => {
  const { isCurrentProjectArchived } = useContext(SettingsContext);
  const { projectId } = useParams();
  const navigate = useNavigate();
  const { permissions } = useContext(PermissionsContext);
  const {
    returnedRequestWarning,
    teamRole,
    projectCommentsPreview,

    openWidget,
    closeWidget,
    widgetsOpened,
    hasYellowOrRedScore,
  } = Controller.useProjectMainInfo(projectId);

  const theme = useTheme();

  // Define breakpoints
  const isSm = useMediaQuery(theme.breakpoints.down('md')); // 0 - 900px
  const isMd = useMediaQuery(theme.breakpoints.only('md')); // 900 - 1200px
  const isLg = useMediaQuery(theme.breakpoints.only('lg')); // 1200 - 1536px
  const isXlAndUp = useMediaQuery(theme.breakpoints.up('xl')); // up 1536

  return (
    <>
      {returnedRequestWarning && (
        <ProjectWarning
          innerElement={returnedRequestWarning}
          variant={ToolTipLineVariantEnum.NOTIFICATION}
        />
      )}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: isSm ? '1fr' : '1fr auto',
          gridTemplateAreas: getGridTemplateAreas(isSm, isMd),
          alignItems: 'center',
          gap: '16px 0',
          width: '100%',
          px: 2,
          pt: 1,
        }}
      >
        <Box sx={{ gridArea: 'name' }}>
          <ProjectNameLabel isLink={false} showStatusChip />
        </Box>

        <Box
          sx={{
            gridArea: 'companies',
            display: 'flex',
            alignItems: 'center',
            gap: 5,
          }}
        >
          {!isSm && !isMd && <RealTimeUsers />}
          <ProjectCompanies />
          {isMd && <RealTimeUsers />}
        </Box>

        {isSm && (
          <Box
            sx={{
              gridArea: 'watchers',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <RealTimeUsers />
          </Box>
        )}
        <Box
          sx={{
            gridArea: 'actions',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            gap: 1,
            justifyContent: 'flex-end',
          }}
        >
          {!isSm && !isCurrentProjectArchived && (
            <WithPermission permissionKey={PermissionNamesEnums.SCORES_VIEW}>
              <ProjectScores
                isWidgetOpen={!!widgetsOpened[WIDGET_TYPE.SCORES]}
                onWidgetOpened={() => {
                  openWidget(WIDGET_TYPE.SCORES);
                  openWidget(WIDGET_TYPE.CHECKLIST);
                  closeWidget(WIDGET_TYPE.WATCHER);
                }}
                onClose={() => {
                  closeWidget(WIDGET_TYPE.SCORES);
                  closeWidget(WIDGET_TYPE.CHECKLIST);
                }}
                hasYellowOrRedScore={hasYellowOrRedScore}
                wrapperStyles={isMd ? { flex: 1 } : {}}
                placement={isMd ? 'bottom-start' : 'bottom-end'}
              />
            </WithPermission>
          )}

          {!isSm && !isMd && (
            <LenderChecklist
              onWidgetOpened={() => {
                openWidget(WIDGET_TYPE.CHECKLIST);
                openWidget(WIDGET_TYPE.SCORES);
                closeWidget(WIDGET_TYPE.WATCHER);
              }}
              isWidgetOpen={!!widgetsOpened[WIDGET_TYPE.CHECKLIST]}
              onClose={() => {
                closeWidget(WIDGET_TYPE.CHECKLIST);
                closeWidget(WIDGET_TYPE.SCORES);
              }}
              wideChecklistButton={isXlAndUp}
              wrapperStyles={isLg ? { flex: 1 } : {}}
            />
          )}
          {(checkIsLender(teamRole) ||
            checkIsInvestor(teamRole) ||
            isAllowed(PermissionNamesEnums.CUSTOMER_SUCCESS_ACCESS, permissions)) && (
            <WatchProject
              onWidgetOpened={() => {
                openWidget(WIDGET_TYPE.WATCHER);
                closeWidget(WIDGET_TYPE.CHECKLIST);
                closeWidget(WIDGET_TYPE.SCORES);
              }}
              isWidgetOpen={!!widgetsOpened[WIDGET_TYPE.WATCHER]}
              onClose={() => closeWidget(WIDGET_TYPE.WATCHER)}
            />
          )}
          <AddRequestButton />
          <WithPermission permissionKey={PermissionNamesEnums.COMMENTS_VIEW}>
            <MessagePanelButton
              commentsPreview={projectCommentsPreview}
              handleRightDrawerOpenerClick={handleRightDrawerOpenerClick}
              source="project"
            />
          </WithPermission>

          <OrderServiceButton />

          <Tooltip title="Project settings">
            <Button
              variant="new"
              color="secondary"
              sx={{ minWidth: 40, maxWidth: 40 }}
              data-cy="project__settings__options_menu"
              onClick={() => navigate(`/projects/${projectId}/settings`)}
            >
              <Stack sx={{ minHeight: 24, minWidth: 24 }} alignItems="center">
                <ProjectSettingsIcon size={24} />
              </Stack>
            </Button>
          </Tooltip>
        </Box>

        {isMd && (
          <Box
            sx={{
              gridArea: 'approval',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <LenderChecklist
              onWidgetOpened={() => {
                openWidget(WIDGET_TYPE.CHECKLIST);
                openWidget(WIDGET_TYPE.SCORES);
                closeWidget(WIDGET_TYPE.WATCHER);
              }}
              isWidgetOpen={!!widgetsOpened[WIDGET_TYPE.CHECKLIST]}
              onClose={() => {
                closeWidget(WIDGET_TYPE.CHECKLIST);
                closeWidget(WIDGET_TYPE.SCORES);
              }}
              wrapperStyles={{ flex: 1 }}
              placement={isMd ? 'bottom-end' : 'bottom-start'}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default ProjectMainInfo;
