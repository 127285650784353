import { IDrawRequest } from '@interfaces';
import { ColumnType } from '@utils';
import React from 'react';

const project_city: ColumnType<IDrawRequest> = () => ({
  field: 'project.address.city',
  renderCell: ({ row }: { row: Partial<IDrawRequest> }) => (
    <span data-cy="requests_queue__table__body__project_city">{row.project?.address?.city}</span>
  ),
  graphFields: [],
  graphNested: {
    'project.address': ['city'],
  },
  headerName: 'City',
  minWidth: 160,
  groupable: false,
  sortable: false,
  filterable: false,
  aggregable: false,
});

export default project_city;
