import React, { FC, useMemo } from 'react';
import { CSVButtonTypesEnum, ITransloaditSignature } from '@interfaces';
import { useGenerateCSVButton } from './controlller';
import { useIsMutating } from 'react-query';
import { Button, IconButton } from '@mui/material';
import { ArrowBackIcon } from '@svgAsComponents';

export const GenerateCSVButton: FC<{
  onClose: () => void;
  transloaditSignature: ITransloaditSignature;
  csvData: Record<string, string>;
  type?: CSVButtonTypesEnum;
  shouldGenerateCSV?: boolean;
}> = ({
  onClose,
  transloaditSignature,
  csvData,
  type = CSVButtonTypesEnum.CREATE_BUTTON,
  shouldGenerateCSV = true,
}) => {
  const isMutating = useIsMutating();

  const { onButtonClick, isLoading } = useGenerateCSVButton({
    onClose,
    transloaditSignature,
    csvData,
  });

  const button = useMemo(() => {
    switch (type) {
      case CSVButtonTypesEnum.BACK_ICON:
        return (
          <IconButton
            onClick={shouldGenerateCSV ? onButtonClick : onClose}
            disabled={Boolean(isMutating) || isLoading}
            data-cy="project__budget__create__structure_table__back__icon"
          >
            <ArrowBackIcon />
          </IconButton>
        );
      case CSVButtonTypesEnum.BACK_BUTTON:
        return (
          <Button
            variant="text"
            sx={{ mr: 1 }}
            onClick={shouldGenerateCSV ? onButtonClick : onClose}
            loading={Boolean(isMutating) || isLoading}
            data-cy="project__budget__create__structure_table__back__button"
          >
            Back
          </Button>
        );
      case CSVButtonTypesEnum.CREATE_BUTTON:
        return (
          <Button
            variant="new"
            color="secondary"
            onClick={shouldGenerateCSV ? onButtonClick : onClose}
            sx={{ mr: 1 }}
            loading={Boolean(isMutating) || isLoading}
            data-cy="project__budget__create__structure_table__create__button"
          >
            Create
          </Button>
        );
      default:
        return null;
    }
  }, [type, isMutating, isLoading, csvData]);

  return <>{button}</>;
};
