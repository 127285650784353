import React from 'react';
import { useParams } from 'react-router-dom';
import { useServiceOrder } from './controller';
import { HookState, ServiceOrderStatusEnum } from '@interfaces';
import { LoadingSkeleton, ServiceMessage, ServiceResults } from '@components';
import { useLaunchDarklyFlags } from '@context';
import OrderServiceComponent from './OrderService';
import ServiceResultComponent from './ServiceResult';

const ServiceOrder = () => {
  const { serviceOrderId, projectId } = useParams();
  const { serviceOrder, state } = useServiceOrder(projectId, serviceOrderId);
  const flags = useLaunchDarklyFlags();

  if (!flags?.['ENG_9657_show_inspections_in_services']) {
    return <ServiceResults />;
  }

  switch (state) {
    case HookState.LOADING: {
      return <LoadingSkeleton type="overviewBlock" />;
    }
    case HookState.ERROR: {
      return <ServiceMessage text="service order" />;
    }

    case HookState.SUCCESS: {
      if (serviceOrder?.status === ServiceOrderStatusEnum.CREATED) return <OrderServiceComponent />;
      return <ServiceResultComponent />;
    }
  }
};

export default ServiceOrder;
