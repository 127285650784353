import React from 'react';
import { HookState } from '@interfaces';
import { LoadingSkeleton, ServiceMessage } from '@components';
import * as Controller from './controller';
import { PhotosDetails, PhotosHeader, PhotosList } from './components';

const PhotosTabV2 = () => {
  const controller = Controller.usePhotos();
  const {
    state,
    activePhoto,
    milestonesWithPhotos,
    handlePhotoClick,
    projectId,
    drawRequestId,
    isAddPhotoAvailable,
    tablePagination,
    itemsCount,
    relatedPhotos,
    showEmptyPhotos,
    isProjectTab,
    showPhotoHeader,
  } = controller;

  if (state === HookState.LOADING) return <LoadingSkeleton type="overviewBlock" />;
  if (state === HookState.ERROR) return <ServiceMessage text="photos" />;
  if (state !== HookState.SUCCESS) return null;

  if (activePhoto) return <PhotosDetails controller={controller} />;

  return (
    <>
      {showPhotoHeader && (
        <PhotosHeader
          itemsCount={itemsCount}
          tablePagination={tablePagination}
          isAddPhotoAvailable={isAddPhotoAvailable}
          projectId={projectId}
          drawRequestId={drawRequestId}
        />
      )}
      <PhotosList
        relatedPhotos={relatedPhotos}
        milestonesWithPhotos={milestonesWithPhotos}
        handlePhotoClick={handlePhotoClick}
        showEmptyPhotos={showEmptyPhotos}
        isAddPhotoAvailable={isAddPhotoAvailable}
        projectId={projectId}
        drawRequestId={drawRequestId}
        isProjectTab={isProjectTab}
      />
    </>
  );
};

export default PhotosTabV2;
