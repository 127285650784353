import { Dispatch, useCallback, useMemo, SetStateAction, useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

import { QueryNamesEnums, IInspectionFields, ProjectUpdatePayload } from '@interfaces';
import { updateProjectFields } from '@globalService';
import { useInspectionFields } from '@hooks';
import { useLaunchDarklyFlags, SettingsContext } from '@context';
import { Override } from '@utils';
import { useParams } from 'react-router-dom';

export type ControllerInterface = Override<
  ReturnType<typeof useInspectionSettings>,
  {
    handleSubmitClick: () => Promise<boolean>;
    isSubmitting: boolean;
    isUpdated: boolean;
    exitPath: string;
    isOrderAutomatically: boolean;
    setIsOrderAutomatically: Dispatch<SetStateAction<boolean>>;
    inspectionFields: IInspectionFields;
    isSubmitDisabled: boolean;
    isCurrentProjectArchived: boolean;
    isDataLoading: boolean;
  }
>;

export const useInspectionSettings = () => {
  const { isCurrentProjectArchived } = useContext(SettingsContext);
  const { projectId } = useParams();
  const flags = useLaunchDarklyFlags();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const {
    additionalContactName,
    additionalContactPhone,
    accessCode,
    primaryContactUser,
    primaryContactUserList,
    isBorrowerUsersLoading,
    isProjectInspectionSettingsUpdated,
    inspectionCadence,
    primaryContactPhone,
    isContactListHasUsersWithPhone,
    inspectionIsOrderAutomatically,
    isDataLoading,
  } = useInspectionFields({});

  const projectMutation = useMutation<
    Response,
    Error,
    { projectId: string; json: ProjectUpdatePayload }
  >(updateProjectFields, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT, { project_id: projectId }]);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const handleSubmitClick = useCallback(async () => {
    if (!additionalContactPhone.validate()) return false;

    await projectMutation.mutateAsync({
      projectId,
      json: {
        inspection_additional_contact_name: additionalContactName.value,
        inspection_additional_contact_phone: additionalContactPhone.valueToSave,
        inspection_entry_access_code: accessCode.value,
        inspection_is_order_automatic: inspectionIsOrderAutomatically.value,
        inspection_primary_contact_user_id: primaryContactUser.value?.id || null,
        inspection_cadence: +inspectionCadence.value,
      },
    });
    return true;
  }, [
    additionalContactName.value,
    additionalContactPhone.value,
    accessCode.value,
    primaryContactUser.value?.id,
    projectId,
    inspectionIsOrderAutomatically.value,
    inspectionCadence.value,
    flags,
  ]);

  const isSubmitDisabled = useMemo(
    () => [additionalContactPhone, primaryContactUser].some((field) => !field.validate()),
    [additionalContactPhone?.value, primaryContactUser?.value?.id],
  );

  const exitPath = useMemo(() => `/projects/${projectId}/overview`, [projectId]);

  return {
    inspectionFields: {
      additionalContactName,
      additionalContactPhone,
      accessCode,
      primaryContactUser,
      primaryContactUserList,
      isBorrowerUsersLoading,
      inspectionCadence,
      primaryContactPhone,
      isContactListHasUsersWithPhone,
      inspectionIsOrderAutomatically,
    },
    handleSubmitClick,
    isSubmitting: projectMutation.isLoading,
    isUpdated: isProjectInspectionSettingsUpdated,
    exitPath,
    isSubmitDisabled,
    isCurrentProjectArchived,
    isLoading: isDataLoading,
  };
};
