import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import dayjs from 'dayjs';
import { SelectChangeEvent } from '@mui/material';
import find from 'lodash/find';
import { useParams } from 'react-router-dom';
import {
  EnumTypeForList,
  FundingSourceInternalIdEnums,
  HookState,
  IProjectFundingSource,
  PaymentConfiguration,
  ProjectUpdatePayload,
  QueryNamesEnums,
} from '@interfaces';
import {
  calculateFraction,
  getCustomFundingSources,
  getFundingSource,
  getReasonText,
  getSourceIndex,
  isCreatedProject,
  Override,
  validationShareRule,
} from '@utils';
import {
  DateFieldModel,
  DropdownFieldModel,
  StringFieldModel,
  useDropdownFieldModel,
  useStringFieldModel,
} from '@models';
import { getProjectFundingSources, updateProjectFields } from '@globalService';
import {
  useCustomFundingSources,
  useEditPaymentConfigurationType,
  useProjectAndLoanDates,
} from '@hooks';
import { SettingsContext, useGraphQuery, useLaunchDarklyFlags } from '@context';

export type ControllerInterface = Override<
  ReturnType<typeof useLoanDetails>,
  {
    external_id: StringFieldModel;
    loc_commitment: StringFieldModel;
    prefunding_cost: StringFieldModel;
    post_funding_construction_budget: StringFieldModel;
    construction_holdback_fraction: StringFieldModel;
    borrower_equity_fraction: StringFieldModel;
    completionDateReasonsList: string[];
    legal_entity: StringFieldModel;
    handleSubmitClick: () => Promise<boolean>;
    isSubmitting: boolean;
    isEditable: boolean;
    isUpdated: boolean;
    exitPath: string;
    isDisabled: boolean;
    projectDates: {
      [key: string]: DateFieldModel;
    };
    handleCompletionDateReasonChange: (event: SelectChangeEvent<string[]>) => void;
    isCompletionDateReasonsValid: boolean;
    completionDateReasons: string[];
    loan_status: DropdownFieldModel;
    loanStatusesList: EnumTypeForList[];
    loan_type: DropdownFieldModel;
    loanTypesList: EnumTypeForList[];
    interest_method: DropdownFieldModel;
    interestMethodsList: EnumTypeForList[];
    configurationType: PaymentConfiguration;
    setConfigurationType: Dispatch<SetStateAction<PaymentConfiguration>>;
    configurationTypeOptions: {
      value: PaymentConfiguration;
      label: string;
    }[];
    projectFundingSources: IProjectFundingSource[];
    state: HookState;
  }
>;
export const useLoanDetails = ({
  isAllProjectDetailsDisabled,
}: {
  isAllProjectDetailsDisabled: boolean;
}) => {
  const { projectId } = useParams();
  const flags = useLaunchDarklyFlags();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { settings } = useContext(SettingsContext);
  const { loan_servicing_statuses, loan_types, interest_methods } = settings?.display || {};

  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: [
      'id',
      'payment_configuration_type',
      'legal_entity',
      'status',
      'estimated_completion_date',
      'estimated_completion_date_change_reason',
      'estimated_start_date',
      'original_completion_date',
    ],
    nested: {
      loan: [
        'id',
        'external_id',
        'servicing_status',
        'type',
        'interest_method',
        'type',
        'funding_date',
        'loc_commitment',
        'construction_holdback',
        'total_construction_budget',
        'maturity_date',
        'extended_maturity_date',
        'post_funding_construction_budget',
        'borrower_equity',
        'contingency',
        'funding_source_active_amount',
        'funding_source_inactive_amount',
        'budget_post_funding_construction_budget',
        'budget_prefunding_cost',
        'prefunding_cost',
      ],
    },
    args: { project_id: projectId },
  });

  const {
    configurationType,
    setConfigurationType,
    configurationTypeOptions,
    isConfigurationTypeChanged,
  } = useEditPaymentConfigurationType({
    initialConfigurationType: project?.data?.payment_configuration_type,
  });

  const projectFundingSourcesQuery = useQuery<{ results: IProjectFundingSource[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_FUNDING_SOURCES, { projectId }],
    getProjectFundingSources.bind(this, projectId),
    { enabled: Boolean(projectId) },
  );

  const projectFundingSources = useMemo(
    () => projectFundingSourcesQuery.data?.results || [],
    [projectFundingSourcesQuery.data?.results],
  );

  const external_id = useStringFieldModel({
    initValue: project?.data?.loan?.external_id,
    withProgressCheck: true,
  });

  const loc_commitment = useStringFieldModel({
    initValue: project?.data?.loan?.loc_commitment?.toString() || '0',
    withProgressCheck: true,
  });

  const loan_status = useDropdownFieldModel({
    initValue:
      find(loan_servicing_statuses, { name: project?.data?.loan?.servicing_status }) || null,
  });

  const loan_type = useDropdownFieldModel({
    initValue: find(loan_types, { name: project?.data?.loan?.type }) || null,
  });

  const interest_method = useDropdownFieldModel({
    initValue: find(interest_methods, { name: project?.data?.loan?.interest_method }) || null,
  });

  const projectPrefundingCost = useMemo(
    () =>
      getFundingSource({
        fundingSources: projectFundingSources,
        internalId: FundingSourceInternalIdEnums.PREFUNDING_COST,
      }),
    [projectFundingSources],
  );

  const prefunding_cost = useStringFieldModel({
    initValue:
      (flags?.['ENG_8718_retrieve_funding_sources']
        ? projectPrefundingCost?.total.toString()
        : project?.data?.loan?.prefunding_cost?.toString()) || '0',
    withProgressCheck: true,
  });

  const projectConstructionHoldback = useMemo(
    () =>
      getFundingSource({
        fundingSources: projectFundingSources,
        internalId: FundingSourceInternalIdEnums.CONSTRUCTION_HOLDBACK,
      }),
    [projectFundingSources],
  );

  const construction_holdback = useStringFieldModel({
    initValue:
      (flags?.['ENG_8718_retrieve_funding_sources']
        ? projectConstructionHoldback?.total.toString()
        : project?.data?.loan?.construction_holdback?.toString()) || '0',
    withProgressCheck: true,
  });

  const projectBorrowerEquity = useMemo(
    () =>
      getFundingSource({
        fundingSources: projectFundingSources,
        internalId: FundingSourceInternalIdEnums.BORROWER_EQUITY,
      }),
    [projectFundingSources],
  );

  const borrower_equity = useStringFieldModel({
    initValue:
      (flags?.['ENG_8718_retrieve_funding_sources']
        ? projectBorrowerEquity?.total.toString()
        : project?.data?.loan?.borrower_equity?.toString()) || '0',
    withProgressCheck: true,
  });

  const initCustomFundingSources = useMemo(
    () => getCustomFundingSources(projectFundingSources),
    [projectFundingSources],
  );

  useEffect(() => {
    if (!flags?.['ENG_8718_retrieve_funding_sources']) return;
    handleInitSourcesChange(initCustomFundingSources);
  }, [flags, initCustomFundingSources]);

  const legal_entity = useStringFieldModel({
    initValue: project?.data?.legal_entity || '',
    withProgressCheck: true,
  });

  // dates block
  const {
    projectDates,
    completionDateReasonsList,
    handleCompletionDateReasonChange,
    isCompletionDateReasonsValid,
    completionDateReasons,
    isDatesValid,
    resetValues,
  } = useProjectAndLoanDates({
    project: project?.data,
  });
  const {
    funding_date,
    estimated_start_date,
    original_completion_date,
    estimated_completion_date,
    maturity_date,
    extended_maturity_date,
  } = projectDates;

  const initConstructionHoldbackFraction = useMemo(
    () =>
      project?.data?.loan
        ? calculateFraction(
            project?.data?.loan?.construction_holdback,
            project?.data?.loan?.post_funding_construction_budget,
          ).toString()
        : '100',
    [project?.data?.loan],
  );
  const construction_holdback_fraction = useStringFieldModel({
    initValue: initConstructionHoldbackFraction,
    validationRule: validationShareRule,
    validateOnChange: true,
    initError: 'The value is outside the valid range of 0 to 100%',
    withProgressCheck: true,
  });

  const initBorrowerFraction = useMemo(
    () =>
      project?.data?.loan
        ? calculateFraction(
            project?.data?.loan?.borrower_equity,
            project?.data?.loan?.post_funding_construction_budget,
          ).toString()
        : '0',
    [project?.data?.loan],
  );
  const borrower_equity_fraction = useStringFieldModel({
    initValue: initBorrowerFraction,
    validationRule: validationShareRule,
    validateOnChange: true,
    initError: 'The value is outside the valid range of 0 to 100%',
    withProgressCheck: true,
  });

  const post_funding_construction_budget = useStringFieldModel({
    initValue: project?.data?.loan?.post_funding_construction_budget?.toString() || '0',
    validationRule: (value) => Boolean(+value),
    validateOnChange: true,
    withProgressCheck: true,
  });

  const projectLoanMutation = useMutation<
    Response,
    Error,
    { projectId: string; json: ProjectUpdatePayload }
  >(updateProjectFields, {
    onSuccess: () => {
      resetValues();
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT, { project_id: projectId }]);
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_FUNDS, { projectId }]);
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_FUNDING_SOURCES, { projectId }]);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const {
    customSources,
    handleSaveFundingSource,
    isCustomSourcesChanged,
    handleInitSourcesChange,
  } = useCustomFundingSources({
    initSources: initCustomFundingSources,
    constructionHoldback: construction_holdback,
    constructionHoldbackFraction: construction_holdback_fraction,
    borrowerEquity: borrower_equity,
    borrowerEquityFraction: borrower_equity_fraction,
    postFundingConstructionBudget: post_funding_construction_budget,
    prefundingCost: prefunding_cost,
  });

  const isProviderItemsChanged = useMemo(
    () =>
      [
        post_funding_construction_budget,
        construction_holdback,
        construction_holdback_fraction,
        borrower_equity,
        borrower_equity_fraction,
        prefunding_cost,
      ].some((field) => field.isChanged) ||
      isConfigurationTypeChanged ||
      isCustomSourcesChanged(initCustomFundingSources),
    [
      post_funding_construction_budget.isChanged,
      construction_holdback.isChanged,
      construction_holdback_fraction.isChanged,
      borrower_equity.isChanged,
      borrower_equity_fraction.isChanged,
      prefunding_cost.isChanged,
      isCustomSourcesChanged,
      initCustomFundingSources,
      isConfigurationTypeChanged,
    ],
  );

  const handleSubmitClick = useCallback(async () => {
    const isExternalIdValid = external_id.validate();
    const isMaturityDateValid = maturity_date.validate();
    const isFundingDateValid = funding_date.validate();
    const isEstimatedCompletionDateValid = estimated_completion_date.validate();
    const isOriginalCompletionDateValid = original_completion_date.validate();
    const isEstimatedStartDateValid = estimated_start_date.validate();
    const isPostFundingConstructionBudgetValid = post_funding_construction_budget.validate();
    const isConstructionHoldbackFractionValid = construction_holdback_fraction.validate();
    const isBorrowerEquityFractionValid = borrower_equity_fraction.validate();

    const borrowerEquityFraction = calculateFraction(
      +borrower_equity.value || 0,
      +post_funding_construction_budget.value,
    );
    const constructionHoldbackFraction = (100 - borrowerEquityFraction).toFixed(12);

    const isFieldsValid = [
      isExternalIdValid,
      isMaturityDateValid,
      isFundingDateValid,
      isEstimatedCompletionDateValid,
      isOriginalCompletionDateValid,
      isEstimatedStartDateValid,
      ...(flags?.['ENG_8718_retrieve_funding_sources']
        ? []
        : [
            isPostFundingConstructionBudgetValid,
            isConstructionHoldbackFractionValid,
            isBorrowerEquityFractionValid,
          ]),
    ].every(Boolean);

    if (!isFieldsValid) return false;

    const sourceMap = {
      [FundingSourceInternalIdEnums.PREFUNDING_COST]: +prefunding_cost.value,
      [FundingSourceInternalIdEnums.BORROWER_EQUITY]: +borrower_equity.value,
      [FundingSourceInternalIdEnums.CONSTRUCTION_HOLDBACK]: +construction_holdback.value,
    };

    const sources = [
      ...customSources.map((source) => {
        return {
          total: source.total,
          is_transactable: source.is_transactable,
          is_active: source.is_active,
          name: source.name,
          index: source.index,
          color: source.colorKey,
          available: source.total,
          ...(source.isNew ? {} : { id: source.id }),
        };
      }),
      ...projectFundingSources
        .filter((source) => !!source.internal_identifier)
        .map((source) => ({
          ...source,
          total: sourceMap[source.internal_identifier],
          index: getSourceIndex({
            internal_identifier: source.internal_identifier,
            configurationType,
          }),
        })),
    ];

    await projectLoanMutation.mutateAsync({
      projectId,
      json: {
        ...(!isAllProjectDetailsDisabled
          ? {
              ...(isProviderItemsChanged
                ? {
                    construction_holdback_rate: +constructionHoldbackFraction,
                    borrower_equity_rate: +borrowerEquityFraction,
                    ...(flags?.['ENG_8718_retrieve_funding_sources'] ? { sources } : {}),
                  }
                : {}),
              loan: {
                ...(project?.data?.loan?.id ? { id: project?.data?.loan?.id } : {}),
                ...(!flags?.['ENG_8718_retrieve_funding_sources']
                  ? {
                      funding_source_inactive_amount:
                        project?.data?.loan?.funding_source_inactive_amount,
                      funding_source_active_amount:
                        project?.data?.loan?.funding_source_active_amount,
                    }
                  : {}),
                ...(external_id.isChanged ? { external_id: external_id.value } : {}),
                ...(loan_status.isChanged
                  ? { servicing_status: loan_status.value?.name || null }
                  : {}),
                ...(loan_type.isChanged ? { type: loan_type.value?.name } : {}),
                ...(interest_method.isChanged
                  ? { interest_method: interest_method.value?.name || null }
                  : {}),
                ...(funding_date.isChanged
                  ? { funding_date: dayjs(funding_date.value).format('YYYY-MM-DD') }
                  : {}),
                ...(maturity_date.isChanged
                  ? { maturity_date: dayjs(maturity_date.value).format('YYYY-MM-DD') }
                  : {}),
                ...(extended_maturity_date.isChanged
                  ? {
                      extended_maturity_date: extended_maturity_date.value
                        ? dayjs(extended_maturity_date.value).format('YYYY-MM-DD')
                        : null,
                    }
                  : {}),
                ...(loc_commitment.isChanged ? { loc_commitment: +loc_commitment.value } : {}),
                ...(prefunding_cost.isChanged ? { prefunding_cost: +prefunding_cost.value } : {}),
                ...(construction_holdback.isChanged
                  ? { construction_holdback: +construction_holdback.value }
                  : {}),
                ...(borrower_equity.isChanged ? { borrower_equity: +borrower_equity.value } : {}),
                ...(post_funding_construction_budget.isChanged &&
                !flags?.['ENG_8718_retrieve_funding_sources']
                  ? { post_funding_construction_budget: +post_funding_construction_budget.value }
                  : {}),
              },
            }
          : {}),
        ...(estimated_completion_date.isChanged
          ? {
              estimated_completion_date: dayjs(estimated_completion_date.value).format(
                'YYYY-MM-DD',
              ),
            }
          : {}),
        ...(completionDateReasons?.length && {
          estimated_completion_date_change_reason: getReasonText(completionDateReasons, ''),
        }),
        ...(original_completion_date.isChanged
          ? {
              original_completion_date: dayjs(original_completion_date.value).format('YYYY-MM-DD'),
            }
          : {}),
        ...(estimated_start_date.isChanged
          ? { estimated_start_date: dayjs(estimated_start_date.value).format('YYYY-MM-DD') }
          : {}),
        ...(legal_entity.isChanged ? { legal_entity: legal_entity.value } : {}),
        ...(configurationType !== project?.data?.payment_configuration_type
          ? { payment_configuration_type: configurationType }
          : {}),
      },
    });

    return true;
  }, [
    external_id.value,
    loan_status.value,
    loan_type.value,
    project,
    funding_date.value,
    estimated_start_date,
    maturity_date.value,
    extended_maturity_date.value,
    original_completion_date.value,
    estimated_completion_date.value,
    loc_commitment.value,
    construction_holdback.value,
    borrower_equity.value,
    prefunding_cost.value,
    post_funding_construction_budget.value,
    configurationType,
    project?.data?.payment_configuration_type,
    completionDateReasons,
    projectFundingSources,
    flags?.['ENG_8718_retrieve_funding_sources'],
    customSources,
  ]);

  const isEditable = useMemo(
    () => isCreatedProject(project?.data?.status),
    [project?.data?.status],
  );

  const isUpdated = useMemo(
    () =>
      [
        external_id,
        loan_status,
        loan_type,
        interest_method,
        estimated_start_date,
        maturity_date,
        extended_maturity_date,
        funding_date,
        loc_commitment,
        prefunding_cost,
        original_completion_date,
        estimated_completion_date,
        legal_entity,
      ].some((field) => field.isChanged) ||
      isConfigurationTypeChanged ||
      isProviderItemsChanged ||
      completionDateReasons.length > 0,
    [
      external_id.isChanged,
      loan_status.isChanged,
      loan_type.isChanged,
      interest_method.isChanged,
      maturity_date.isChanged,
      extended_maturity_date.isChanged,
      funding_date.isChanged,
      loc_commitment.isChanged,
      prefunding_cost.isChanged,
      estimated_completion_date.isChanged,
      estimated_start_date.isChanged,
      original_completion_date.isChanged,
      legal_entity.isChanged,
      isConfigurationTypeChanged,
      completionDateReasons,
      isProviderItemsChanged,
    ],
  );

  const exitPath = useMemo(() => `/projects/${projectId}/overview`, [projectId]);

  const loanStatusesList = useMemo(() => loan_servicing_statuses, [loan_servicing_statuses]);
  const loanTypesList = useMemo(() => loan_types, [loan_types]);
  const interestMethodsList = useMemo(() => interest_methods, [interest_methods]);

  return {
    external_id,
    loc_commitment,
    prefunding_cost,
    post_funding_construction_budget,
    construction_holdback,
    construction_holdback_fraction,
    borrower_equity,
    borrower_equity_fraction,
    legal_entity,
    handleSubmitClick,
    isSubmitting: projectLoanMutation.isLoading,
    isEditable,
    isUpdated,
    exitPath,
    isDisabled: !isDatesValid,
    completionDateReasonsList,
    projectDates,
    handleCompletionDateReasonChange,
    isCompletionDateReasonsValid,
    completionDateReasons,
    loan_status,
    loan_type,
    loanStatusesList,
    loanTypesList,
    interest_method,
    interestMethodsList,
    configurationType,
    setConfigurationType,
    configurationTypeOptions,
    project: project?.data,
    projectFundingSources,
    isLoading: project?.isPostLoading,
    customSources,
    handleSaveFundingSource,
  };
};
