import React from 'react';
import { useProjectsStats } from './controller';
import { PieChartItem, StyledBox } from '../index';
import { Grid2, Theme, Typography, useMediaQuery } from '@mui/material';
import { IPieChartData, PermissionNamesEnums } from '@interfaces';
import { WithPermission } from '@utils';
import { LegendPerItemProps } from '@mui/x-charts/ChartsLegend/LegendPerItem';

const ProjectsStats = ({
  filterStringQuery,
  filtersReady,
}: {
  filterStringQuery: string;
  filtersReady: boolean;
}) => {
  const { statuses, scores, states, borrowers, largeSize, borrowerLabels } = useProjectsStats({
    filterStringQuery,
    filtersReady,
  });

  return (
    <Grid2 container spacing={1} sx={{ width: '100%', flexWrap: 'wrap', mb: 1 }}>
      <PieChartWrapper data={statuses} label="Project status" largeSize={largeSize} />
      <WithPermission permissionKey={PermissionNamesEnums.SCORES_VIEW}>
        <PieChartWrapper data={scores} label="TrustPoint® score" largeSize={largeSize} />
        <PieChartWrapper
          data={borrowers}
          label="Borrower"
          largeSize={largeSize}
          customLabels={borrowerLabels}
        />
      </WithPermission>

      <PieChartWrapper data={states} label="State" largeSize={largeSize} />
    </Grid2>
  );
};

const PieChartWrapper = ({
  data,
  label,
  largeSize,
  customLabels,
}: {
  data: IPieChartData[];
  label: string;
  largeSize: 6 | 3;
  customLabels?: LegendPerItemProps['itemsToDisplay'];
}) => {
  const xlMediaQuery = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'));

  return (
    <Grid2 size={{ xs: 12, sm: 6, lg: largeSize }} sx={{ width: '100%' }}>
      <StyledBox sx={{ flex: 1, width: '100%', minWidth: '250px' }}>
        {Boolean(data?.length) && (
          <>
            <Typography variant="h4" sx={{ mb: 1 }}>
              {label}
            </Typography>
            <PieChartItem
              data={data}
              legend="label"
              padding={{ ...(xlMediaQuery && { right: 100, bottom: 0 }) }}
              height={xlMediaQuery ? 65 : 110}
              legendPosition={{ vertical: 'top', horizontal: 'left' }}
              margin={{ right: xlMediaQuery ? 30 : 55, top: 0 }}
              customLabels={customLabels}
              showTooltip
            />
          </>
        )}
      </StyledBox>
    </Grid2>
  );
};

export default ProjectsStats;
