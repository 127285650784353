import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';

import { HookState, TableKeyEnum, MessagePanelTabsEnum } from '@interfaces';
import {
  ServiceMessage,
  StyledBox,
  Filters,
  RightDrawer,
  TableItemRightDrawer,
  TablePaginationNew,
  DataTableV3,
  DrawRequestsListFilters,
} from '@components';
import * as Controller from './controller';
import * as ControllerOld from './ServicesQueueOld/controller';
import { colors } from '@theme';
import { ControllerInterface } from './interface';
import { useLaunchDarklyFlags } from '@context';
import { ServicesQueue as ServicesQueueOld } from './ServicesQueueOld';

export const ServicesQueue = () => {
  const flags = useLaunchDarklyFlags();
  const newController = Controller.useServicesQueue();
  const oldController = ControllerOld.useServicesQueue();
  const controller = flags?.['ENG_9657_show_inspections_in_services']
    ? newController
    : oldController;

  if (controller.state === HookState.ERROR) {
    return <ServiceMessage text="services" />;
  }

  return flags?.['ENG_9657_show_inspections_in_services'] ? (
    <Table controller={newController} />
  ) : (
    <ServicesQueueOld />
  );
};

export default ServicesQueue;

const Table: FC<{
  controller: ControllerInterface;
}> = ({ controller }) => {
  const {
    rows,
    columns,
    setFilterStringQuery,
    paginationProps,
    rightDrawerParams,
    rightMenu,
    handleRowClick,
    onFiltersReady,
  } = controller;

  return (
    <Stack sx={{ flex: 1, backgroundColor: colors.background.gray, p: 2 }}>
      <StyledBox sx={{ display: 'flex', flexDirection: 'row', p: 2, mb: 1 }}>
        <Typography variant="h2" sx={{ mr: 2 }}>
          Services {paginationProps.itemsCount ? `(${paginationProps.itemsCount})` : ''}
        </Typography>
      </StyledBox>

      <StyledBox sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, p: 0 }}>
        <DataTableV3
          withProgressiveColumns
          withoutAdaptiveScroll
          controllerLoading={controller.state === HookState.LOADING}
          tableKey={TableKeyEnum.SERVICES_LIST}
          onRowClick={handleRowClick}
          withBuiltInSearch={false}
          headerRight={[]}
          footerRight={[
            {
              Component: (
                <Stack direction="row" justifyContent="flex-end" sx={{ mt: 2, pr: 2 }}>
                  <TablePaginationNew
                    page={paginationProps.page}
                    rowsPerPage={paginationProps.rowsPerPage}
                    rowsPerPageOptions={paginationProps.rowsPerPageOptions}
                    itemsCount={paginationProps.itemsCount}
                    onPageChange={paginationProps.onPageChange}
                    onRowsPerPageChange={paginationProps.onRowsPerPageChange}
                    source="all_services__list"
                  />
                </Stack>
              ),
            },
          ]}
          headerLeft={[
            {
              Component: (
                <Filters
                  skipToggle
                  source="all_services__list"
                  setFilterStringQuery={setFilterStringQuery}
                  filters={[
                    'textSearchFilter',
                    'servicesTypeFilter',
                    'servicesListStatusFilter',
                    'serviceProviderV2',
                    'customerFilter',
                  ]}
                  padding={0}
                  width="unset"
                  tableKey={TableKeyEnum.SERVICES_LIST}
                  onPageChange={paginationProps.onPageChange}
                  onReady={onFiltersReady}
                  LeftComponent={DrawRequestsListFilters}
                />
              ),
            },
          ]}
          rows={rows}
          columns={columns}
        />
      </StyledBox>
      <RightDrawer {...rightMenu}>
        <TableItemRightDrawer
          {...rightDrawerParams}
          requestDocuments={false}
          messagePanelTab={MessagePanelTabsEnum.SERVICES}
          showMessagePanelTabs={false}
          source="all_services__list__right_drawer"
        />
      </RightDrawer>
    </Stack>
  );
};
