import React, { FC, useContext } from 'react';
import { PermissionNamesEnums } from '@interfaces';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { colors } from '@theme';
import {
  currencyFormatter,
  isChangeRequest,
  isRequestInReview,
  percentFormatter,
  WithPermission,
} from '@utils';
import {
  DrawRequestStatusChip,
  InspectionLabelAndValue,
  LabelAndValue,
  ProgressWithArrow,
  StyledBoxReport,
} from '@components';
import { useDayJsFormatter } from '@hooks';
import { ControllerInterface } from '../controller';
import ProjectDetails from '../../ProjectDetails';
import Sources from '../../Sources';
import Uses from '../../Uses';
import { Page } from '../common';
import { SettingsContext, useLaunchDarklyFlags } from '@context';
import NodeCard from '../../NodeCard';
import find from 'lodash/find';

export const ProjectAndDrawInfoV2: FC<{ controller: ControllerInterface }> = ({ controller }) => {
  const { dateFormatter } = useDayJsFormatter();
  const { drawRequestData, lastInspection } = controller;
  const isInReview = isRequestInReview(drawRequestData?.status);
  const flags = useLaunchDarklyFlags();
  const {
    project: { loan, retainage_rate },
  } = controller;
  const { settings } = useContext(SettingsContext);
  const { loan_servicing_statuses, loan_types } = settings?.display || {};

  const servicingStatus = find(loan_servicing_statuses, {
    name: loan?.servicing_status,
  })?.name_display;

  const loanType = find(loan_types, {
    name: loan?.type,
  })?.name_display;

  return (
    <>
      <Page>
        <Box sx={{ breakInside: 'avoid' }} pr="15px" pl="15px">
          <Stack alignItems="center" direction="column">
            <Stack direction="column" spacing="5px" width="100%">
              <Stack direction="row" spacing="5px" width="100%">
                <StyledBoxReport
                  sx={{ flex: 1, border: `1px solid ${colors.background.gray}`, padding: 0 }}
                >
                  <ProjectDetails onlyInfo />
                </StyledBoxReport>
                <StyledBoxReport
                  sx={{ flex: 1, border: `1px solid ${colors.background.gray}`, padding: 0 }}
                >
                  <NodeCard title="Loan details">
                    <Stack spacing={0.5} sx={{ pt: 1 }}>
                      <LabelAndValue label="Loan ID" text={loan?.external_id || '-'} />
                      <LabelAndValue
                        label="Funding date"
                        text={dateFormatter({ date: loan?.funding_date })}
                        textDataTestName="project__loan_details__funding_date"
                      />
                      <LabelAndValue
                        label="Servicing status"
                        text={servicingStatus}
                        textDataTestName="project__loan_details__servicing_status"
                      />
                      {loan?.type && (
                        <LabelAndValue
                          label="Loan type"
                          text={loanType}
                          textDataTestName="project__loan_details__type"
                        />
                      )}
                      {loan?.interest_method && (
                        <LabelAndValue
                          label="Interest method"
                          text={loan?.interest_method_display}
                          textDataTestName="project__loan_details__interest_method"
                        />
                      )}
                      <Box sx={{ py: 0.5 }}>
                        <Divider />
                      </Box>
                      <LabelAndValue
                        label="Total loan commitment"
                        text={currencyFormatter(loan?.loc_commitment, '-')}
                        textDataTestName="project__loan_details__total_loan_commitment"
                      />
                      <LabelAndValue
                        label="Construction holdback"
                        text={currencyFormatter(loan?.construction_holdback, '-')}
                        textDataTestName="project__loan_details__construction_holdback"
                      />
                      <LabelAndValue
                        label="Total construction budget"
                        text={currencyFormatter(loan?.total_construction_budget, '-')}
                        textDataTestName="project__loan_details__total_construction_budget"
                      />
                      <LabelAndValue
                        label="Schedule of values"
                        text={currencyFormatter(loan?.post_funding_construction_budget, '-')}
                        textDataTestName="project__loan_details__construction_budget_post_funding"
                      />
                      <LabelAndValue
                        label="Borrower equity"
                        text={currencyFormatter(loan?.borrower_equity, '-')}
                        textDataTestName="project__loan_details__borrower_equity"
                      />
                      <LabelAndValue
                        label="Contingency"
                        text={currencyFormatter(loan?.contingency, '-')}
                        textDataTestName="project__loan_details__contingency"
                      />
                      <LabelAndValue
                        label="Original maturity date"
                        text={dateFormatter({ date: loan?.maturity_date })}
                        textDataTestName="project__loan_details__original_maturity_date"
                      />
                      <LabelAndValue
                        label="Extended maturity date"
                        text={dateFormatter({
                          date: loan?.extended_maturity_date || loan?.maturity_date,
                        })}
                        textDataTestName="project__loan_details__extended_maturity_date"
                      />
                      {Boolean(retainage_rate) && (
                        <LabelAndValue
                          label="Retainage rate"
                          text={percentFormatter({
                            value: retainage_rate,
                          })}
                          textDataTestName="project__loan_details__retainage_rate"
                        />
                      )}
                    </Stack>
                  </NodeCard>
                </StyledBoxReport>
              </Stack>
              <Stack direction="row" spacing="5px" width="100%">
                <StyledBoxReport
                  sx={{
                    maxWidth: '50%',
                    flex: 1,
                    border: `1px solid ${colors.background.gray}`,
                    padding: 0,
                  }}
                >
                  <Sources onLoad={() => controller.setOtherModuleLoaded((data) => data + 1)} />
                </StyledBoxReport>
                <StyledBoxReport
                  sx={{
                    maxWidth: '50%',
                    flex: 1,
                    border: `1px solid ${colors.background.gray}`,
                    padding: 0,
                  }}
                >
                  <Uses onLoad={() => controller.setOtherModuleLoaded((data) => data + 1)} />
                </StyledBoxReport>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Page>
      <Page>
        <Box sx={{ breakInside: 'avoid' }} pr="15px" pl="15px">
          <Stack alignItems="center" direction="column">
            <Stack direction="row" width="100%">
              <Typography variant="printH2" data-cy="report_request_section_title">{`${
                isChangeRequest(drawRequestData) ? 'Change' : 'Draw'
              } # ${drawRequestData.number}`}</Typography>
            </Stack>
            <Stack direction="row" spacing="5px" width="100%">
              <Stack flex={1} spacing="5px" width="100%">
                <StyledBoxReport
                  sx={{
                    flex: 1,
                    border: `1px solid ${colors.background.gray}`,
                    paddingX: '10px',
                    paddingY: '4px',
                  }}
                >
                  <Typography variant="printH3" data-cy="report_request_section_title">{`${
                    isChangeRequest(drawRequestData) ? 'Change' : 'Draw'
                  } details`}</Typography>
                  <Stack flex={1}>
                    <LabelAndValue
                      label="Submitted date"
                      text={dateFormatter({ date: drawRequestData.submitted_at }) || '-'}
                    />
                    <WithPermission permissionKey={PermissionNamesEnums.INSPECTIONS_VIEW}>
                      <InspectionLabelAndValue
                        label="Inspection date"
                        inspection={lastInspection}
                      />
                    </WithPermission>
                    <LabelAndValue
                      label="Approved date"
                      text={dateFormatter({ date: drawRequestData.approved_at }) || '-'}
                    />
                    <LabelAndValue
                      label="Disbursement date"
                      text={dateFormatter({ date: drawRequestData.disbursed_at }) || '-'}
                    />
                    <Divider />
                    <LabelAndValue label="Status">
                      <DrawRequestStatusChip drawRequest={drawRequestData} />
                    </LabelAndValue>
                    <LabelAndValue
                      label="Original scheduled value"
                      text={currencyFormatter(drawRequestData.totals.all.original_estimate)}
                      noWrap
                    />
                    <LabelAndValue
                      label="Revised scheduled value"
                      text={currencyFormatter(drawRequestData.totals.all.revised_estimate)}
                      noWrap
                    />
                  </Stack>
                </StyledBoxReport>
                <StyledBoxReport
                  sx={{
                    flex: 1,
                    border: `1px solid ${colors.background.gray}`,
                    paddingX: '10px',
                    paddingY: '4px',
                  }}
                >
                  <Stack flex={1} sx={{ '&:empty': { flex: 0, margin: 0 } }}>
                    <WithPermission permissionKey={PermissionNamesEnums.INSPECTIONS_VIEW}>
                      <LabelAndValue label="" text="Pre ➝ Post draw" color={colors.text.medium} />
                      <LabelAndValue label="Lender allowance">
                        <ProgressWithArrow
                          prevValue={percentFormatter({
                            value: isInReview
                              ? drawRequestData.totals?.all?.previous_lender_allowance_rate
                              : drawRequestData.totals?.all?.lender_allowance_rate,
                            roundTo: 0,
                          })}
                          nextValue={percentFormatter({
                            value: drawRequestData.totals?.all?.lender_allowance_rate,
                            roundTo: 0,
                          })}
                          showProgress={isInReview}
                          getTextStyleForActive={isInReview}
                        />
                      </LabelAndValue>
                      <LabelAndValue label="Inspection allowance">
                        <ProgressWithArrow
                          prevValue={percentFormatter({
                            value: drawRequestData.inspector_allowance_rate_before,
                            roundTo: 0,
                          })}
                          nextValue={percentFormatter({
                            value: drawRequestData.totals?.all?.inspector_allowance_rate,
                            roundTo: 0,
                          })}
                        />
                      </LabelAndValue>
                      <LabelAndValue label="Variance to lender allowance" noWrap>
                        <ProgressWithArrow
                          prevValue={percentFormatter({
                            value: drawRequestData.gap_before,
                            roundTo: 0,
                          })}
                          nextValue={percentFormatter({
                            value: drawRequestData.totals?.all?.variance_to_lender_allowance_rate,
                            roundTo: 0,
                          })}
                          getTextStyleForActive={isInReview}
                        />
                      </LabelAndValue>
                    </WithPermission>
                  </Stack>
                </StyledBoxReport>
              </Stack>
              <Stack flex={1} spacing="5px" width="100%">
                <StyledBoxReport
                  sx={{
                    flex: 1,
                    border: `1px solid ${colors.background.gray}`,
                    paddingX: '10px',
                    paddingY: '4px',
                  }}
                >
                  <Typography variant="printH3" data-cy="report_request_section_title">
                    Payment Info
                  </Typography>
                  <Stack flex={1}>
                    <LabelAndValue
                      label="Total draw amount"
                      text={currencyFormatter(drawRequestData.total, '-')}
                      noWrap
                    />
                    <WithPermission permissionKey={PermissionNamesEnums.INSPECTIONS_VIEW}>
                      <LabelAndValue
                        label="Credit amount"
                        text={currencyFormatter(
                          drawRequestData.totals.all.approved_credit_amount,
                          '-',
                        )}
                        noWrap
                      />
                    </WithPermission>
                    <LabelAndValue
                      label="Retainage heldback"
                      text={currencyFormatter(
                        -1 * drawRequestData.totals.all.retainage_approved_amount_holdback,
                        '-',
                      )}
                      noWrap
                    />
                    <LabelAndValue
                      label="Retainage release"
                      text={currencyFormatter(
                        drawRequestData?.totals?.all?.retainage_release_approved,
                        '-',
                      )}
                      noWrap
                    />
                    <LabelAndValue
                      label="Borrower portion"
                      text={
                        flags?.['ENG_9698_funding_sources_usage_in_DR_metrics']
                          ? currencyFormatter(
                              -1 * drawRequestData.total_non_transactable_amount,
                              '-',
                            )
                          : currencyFormatter(-1 * drawRequestData.borrower_equity_with_fees, '-')
                      }
                      noWrap
                    />
                    {drawRequestData?.fees?.length ? (
                      <>
                        <Divider />
                        <Typography variant="h4">Draw fees</Typography>
                        {drawRequestData.fees?.map((fee) => (
                          <LabelAndValue
                            key={fee.id}
                            label={fee.name}
                            text={currencyFormatter(-1 * fee.amount)}
                          />
                        ))}
                        <LabelAndValue
                          colorLabel={colors.text.dark}
                          label="Total draw fees"
                          text={currencyFormatter(-1 * drawRequestData.fees_amount || '-')}
                        />
                      </>
                    ) : null}
                    <Divider />
                    <LabelAndValue
                      label="Net funds for disbursement"
                      text={
                        flags?.['ENG_9698_funding_sources_usage_in_DR_metrics']
                          ? currencyFormatter(drawRequestData?.total_transactable_amount, '-')
                          : currencyFormatter(drawRequestData?.construction_holdback_with_fees, '-')
                      }
                      noWrap
                    />
                  </Stack>
                </StyledBoxReport>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Page>
    </>
  );
};
