import { Dispatch, SetStateAction, useCallback, useContext, useMemo, useState } from 'react';
import { useMutation, useQueries, useQueryClient } from 'react-query';
import { useConfirmationModal, useSafeSnackbar } from '@hooks';
import {
  ActivableNoBudgetLoanTypeEnums,
  ChecklistItemLocal,
  ICustomLoanError,
  ILoanError,
  IProject,
  ProjectStatusEnum,
  QueryNamesEnums,
  ProjectUpdatePayload,
} from '@interfaces';
import {
  getProjectChecklist,
  getProjectMilestonesList,
  getProjectTeams,
  updateProjectFields,
} from '@globalService';
import { AuthContext } from '@context';
import {
  checkIsAllNamesFillInProject,
  checkIsLender,
  getBorrower,
  getChecklistIdByRole,
  getCheckListItemsByRole,
  getTeamRole,
  getTooltipText,
  parseLoanErrors,
} from '@utils';
import { excludeCommentsQueryFields } from '@constants';

const actionTypes = {
  SUBMIT: {
    confirmText:
      'You are about to submit your project. Once submitted, your budget will be under review by the lender and cannot be modified.',
    successText: 'Project was submitted.',
  },
  LENDER_SUBMIT: {
    confirmText:
      'You are about to submit your project. Once the loan is closed and finalized, return to the checklist to activate the project. This will enable the borrower to submit requests.',
    successText: 'Project was submitted',
  },
  RETURN: {
    confirmText:
      'You are about to return your project. The borrower will be able to make changes to the budget and resubmit the project.',
    successText: 'Project was returned.',
  },
  ACTIVATE: {
    confirmText:
      'Updating the project status to Active will enable features such as request submittal and approval, document uploads.',
    successText: 'Project was activated.',
  },
};

interface ControllerInterface {
  submitProject: (locked: boolean) => void;
  loading: boolean;
  checklistModalShow: boolean;
  setChecklistModalShow: (value: boolean) => void;
  checklistId: string;
  policyItems: ChecklistItemLocal[];
  confirmModal: ReturnType<typeof useConfirmationModal>;
  isChecklistCompleted: boolean;
  handleActivateProject: () => void;
  handleApproveClose: () => void;
  confirmText: string;
  confirmCallback: ({ value }: { value: Partial<IProject> }) => void;
  buttonsDisabled: boolean;
  errors: ILoanError[];
  setErrors: Dispatch<SetStateAction<ILoanError[]>>;
  getButtonTooltipText: (type: string) => string;
  isLender: boolean;
}

export const useSubmitProjectButtons = ({
  project,
}: {
  project: Pick<IProject, 'id' | 'is_underwriting_enabled' | 'is_budget_locked' | 'loan'>;
}): ControllerInterface => {
  const { enqueueSnackbar } = useSafeSnackbar();
  const queryClient = useQueryClient();
  const [checklistModalShow, setChecklistModalShow] = useState(false);
  const confirmModal = useConfirmationModal();
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const [actionType, setActionType] = useState<{ confirmText: string; successText: string }>();
  const [errors, setErrors] = useState(null);
  const projectId = project.id;
  const isLender = useMemo(() => checkIsLender(teamRole), [teamRole]);

  const query = excludeCommentsQueryFields;
  // request project milestones list to understand if budget was added or not
  const requestedDataQueries = useQueries([
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_MILESTONES, { projectId, query }],
      queryFn: getProjectMilestonesList.bind(this, { projectId, query }),
      enabled: Boolean(projectId),
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_TEAMS, { projectId, companyId: null }],
      queryFn: getProjectTeams.bind(this, { projectId }),
      enabled: Boolean(projectId),
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_CHECKLIST, { projectId }],
      queryFn: getProjectChecklist.bind(this, projectId),
      enabled: Boolean(projectId),
    },
  ]);

  const projectMilestonesQueryData = requestedDataQueries[0].data;
  const teams = requestedDataQueries[1].data?.results;
  const projectPolicy = requestedDataQueries[2].data;

  const borrower = useMemo(() => getBorrower(teams), [teams]);

  const isChecklistCompleted = useMemo(
    () => projectPolicy?.find((x) => x.assignee_role === teamRole)?.is_completed,
    [projectPolicy, teamRole],
  );

  const submitProjectMutation = useMutation<
    Response,
    Error,
    { projectId: string; json: ProjectUpdatePayload }
  >(updateProjectFields, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT, { project_id: projectId }]);
      enqueueSnackbar(actionType.successText, { variant: 'success' });
    },
    onError: (error) => {
      const message = error?.message as string | ICustomLoanError;
      if (typeof message === 'object') {
        const loanErrors = parseLoanErrors(message);
        if (loanErrors.length > 0) setErrors(loanErrors);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
  });

  const confirmCallback = ({ value }) => {
    submitProjectMutation.mutateAsync({
      projectId,
      json: value,
    });
  };

  const submitProject = (locked: boolean) => {
    if (policyItems?.length && !isChecklistCompleted && !isLender && locked) {
      setChecklistModalShow(true);
    } else {
      confirmModal.askConfirm({ value: { is_budget_locked: locked } });
      setActionType(
        locked ? (isLender ? actionTypes.LENDER_SUBMIT : actionTypes.SUBMIT) : actionTypes.RETURN,
      );
    }
  };

  const policyItems = useMemo(() => {
    if (!projectPolicy) return [];
    return getCheckListItemsByRole({
      policies: projectPolicy,
      teamRole,
      teamId: user?.active_team?.id,
    }).filter((item) => !item.checked);
  }, [projectPolicy, teamRole]);

  const checklistId = useMemo(
    () => getChecklistIdByRole(projectPolicy, teamRole),
    [projectPolicy, teamRole],
  );

  const handleActivateProject = () => {
    if (policyItems?.length && !isChecklistCompleted) {
      setChecklistModalShow(true);
    } else {
      confirmModal.askConfirm({ value: { status: ProjectStatusEnum.ACTIVE } });
      setActionType(actionTypes.ACTIVATE);
    }
  };

  const handleApproveClose = () => {
    setChecklistModalShow(false);
    if (project.is_budget_locked) {
      confirmModal.askConfirm({ value: { status: ProjectStatusEnum.ACTIVE } });
      setActionType(actionTypes.ACTIVATE);
    } else {
      confirmModal.askConfirm({ value: { is_budget_locked: true } });
      setActionType(actionTypes.SUBMIT);
    }
  };

  const isAllNamesNotFill = useMemo(
    () => !checkIsAllNamesFillInProject(projectMilestonesQueryData?.results),
    [projectMilestonesQueryData],
  );

  const needsBudget = useMemo(
    () =>
      !projectMilestonesQueryData?.count &&
      !Object.values(ActivableNoBudgetLoanTypeEnums).includes(
        project?.loan?.type as ActivableNoBudgetLoanTypeEnums,
      ),
    [projectMilestonesQueryData?.count, project?.loan?.type],
  );

  const getButtonTooltipText = useCallback(
    (type: string) => {
      if (needsBudget) {
        return `To ${type} the project, you need to add budget.`;
      }
      if (isAllNamesNotFill) {
        return getTooltipText({ isAllNamesNotFill });
      }
      if (!borrower?.company) {
        return 'To activate the project, you need to add borrower company.';
      }
    },
    [needsBudget, isAllNamesNotFill, !borrower?.company],
  );

  const buttonsDisabled = useMemo(() => {
    return needsBudget || isAllNamesNotFill || !borrower?.company;
  }, [needsBudget, isAllNamesNotFill, !borrower?.company]);

  return {
    submitProject,
    loading: submitProjectMutation.isLoading,
    checklistModalShow,
    setChecklistModalShow,
    checklistId,
    policyItems,
    confirmModal,
    isChecklistCompleted,
    handleActivateProject,
    handleApproveClose,
    confirmText: actionType?.confirmText,
    confirmCallback,
    buttonsDisabled,
    errors,
    setErrors,
    getButtonTooltipText,
    isLender,
  };
};
