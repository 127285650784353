import { EnumTypeForList } from '@interfaces';

export enum SummaryEditableDataEnum {
  SUBMITTED = 'submitted_at',
  APPROVED = 'approved_at',
  NUMBER = 'counter_per_request_type',
  RATE = 'inspector_allowance_rate',
  ESTIMATED_DISBURSEMENT_DATE = 'estimated_disbursement_date',
  EXTENDED_MATURITY_DATE = 'extended_maturity_date',
  ESTIMATED_COMPLETION_DATE = 'estimated_completion_date',
  DISBURSED_AT = 'disbursed_at',
  SERVICING_STATUS = 'servicing_status',
  SCOPE_OF_WORK = 'scope_of_work',
  INTEREST_METHOD = 'interest_method',
  LOAN_TYPE = 'type',
  FUNDING_DATE = 'funding_date',
}

export type TChangedData = Date | string | number | EnumTypeForList;

export interface IChangedData {
  title: string;
  type: SummaryEditableDataEnum;
  data: TChangedData;
  validationRule?: (value: Date) => { value: boolean; reason: string };
  minDate?: Date;
  maxDate?: Date;
  options?: EnumTypeForList[];
}
