import { QueryNamesEnums } from '@interfaces';
import { useGraphQuery } from '@context';
import { useParams } from 'react-router';
import { useMemo } from 'react';
import sortBy from 'lodash/sortBy';

export const useProjectWorkflow = () => {
  const { projectId } = useParams();

  const listData = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT_ROLES,
    keys: ['company_role', 'id', 'review_level', 'permissions', 'display_name'],
    args: {
      project_id: projectId,
    },
  });

  const roles = useMemo(
    () => sortBy(listData?.data?.results || [], 'review_level'),
    [listData.data],
  );

  return {
    status: listData.status,
    roles,
  };
};
