import { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { QueryNamesEnums, ProjectStatusEnum, IStatusChip, HookState, ICompany } from '@interfaces';
import { SettingsContext, useGraphQuery } from '@context';
import { statusMap } from '@constants';
import { getHookState } from '@utils';
import { useProjectTeamsAndCompanies } from '@hooks';

interface ControllerInterface {
  state: HookState;
  projectName: string;
  statusValue: IStatusChip;
  projectBorrowerCompany: ICompany;
  projectInvestorCompany: ICompany;
  projectCustomerCompany: ICompany;
  isCurrentProjectActive: boolean;
  status_change_reason: string;
  teamsIsLoading: boolean;
}

export const useProjectHeader = (): ControllerInterface => {
  const { projectId } = useParams();

  const { projectBorrowerTeam, projectCustomerCompany, teamsIsLoading, projectInvestorCompany } =
    useProjectTeamsAndCompanies({});

  const {
    isCurrentProjectActive,
    settings: { display: staticStatuses },
  } = useContext(SettingsContext);

  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['name', 'status', 'status_change_reason'],
    nested: {
      loan: ['external_id'],
    },
    args: { project_id: projectId },
  });

  const statusValue = useMemo(
    () =>
      staticStatuses && project?.data?.status !== ProjectStatusEnum.ACTIVE
        ? statusMap(project?.data?.status, staticStatuses, 'project')
        : null,
    [project?.data?.status, staticStatuses],
  );

  const projectName = useMemo(
    () =>
      (project?.data?.loan?.external_id ? `#${project?.data?.loan.external_id}, ` : '') +
      project?.data?.name,
    [project?.data?.loan?.external_id, project?.data?.name],
  );

  return {
    state: getHookState(project),
    projectName,
    statusValue: { ...statusValue, label: statusValue?.text },
    projectBorrowerCompany: projectBorrowerTeam?.company,
    projectInvestorCompany,
    projectCustomerCompany,
    isCurrentProjectActive,
    status_change_reason: project?.data?.status_change_reason,
    teamsIsLoading,
  };
};
