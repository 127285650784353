import { useCallback } from 'react';
import { currencyFormatter } from '@utils';
import { useGraphQuery } from '@context';
import { QueryNamesEnums } from '@interfaces';

export type ControllerInterface = {
  isLoading: boolean;
  hasPrefundingCostError: boolean;
  hasScheduleOfValuesError: boolean;
  loan: {
    funding_source_inactive_amount?: number;
    funding_source_active_amount?: number;
    budget_prefunding_cost?: number;
    budget_post_funding_construction_budget?: number;
  };
  valueIconTooltip: ((amount: number) => string) | null;
};

export const useBudgetSummary = (projectId: string): ControllerInterface => {
  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    args: { project_id: projectId },
    nested: {
      loan: [
        'funding_source_inactive_amount',
        'funding_source_active_amount',
        'budget_prefunding_cost',
        'budget_post_funding_construction_budget',
      ],
    },
  });
  const loan = project.data?.loan;

  const valueIconTooltip = useCallback((amount: number) => {
    return `The total sum in the table below does not match the amount of ${currencyFormatter(amount)} entered in Funding Sources.`;
  }, []);

  return {
    isLoading: project.isPostLoading,
    hasPrefundingCostError: loan?.budget_prefunding_cost !== loan?.funding_source_inactive_amount,
    hasScheduleOfValuesError:
      loan?.budget_post_funding_construction_budget !== loan?.funding_source_active_amount,
    loan,
    valueIconTooltip,
  };
};
