import { useMutation, useQueryClient } from 'react-query';
import { updateUiSettings } from '@globalService';
import { DrawRequestTypeEnum, IUISettings, QueryNamesEnums, TableKeyEnum } from '@interfaces';
import { SettingsContext } from '@context';
import { useCallback, useContext, useMemo } from 'react';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';

export const useUserTableSettings = ({
  tableKey,
}: {
  tableKey: TableKeyEnum | DrawRequestTypeEnum;
}) => {
  const queryClient = useQueryClient();
  const { settings } = useContext(SettingsContext);

  const userSettings = useMemo(() => settings.personal_setting, [settings]);
  const userTableSettings = useMemo(
    () => userSettings?.tableV3?.[tableKey],
    [userSettings, tableKey],
  );

  const updateSettingsMutation = useMutation<IUISettings, Error, Partial<IUISettings>>(
    updateUiSettings,
    {
      onSettled: () => {
        queryClient.invalidateQueries(QueryNamesEnums.GET_UI_SETTINGS);
      },
    },
  );

  const updateUserTableSettings = useCallback(
    async (settings: GridInitialStatePremium, skipSave: boolean = false) => {
      queryClient.setQueryData(QueryNamesEnums.GET_UI_SETTINGS, (old: IUISettings) => {
        const newSettings = {
          ...old,
          personal_setting: {
            ...old.personal_setting,
            tableV3: {
              ...old.personal_setting.tableV3,
              [tableKey]: settings,
            },
          },
        };
        if (!skipSave) {
          updateSettingsMutation.mutateAsync(newSettings);
        }
        return { ...newSettings };
      });
    },
    [userSettings],
  );

  return {
    userTableSettings,
    updateUserTableSettings,
  };
};
