import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Divider, Skeleton, Stack, Typography } from '@mui/material';
import snakeCase from 'lodash/snakeCase';
import {
  BarChart,
  LabelAndValue,
  LoadingSkeleton,
  PieChartItem,
  ServiceMessage,
  StyledBox,
} from '@components';
import { HookState, IProjectProgressItem } from '@interfaces';
import { colors } from '@theme';
import { currencyFormatter } from '@utils';
import DrawPaymentInfo from '../DrawPaymentInfo';
import { getLabels, usePaymentGraph } from './controller';

const PaymentGraphs = () => {
  const { drawRequestId: isRequestTab } = useParams();

  const controller = usePaymentGraph();
  const labels = getLabels(Boolean(isRequestTab));

  const { state, originalValuePieChartData, fundingSources, progressItems } = controller;

  switch (state) {
    case HookState.LOADING: {
      return (
        <>
          <StyledBox>
            <Skeleton />
          </StyledBox>
          <Divider sx={{ my: '24px' }} />
          <LoadingSkeleton type="twoBlocks" />
        </>
      );
    }
    case HookState.ERROR: {
      return <ServiceMessage text="audit log" />;
    }

    case HookState.SUCCESS: {
      return (
        <Stack>
          {!isRequestTab && (
            <StyledBox sx={{ width: '100%', mt: 2 }}>
              <Typography variant="h3">{labels.progress}</Typography>
              <Divider sx={{ my: 3 }} />
              <Stack direction="row" spacing={2}>
                <Stack spacing={2} pr={2} sx={{ width: { lg: '50%', xs: '100%' } }}>
                  <BarChartItem
                    item={progressItems.originalScheduledValue}
                    label="Original scheduled value"
                    subLabel="(Construction holdback + Borrower equity)"
                    source="payments_tab__original_scheduled_value"
                  />
                </Stack>
              </Stack>
            </StyledBox>
          )}

          <Stack direction="row" sx={{ mt: 2, flexWrap: { lg: 'nowrap', xs: 'wrap' } }}>
            <Stack sx={{ mt: { lg: 0, xs: 2 }, width: { lg: '50%', xs: '100%' } }} spacing={2}>
              {isRequestTab && (
                <StyledBox sx={{ flexGrow: 1 }}>
                  <Typography variant="h3">{labels.progress}</Typography>
                  <Divider sx={{ my: 3 }} />
                  <Stack direction="row" spacing={2}>
                    <Stack spacing={2} sx={{ width: '100%' }}>
                      <BarChartItem
                        item={progressItems.originalScheduledValue}
                        label="Original scheduled value"
                        subLabel="(Construction holdback + Borrower equity)"
                        source="payments_tab__original_scheduled_value"
                      />
                    </Stack>
                  </Stack>
                </StyledBox>
              )}

              <StyledBox sx={{ flexGrow: 1 }}>
                <Typography variant="h3">Funding sources</Typography>
                <Divider sx={{ mt: 3 }} />
                {fundingSources.map((fundingSource) => (
                  <BarChartItem
                    key={fundingSource.display_name}
                    label={fundingSource.display_name}
                    item={fundingSource}
                    source={`payments_tab__${snakeCase(fundingSource.display_name)}`}
                  />
                ))}
              </StyledBox>
            </Stack>
            <Stack
              spacing={2}
              sx={{ ml: { lg: 2, xs: 0 }, mt: { lg: 0, xs: 2 }, width: { lg: '50%', xs: '100%' } }}
            >
              <StyledBox sx={{ flexGrow: 1 }}>
                <Typography variant="h3" data-cy="payments_tab__graphs__pie_chart__label">
                  {labels.budget}
                </Typography>
                {isRequestTab ? (
                  <PieChartItem data={originalValuePieChartData} height={150} />
                ) : (
                  <>
                    <Divider sx={{ my: 3 }} />
                    <Typography variant="h4" sx={{ mb: 2 }}>
                      Original scheduled value
                    </Typography>
                    <PieChartItem data={originalValuePieChartData} height={150} />
                  </>
                )}
              </StyledBox>
              {isRequestTab && <DrawPaymentInfo />}
            </Stack>
          </Stack>
        </Stack>
      );
    }
    default:
      return null;
  }
};

const BarChartItem: FC<{
  item: Partial<IProjectProgressItem>;
  label: string;
  subLabel?: string;
  source: string;
}> = ({ item, label, subLabel, source }) => {
  const { drawRequestId: isRequestTab } = useParams();
  const color = colors.fundingSource[item.colorKey || 'green'];

  if (!item?.total) return null;
  return (
    <Stack>
      <BarChart
        stacked
        orientation="horizontal"
        values={item?.barValues}
        total={item?.total}
        barWidth={24}
        colors={[
          color.medium, // beforeValueColor
          ...(isRequestTab ? [color.light] : []), // afterValueColor
          color.lighter, // totalValueColor
        ]}
        legendPosition="top"
        borderRadius="2px"
        CustomLegend={
          <Stack
            sx={{
              width: '100%',
              flexDirection: 'row',
              justifyContent: 'space-between',
              mb: 1,
              mt: 3,
            }}
          >
            <Stack>
              <Typography variant="h4">{label}</Typography>
              {subLabel && (
                <Typography variant="label" color={colors.main.primary.main}>
                  {subLabel}
                </Typography>
              )}
            </Stack>
            <Typography variant="h4" data-cy={`payments_tab__graphs__${snakeCase(label)}__value`}>
              {currencyFormatter(item?.total)}
            </Typography>
          </Stack>
        }
        tooltipTitle={<TooltipContent label={label} item={item} />}
        source={source}
      />
    </Stack>
  );
};

const TooltipContent: FC<{
  label: string;
  item: Partial<IProjectProgressItem>;
}> = ({ label, item }) => {
  const { drawRequestId: isRequestTab } = useParams();
  const labels = getLabels(Boolean(isRequestTab));
  const itemBoxStyle = {
    width: 16,
    height: 16,
    borderRadius: '2px',
  };
  const color = colors.fundingSource[item.colorKey || 'green'];

  return (
    <Stack sx={{ minWidth: 360 }}>
      <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
        <Typography variant="h4">{label}</Typography>
        <Typography variant="body2SemiBold">{currencyFormatter(item?.total)}</Typography>
      </Stack>
      <Stack spacing={1}>
        {isRequestTab && (
          <LabelAndValue
            label="Current draw"
            text={item?.currentDraw}
            icon={<Box sx={{ ...itemBoxStyle, background: color.light }} />}
            iconPosition="left"
            textDataTestName={`payments_tab__graphs__${snakeCase(label)}__current_draw__value`}
          />
        )}

        <LabelAndValue
          label={labels.contributed}
          text={item?.contributedText}
          icon={<Box sx={{ ...itemBoxStyle, background: color.medium }} />}
          iconPosition="left"
          textDataTestName={`payments_tab__graphs__${snakeCase(label)}__contributed__value`}
        />
        <LabelAndValue
          label={labels.remaining}
          text={item?.remainingText}
          icon={<Box sx={{ ...itemBoxStyle, background: color.lighter }} />}
          iconPosition="left"
          textDataTestName={`payments_tab__graphs__${snakeCase(label)}__remaining__value`}
        />
      </Stack>
    </Stack>
  );
};

export default PaymentGraphs;
