import React, { FC } from 'react';
import { StyledBox } from '@components';
import { TablePaginationNew } from '@components';
import UploadPhotosButton from './UploadPhotosButton';
import { ITablePagination } from '@interfaces';
const PhotosHeader: FC<{
  itemsCount: number;
  tablePagination: ITablePagination;
  isAddPhotoAvailable: boolean;
  projectId: string;
  drawRequestId: string;
}> = ({ itemsCount, tablePagination, isAddPhotoAvailable, projectId, drawRequestId }) => {
  const { page, rowsPerPage, rowsPerPageOptions, onPageChange, onRowsPerPageChange } =
    tablePagination;

  return (
    <StyledBox
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        mb: 1,
        alignItems: 'center',
        gap: 1,
      }}
    >
      <TablePaginationNew
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        itemsCount={itemsCount}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        source="photos_tab__list"
        showPerPageSelect={false}
        labelEntityName=" line items"
      />
      {isAddPhotoAvailable && (
        <UploadPhotosButton projectId={projectId} drawRequestId={drawRequestId} />
      )}
    </StyledBox>
  );
};

export default PhotosHeader;
