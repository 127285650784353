import React, { FC } from 'react';
import { Button, Tooltip } from '@mui/material';
import { useDownloadAllButton } from './controller';
import { IDocument, IProjectDocument } from '@interfaces';
import { DownloadIcon } from '@svgAsComponents';

const DownloadAllButton: FC<{
  documents: IProjectDocument[] | IDocument[];
  source: string;
  buttonLabel?: string;
  drawRequestId?: string;
}> = ({ documents, source, buttonLabel = 'Download all', drawRequestId }) => {
  const { downloadDocs } = useDownloadAllButton({ documents, drawRequestId });

  const button = (
    <Button
      variant="new"
      color="secondary"
      size="small"
      onClick={downloadDocs}
      disabled={!documents?.length}
      startIcon={<DownloadIcon size={16} />}
      data-cy={`${source}__download_all__button`}
      data-count={documents?.length}
      sx={{ ml: 1 }}
    >
      {buttonLabel}
    </Button>
  );

  return !documents?.length ? (
    <Tooltip title="No documents to download">
      <span>{button}</span>
    </Tooltip>
  ) : (
    button
  );
};

export default DownloadAllButton;
