import React, { FC, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { Button } from '@mui/material';
import { useFilesUploader } from '@hooks';
import { TransloaditTemplateEnum } from '@interfaces';
import { getPhotoContentType } from '@utils';
import { UploaderWithForm } from '@components';

const UploadPhotosButton: FC<{
  projectId: string;
  drawRequestId: string;
  size?: 'small' | 'large';
}> = ({ projectId, drawRequestId, size = 'small' }) => {
  const {
    isFilesUploaderOpened,
    transloaditSignature,
    uploadMedia,
    filesUploaderClose,
    restrictions,
    progressPhotoTypeId,
  } = useFilesUploader();

  const uploadClick = useCallback(() => {
    uploadMedia({
      fields: {
        ...(drawRequestId && { draw_request_id: drawRequestId }),
        project_id: projectId,
        object_id: drawRequestId || projectId,
        content_type: getPhotoContentType({ drawRequestId }),
      },
      templateType: TransloaditTemplateEnum.PROOFPOINTS,
    });
  }, [projectId, drawRequestId]);

  const uploader = () => {
    return (
      <UploaderWithForm
        isOpen={isFilesUploaderOpened}
        closeUploader={filesUploaderClose}
        transloaditSignature={transloaditSignature}
        restrictions={restrictions}
        source="photos_tab__list__photos__upload__button"
        showLineItems
        projectId={projectId}
        drawRequestId={drawRequestId}
        documentTypeId={progressPhotoTypeId}
      />
    );
  };
  return (
    <>
      <Button size={size} onClick={uploadClick}>
        Upload photos
      </Button>
      {isFilesUploaderOpened &&
        transloaditSignature &&
        ReactDOM.createPortal(uploader(), document.body)}
    </>
  );
};

export default UploadPhotosButton;
