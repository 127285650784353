import Uppy from '@uppy/core';
import Webcam from '@uppy/webcam';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import Transloadit from '@uppy/transloadit';
import { datadogRum } from '@datadog/browser-rum';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/webcam/dist/style.css';
import '@uppy/informer/dist/style.css';

export const useCustomUppy = ({ params, signature, restrictions = {}, enqueueSnackbar = null }) => {
  const uppy = new Uppy({ restrictions })
    .use(Webcam, { modes: ['picture'] })
    .use(ThumbnailGenerator, {
      thumbnailWidth: 200,
      waitForThumbnailsBeforeUpload: true,
    })
    .use(Transloadit, {
      assemblyOptions: {
        params,
        signature,
      },
      waitForEncoding: true,
    })
    .on('upload-error', (file, error, response) => {
      console.log('error with file>:', file.id);
      console.log('error message>:', error.message);
      console.log('error details>:', error.details);

      const transloaditError = new Error(
        `Transloadit upload error: ${error?.message || 'Unknown error'}`,
      );
      transloaditError.name = 'TransloaditUploadError';
      transloaditError.cause = error;

      datadogRum.addError(transloaditError, {
        fileId: file.id,
        fileName: file.name,
        fileType: file.type,
        params: JSON.stringify(params),
        errorDetails: error?.details,
        errorMessage: error?.message,
        response: JSON.stringify(response),
      });

      if (error?.details?.includes('document_ocr')) {
        uppy.setFileMeta(file.id, { ocr_pipeline: 'skip' });
        uppy.retryUpload(file.id);
      }
    })
    .on('complete', (result) => {
      if (result.successful?.length > 0 && enqueueSnackbar) {
        enqueueSnackbar(`Uploading is being processed and may take a moment to appear.`, {
          variant: 'info',
        });
      }
    });

  return uppy;
};
