import React from 'react';
import {
  ActionButton,
  CellRenderWithError,
  gridCellSet,
  isFooterRow,
  MilestoneListColumnTypeV2,
} from './common';
import {
  calculateRequestedAdjustment,
  currencyFormatter,
  currencyToNumber,
  isAllowed,
} from '@utils';
import { CurrencyInput } from '@components';
import { CommonRowType, PermissionNamesEnums } from '@interfaces';
import { GridEditInputCell } from '@mui/x-data-grid-premium';
import { InputBaseComponentProps } from '@mui/material';

const editableByMilestone = (row: CommonRowType) =>
  (row.localIsUserCreator || row.isSubmission) && row.isEditableV2;
const actionButtons: ActionButton[] = [
  {
    variant: 'text',
    size: 'small',
    onClickCustom(api, row) {
      const requested_adjustments = row.requested_adjustments;
      gridCellSet(api, row, 'requested_adjustments', requested_adjustments);
    },
    label: 'Clear input',
  },
  {
    color: 'secondary',
    variant: 'new',
    size: 'small',
    onClickCustom(api, row) {
      const requested_adjustments = calculateRequestedAdjustment(row, +row.requested_amount);
      gridCellSet(api, row, 'requested_adjustments', requested_adjustments);
    },
    label: 'Increase change',
  },
  {
    color: 'secondary',
    variant: 'new',
    size: 'small',
    onClickCustom(api, row) {
      const requested_amount = row.balance_to_finish;
      gridCellSet(api, row, 'requested_amount', requested_amount);
    },
    label: 'Adjust draw',
  },
];

const requestedAdjustments: MilestoneListColumnTypeV2 = {
  field: 'requested_adjustments',
  valueParser: (value) => currencyToNumber(value, true),
  renderEditCell: (props) => (
    <GridEditInputCell
      {...props}
      inputComponent={CurrencyInput as unknown as React.ElementType<InputBaseComponentProps>}
    />
  ),
  renderCell: (params) => <CellRenderWithError params={params} actionButtons={actionButtons} />,
  valueFormatter: (value, row) =>
    isFooterRow(row)
      ? currencyFormatter(row.requested_budget_change, '')
      : currencyFormatter(value, ''),
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Requested +/- change ($)',
  description: 'Requested change to revised scheduled value',
  editableByMilestone,
  editableByPermissions: (permissions) =>
    isAllowed(PermissionNamesEnums.DRAWREQUESTS_CREATE, permissions) ||
    isAllowed(PermissionNamesEnums.DRAWREQUESTS_MANUAL_CREATE, permissions),
  editable: true,
  minWidth: 120,
};

export default requestedAdjustments;
