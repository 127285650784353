import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Stack, Typography } from '@mui/material';

import {
  ButtonWithTooltip,
  CenteredStyledBox,
  DocumentButton,
  DocumentUploaderWithForm,
  LoadingSkeleton,
  ToolTipLine,
} from '@components';
import { HookState, ToolTipLineVariantEnum } from '@interfaces';
import * as Controller from './controller';
import { ServiceRelatedDocumentsProps } from './interface';

const AppraisalDocuments = ({
  serviceOrderId,
  source,
  onDocumentClick,
  isEditable = true,
  activeDocumentId = '',
}: ServiceRelatedDocumentsProps) => {
  const { projectId } = useParams();
  const controller = Controller.useAppraisalDocuments({
    projectId,
    serviceOrderId,
  });

  const {
    state,
    handleOpenDocumentUploader,
    isFilesUploaderOpened,
    transloaditSignature,
    filesUploaderClose,
    documentTypes,
    documents,
    isLoading,
    restrictions,
  } = controller;

  return (
    <CenteredStyledBox
      sx={{
        overflow: 'unset',
      }}
    >
      <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
        <Typography variant="h3">Appraisal documents</Typography>
        <Stack direction="row" justifyContent="flex-end" spacing={1}>
          <ButtonWithTooltip
            size="small"
            onClick={() => {
              handleOpenDocumentUploader();
            }}
            dataTestName={`${source}__add_appraisal__button`}
            disabled={!isEditable}
            tooltipText={!isEditable && 'Service is not editable'}
          >
            Add appraisal
          </ButtonWithTooltip>
        </Stack>
      </Stack>
      {state === HookState.LOADING && (
        <Stack spacing={2} direction="row">
          <LoadingSkeleton type="overviewBlock" />
        </Stack>
      )}
      {state === HookState.SUCCESS && (
        <>
          <Typography variant="body3" sx={{ mb: 3 }}>
            Upload documents such as appraisals. Appraisals will accompany all inspection orders.
          </Typography>

          <Box
            sx={{ display: 'flex', flexWrap: 'wrap', mt: 2 }}
            onClick={(e) => (isLoading ? e.stopPropagation() : null)}
          >
            {!documents?.length && (
              <Box sx={{ my: 2, mr: 2 }}>
                <ToolTipLine
                  variant={ToolTipLineVariantEnum.ERROR}
                  text="No appraisal attached"
                  withBackground
                  typographyVariant="labelSemiBold"
                  size="small"
                />
              </Box>
            )}

            {documents?.map((document) => (
              <Box sx={{ mb: 2, mr: 2 }} key={document.id}>
                <DocumentButton
                  document={document}
                  onButtonClick={() => onDocumentClick?.(document)}
                  dataTestName={`${source}__docs_button__button`}
                  isActive={activeDocumentId === document.id}
                  width="175px"
                />
              </Box>
            ))}
          </Box>
        </>
      )}
      {transloaditSignature?.signature && (
        <DocumentUploaderWithForm
          isOpen={isFilesUploaderOpened}
          documentTypes={documentTypes}
          closeUploader={filesUploaderClose}
          transloaditSignature={transloaditSignature}
          restrictions={restrictions}
          serviceOrderId={serviceOrderId}
          source={`${source}__documents`}
        />
      )}
    </CenteredStyledBox>
  );
};

export default AppraisalDocuments;
