import React, { FC, useContext } from 'react';
import { Checkbox, Stack, Tooltip, Typography } from '@mui/material';
import { InfoIcon } from '@svgAsComponents';
import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { QueryNamesEnums, ProjectUpdatePayload } from '@interfaces';
import { updateProjectFields } from '@globalService';
import { useSafeSnackbar } from '@hooks';
import { colors } from '@theme';
import { SettingsContext } from '@context';

const IsProductionBuildCheckbox: FC<{
  wrapperPadding?: number;
  onChange?: (isProductionBuildProject: boolean) => void;
  justifyContent?: 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around';
  label?: string;
  checked?: boolean;
  disabled?: boolean;
}> = ({
  wrapperPadding = 2,
  onChange,
  justifyContent = 'flex-start',
  label = 'Advanced project tracking (Budget by line items with other dimensions)',
  checked,
  disabled,
}) => {
  const { projectId } = useParams();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();
  const { setPHBProject } = useContext(SettingsContext);

  const updateInfoProject = useMutation<
    Response,
    Error,
    { projectId: string; json: ProjectUpdatePayload }
  >(updateProjectFields, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT, { project_id: projectId }]);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  return (
    <Stack
      direction="row"
      spacing={0.5}
      padding={wrapperPadding}
      alignItems="center"
      justifyContent={justifyContent}
      sx={{ flexGrow: 1, backgroundColor: colors.background.white }}
    >
      <Checkbox
        checked={checked ?? false}
        disabled={disabled}
        onChange={(_e, value) => {
          if (onChange) {
            onChange(value);
          } else if (projectId) {
            setPHBProject(value);
            updateInfoProject.mutateAsync({
              projectId,
              json: { is_advanced_budget_tracking_enabled: value },
            });
          }
        }}
        data-cy="project__is_phb__checkbox"
      />
      <Typography variant="body3">{label}</Typography>
      <Tooltip
        title={
          <>
            This option allows tracking across different dimensions such as models and lots in
            addition to the standard line item level.
            <br /> <br /> You can update this setting when you enter in the budget.
          </>
        }
      >
        <Stack>
          <InfoIcon size={16} />
        </Stack>
      </Tooltip>
    </Stack>
  );
};

export default IsProductionBuildCheckbox;
