import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQueries, useQueryClient, UseQueryResult } from 'react-query';
import * as Sentry from '@sentry/react';

import { getMyCompany, getProjectSettings } from '@globalService';
import {
  ICompanyFull,
  IProjectSettings,
  PermissionNamesEnums,
  QueryNamesEnums,
  ServerErrorCodesEnum,
} from '@interfaces';
import { checkIsOwner, getHookState, getTeamRole, isAllowed } from '@utils';
import { AuthContext, PermissionsContext, useGraphQuery } from '@context';
import {
  AnalyticsIcon,
  DashboardIcon,
  FileIcon,
  FolderIcon,
  NavLinkIcon1,
  NavLinkIcon2,
  ServicesIcon,
} from '@svgAsComponents';
import { ControllerInterface, NavigationLink } from './interface';
import { screensACL } from '@constants';

export const usePageLayout = (): ControllerInterface => {
  const { user, setUser } = useContext(AuthContext);
  useEffect(() => {
    const checkTokenExpiration = () => {
      const expirationTime = localStorage.getItem('tokenExpirationTime');
      const currentTime = new Date().getTime();
      const isShouldBeLoggedOut = expirationTime && currentTime > parseInt(expirationTime, 10);
      if (isShouldBeLoggedOut) {
        localStorage.clear();
        sessionStorage.clear();
        setUser(null);
        window.location.reload();
      }
    };

    // Optionally preload chat on mount, or wait for click
    if (!window.hsConversationsOnReady) {
      window.hsConversationsOnReady = [];
    }

    window.addEventListener('focus', checkTokenExpiration);
    return () => {
      window.removeEventListener('focus', checkTokenExpiration);
    };
  }, []);

  const queryClient = useQueryClient();
  const { projectId } = useParams();

  const teamRole = getTeamRole(user);
  const isOwner = useMemo(() => checkIsOwner(teamRole), [teamRole]);
  const { permissions } = useContext(PermissionsContext);

  const checkAvailability = ({ permissionName }: { permissionName: PermissionNamesEnums }) =>
    isAllowed(permissionName, permissions);

  const isScreenAvailable = (path: string) => screensACL[teamRole]?.available?.includes(path);

  const initialPages = useMemo(() => {
    const pages = [
      {
        title: 'Projects',
        icon: FolderIcon,
        path: '/projects',
        value: 'projects',
        dataTestName: 'projects_tab',
        isAvailable: () => isScreenAvailable('/projects'),
      },
      {
        title: 'Requests',
        icon: FileIcon,
        path: '/requests',
        value: 'requests',
        dataTestName: 'menu-item-draw-requests',
        isAvailable: () => isScreenAvailable('/requests'),
      },
      {
        title: 'Services',
        icon: ServicesIcon,
        path: '/services',
        value: 'services',
        dataTestName: 'services_tab',
        isAvailable: () =>
          isScreenAvailable('/services') &&
          checkAvailability({
            permissionName: PermissionNamesEnums.INSPECTIONS_VIEW,
          }),
      },
      {
        title: 'Analytics',
        icon: AnalyticsIcon,
        path: '/analytics',
        value: 'analytics',
        dataTestName: 'menu-item-analytics',
        isAvailable: () =>
          isScreenAvailable('/analytics') &&
          checkAvailability({
            permissionName: PermissionNamesEnums.ANALYTICS_DASHBOARD_VIEW,
          }),
      },
      {
        title: 'Home',
        icon: DashboardIcon,
        path: '/home',
        value: 'home',
        isAvailable: () => isScreenAvailable('/home') && isOwner,
      },
    ];

    // Filter out unavailable pages
    return pages.filter((page) => page.isAvailable());
  }, [permissions, isOwner, teamRole]);

  const [pages, setPages] = useState<NavigationLink[]>(initialPages);

  const requestedDataQueries = useQueries([
    { queryKey: [QueryNamesEnums.GET_MY_COMPANY], queryFn: getMyCompany.bind(this) },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_SETTINGS, { projectId }],
      queryFn: getProjectSettings.bind(this, projectId),
      enabled: Boolean(projectId && user?.isAllowedToLogin),
    },
  ]);

  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['name', 'customer_name', 'lender_company_logo'],
    args: { project_id: projectId },
    options: { skip: !projectId },
  });
  const companyQuery = requestedDataQueries[0] as UseQueryResult<ICompanyFull, Error>;
  const projectSettingsQuery = requestedDataQueries[1] as UseQueryResult<IProjectSettings, Error>;

  useEffect(() => {
    if (project.data) {
      Sentry.setTag('project.name', project.data.name);
      Sentry.setTag('project.customer', project.data.customer_name);
      if (!user?.project_information_first_accessed_at && isOwner)
        queryClient.invalidateQueries(QueryNamesEnums.GET_USER);
    }
  }, [project.data, user]);

  useEffect(() => {
    // user who can add custom link will see them on any page from my-company data,
    // other user (who enrolled to the projects, where ^user is Customer) will see these links
    // on project-related pages from project settings
    const customNavigationLinks = projectSettingsQuery.data?.custom_navigation_links?.length
      ? projectSettingsQuery.data.custom_navigation_links
      : companyQuery.data?.custom_navigation_links;
    if (customNavigationLinks) {
      const additionalNavItems = customNavigationLinks.map((item, index) => {
        return {
          title: item.label.charAt(0).toUpperCase() + item.label.slice(1),
          icon: index === 0 ? NavLinkIcon1 : NavLinkIcon2,
          path: item.url,
          isAvailable: () => true,
          isExternal: true,
        };
      });
      setPages([...initialPages, ...additionalNavItems]);
    } else {
      setPages(initialPages);
    }
  }, [projectSettingsQuery.data, companyQuery.data, initialPages]);

  const customerLogo = user?.active_team?.company?.logo || project.data?.lender_company_logo;

  const handleOpenHubSpotChat = () => {
    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.open();
    } else {
      // Add to onReady queue if not yet available
      window.hsConversationsOnReady.push(() => {
        window.HubSpotConversations.widget.open();
      });
    }
  };

  return {
    customerLogo,
    state: getHookState(requestedDataQueries),
    permissionDenied: (project as any)?.error?.code === ServerErrorCodesEnum.PERMISSION_DENIED, ///!!!TODO
    user,
    pages,
    handleOpenHubSpotChat,
  };
};
