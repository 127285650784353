import React from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { PoliciesTypeLabel, teamRolesMap } from '@constants';

const PoliciesTemplateSelect = ({ selectedTemplate, onChangeTemplate, templatesList }) => {
  return (
    <Stack sx={{ mt: 4 }}>
      <Box sx={{ ml: 1.5, mb: 0.5 }}>
        <Typography variant="label">Duplicate policy items from (Optional)</Typography>
      </Box>
      <FormControl>
        <InputLabel
          id="policy-template-label"
          sx={{
            '&:not(.MuiFormLabel-filled)': { transform: 'translate(12px, 10px) scale(1)' },
          }}
        >
          Choose policy
        </InputLabel>
        <Select
          id="policy-template"
          value={selectedTemplate}
          onChange={onChangeTemplate}
          sx={{ height: '40px', minWidth: '300px' }}
          data-cy="policy__section__policies_list__input"
        >
          {templatesList?.map((item) => (
            <MenuItem value={item.id} key={item.id}>
              {`${item.name} (${teamRolesMap[item.assignee_role]} / ${PoliciesTypeLabel[item.type]})`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};

export default PoliciesTemplateSelect;
