import { FilterObject } from '../interface';

export const loansTypeFilter: FilterObject = {
  title: 'Loan type',
  filterKey: 'loan_type',
  needToUpdateUrl: true,
  needToUpdateSetting: true,
  getStaticValues: ({ settings }) =>
    Array.isArray(settings.display?.loan_types)
      ? settings.display?.loan_types.map((item) => ({
          value: item.name,
          label: item.name_display,
        }))
      : [],
  cypressSelector: 'filters__loan_type',
};
