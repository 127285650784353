const model = {
  formId: 'uploadDocument',
  formField: {
    generalType: {
      name: 'generalType',
      label: 'General type for all docs',
    },
    type: {
      name: 'type',
      label: 'Document type',
      requiredErrorMsg: 'Document type is required',
    },
    milestone: {
      name: 'milestone',
      label: 'Line item (optional)',
    },
    comment: {
      name: 'comment',
      label: 'Comment (optional)',
    },
    scope: {
      name: 'scope',
      label: 'Private',
    },
    name: {
      name: 'name',
      label: 'Document name',
      requiredErrorMsg: 'Document name is required',
      invalidErrorMsg: 'Should be more than 5 characters',
    },
  },
};

export default model;
