import * as React from 'react';
import { GridToolbarContainer } from '@mui/x-data-grid-premium';
import Box from '@mui/material/Box';
import { Stack } from '@mui/material';

import { HeaderIcons } from '../interface';

declare module '@mui/x-data-grid' {
  interface FooterPropsOverrides {
    footerLeft: HeaderIcons[];
    footerRight: HeaderIcons[];
  }
}

const CustomFooter = ({
  footerLeft,
  footerRight,
}: {
  footerLeft?: HeaderIcons[];
  footerRight?: HeaderIcons[];
}) => {
  return (
    <GridToolbarContainer
      sx={{
        pl: 2,
        pr: 2,
        pb: 1,
        pt: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
      }}
    >
      <Stack flex={2} spacing={1} direction="row" alignItems="stretch">
        {Boolean(footerLeft?.length) && (
          <Stack direction="row" alignItems="center">
            {footerLeft?.map(({ Component }, index) => (
              <Box key={index.toString()}>{Component}</Box>
            ))}
          </Stack>
        )}
      </Stack>
      <Stack spacing={1} direction="row" flex={1} justifyContent="flex-end" alignItems="center">
        {Boolean(footerRight?.length) && (
          <Stack direction="row" spacing={1} alignItems="center" sx={{ ml: 'auto' }}>
            {footerRight?.map(({ Component }, index) => (
              <Box key={index.toString()}>{Component}</Box>
            ))}
          </Stack>
        )}
      </Stack>
    </GridToolbarContainer>
  );
};

export default CustomFooter;
