import React, { useContext, useMemo, useRef } from 'react';
import { Skeleton, Stack, Typography } from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import { documentCanBeProcessed, getTeamRole, isCreatedByUser, isAllowed } from '@utils';
import {
  CommentIconWithIndicator,
  DownloadIcon,
  EditIcon,
  HistoryIcon,
  MagicWandIcon,
} from '@svgAsComponents';
import { colors } from '@theme';
import { BetaChip, IconButtonWithTooltip } from '@components';
import { TOOLTIP_TEXTS } from '@constants';
import { AuthContext, PermissionsContext, SettingsContext } from '@context';
import {
  ChangeProjectDocumentParam,
  IProjectDocument,
  PermissionNamesEnums,
  QueryNamesEnums,
  TeamRolesEnum,
} from '@interfaces';
import { patchProjectDocument } from '@globalService';
import { useSafeSnackbar } from '@hooks';
import {
  companyPrivacySwitcher,
  lineItemV2Column,
  nameColumn,
  processedStatusColumn,
  uploadedByColumn,
} from './columns';

export const useDocumentTableColumns = ({
  projectId,
  drawRequestId,
  isLoading = false,
  source,
  open,
  setDocSummaryParams,
  handleDocSummaryOpenerClick,
  isMilestoneDocs,
  openComments,
  restoreDocument,
  setActiveDocument,
}) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();
  const { permissions } = useContext(PermissionsContext);
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const isAdmin = React.useMemo(() => teamRole === TeamRolesEnum.CUSTOMER_SUCCESS, [teamRole]);
  const { isCurrentProjectArchived } = useContext(SettingsContext);
  const downloadsRef = useRef([]);
  const updateProjectDocumentation = useMutation<
    Response,
    Error,
    ChangeProjectDocumentParam & { scope: string }
  >(patchProjectDocument, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_DOCUMENTS);
      if (drawRequestId) {
        queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_DOCUMENTS);
      }
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const openDocumentSummary = (document: IProjectDocument) => {
    handleDocSummaryOpenerClick({
      title: (
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
          <MagicWandIcon />
          <Typography variant="h2" sx={{ whiteSpace: 'nowrap' }}>
            SmartSummary
          </Typography>
          <Stack ml={1}>
            <BetaChip dataTestName="right_drawer__document_summary__beta__chip" />
          </Stack>
        </Stack>
      ),
    });
    setDocSummaryParams({ projectId, documentId: document.id });
  };

  const openAllDocsSummary = () => {
    handleDocSummaryOpenerClick({
      title: (
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
          <MagicWandIcon />
          <Typography variant="h2" sx={{ whiteSpace: 'nowrap' }}>
            SmartSummary
          </Typography>
          <Stack ml={1}>
            <BetaChip dataTestName="right_drawer__documents_summary__beta__chip" />
          </Stack>
        </Stack>
      ),
    });
    setDocSummaryParams({ projectId });
  };

  const canBeEdited = (creatorId) =>
    isAllowed(PermissionNamesEnums.DOCUMENTS_EDIT, permissions) ||
    isCreatedByUser(creatorId, user.id);

  const icons = () => ({
    Header: 'Actions',
    accessor: 'icons',
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ row }) => {
      const {
        deleted,
        name,
        id: documentId,
        link,
        created_by,
        comments_preview,
      } = row?.original || {};
      if (isLoading) return <Skeleton />;

      return (
        <Stack
          direction="row"
          justifyContent={isMilestoneDocs ? 'flex-end' : 'flex-start'}
          alignItems="center"
        >
          {documentCanBeProcessed(row?.original) && row?.original?.has_smart_summary ? (
            <Stack
              sx={{
                width: 32,
                height: 32,
                borderRadius: '2px',
                backgroundColor: colors.main.primary.lightest,
                justifyContent: 'center',
                alignItems: 'center',
                mr: 1,
              }}
            >
              <IconButtonWithTooltip
                tooltipText="SmartSummary®"
                onClick={() => openDocumentSummary(row.original)}
                data-cy={`${source}__document_summary__icon`}
              >
                <MagicWandIcon />
              </IconButtonWithTooltip>
            </Stack>
          ) : (
            <Stack sx={{ width: 32, height: 32, mr: 1 }} />
          )}
          {isAdmin && deleted && (
            <IconButtonWithTooltip
              tooltipText={
                isCurrentProjectArchived ? TOOLTIP_TEXTS.isCurrentProjectArchived : 'Restore'
              }
              onClick={() => {
                restoreDocument(documentId, name);
              }}
              disabled={isCurrentProjectArchived}
            >
              <HistoryIcon size={24} />
            </IconButtonWithTooltip>
          )}

          {!deleted && (
            <IconButtonWithTooltip
              tooltipText="Edit"
              onClick={() => setActiveDocument(row.original)}
              data-cy={`${source}__edit__icon`}
              disabled={!canBeEdited(created_by?.user_id)}
            >
              <EditIcon size={24} />
            </IconButtonWithTooltip>
          )}
          {link && (
            <a href={link} download ref={(element) => (downloadsRef.current[row.index] = element)}>
              <IconButtonWithTooltip
                tooltipText="Download"
                data-cy={`${source}__download__icon`}
                data-documentname={row.original?.name}
              >
                <DownloadIcon size={24} />
              </IconButtonWithTooltip>
            </a>
          )}
          <IconButtonWithTooltip
            tooltipText="Comments"
            onClick={() => openComments(row)}
            data-cy={`${source}__comments__icon`}
          >
            <CommentIconWithIndicator
              hasComments={comments_preview?.has_comments}
              hasUnreadComments={comments_preview?.has_unread_comments}
            />
          </IconButtonWithTooltip>
        </Stack>
      );
    },
  });

  const name = useMemo(
    () => nameColumn({ isLoading, source, drawRequestId, open }),
    [isLoading, source, drawRequestId, open],
  );

  const lineItem = useMemo(() => lineItemV2Column({ isLoading, source }), [isLoading, source]);

  const updateDocumentPrivacy = async ({ documentId, scope }) => {
    await updateProjectDocumentation.mutateAsync({
      projectId,
      documentId,
      scope,
    });
  };
  const companyPrivateSwitcher = useMemo(
    () =>
      companyPrivacySwitcher({
        isLoading,
        source,
        userId: user.id,
        updateDocumentPrivacy,
      }),
    [isLoading, source, user.id, updateDocumentPrivacy],
  );

  const processedStatus = useMemo(() => processedStatusColumn({ isLoading }), [isLoading]);

  const uploadedBy = useMemo(() => uploadedByColumn({ isLoading, source }), [isLoading, source]);

  return {
    name,
    lineItem,
    companyPrivateSwitcher,
    processedStatus,
    uploadedBy,
    icons,
    openAllDocsSummary,
  };
};
