import React from 'react';
import { StatusChip, StatusChipWithIconAndTooltip } from '@components';
import { isFailedService, ColumnType } from '@utils';
import { WarningIcon } from '@svgAsComponents';
import { statusMap } from '@constants';

const serviceStatus: ColumnType = ({ settings }) => ({
  field: 'status',
  graphFields: ['status'],
  headerName: 'Status',
  minWidth: 150,
  groupable: false,
  aggregable: false,
  renderCell: ({ row }) => {
    const statusValue = statusMap(row?.status, settings.display, 'service_order');
    const chipProps = {
      color: statusValue.color,
      backgroundColor: statusValue.backgroundColor,
      label: statusValue.text || row?.status,
      dataTestName: 'project__services_tab__list__status_chip',
    };

    if (isFailedService(row?.status))
      return (
        <StatusChipWithIconAndTooltip
          {...chipProps}
          tooltipText={row?.error_msg}
          icon={<WarningIcon size={14} />}
        />
      );

    return <StatusChip {...chipProps} />;
  },
});

export default serviceStatus;
