import React, { FC, useContext, useMemo } from 'react';
import { Stack, Typography } from '@mui/material';
import {
  ActiveFundingSource,
  FundingSourceInternalIdEnums,
  IProjectFundingSource,
  PaymentConfiguration,
  ToolTipLineVariantEnum,
} from '@interfaces';
import { StringFieldModel } from '@models';
import { colors } from '@theme';
import {
  currencyFormatter,
  getFundingSource,
  isPariPassuPaymentConfiguration,
  isReverseSequentialPaymentConfiguration,
  percentFormatter,
} from '@utils';
import {
  CustomRadioGroup,
  EditFundingSourceModal,
  FundingSourceCard,
  IconButtonWithTooltip,
  LabelAndValue,
  ToolTipLine,
} from '@components';
import { PlusIcon } from '@svgAsComponents';
import { SettingsContext, useLaunchDarklyFlags } from '@context';

interface FundingSourcesContainerProps {
  prefunding_cost: StringFieldModel;
  construction_holdback: StringFieldModel;
  constructionHoldbackFraction: StringFieldModel;
  borrower_equity: StringFieldModel;
  borrowerEquityFraction: StringFieldModel;
  configurationType: PaymentConfiguration;
  setConfigurationType: (value: PaymentConfiguration) => void;
  constructionBudget: StringFieldModel;
  source: string;
  isEditable?: boolean;
  configurationTypeOptions: { value: PaymentConfiguration; label: string }[];
  budgetPrefundingCost?: string;
  budgetScheduleOfValues?: string;
  projectFundingSources?: IProjectFundingSource[];
  customSources?: ActiveFundingSource[];
  handleSaveFundingSource: (source: ActiveFundingSource) => void;
}

const FundingSourcesContainer: FC<FundingSourcesContainerProps> = ({
  prefunding_cost,
  construction_holdback,
  constructionHoldbackFraction,
  borrower_equity,
  borrowerEquityFraction,
  configurationType,
  setConfigurationType,
  constructionBudget,
  source,
  isEditable = true,
  configurationTypeOptions,
  budgetPrefundingCost,
  budgetScheduleOfValues,
  projectFundingSources,
  customSources = [],
  handleSaveFundingSource,
}) => {
  const flags = useLaunchDarklyFlags();
  const { isCurrentProjectArchived } = useContext(SettingsContext);
  const newFundingSource = {
    index: customSources?.length + 4 || 1,
    is_active: true,
    is_transactable: true,
    name: '',
    total: 0,
    internal_identifier: null,
    colorKey: 'violet',
    isNew: true,
    id: new Date().toISOString(),
  };
  const borrowerEquitySource = getFundingSource({
    internalId: FundingSourceInternalIdEnums.BORROWER_EQUITY,
    fundingSources: projectFundingSources,
  });
  const constructionHoldbackSource = getFundingSource({
    internalId: FundingSourceInternalIdEnums.CONSTRUCTION_HOLDBACK,
    fundingSources: projectFundingSources,
  });
  const sources = {
    [FundingSourceInternalIdEnums.PREFUNDING_COST]: {
      ...getFundingSource({
        internalId: FundingSourceInternalIdEnums.PREFUNDING_COST,
        fundingSources: projectFundingSources,
      }),
      colorKey: 'green',
      field: prefunding_cost,
    },
    [FundingSourceInternalIdEnums.BORROWER_EQUITY]: {
      ...borrowerEquitySource,
      colorKey: 'orange',
      fractionValue: isPariPassuPaymentConfiguration(configurationType)
        ? percentFormatter({ value: +borrowerEquityFraction.value })
        : '',
      field: borrower_equity,
    },
    [FundingSourceInternalIdEnums.CONSTRUCTION_HOLDBACK]: {
      ...constructionHoldbackSource,
      colorKey: 'blue',
      fractionValue: isPariPassuPaymentConfiguration(configurationType)
        ? percentFormatter({ value: +constructionHoldbackFraction.value })
        : '',
      field: construction_holdback,
    },
  };

  const [activeFundingSource, setActiveFundingSource] = React.useState<ActiveFundingSource | null>(
    null,
  );
  const isNewProject = source === 'project__create';
  const showPrefundingCostFromBudget =
    !isNewProject && +(budgetPrefundingCost || 0) !== +prefunding_cost.value;
  const showScheduleOfValuesFromBudget =
    !isNewProject && +(budgetScheduleOfValues || 0) !== +constructionBudget.value;

  const scheduleOfValues = useMemo(
    () =>
      (+constructionBudget.value || 0) +
      customSources.reduce((acc, source) => acc + (source.total || 0), 0),
    [constructionBudget.value, customSources],
  );
  const totalProjectSources = useMemo(
    () => (+prefunding_cost.value || 0) + scheduleOfValues,
    [prefunding_cost.value, scheduleOfValues],
  );

  return (
    <>
      <Stack spacing={3}>
        <Stack>
          <Typography variant="h3">Funding sources</Typography>
          <Typography variant="body1" sx={{ mt: '8px', display: 'inline-block' }}>
            List and input funding sources and their amounts into the schedule of values.
          </Typography>
        </Stack>
        <Stack direction="row" spacing={5}>
          <Stack alignItems="flex-start">
            <LabelAndValue
              label="Total project sources:"
              text={currencyFormatter(totalProjectSources)}
              marginRightAfterLabel={false}
              textDataTestName={`${source}__funding_sources__total_project_sources__value`}
            />
          </Stack>
          <Stack>
            <LabelAndValue
              label="Schedule of values:"
              text={currencyFormatter(scheduleOfValues)}
              marginRightAfterLabel={false}
              textDataTestName={`${source}__funding_sources__construction_budget__value`}
            />
            {showScheduleOfValuesFromBudget && (
              <Typography
                variant="label"
                color={colors.status.information.medium}
                mt={0.5}
                data-cy={`${source}__funding_sources__construction_budget__from_budget`}
              >
                From budget: {currencyFormatter(+budgetScheduleOfValues, '$0.00')}
              </Typography>
            )}
          </Stack>
        </Stack>
        <Stack>
          <Stack direction="row" spacing={2}>
            <Typography variant="body1">Equity & Holdback disbursement sequence</Typography>
            <CustomRadioGroup
              dataTestName={`${source}__payment_configuration__radio_group`}
              value={configurationType}
              onChange={(event) => setConfigurationType(event.target.value as PaymentConfiguration)}
              options={configurationTypeOptions}
              disabled={!isEditable}
              radioButtonStyle={{ mb: 1 }}
            />
          </Stack>
          {isReverseSequentialPaymentConfiguration(configurationType) && (
            <ToolTipLine
              variant={ToolTipLineVariantEnum.WARNING2}
              text="Prioritizing construction holdback funds may delay cash flow and increase financial risk."
              typographyVariant="body2"
              size="medium"
              dataTestName={`${source}__funding_sources__reverse_sequential_payment_configuration__warning`}
              sx={{ justifyContent: 'flex-start' }}
            />
          )}
        </Stack>
        <Stack direction="row">
          <Stack mr={1}>
            <FundingSourceCard
              fundingSource={sources[FundingSourceInternalIdEnums.PREFUNDING_COST]}
              setActiveFundingSource={setActiveFundingSource}
              source={source}
              isEditable={isEditable}
            />
            {showPrefundingCostFromBudget && (
              <Typography
                variant="label"
                color={colors.status.information.medium}
                mt={0.5}
                data-cy={`${source}__funding_sources__prefunding_cost__from_budget`}
              >
                From budget: {currencyFormatter(+budgetPrefundingCost, '$0.00')}
              </Typography>
            )}
          </Stack>
          <Stack
            spacing={isPariPassuPaymentConfiguration(configurationType) ? 1 : 0}
            direction={
              isReverseSequentialPaymentConfiguration(configurationType) ? 'row' : 'row-reverse'
            }
          >
            <Stack>
              <FundingSourceCard
                fundingSource={sources[FundingSourceInternalIdEnums.CONSTRUCTION_HOLDBACK]}
                setActiveFundingSource={setActiveFundingSource}
                source={source}
                isEditable={isEditable}
              />
            </Stack>
            <Stack>
              <FundingSourceCard
                fundingSource={sources[FundingSourceInternalIdEnums.BORROWER_EQUITY]}
                setActiveFundingSource={setActiveFundingSource}
                source={source}
                isEditable={isEditable}
              />
            </Stack>
          </Stack>
          {customSources?.map((fundingSource) => (
            <Stack key={fundingSource.id} ml={1}>
              <FundingSourceCard
                fundingSource={{
                  ...fundingSource,
                  colorKey: fundingSource.is_transactable ? 'violet' : 'yellow',
                }}
                setActiveFundingSource={setActiveFundingSource}
                source={source}
                isEditable={!fundingSource.funded}
              />
            </Stack>
          ))}
          {flags?.['ENG_8722_funding_source_create_delete'] &&
            !isNewProject &&
            !isCurrentProjectArchived && (
              <Stack>
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    backgroundColor: colors.background.lightest,
                    minWidth: '200px',
                    minHeight: '146px',
                    cursor: 'pointer',
                    borderRadius: 1,
                    ml: 1,
                  }}
                  onClick={() => setActiveFundingSource(newFundingSource)}
                >
                  <IconButtonWithTooltip data-cy={`${source}__funding_sources__add_new__button`}>
                    <PlusIcon size={40} />
                  </IconButtonWithTooltip>
                </Stack>
              </Stack>
            )}
        </Stack>
      </Stack>

      {Boolean(activeFundingSource) && (
        <EditFundingSourceModal
          open={Boolean(activeFundingSource)}
          onClose={() => setActiveFundingSource(null)}
          handleSave={(value) => {
            handleSaveFundingSource?.(value);
            setActiveFundingSource(null);
          }}
          source={source}
          activeFundingSource={activeFundingSource}
        />
      )}
    </>
  );
};

export default FundingSourcesContainer;
