import { useGraphQuery } from '@context';
import { QueryNamesEnums, HookState } from '@interfaces';
import { getHookState, Override } from '@utils';

export type ControllerInterface = Override<
  ReturnType<typeof useServiceOrder>,
  {
    state: HookState;
  }
>;

export const useServiceOrder = (projectId: string, serviceOrderId: string) => {
  const serviceOrderQuery = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT_SERVICE_ORDER_BY_ID,
    args: { project_id: projectId, service_order_id: serviceOrderId },
    keys: ['id', 'status'],
  });

  return {
    serviceOrder: serviceOrderQuery.data,
    state: getHookState(serviceOrderQuery),
  };
};
