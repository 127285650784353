import { useContext, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useSafeSnackbar } from '@hooks';
import { useDropdownFieldModel } from '@models';
import { updateCompany } from '@globalService';
import { EnumTypeForList, ErrorDual, ICompanyOverview, QueryNamesEnums } from '@interfaces';
import { parsePathErrorDual } from '@utils';
import { SettingsContext } from '@context';

interface ControllerInterface {
  timeZoneField: ReturnType<typeof useDropdownFieldModel>;
  isSubmitting: boolean;
  options: EnumTypeForList[];
}

export const useCompanyTimeZone = (initTimeZone: string): ControllerInterface => {
  const { enqueueSnackbar } = useSafeSnackbar();
  const queryClient = useQueryClient();
  const { settings } = useContext(SettingsContext);
  const { timezones } = settings?.display || {};

  const timeZoneField = useDropdownFieldModel({
    initValue: timezones?.find((tz) => tz.name === initTimeZone) || null,
    validationRule: (value) => Boolean(value?.name),
    validateOnChange: true,
  });

  const updateCompanyMutation = useMutation<Response, ErrorDual, ICompanyOverview>(updateCompany, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryNamesEnums.GET_MY_COMPANY);
    },
    onError: (error) => {
      enqueueSnackbar(parsePathErrorDual(error), { variant: 'error' });
    },
  });

  useEffect(() => {
    if (timeZoneField.value && timeZoneField.value.name !== initTimeZone) {
      updateCompanyMutation.mutateAsync({
        company: { timezone: timeZoneField.value.name },
      });
    }
  }, [timeZoneField.value]);

  return {
    timeZoneField,
    isSubmitting: updateCompanyMutation.isLoading,
    options: timezones,
  };
};
