import { ColumnType } from '@utils';
import { useDayJsFormatter } from '@hooks';
import { IDrawRequest } from '@interfaces';

const disbursed_at: ColumnType<IDrawRequest> = () => ({
  field: 'disbursed_at',
  graphFields: ['disbursed_at'],
  headerName: 'Transaction date',
  minWidth: 80,
  groupable: false,
  aggregable: false,
  renderCell: ({ row }) => {
    const { dateFormatter } = useDayJsFormatter();
    return dateFormatter({ date: row.disbursed_at });
  },
});

export default disbursed_at;
