import React, { FC } from 'react';
import { Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import { ICompanyFull } from '@interfaces';
import * as Controller from './controller';

interface ComponentProps {
  company: ICompanyFull;
}

const PasswordAuthSettings: FC<ComponentProps> = ({ company }) => {
  const { updatePasswordAuth } = Controller.usePasswordAuthSettings();
  const isInternalSSO = company?.auth_providers?.some(
    (provider) => provider.access_type === 'INTERNAL',
  );

  return (
    <Stack spacing={2}>
      <Typography variant="h3">Password-Based Authentication</Typography>
      <FormControlLabel
        disabled={!isInternalSSO}
        control={
          <Checkbox
            sx={{ p: 0, mr: 1 }}
            checked={company?.is_password_authentication_enabled}
            onChange={(_e, value) => updatePasswordAuth(value)}
            data-cy="my_company__password_auth_settings__checkbox"
          />
        }
        label={
          <Typography variant="body3" color={!isInternalSSO ? 'text.disabled' : 'inherit'}>
            Allow users to sign in with email and password. If disabled, users will be required to
            use SSO for authentication.
          </Typography>
        }
      />
      {!isInternalSSO && (
        <Typography variant="body3" color="error" sx={{ pl: 4 }}>
          Internal SSO must be configured to manage password-based authentication settings.
        </Typography>
      )}
      {isInternalSSO && !company?.is_password_authentication_enabled && (
        <Typography variant="body3" color="info.main" sx={{ pl: 4 }}>
          Password-based authentication is currently disabled. All users must use SSO to access
          their accounts.
        </Typography>
      )}
    </Stack>
  );
};

export default PasswordAuthSettings;
