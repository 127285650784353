import React, { FC } from 'react';

import { currencyFormatter, getTextOrDash, getValueColor, percentFormatter } from '@utils';
import { ActionUser, ValueTypeEnum } from '@interfaces';
import { LabelAndValueWithPreload, ProgressWithArrow } from '@components';
import * as Controller from '../controller';
import { InfoIcon, WarningIcon } from '@svgAsComponents';

export const RemainingReallocationBalanceWithProgress = ({
  controller,
  valueErrorText,
}: {
  controller: Controller.ControllerInterface;
  valueErrorText: string;
}) => {
  const { requested_budget_change, approved_budget_change } =
    controller.drawRequest?.totals?.all || {};
  const { isApproveReallocateComplete, isRequestReallocateComplete } = controller;

  return (
    <>
      {controller.localIsUserCreator && (
        <LabelAndValueWithPreload
          color={getValueColor({ isError: !isRequestReallocateComplete })}
          valueIcon={!isRequestReallocateComplete ? <WarningIcon size={14} /> : null}
          valueIconTooltip={valueErrorText}
          isLoading={controller.isUpdating}
          label="Borrower reallocation balance"
          text={currencyFormatter(requested_budget_change, '-')}
          textDataTestName="requests_tab__summary__borrower_reallocation__value"
        />
      )}
      <LabelAndValueWithPreload
        color={getValueColor({ isError: !isApproveReallocateComplete })}
        valueIcon={!isApproveReallocateComplete ? <WarningIcon size={14} /> : null}
        valueIconTooltip={valueErrorText}
        isLoading={controller.isUpdating}
        label={
          controller.localIsUserCreator ? 'Lender reallocation balance' : 'Reallocation balance'
        }
        text={currencyFormatter(approved_budget_change, '-')}
        textDataTestName="requests_tab__summary__lender_reallocation__value"
      />
    </>
  );
};

export const RetainageBalanceToDate = ({ isUpdating, totals, retainageRate, isInReview }) => {
  return (
    <LabelAndValueWithPreload
      isLoading={isUpdating}
      label={`Retainage balance to date ${retainageRate ? `(${retainageRate}%)` : ''}`}
    >
      <ProgressWithArrow
        showProgress
        prevValue={getTextOrDash(totals?.previous_retainage_balance_to_date)}
        nextValue={getTextOrDash(totals?.retainage_balance_to_date)}
        nextValueType={
          totals?.retainage_balance_to_date < 0
            ? { [ValueTypeEnum.IS_ERROR]: true }
            : { [ValueTypeEnum.IS_IN_REVIEW]: isInReview }
        }
        prevValueDataTestName="requests_tab__summary__retainage_balance__value"
        nextValueDataTestName="requests_tab__summary__retainage_balance__nextValue"
      />
    </LabelAndValueWithPreload>
  );
};

export const ApprovedToDate = ({ isUpdating, totals, isInReview, outOfBudgetError }) => (
  <LabelAndValueWithPreload
    isLoading={isUpdating}
    label="Approved to date"
    valueIcon={outOfBudgetError ? <WarningIcon size={14} /> : null}
  >
    <ProgressWithArrow
      showProgress
      prevValue={getTextOrDash(
        totals?.previous_approved_amount_cumulative,
        totals?.previous_lender_allowance_rate,
      )}
      nextValue={getTextOrDash(
        totals?.approved_amount_cumulative - totals?.approved_budget_change,
        totals?.lender_allowance_rate - totals?.approved_adjustments_rate,
      )}
      nextValueType={
        isInReview
          ? totals?.requested_budget_change || outOfBudgetError
            ? { [ValueTypeEnum.IS_ERROR]: true }
            : { [ValueTypeEnum.IS_IN_REVIEW]: true }
          : null
      }
      prevValueDataTestName="requests_tab__summary__approved_to_date__value"
      nextValueDataTestName="requests_tab__summary__approved_to_date__nextValue"
    />
  </LabelAndValueWithPreload>
);

export const BalanceToFinish = ({ isUpdating, totals, isInReview, outOfBudgetError }) => (
  <LabelAndValueWithPreload
    color={getValueColor({ isInReview })}
    isLoading={isUpdating}
    label="Balance to finish"
    valueIcon={outOfBudgetError ? <WarningIcon size={14} /> : null}
  >
    <ProgressWithArrow
      showProgress
      prevValue={getTextOrDash(
        totals?.balance_to_finish + (totals?.approved_amount - totals?.approved_budget_change),
        totals?.balance_to_finish_rate +
          (totals?.approved_amount_relative - totals?.approved_adjustments_rate),
      )}
      nextValue={getTextOrDash(totals?.balance_to_finish, totals?.balance_to_finish_rate)}
      nextValueType={
        isInReview
          ? totals?.requested_budget_change || outOfBudgetError
            ? { [ValueTypeEnum.IS_ERROR]: true }
            : { [ValueTypeEnum.IS_IN_REVIEW]: true }
          : null
      }
      prevValueDataTestName="requests_tab__summary__balance_to_finish__value"
      nextValueDataTestName="requests_tab__summary__balance_to_finish__nextValue"
    />
  </LabelAndValueWithPreload>
);

export const ProjectCompletionRate = ({ isUpdating, isInReview, totals }) => (
  <LabelAndValueWithPreload
    isLoading={isUpdating}
    label="Project completion"
    icon={<InfoIcon size={14} />}
    iconTooltip="Includes prefunding costs"
  >
    <ProgressWithArrow
      showProgress
      prevValue={percentFormatter({ value: totals?.previous_project_completion_rate })}
      nextValue={percentFormatter({ value: totals?.project_completion_rate })}
      nextValueType={isInReview ? { [ValueTypeEnum.IS_IN_REVIEW]: true } : null}
      prevValueDataTestName="requests_tab__summary__project_completion_rate__value"
      nextValueDataTestName="requests_tab__summary__project_completion_rate__nextValue"
    />
  </LabelAndValueWithPreload>
);

export const UserAndCompanyLabel: FC<{
  actionUser: ActionUser;
  label: string;
  isUpdating: boolean;
}> = ({ actionUser, label, isUpdating }) => {
  if (!actionUser) return null;
  return (
    <LabelAndValueWithPreload
      isLoading={isUpdating}
      label={label}
      text={`${actionUser?.full_name || ''} / ${actionUser?.company?.name || ''}`}
    />
  );
};
