import React, { FC } from 'react';
import { Box, Button, CardMedia, Stack } from '@mui/material';
import { CustomAutocomplete, InternationalPhoneField, CustomTextField, Popup } from '@components';
import * as Controller from './controller';
import { ICompanyFull } from '@interfaces';
import { states } from '@constants';

const CompanyInfoEditPopup: FC<{
  open: boolean;
  onClose: () => void;
  company: ICompanyFull;
  source: string;
}> = ({ open, onClose, company, source }) => {
  const {
    companyNameField,
    emailField,
    phoneNumberField,
    address,
    submitCompanyInfo,
    isSubmitting,
  } = Controller.useCompanyInfoEdit(onClose, company);
  const { stateField, address_1, cityField, zipCodeField } = address;

  return (
    <Popup open={open} maxWidth="sm" title="Edit company">
      <Stack sx={{ width: '100%', justifyContent: 'space-between', flex: 1 }}>
        <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          {company?.logo && (
            <Box sx={{ width: '100px', mr: 2 }}>
              <CardMedia
                component="img"
                height="100"
                image={company.logo}
                alt="Company logo"
                sx={{ width: 100, alignSelf: 'center', objectFit: 'contain' }}
              />
            </Box>
          )}
          <Stack spacing={4} width="100%">
            <CustomTextField
              fullWidth
              field={companyNameField}
              validationText="Company name is required"
              label="Company name"
              inputProps={{
                'data-cy': `${source}__company_info__edit_modal__name_input`,
              }}
            />
            <CustomTextField
              fullWidth
              field={emailField}
              validationText="Invalid email"
              label="Email"
              inputProps={{
                'data-cy': `${source}__company_info__edit_modal__email_input`,
              }}
            />
            <InternationalPhoneField
              field={phoneNumberField}
              inputProps={{
                'data-cy': `${source}__company_info__edit_modal__phone_input`,
              }}
            />
            <CustomTextField
              field={address_1}
              label="Address"
              required
              inputProps={{
                'data-cy': `${source}__company_info__edit_modal__address_1_input`,
              }}
            />
            <CustomTextField
              field={cityField}
              label="City"
              required
              inputProps={{
                'data-cy': `${source}__company_info__edit_modal__city_input`,
              }}
            />
            <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
              <CustomAutocomplete
                field={stateField}
                label="State"
                options={states}
                required
                inputProps={{
                  'data-cy': `${source}__company_info__edit_modal__state_input`,
                }}
              />
              <CustomTextField
                field={zipCodeField}
                label="Zip code"
                validationText="Please enter a valid 5-digit zip code"
                required
                inputProps={{
                  'data-cy': `${source}__company_info__edit_modal__zip_code_input`,
                }}
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ mt: 4 }}>
          <Button
            variant="text"
            onClick={onClose}
            data-cy={`${source}__company_info__edit_modal__cancel_button`}
          >
            Cancel
          </Button>
          <Button
            loading={isSubmitting}
            onClick={() => submitCompanyInfo()}
            disabled={
              isSubmitting ||
              !companyNameField.isValid ||
              !emailField.isValid ||
              !phoneNumberField.isValid
            }
            data-cy={`${source}__company_info__edit_modal__save_button`}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </Popup>
  );
};

export default CompanyInfoEditPopup;
