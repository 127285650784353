import { Stack, Typography } from '@mui/material';
import { colors } from '@theme';

import React from 'react';
import { IconWithProjectPermission } from '@components';
import { IDrawRequest, PermissionNamesEnums } from '@interfaces';
import {
  isRequestCompleted,
  WithPermission,
  isInactiveProject,
  isAllowed,
  ColumnType,
} from '@utils';

const coordinator: ColumnType<IDrawRequest> = ({ updateAssignCoordinatorPopup }) => ({
  field: 'coordinator.full_name',
  graphFields: ['status', 'id'],
  graphNested: {
    project: ['status', 'id'],
    coordinator: ['full_name'],
  },
  headerName: 'Coordinator',
  minWidth: 150,
  groupable: false,
  aggregable: false,
  enableByPermissions: (permissions) =>
    isAllowed(PermissionNamesEnums.DRAWREQUESTS_COORDINATOR_VIEW, permissions),
  renderCell: ({ row }) => {
    const openPopupCallback = () => updateAssignCoordinatorPopup?.(row);
    return (
      <Stack flexDirection="row" alignItems="center">
        <Typography variant="body3SemiBold" sx={{ color: colors.text.link }}>
          {row.coordinator?.full_name || ''}
        </Typography>

        {!isRequestCompleted(row.original?.status) && (
          <WithPermission permissionKey={PermissionNamesEnums.DRAWREQUESTS_COORDINATOR_EDIT}>
            <IconWithProjectPermission
              permissionKey={PermissionNamesEnums.DRAWREQUESTS_COORDINATOR_EDIT}
              projectId={row.project.id || row.id}
              openPopupCallback={openPopupCallback}
              isCurrentProjectArchived={isInactiveProject(
                row.original?.project?.status || row.status,
              )}
              source="requests_queue__draw_coordinator_edit"
            />
          </WithPermission>
        )}
      </Stack>
    );
  },
});

export default coordinator;
