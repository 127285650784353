import { PermissionNamesEnums, QueryNamesEnums, TeamRolesEnum } from '@interfaces';
import { FilterObject } from '../interface';
import { basicFilterSerializer } from '@utils';

export const investorFilter: FilterObject = {
  title: 'Investor',
  filterKey: 'investor',
  needToUpdateUrl: true,
  needToUpdateSetting: true,
  permissionKey: PermissionNamesEnums.INVESTOR_VIEW,
  getDataParamsPaginated: ({ pagination, q, skip, id }) => ({
    type: QueryNamesEnums.GET_COMPANIES_BY_ROLE,
    keys: ['id', 'name'],
    args: {
      role: TeamRolesEnum.INVESTOR,
      pagination,
      q,
      id,
    },
    options: {
      skip,
    },
  }),
  strictSerialize: (data) => [
    {
      value: 'null',
      label: 'Unassigned',
    },
    ...basicFilterSerializer(data),
  ],
  cypressSelector: 'filters__investor',
  withPagination: true,
};
