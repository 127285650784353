import { FilterObject } from '../interface';
import { PermissionNamesEnums } from '@interfaces';

export const servicesTypeFilter: FilterObject = {
  title: 'Service type',
  filterKey: 'service_type',
  permissionKey: PermissionNamesEnums.INSPECTIONS_VIEW,
  needToUpdateUrl: true,
  needToUpdateSetting: true,
  getStaticValues: ({ settings }) =>
    Array.isArray(settings.display?.service_types)
      ? settings.display?.service_types.map((item) => ({
          value: item.name,
          label: item.name_display,
        }))
      : [],
  cypressSelector: 'filters__services_list_type',
  withPagination: true,
};
