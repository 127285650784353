import React, { FC } from 'react';
import { Tooltip, Typography } from '@mui/material';

import { StatusChip } from '@components';

import * as Controller from './controller';
import { UpdateProjectStatus } from './components';

const ProjectStatusClicableChip: FC = () => {
  const {
    changeStatusComponent,
    valuesBasedOnPermissions,
    statusValue,
    isCurrentProjectActive,
    statusChangeReason,
  } = Controller.useProjectOptions();

  return (
    <>
      <Tooltip
        title={
          isCurrentProjectActive
            ? ''
            : statusChangeReason && (
                <>
                  Reason:{' '}
                  <Typography
                    variant="body3"
                    dangerouslySetInnerHTML={{ __html: statusChangeReason }}
                  />
                </>
              )
        }
      >
        <span>
          <StatusChip
            color={statusValue.color}
            backgroundColor={statusValue.backgroundColor}
            label={statusValue.text}
            size="medium"
            onClick={() => changeStatusComponent.handleOpen(true)}
          />
        </span>
      </Tooltip>

      <UpdateProjectStatus
        open={changeStatusComponent.open}
        handleOpen={changeStatusComponent.handleOpen}
        valuesBasedOnPermissions={valuesBasedOnPermissions}
      />
    </>
  );
};

export default ProjectStatusClicableChip;
