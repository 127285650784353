import React, { FC, useState } from 'react';
import { IconButton, Stack } from '@mui/material';
import { AuditLog, auditLogTypeFilter, FilterButton } from '@components';
import { SortIcon } from '@svgAsComponents';
import { colors } from '@theme';

const LineItemAuditLog: FC<{
  projectId: string;
  milestoneId: string;
}> = ({ projectId, milestoneId }) => {
  const [filters, setFilters] = useState<string[]>([]);
  const [sorting, setSorting] = useState<boolean>(false);

  const handleFiltersChange = (value: string[]) => {
    setFilters(value);
  };

  const handleSortClick = () => {
    setSorting((old) => !old);
  };

  return (
    <>
      <Stack direction="row" spacing={1}>
        <FilterButton
          handleFiltersChange={(_key, value) => {
            handleFiltersChange(value);
          }}
          initValues={filters}
          fixedWidth="164px"
          closeOnChange
          {...auditLogTypeFilter}
        />
        <IconButton
          onClick={handleSortClick}
          sx={sorting ? { paddingRight: 0 } : { transform: ['scaleY(-1)'], paddingRight: 0 }}
          data-cy={'line_item__right_drawer__switcher__audit_log__sort__icon'}
          size="small"
        >
          <SortIcon color={colors.icons.gray} size={16} />
        </IconButton>
      </Stack>
      <AuditLog
        projectId={projectId}
        milestoneId={milestoneId}
        sorting={sorting}
        filters={filters}
        source="line_item__right_drawer__audit_log"
      />
    </>
  );
};

export default LineItemAuditLog;
