import { useMemo, useContext } from 'react';

import { PermissionNamesEnums } from '@interfaces';
import { isAllowed } from '@utils';
import { SettingsContext, PermissionsContext } from '@context';

interface ControllerInterface {
  isAllProjectDetailsDisabled: boolean;
  isLimitedProjectDetailsDisabled: boolean;
  hasInspectionsViewPermission: boolean;
  hasInspectionsEditPermission: boolean;
}

export const useProjectSettings = (): ControllerInterface => {
  const { permissions } = useContext(PermissionsContext);
  const { isCurrentProjectArchived } = useContext(SettingsContext);

  const hasInspectionsViewPermission = useMemo(
    () => isAllowed(PermissionNamesEnums.INSPECTIONS_VIEW, permissions),
    [permissions],
  );

  const hasInspectionsEditPermission = useMemo(
    () => isAllowed(PermissionNamesEnums.INSPECTIONS_EDIT, permissions),
    [permissions],
  );

  // permission projects_details_edit (mostly for lenders)
  const isAllProjectDetailsDisabled = useMemo(
    () =>
      isCurrentProjectArchived ||
      !isAllowed(PermissionNamesEnums.PROJECTS_DETAILS_EDIT, permissions),
    [permissions, isCurrentProjectArchived],
  );
  // permission projects_edit (mostly for borrowers)
  const isLimitedProjectDetailsDisabled = useMemo(
    () => isCurrentProjectArchived || !isAllowed(PermissionNamesEnums.PROJECTS_EDIT, permissions),
    [permissions, isCurrentProjectArchived],
  );

  return {
    isAllProjectDetailsDisabled,
    isLimitedProjectDetailsDisabled,
    hasInspectionsViewPermission,
    hasInspectionsEditPermission,
  };
};
