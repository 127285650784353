import React from 'react';
import {
  Autocomplete,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';

import { HookState } from '@interfaces';
import { LoadingSkeleton, ServiceMessage, StyledBox } from '@components';
import * as Controller from './controller';
import isEmpty from 'lodash/isEmpty';
import { useLaunchDarklyFlags } from '@context';
import { typography } from '@theme';

const CompanyOtherSettings = () => {
  const controller = Controller.useCompanyOtherSettings();
  const {
    state,
    changeRequestConfiguration,
    options,
    companyUsers,
    companyUpdate,
    hasPoliciesEditPermission,
    defaultCoordinator,
    defaultCoordinatorUpdate,
    hasCoordinatorEditPermission,
    isInspectionLumpSumEnabled,
    updateInspectionLumpSumEnabled,
  } = controller;
  const flags = useLaunchDarklyFlags();

  switch (state) {
    case HookState.LOADING: {
      return (
        <StyledBox sx={{ flex: 1 }}>
          <LoadingSkeleton type="overviewBlock" />
        </StyledBox>
      );
    }

    case HookState.ERROR: {
      return <ServiceMessage text="Company Other Settings" />;
    }

    case HookState.SUCCESS: {
      return (
        <Stack>
          <Stack sx={{ flexDirection: 'row' }}>
            {flags?.['ENG_9584_default_company_coordinator'] && (
              <StyledBox sx={{ flex: 1, mr: 2, p: 3 }}>
                <Typography variant="h3">Default coordinator</Typography>
                <Autocomplete
                  size="small"
                  options={companyUsers}
                  getOptionLabel={(option) => option.label}
                  onChange={(_, value) => defaultCoordinatorUpdate(value.value)}
                  value={
                    isEmpty(defaultCoordinator)
                      ? null
                      : { label: defaultCoordinator?.full_name, value: defaultCoordinator?.id }
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="default-company-coordinator"
                      label="Default company coordinator"
                      variant="outlined"
                      inputProps={{
                        ...params?.inputProps,
                        'data-cy': 'company__other_settings__default_company_coordinator__input',
                      }}
                    />
                  )}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  disabled={!hasCoordinatorEditPermission}
                  sx={{ minWidth: 200, maxWidth: 480, width: '100%', mt: 3 }}
                />
              </StyledBox>
            )}
            {flags?.['ENG_9252_change_request_configuration_options'] && (
              <StyledBox sx={{ flex: 1, p: 3 }}>
                <Typography variant="h3">Change request</Typography>
                <Autocomplete
                  size="small"
                  options={options}
                  getOptionLabel={(option) => option.label}
                  onChange={(_, value) => companyUpdate(value.value)}
                  value={changeRequestConfiguration}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="change-request-configuration"
                      label="Change request configuration"
                      variant="outlined"
                      inputProps={{
                        ...params?.inputProps,
                        'data-cy': 'company__other_settings__change_request_configuration__input',
                      }}
                    />
                  )}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  disabled={!hasPoliciesEditPermission}
                  sx={{ minWidth: 200, maxWidth: 480, width: '100%', mt: 3 }}
                />
              </StyledBox>
            )}
          </Stack>
          {flags?.['ENG_10009_inspection_lump_sum'] && (
            <StyledBox sx={{ flex: 1, p: 3, mt: 2, maxWidth: '49.5%' }}>
              <Typography variant="h3">Single-entry inspections</Typography>
              <Typography variant="body1" sx={{ mt: 1 }}>
                Allow inspectors to provide a total inspection allowance instead of per line item
                entries.
              </Typography>
              <FormControlLabel
                sx={{
                  mt: 2,
                  '& .MuiTypography-root': {
                    ...typography.body3,
                  },
                }}
                control={
                  <Switch
                    sx={{ mr: 1 }}
                    checked={isInspectionLumpSumEnabled}
                    onChange={(_, value) => updateInspectionLumpSumEnabled(value)}
                    data-cy="company__other_settings__single_total_inspection_allowance__switch"
                  />
                }
                label="Enable single total inspection allowance"
              />
            </StyledBox>
          )}
        </Stack>
      );
    }

    default:
      return null;
  }
};

export default CompanyOtherSettings;
