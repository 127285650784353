import React from 'react';
import { CellRenderWithError } from './common';
import { currencyFormatter, currencyToNumber } from '@utils';
import { CurrencyInput } from '@components';
import { isFooterRow, MilestoneListColumnTypeV2 } from '../columnsV2/common';
import { GridEditInputCell } from '@mui/x-data-grid';
import { InputBaseComponentProps } from '@mui/material';

const approvedAdjustments: MilestoneListColumnTypeV2 = {
  field: 'approved_adjustments',
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Approved +/- change ($)',
  description: 'Approved changes to revised scheduled value for this request',
  editableByMilestone: (row) => row.activeToEdit && row?.nonBorrower,
  editableByPermissions: (_pr) => true,
  valueParser: (value) => currencyToNumber(value, true),
  renderEditCell: (props) => (
    <GridEditInputCell
      {...props}
      inputComponent={CurrencyInput as unknown as React.ElementType<InputBaseComponentProps>}
    />
  ),
  renderCell: (params) => <CellRenderWithError params={params} />,
  valueFormatter: (value, row) =>
    currencyFormatter(isFooterRow(row) ? row.approved_budget_change : value, ''),
  minWidth: 120,
};

export default approvedAdjustments;
