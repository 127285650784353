import React from 'react';
import { ColumnType } from '@utils';
import { IServiceOrder } from '@interfaces';

const loanNumber: ColumnType = () => ({
  field: 'project.loan.external_id',
  renderCell: ({ row }: { row: Partial<IServiceOrder> }) => (
    <span data-cy="requests_queue__table__body__loan_number">{row.project?.loan?.external_id}</span>
  ),
  graphFields: [],
  graphNested: {
    'project.loan': ['external_id'],
  },
  headerName: 'Loan #',
  minWidth: 160,
  groupable: false,
  sortable: false,
  aggregable: false,
});

export default loanNumber;
