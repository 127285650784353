import React, { FC } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { CustomCurrencyTextField, CustomRadioGroup, CustomTextField, Popup } from '@components';
import { EditFundingSourceProps } from './interface';
import { useEditFundingSourceModal } from './controller';
import { AddProofpointIcon, EditIcon } from '@svgAsComponents';
import { FundingSourceStatusEnum, FundingSourceTypeEnum } from '@interfaces';

const EditFundingSourceModal: FC<EditFundingSourceProps> = ({
  open,
  onClose,
  activeFundingSource,
  title,
  handleSave,
  source,
}) => {
  const { name, total, is_active, is_transactable, isNew, internal_identifier } =
    activeFundingSource;
  const {
    sourceTypeOptions,
    sourceStatusOptions,
    nameField,
    amountField,
    sourceStatus,
    sourceType,
    setSourceType,
  } = useEditFundingSourceModal({
    name,
    value: internal_identifier ? activeFundingSource.field?.value : total?.toString(),
    is_active,
    is_transactable,
  });

  if (!open) return null;

  return (
    <Popup
      open={open}
      title={`${isNew ? 'Add' : 'Edit'} ${title || name}`}
      icon={isNew ? AddProofpointIcon : EditIcon}
    >
      <Stack sx={{ width: '100%' }}>
        <Stack sx={{ mt: 4 }} spacing={2}>
          <CustomTextField
            field={nameField}
            label="Source name"
            required
            disabled={Boolean(internal_identifier)}
            inputProps={{
              'data-cy': `${source}__funding_source_modal__source_name__input`,
            }}
          />
          <Stack direction="row" alignItems="center" sx={{ pt: 2 }}>
            <Box sx={{ width: 160, pl: 2, pb: 3 }}>
              <Typography variant="body3">Source status</Typography>
            </Box>
            <CustomRadioGroup
              disabled
              dataTestName={`${source}__funding_source_modal__source_status__radio_group`}
              value={sourceStatus}
              onChange={() => {}}
              options={sourceStatusOptions}
            />
          </Stack>
          <Stack direction="row" alignItems="center">
            <Box sx={{ width: 160, pl: 2, pb: 3 }}>
              <Typography variant="body3">Source type</Typography>
            </Box>
            <CustomRadioGroup
              disabled={!isNew}
              dataTestName={`${source}__funding_source_modal__source_type__radio_group`}
              value={sourceType}
              onChange={(event) => setSourceType(event.target.value as FundingSourceTypeEnum)}
              options={sourceTypeOptions}
            />
          </Stack>
          <CustomCurrencyTextField
            label="Amount ($)"
            field={amountField}
            inputProps={{
              'data-cy': `${source}__funding_source_modal__amount__input`,
            }}
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mt: 7.5 }}
          spacing={1}
        >
          <Button
            variant="text"
            onClick={() => {
              onClose();
              nameField.setValue('');
              amountField.setValue('');
            }}
            data-cy={`${source}__funding_source_modal__cancel__button`}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (amountField.isValid) {
                handleSave({
                  ...activeFundingSource,
                  name: nameField.value,
                  total: +amountField.value,
                  is_active: sourceStatus === FundingSourceStatusEnum.ACTIVE,
                  is_transactable: sourceType === FundingSourceTypeEnum.BORROWER_EQUITY,
                  colorKey:
                    sourceType === FundingSourceTypeEnum.BORROWER_EQUITY ? 'violet' : 'yellow',
                });
                onClose();
                nameField.setValue('');
                amountField.setValue('');
              }
            }}
            data-cy={`${source}__funding_source_modal__save__button`}
          >
            {isNew ? 'Add' : 'Save'}
          </Button>
        </Stack>
      </Stack>
    </Popup>
  );
};

export default EditFundingSourceModal;
