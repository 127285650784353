import { Dispatch, useContext, useEffect, useMemo, useState } from 'react';
import { useMutation, useQueries, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import {
  FundingSourceInternalIdEnums,
  HookState,
  IDrawRequest,
  PatchDrawRequestParam,
  PermissionNamesEnums,
  QueryNamesEnums,
} from '@interfaces';
import { getDrawRequestSources, patchDrawRequest } from '@globalService';
import {
  calculateFraction,
  currencyFormatter,
  getFundingSource,
  getHookState,
  getRequestRetainageRate,
  isAllowed,
  isRequestActive,
  isRequestApproved,
  isRequestCompleted,
  isRequestInReview,
  Override,
  percentFormatter,
} from '@utils';
import { PermissionsContext, useGraphQuery, useLaunchDarklyFlags } from '@context';
import { useSafeSnackbar, useDayJsFormatter, useProjectPaymentData } from '@hooks';
import { DateFieldModel, StringFieldModel, useDateFieldModel, useStringFieldModel } from '@models';

type TransactionDateIdPopupData = {
  external_transaction_id?: string;
  disbursed_at?: Date;
};

export type ControllerInterface = Override<
  ReturnType<typeof useRequestPaymentSummary>,
  {
    state: HookState;
    borrowerEquity: number;
    constructionHoldback: number;
    feesAmount: number;
    requestProportionText: string;
    drawRequest: IDrawRequest;
    paymentSummaryModalData: PaymentSummaryModalDataEnum | null;
    setPaymentSummaryModalData: Dispatch<React.SetStateAction<PaymentSummaryModalDataEnum | null>>;
    showDisbursement: boolean;
    setShowDisbursement: Dispatch<React.SetStateAction<boolean>>;
    retainageRate: number;
    progressValues: {
      prevRetainageBalance: string;
      retainageBalance: string;
      requestedAmount: string;
      approvedAmount: string;
    };
    isInReview: boolean;
    transactionDate: string;
    transactionId: string;
    canEditFees: boolean;
    canEditProportion: boolean;
    isRequestApprovedOrCompleted: boolean;
    handleSubmit: ({ external_transaction_id, disbursed_at }: TransactionDateIdPopupData) => void;
    transactionDateField: DateFieldModel;
    transactionIdField: StringFieldModel;
    isLoading: boolean;
  }
>;

export enum PaymentSummaryModalDataEnum {
  TRANSACTION_DATE = 'transaction_date',
  TRANSACTION_ID = 'transaction_id',
  FEES = 'fees',
  PROPORTION = 'proportion',
}

export const useRequestPaymentSummary = () => {
  const { projectId, drawRequestId } = useParams();
  const [paymentSummaryModalData, setPaymentSummaryModalData] =
    useState<PaymentSummaryModalDataEnum | null>(null);
  const { borrowerEquityFraction, constructionHoldbackFraction } = useProjectPaymentData();

  const [showDisbursement, setShowDisbursement] = useState(false);
  const { permissions } = useContext(PermissionsContext);
  const { dateFormatter, getInitialValue } = useDayJsFormatter();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();
  const flags = useLaunchDarklyFlags();

  const drawRequestQuery = useGraphQuery({
    type: QueryNamesEnums.GET_DRAW_REQUEST_V2,
    keys: [
      'id',
      'approved_amount',
      'borrower_equity_rate',
      'borrower_equity',
      'construction_holdback_rate',
      'construction_holdback',
      'disbursed_at',
      'external_transaction_id',
      'fees_amount',
      'is_on_hold_change_reason',
      'is_on_hold',
      'is_resubmit_change_reason',
      'is_resubmit',
      'project',
      'requested_amount',
      'status',
      'submitted_at',
      'type',
    ],
    nested: {
      totals: ['all'],
    },
    args: {
      project_id: projectId,
      draw_request_id: drawRequestId,
    },
  });

  const drawRequest = drawRequestQuery.data;

  const requestedDrawRequestQueries = useQueries([
    {
      queryKey: [QueryNamesEnums.GET_DRAW_REQUEST_FUNDING_SOURCES, { projectId, drawRequestId }],
      queryFn: getDrawRequestSources.bind(this, projectId, drawRequestId),
      enabled: Boolean(projectId && drawRequestId),
    },
  ]);

  const fundingSources = useMemo(
    () => requestedDrawRequestQueries[0]?.data?.results,
    [requestedDrawRequestQueries[0]?.data?.results],
  );

  const borrowerEquityFS = useMemo(
    () =>
      getFundingSource({
        fundingSources,
        internalId: FundingSourceInternalIdEnums.BORROWER_EQUITY,
      }),
    [fundingSources],
  );

  const borrowerEquity = useMemo(
    () =>
      flags?.['ENG_9697_funding_source_payment_tab']
        ? (borrowerEquityFS?.amount ?? 0)
        : drawRequest?.borrower_equity,
    [
      drawRequest?.borrower_equity,
      borrowerEquityFS,
      flags?.['ENG_9697_funding_source_payment_tab'],
    ],
  );

  const constructionHoldbackFS = useMemo(
    () =>
      getFundingSource({
        fundingSources,
        internalId: FundingSourceInternalIdEnums.CONSTRUCTION_HOLDBACK,
      }),
    [fundingSources],
  );

  const constructionHoldback = useMemo(
    () =>
      flags?.['ENG_9697_funding_source_payment_tab']
        ? (constructionHoldbackFS?.amount ?? 0)
        : drawRequest?.construction_holdback,
    [
      drawRequest?.construction_holdback,
      constructionHoldbackFS,
      flags?.['ENG_9697_funding_source_payment_tab'],
    ],
  );

  const feesAmount = useMemo(() => drawRequest?.fees_amount || 0, [drawRequest]);

  const requestProportionText = useMemo(() => {
    if (flags?.['ENG_9697_funding_source_payment_tab']) {
      if (!drawRequest?.approved_amount) {
        const proportion = `${percentFormatter({ value: constructionHoldbackFraction })} / ${percentFormatter({ value: borrowerEquityFraction })}`;
        return `${proportion} Loan to equity`;
      }
      const total = constructionHoldback + borrowerEquity;
      const CHProportion = calculateFraction(constructionHoldback, total);
      const BEProportion = calculateFraction(borrowerEquity, total);
      const proportion = `${percentFormatter({ value: CHProportion })}/${percentFormatter({ value: BEProportion })}`;
      return `${proportion} Loan to equity`;
    }
    return `${percentFormatter({ value: drawRequest?.construction_holdback_rate })}/${percentFormatter({ value: drawRequest?.borrower_equity_rate })} Loan to equity`;
  }, [
    drawRequest,
    constructionHoldback,
    borrowerEquity,
    flags,
    constructionHoldbackFraction,
    borrowerEquityFraction,
  ]);

  const progressValues = useMemo(
    () => ({
      prevRetainageBalance: currencyFormatter(
        drawRequest?.totals?.all?.previous_retainage_balance_to_date || 0,
      ),
      retainageBalance: currencyFormatter(drawRequest?.totals?.all?.retainage_balance_to_date || 0),
      requestedAmount: currencyFormatter(drawRequest?.requested_amount || 0),
      approvedAmount: currencyFormatter(drawRequest?.approved_amount || 0),
    }),
    [drawRequest],
  );

  const patchDrawRequestMutation = useMutation<Response, Error, PatchDrawRequestParam>(
    patchDrawRequest,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST);
        queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_V2);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const handleSubmit = ({ external_transaction_id, disbursed_at }: TransactionDateIdPopupData) => {
    patchDrawRequestMutation.mutateAsync({
      id: projectId,
      drawRequest: drawRequestId,
      ...(external_transaction_id && { external_transaction_id }),
      ...(disbursed_at && { disbursed_at: disbursed_at.toISOString() }),
    });
    setPaymentSummaryModalData(null);
  };

  const retainageRate = useMemo(() => getRequestRetainageRate(drawRequest), [drawRequest]);

  const isInReview = useMemo(() => isRequestInReview(drawRequest?.status), [drawRequest]);

  const isActiveDrawRequest = useMemo(
    () => isRequestActive(drawRequest?.status),
    [drawRequest?.status],
  );

  const transactionDate = useMemo(
    () => dateFormatter({ date: drawRequest?.disbursed_at }),
    [drawRequest],
  );

  const transactionId = useMemo(() => drawRequest?.external_transaction_id, [drawRequest]);

  const canEditFees = useMemo(
    () => isActiveDrawRequest && isAllowed(PermissionNamesEnums.PAYMENTS_MARK_AS_PAID, permissions),
    [isActiveDrawRequest, permissions],
  );

  // allow edit proportion if the draw request is active
  // and the user has the permission to edit the project details
  // and the draw request has an approved amount
  // and the borrower equity and construction holdback are set
  const canEditProportion = useMemo(
    () =>
      isActiveDrawRequest &&
      isAllowed(PermissionNamesEnums.PROJECTS_DETAILS_EDIT, permissions) &&
      Boolean(drawRequest?.approved_amount) &&
      Boolean(borrowerEquityFS?.amount) &&
      Boolean(constructionHoldbackFS?.amount),
    [isActiveDrawRequest, permissions, borrowerEquityFS, constructionHoldbackFS, drawRequest],
  );

  const isRequestApprovedOrCompleted = useMemo(
    () => isRequestApproved(drawRequest?.status) || isRequestCompleted(drawRequest?.status),
    [drawRequest?.status],
  );

  const transactionDateField = useDateFieldModel({
    initValue: transactionDate ? getInitialValue({ date: transactionDate }) : null,
  });

  const transactionIdField = useStringFieldModel({
    initValue: transactionId,
  });

  useEffect(() => {
    transactionDateField.setValue(transactionDate ? new Date(transactionDate) : null);
  }, [transactionDate]);

  useEffect(() => {
    transactionIdField.setValue(transactionId);
  }, [transactionId]);

  return {
    state: getHookState(requestedDrawRequestQueries),
    borrowerEquity,
    constructionHoldback,
    feesAmount,
    requestProportionText,
    drawRequest,
    paymentSummaryModalData,
    setPaymentSummaryModalData,
    retainageRate,
    progressValues,
    isInReview,
    transactionDate,
    transactionId,
    canEditFees,
    canEditProportion,
    showDisbursement,
    setShowDisbursement,
    isRequestApprovedOrCompleted,
    handleSubmit,
    transactionDateField,
    transactionIdField,
    isLoading: patchDrawRequestMutation.isLoading,
  };
};
