import { SxProps } from '@mui/system';
import { ReactElement } from 'react';
import { PopperPlacementType } from '@mui/material/Popper';

export interface ComponentProps {
  wrapperStyles?: SxProps;
  containerStyles?: SxProps;
  listStyles?: SxProps;
  title: ReactElement;
  widgetType: string;
  titleBackgroundColor: string;
  closeOnClickAway?: boolean;
  initiallyOpened?: boolean;
  shouldSaveState?: boolean;
  showIcon?: boolean;
  dataTestNameButton?: string;
  popperWidth?: string;
  isWidgetOpen?: boolean;
  onWidgetOpened?: () => void;
  onWidgetClosed?: () => void;
  placement?: PopperPlacementType;
}

export const defaultProps = {
  listStyles: {
    position: 'absolute',
    width: '510px ',
    right: { md: 0, xs: 'auto' },
    padding: '16px',
    filter:
      'drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.12)) drop-shadow(0px 6px 12px rgba(0, 0, 0, 0.12)) drop-shadow(0px 1px 16px rgba(0, 0, 0, 0.12))',
  },
  containerStyles: { pl: '8px', pt: '16px' },
  closeOnClickAway: true,
  initiallyOpened: false,
  shouldSaveState: false,
  showIcon: true,
  popperWidth: '372px',
  placement: 'bottom-end' as PopperPlacementType,
};
