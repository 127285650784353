import { useCallback, useEffect, useMemo, useState } from 'react';
import { ILocalFee, QueryNamesEnums } from '@interfaces';
import { useParams } from 'react-router-dom';
import { useGraphQuery } from '@context';

export interface IFeesControllerInterface {
  addFee: () => void;
  fees: ILocalFee[];
  deleteFee: (index: number) => void;
  updateFee: (key: keyof ILocalFee, value: string | number, index: number) => void;
  isFeesUpdated: boolean;
  isFeesLoading: boolean;
}

export const useFees = (): IFeesControllerInterface => {
  const { projectId, drawRequestId } = useParams();
  const [fees, setFees] = useState<ILocalFee[]>([]);

  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['fees'],
    args: { project_id: projectId },
    options: {
      skip: !projectId,
    },
  });

  const drawRequest = useGraphQuery({
    type: QueryNamesEnums.GET_DRAW_REQUEST_V2,
    keys: ['fees'],
    args: { project_id: projectId, draw_request_id: drawRequestId },
    options: {
      skip: !projectId || !drawRequestId,
    },
  });

  const createFeesObject = (fee) => ({
    ...fee,
    isNew: false,
  });

  const fetchedFees = useMemo(() => {
    const paymentConfig = drawRequest.data || project.data;
    return paymentConfig?.fees?.map(createFeesObject) || [];
  }, [drawRequest.data?.fees, project.data?.fees]);

  useEffect(() => {
    const isDataFetched = drawRequestId
      ? !drawRequest.isPostLoading && drawRequest.isSuccess
      : !project.isPostLoading && project.isSuccess;
    if (isDataFetched) {
      setFees(fetchedFees);
    }
  }, [
    fetchedFees,
    drawRequest.isSuccess,
    project.isSuccess,
    drawRequest.isPostLoading,
    project.isPostLoading,
  ]);

  const addFee = () =>
    setFees((fees) => [
      ...fees,
      {
        name: '',
        amount: null,
        isNew: true,
      },
    ]);

  const updateFee = (key: keyof ILocalFee, value: string | number, index: number) =>
    setFees((fees) => Object.assign([...fees], { [index]: { ...fees[index], [key]: value } }));

  const deleteFee = useCallback(
    (index) => {
      const newFees = fees.filter((_fee, i) => i !== index);
      setFees(newFees);
    },
    [fees, projectId],
  );

  const isFeesUpdated = useMemo(() => {
    return JSON.stringify(fetchedFees) !== JSON.stringify(fees);
  }, [fetchedFees, fees]);

  return {
    addFee,
    fees,
    deleteFee,
    updateFee,
    isFeesUpdated,
    isFeesLoading: project.isPostLoading,
  };
};
