import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { matchPath, Navigate, Outlet, useLocation, useSearchParams } from 'react-router-dom';

import {
  LoadingSkeleton,
  NewAppVersionMessage,
  NoTeamEnrollmentPage,
  PageLayoutContentWrapper,
  PermissionDenied,
  ScheduledMaintenanceBanner,
  StyledBox,
  TermsOfServiceModal,
  VerifyBorrowerModal,
} from '@components';
import { getTeamRole } from '@utils';
import { HookState } from '@interfaces';
import { LaunchdarklyProvider } from '@context';
import { useMobileWarning } from '@hooks';
import { ComponentProps } from './interface';
import { ContentBody } from './Content';
import * as Controller from './controller';
import AppToolbar from './AppToolbar';

function PageLayout({ screensACL, updateAvailable }: ComponentProps) {
  const controller = Controller.usePageLayout();
  const { permissionDenied, state, user } = controller;

  const { MobileWarningComponent } = useMobileWarning();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const paramsToRemove = ['sso_domain_name', 'sso_access_token', 'set_password_email', 'iss'];
  const cleanedSearch = Array.from(searchParams.entries())
    .filter(([key]) => !paramsToRemove.includes(key))
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
  const finalSearch = cleanedSearch ? `?${cleanedSearch}` : '';

  const useEncodedSearchParams = (params) => {
    return params.reduce((acc, param) => {
      const value = searchParams.get(param);
      if (value) acc[param] = encodeURIComponent(value);
      return acc;
    }, {});
  };
  const { sso_domain_name, sso_access_token, set_password_email } = useEncodedSearchParams([
    'sso_domain_name',
    'sso_access_token',
    'set_password_email',
  ]);

  useEffect(() => {
    if (set_password_email) {
      // we use local storage here (not session storage) because we want to keep the original url between tabs and sessions
      // after the user will get link to set password and then will go to our origin from email
      localStorage.setItem('originalUrl', JSON.stringify(pathname));
    }
  }, [set_password_email]);

  const match = matchPath({ path: '/:tab', end: false }, pathname);

  const teamRole = getTeamRole(user);

  if (MobileWarningComponent) return <MobileWarningComponent />;

  if (!user?.isAllowedToLogin && sso_domain_name && sso_access_token)
    return (
      <Navigate
        to={`/login-with-sso?sso_access_token=${sso_access_token}&sso_domain_name=${sso_domain_name}`}
        state={{ from: `${pathname}${finalSearch}` }}
      />
    );

  if (!user?.isAllowedToLogin && set_password_email)
    return <Navigate to={`/forgot-password?set_password_email=${set_password_email}`} />;

  if (!user?.isAllowedToLogin)
    return <Navigate to="/login" state={{ from: `${pathname}${finalSearch}` }} />;

  if (!teamRole) return <NoTeamEnrollmentPage />;

  if (!screensACL[teamRole]) return <Navigate to="/no-content" />;

  if (!screensACL[teamRole]?.available?.includes(match?.pathname))
    return <Navigate to={screensACL[teamRole]?.default} />;

  return (
    <LaunchdarklyProvider>
      <AppToolbar screensACL={screensACL} controller={controller} />
      <ContentBody>
        <Box sx={{ minHeight: 64 }} />
        <ScheduledMaintenanceBanner />
        {updateAvailable && <NewAppVersionMessage />}
        <PageLayoutContentWrapper>
          {state === HookState.LOADING && (
            <StyledBox>
              <LoadingSkeleton type="overviewBlock" />
            </StyledBox>
          )}
          {user?.agreed_to_terms && !permissionDenied && state !== HookState.LOADING && <Outlet />}
          {permissionDenied && <PermissionDenied />}
        </PageLayoutContentWrapper>
      </ContentBody>
      <TermsOfServiceModal />
      <VerifyBorrowerModal />
    </LaunchdarklyProvider>
  );
}

export default PageLayout;
