import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Divider, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { PopperPlacementType } from '@mui/material/Popper';
import { isMobileOnly } from 'react-device-detect';
import { BarChart, Checklist, CollapsibleWidget, DrawRequestOperationButtons } from '@components';
import { ChecklistIcon } from '@svgAsComponents';
import { colors } from '@theme';
import * as Controller from './controller';
import { WIDGET_TYPE } from '@constants';

const PROGRES_BAR_COLOR = colors.status.information.medium;

const PROGRES_BG_COLOR = colors.status.information.lighter;

type ProgressProps = {
  width: number;
  progress: number;
  barColor: string;
};

const Progress = React.memo(({ width = 100, progress, barColor }: ProgressProps) => (
  <Box sx={{ width }}>
    <BarChart
      stacked
      orientation="horizontal"
      values={[progress]}
      total={100}
      barWidth={8}
      borderRadius="2px"
      colors={[barColor, colors.disabled]}
    />
  </Box>
));

const LenderChecklist: FC<{
  requestType?: string;
  onWidgetOpened?: () => void;
  onClose?: () => void;
  isWidgetOpen: boolean;
  wideChecklistButton?: boolean;
  wrapperStyles?: SxProps;
  placement?: PopperPlacementType;
}> = ({
  requestType,
  onWidgetOpened,
  isWidgetOpen,
  onClose,
  wideChecklistButton,
  wrapperStyles,
  placement = 'bottom-start',
}) => {
  const { projectId } = useParams();
  const controller = Controller.useProjectChecklist(projectId, requestType);
  const {
    items,
    handleItemClick,
    showLenderChecklist,
    addChecklistItem,
    deleteChecklistItem,
    shouldShowComponent,
    drawRequestId,
    hideAllChecklists,
    hideActonButtons,
    project,
  } = controller;

  if (!shouldShowComponent) return null;

  return (
    <CollapsibleWidget
      title={<ChecklistTitle controller={controller} />}
      titleBackgroundColor={PROGRES_BG_COLOR}
      containerStyles={{
        minWidth: wideChecklistButton ? '510px' : '300px',
        padding: '7px 8px',
      }}
      listStyles={{
        minWidth: isMobileOnly ? '320px' : '510px',
        filter: 'drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.15))',
      }}
      widgetType={WIDGET_TYPE.CHECKLIST}
      closeOnClickAway={false}
      initiallyOpened
      shouldSaveState
      onWidgetOpened={onWidgetOpened}
      dataTestNameButton="project__checklist__expand_icon"
      placement={placement}
      isWidgetOpen={isWidgetOpen}
      onWidgetClosed={onClose}
      wrapperStyles={wrapperStyles}
    >
      {!hideAllChecklists && (
        <Box sx={{ overflow: 'auto', maxHeight: '40vh', mb: showLenderChecklist ? 2 : 0 }}>
          <Box>
            <Checklist
              items={items.filter((item) => !showLenderChecklist || !item.canBeDeleted)}
              onItemClick={handleItemClick}
              isEditable={false}
              showCheckedBy={false}
            />
            <Divider />
          </Box>

          {showLenderChecklist && (
            <>
              <Box sx={{ py: 2 }}>
                <Checklist
                  items={items.filter((item) => item.canBeDeleted)}
                  onItemClick={handleItemClick}
                  isEditable
                  addChecklistItem={addChecklistItem}
                  deleteChecklistItem={deleteChecklistItem}
                  showCheckedBy={false}
                />
              </Box>

              <Divider sx={{ mb: 2 }} />
            </>
          )}
        </Box>
      )}

      <DrawRequestOperationButtons
        checklistItems={items.filter((item) => !item.checked)}
        projectId={projectId}
        drawRequestId={drawRequestId}
        hideActonButtons={hideActonButtons}
        project={project}
      />
    </CollapsibleWidget>
  );
};

export default LenderChecklist;

const ChecklistTitle = ({ controller }) => {
  const { progress, totalItems, checkedItems, checklistTitle } = controller;
  return (
    <>
      <ChecklistIcon />
      <Box sx={{ mr: 0.5 }} />
      <Typography
        variant="labelSemiBold"
        sx={{ flex: 1, pr: '1rem' }}
        data-cy="request__action_popup__checklist__title"
      >
        {checklistTitle}
        {Boolean(totalItems) && ` (${checkedItems} / ${totalItems})`}
      </Typography>

      <Progress width={100} progress={progress} barColor={PROGRES_BAR_COLOR} />
    </>
  );
};
