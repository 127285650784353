import { useState, useContext, Dispatch, SetStateAction } from 'react';
import { useMutation } from 'react-query';
import { useUser, useLogin } from '@hooks';
import { ITokens, IUser } from '@interfaces';
import { createPassword } from '@globalService';
import { AuthContext } from '@context';

export interface LoginPayload {
  account_token?: string;
}
export interface ControllerInterface {
  showProgress: boolean;
  handleLogin: (params: LoginPayload) => Promise<ITokens>;
  handleCreatePassword: (password: string) => void;
  setUser: Dispatch<SetStateAction<IUser>>;
  accountToken: string;
}

export const useSetupPassword = (): ControllerInterface => {
  const accountToken = new URL(window.location.href).searchParams.get('accountToken');
  const { postLoginData } = useLogin({});

  const { setUser } = useContext(AuthContext);
  const { refetch } = useUser();
  const [showProgress, setShowProgress] = useState(false);

  const handleLogin = async (params) => {
    setShowProgress(true);
    try {
      return await postLoginData.mutateAsync(params);
    } catch (error) {
      console.warn(error);
    } finally {
      setShowProgress(false);
    }
  };

  const createPasswordPost = useMutation<Response, Error, { password }>(createPassword, {
    onSuccess: () => {
      refetch();
    },
  });

  const handleCreatePassword = async (password) => createPasswordPost.mutateAsync(password);

  return {
    showProgress,
    handleLogin,
    setUser,
    handleCreatePassword,
    accountToken,
  };
};
