import { useEffect, useMemo, useState, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useQuery } from 'react-query';
import find from 'lodash/find';
import { useLocation, useParams } from 'react-router-dom';

import {
  DateFieldModel,
  DropdownFieldModel,
  PhoneFieldModel,
  StringFieldModel,
  BooleanFieldModel,
  useDateFieldModel,
  useDropdownFieldModel,
  usePhoneFieldModel,
  useStringFieldModel,
  useBooleanFieldModel,
} from '@models';
import {
  DateValidationEnum,
  EnumTypeForList,
  IChecklistMetadata,
  ITeam,
  QueryNamesEnums,
  TeamRolesEnum,
} from '@interfaces';
import { useDayJsFormatter } from '@hooks';
import { getProjectTeamsWithQuery } from '@globalService';

import { useGraphQuery } from '@context';
interface HookInterface {
  additionalContactName: StringFieldModel;
  primaryContactPhone: PhoneFieldModel;
  additionalContactPhone: PhoneFieldModel;
  accessCode: StringFieldModel;
  primaryContactUser: DropdownFieldModel;
  primaryContactUserList: EnumTypeForList[];
  inspectionCadence: StringFieldModel;
  isBorrowerUsersLoading: boolean;
  inspectionRequestedAt: DateFieldModel;
  isProjectInspectionSettingsUpdated: boolean;
  isContactListHasUsersWithPhone: boolean;
  isDataLoading: boolean;
  inspectionIsOrderAutomatically: BooleanFieldModel;
}

export const useInspectionFields = ({
  metadata,
}: {
  metadata?: IChecklistMetadata;
}): HookInterface => {
  const { getInitialValue, getDateValidationRule } = useDayJsFormatter();
  const location = useLocation();
  const isSettingsPage = location?.pathname.includes('/settings/');
  const { projectId } = useParams();

  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: [
      'inspection_cadence',
      'inspection_additional_contact_name',
      'inspection_additional_contact_phone',
      'inspection_entry_access_code',
      'inspection_primary_contact_user_id',
      'inspection_is_order_automatic',
    ],
    args: { project_id: projectId },
  });

  const {
    inspection_additional_contact_name,
    inspection_additional_contact_phone,
    inspection_entry_access_code,
    inspection_primary_contact_user_id,
    inspection_cadence,
    inspection_is_order_automatic,
  } = project?.data || {};

  const inspectionIsOrderAutomatically = useBooleanFieldModel({
    initValue: inspection_is_order_automatic || false,
  });

  const inspectionCadence = useStringFieldModel({
    initValue: inspection_cadence ? inspection_cadence?.toString() : '',
    withProgressCheck: true,
  });

  const query = `role=${TeamRolesEnum.OWNER}`;
  const { data: { results: teamsList = [] } = {}, isLoading } = useQuery<
    { results: ITeam[] },
    Error
  >(
    [QueryNamesEnums.GET_PROJECT_TEAMS, { projectId, query }],
    getProjectTeamsWithQuery.bind(this, { projectId, query }),
    { enabled: Boolean(projectId) },
  );

  const getRequestedDate = useCallback((metadata?: { date?: string }) => {
    const currentDate = getInitialValue({});
    const metadataDate = metadata?.date ? getInitialValue({ date: metadata.date }) : null;

    return metadataDate && metadataDate > currentDate ? metadataDate : currentDate;
  }, []);

  const inspectionRequestedAt = useDateFieldModel({
    initValue: getInitialValue({}),
    validationRule: (value) =>
      getDateValidationRule({
        value,
        rule: DateValidationEnum.MORE_OR_EQUAL,
        minDate: new Date(),
        required: false,
      }),
    validateOnChange: true,
  });

  const primaryContactUserList = useMemo(
    () =>
      teamsList?.[0]?.members?.map((o) => ({
        id: o.id,
        name_display: o?.full_name || o?.email,
        name: o?.full_name || o?.email,
        phone: o?.phone,
        disabled: !o?.phone,
      })) || [],
    [teamsList],
  );

  const primaryContactInitValue = useMemo(() => {
    if (!primaryContactUserList?.length) return null;
    if (isSettingsPage && !inspection_primary_contact_user_id) return null;
    const primaryContact =
      find(primaryContactUserList, {
        id: metadata?.inspection_primary_contact_user_id || inspection_primary_contact_user_id,
      }) || primaryContactUserList.find((option) => !option?.disabled);

    if (!primaryContact) return null;
    return primaryContact;
  }, [primaryContactUserList, inspection_primary_contact_user_id, metadata, isSettingsPage]);

  const primaryContactUser = useDropdownFieldModel({
    initValue: primaryContactInitValue,
  });

  const additionalContactName = useStringFieldModel({
    initValue: inspection_additional_contact_name || '',
    validationRule: (value) => Boolean(primaryContactUser?.value?.id || value?.trim()),
    validateOnChange: true,
    withProgressCheck: true,
  });

  const primaryContactPhone = usePhoneFieldModel({
    initValue: '',
    withProgressCheck: true,
  });

  const additionalContactPhone = usePhoneFieldModel({
    initValue: inspection_additional_contact_phone || '+1',
    validationRule: (value) =>
      Boolean(primaryContactUser?.value?.id || value?.replace('+1', '')?.trim()),
    withProgressCheck: true,
  });

  const accessCode = useStringFieldModel({
    initValue: inspection_entry_access_code || '',
    validationRule: (value) => (value?.length || 0) <= 50,
    validateOnChange: true,
    withProgressCheck: true,
  });

  const [isInitMetadataUsed, setIsInitMetadataUsed] = useState(false);
  useEffect(() => {
    if (!isEmpty(metadata)) {
      additionalContactName.setValue(metadata?.inspection_additional_contact_name);
      additionalContactPhone.setValue(metadata?.inspection_additional_contact_phone || '+1');
      accessCode.setValue(metadata?.inspection_entry_access_code);
      inspectionRequestedAt.setValue(getRequestedDate(metadata));

      setIsInitMetadataUsed(true);
    }
  }, [metadata, isInitMetadataUsed]);

  useEffect(() => {
    primaryContactPhone.setValue(primaryContactUser?.value?.phone || '+1');
  }, [primaryContactUser?.value?.id]);

  const isContactListHasUsersWithPhone = useMemo(() => {
    return primaryContactUserList.some((user) => user.phone);
  }, [primaryContactUserList]);

  const isProjectInspectionSettingsUpdated = useMemo(
    () =>
      [
        additionalContactName,
        additionalContactPhone,
        accessCode,
        primaryContactUser,
        inspectionCadence,
        inspectionIsOrderAutomatically,
      ].some((field) => field.isChanged),
    [
      additionalContactName.isChanged,
      additionalContactPhone.isChanged,
      accessCode.isChanged,
      primaryContactUser.isChanged,
      inspectionCadence.isChanged,
      inspectionIsOrderAutomatically.isChanged,
    ],
  );

  return {
    additionalContactName,
    additionalContactPhone,
    accessCode,
    primaryContactUser,
    primaryContactPhone,
    primaryContactUserList,
    inspectionCadence,
    isBorrowerUsersLoading: isLoading,
    inspectionRequestedAt,
    isProjectInspectionSettingsUpdated,
    isContactListHasUsersWithPhone,
    inspectionIsOrderAutomatically,
    isDataLoading: project?.isPostLoading,
  };
};
