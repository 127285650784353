import { useState } from 'react';

import { IRightMenu } from '@interfaces';
import { useRightMenu } from '@hooks';

export interface ControllerInterface {
  rightMenu: IRightMenu;
  handleRightDrawerOpenerClick: ({ title }: { title: string }) => void;
  filters: string[];
  handleFiltersChange: (newFilters) => void;
  handleSortClick: () => void;
  sorting: boolean;
}

export const useProjectOptions = (): ControllerInterface => {
  const { handleRightDrawerOpenerClick, ...rightMenu } = useRightMenu({});

  const [sorting, setSorting] = useState<boolean>(true);
  const [filters, setFilters] = useState([]);

  const handleSortClick = () => setSorting((old) => !old);

  const handleFiltersChange = (newFilters) => setFilters(newFilters);

  return {
    rightMenu,
    filters,
    handleFiltersChange,
    handleSortClick,
    sorting,
    handleRightDrawerOpenerClick,
  };
};
