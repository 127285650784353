import { useContext, useMemo } from 'react';
import dayjs from 'dayjs';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
  HookState,
  IDrawRequest,
  IInspection,
  ServiceActionEnum,
  PermissionNamesEnums,
  QueryNamesEnums,
  ServiceTypeEnum,
} from '@interfaces';
import { getProjectDrawRequestsList, getProjectInspectionsList } from '@globalService';
import {
  findLastCompletedInspectionDate,
  getActiveDrawRequestFromListForInspection,
  getHookState,
  getLastRelevantInspection,
  isCompletedService,
  isInspectionCanceled,
  isFailedService,
  isAllowed,
} from '@utils';
import { PermissionsContext, SettingsContext } from '@context';

export interface ControllerInterface {
  lastInspection: IInspection;
  state: HookState;
  drawRequestInProgressForInspection: IDrawRequest;
  isCurrentProjectArchived: boolean;
  navigateToInspection: (mode: ServiceActionEnum) => void;
  daysSinceLastInspectionText: string;
  isInactiveLastInspection: boolean;
  isFailedInspection: boolean;
  getInspectionLink: (mode: ServiceActionEnum) => string;
  linkState: string;
  isInspectionFetching: boolean;
  navigateToOrderInspection: () => void;
}

export const useInspectionCard = (): ControllerInterface => {
  const { permissions } = useContext(PermissionsContext);
  const { isCurrentProjectArchived } = useContext(SettingsContext);
  const { projectId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const projectInspectionsQuery = useQuery<{ results: IInspection[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_INSPECTIONS, { projectId }],
    getProjectInspectionsList.bind(this, { projectId }),
    {
      enabled: isAllowed(PermissionNamesEnums.INSPECTIONS_VIEW, permissions),
    },
  );
  const drawRequestsQuery = useQuery<{ results: IDrawRequest[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }],
    getProjectDrawRequestsList.bind(this, projectId),
  );

  const drawRequestInProgressForInspection = useMemo(
    () => getActiveDrawRequestFromListForInspection(drawRequestsQuery.data?.results),
    [drawRequestsQuery.data],
  );

  const lastInspection = useMemo(
    () => getLastRelevantInspection(projectInspectionsQuery.data?.results),
    [projectInspectionsQuery.data],
  );

  const lastCompletedInspectionDate = useMemo(
    () => findLastCompletedInspectionDate(projectInspectionsQuery.data?.results),
    [projectInspectionsQuery.data],
  );

  const daysSinceLastInspectionText = useMemo(
    () =>
      dayjs(lastCompletedInspectionDate).isValid()
        ? `${dayjs().diff(dayjs(lastCompletedInspectionDate), 'days')} days`
        : '-',
    [lastCompletedInspectionDate],
  );

  const isFailedInspection = useMemo(
    () => isFailedService(lastInspection?.status),
    [lastInspection],
  );

  const isInactiveLastInspection = useMemo(
    () =>
      isCompletedService(lastInspection?.status) ||
      isInspectionCanceled(lastInspection?.status) ||
      isFailedInspection,
    [lastInspection, isFailedInspection],
  );

  const getInspectionLink = (mode: ServiceActionEnum) =>
    `/projects/${projectId}/inspection/${lastInspection?.id}/${mode}`;

  const linkState = useMemo(() => location?.pathname, [location?.pathname]);

  return {
    state: getHookState([projectInspectionsQuery, drawRequestsQuery]),
    lastInspection,
    isCurrentProjectArchived,
    daysSinceLastInspectionText,
    isInactiveLastInspection,
    getInspectionLink,
    linkState,
    isFailedInspection,
    isInspectionFetching: projectInspectionsQuery.isFetching,
    navigateToInspection: (mode: ServiceActionEnum) =>
      navigate(getInspectionLink(mode), {
        state: linkState,
      }),
    navigateToOrderInspection: () =>
      navigate(`/projects/${projectId}/order-service/?service_type=${ServiceTypeEnum.INSPECTION}`),
    drawRequestInProgressForInspection,
  };
};
