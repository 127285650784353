import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { IconButton, Stack } from '@mui/material';

import {
  AuditLog,
  FilterButton,
  IconButtonWithTooltip,
  RightDrawer,
  auditLogTypeFilter,
} from '@components';
import { ActivityLogIcon, SortIcon } from '@svgAsComponents';
import * as Controller from './controller';
import { colors } from '@theme';

const AuditLogIconWithRightDrawer: FC = () => {
  const { projectId } = useParams();

  const controller = Controller.useProjectOptions();
  const {
    rightMenu,
    handleRightDrawerOpenerClick,
    filters,
    handleFiltersChange,
    handleSortClick,
    sorting,
  } = controller || {};

  return (
    <>
      <IconButtonWithTooltip
        tooltipText="Audit log"
        sx={{ mr: '4px', height: '40px' }}
        onClick={() => handleRightDrawerOpenerClick({ title: 'Audit log' })}
        data-cy="project__overview__audit_log__icon"
      >
        <ActivityLogIcon />
      </IconButtonWithTooltip>
      <RightDrawer {...rightMenu}>
        <>
          <Stack direction="row" spacing={1}>
            <FilterButton
              handleFiltersChange={(key, value) => {
                handleFiltersChange(value);
              }}
              initValues={filters}
              fixedWidth="164px"
              closeOnChange
              {...auditLogTypeFilter}
            />
            <IconButton
              onClick={handleSortClick}
              sx={sorting ? { paddingRight: 0 } : { transform: ['scaleY(-1)'], paddingRight: 0 }}
              data-cy={'project__right_drawer__switcher__audit_log__sort__icon'}
              size="small"
            >
              <SortIcon color={colors.icons.gray} size={16} />
            </IconButton>
          </Stack>
          <AuditLog
            projectId={projectId}
            sorting={sorting}
            filters={filters}
            source="project__right_drawer__audit_log"
          />
        </>
      </RightDrawer>
    </>
  );
};

export default AuditLogIconWithRightDrawer;
