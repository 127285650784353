import { QueryNamesEnums } from '@interfaces';

import {
  getDrawRequestMilestone,
  getDrawRequestMilestoneGroup,
  getDrawRequestMilestones,
  getProjectMilestone,
  getProjectMilestonesList,
  getProjectsCompaniesV2,
  getProjectTeamsWithQuery,
  getProjectsUsersV2,
  getProjectV2,
  getProjectsTeamsV2,
  getCompaniesV2,
  getProjectsInspectionAgenciesV2,
  getProjectsServiceAgenciesV2,
  getBuildingsTypesV2,
  getProjectsTypesV2,
  getProjectsRolesV2,
  getCompaniesRoleByIdV2,
  getCompaniesRolesV2,
  getProjectsDrawRequestsV2,
  getServiceOrderByIdV2,
  //  getDrawRequestMilestonesWithPhotos,
  getDrawRequestV2,
  getProjectDocumentTypesV2,
  getProjectServiceOrdersV2,
  getDrawRequestServiceOrdersV2,
  getServiceOrdersListV2,
  getServiceOrderMilestonesV2,
  getServiceOrderDocumentsV2,
} from '@globalService';

export const dataProviders = {
  [QueryNamesEnums.GET_PROJECT]: getProjectV2,
  [QueryNamesEnums.GET_PROJECT_MILESTONE]: getProjectMilestone,
  [QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE]: getDrawRequestMilestone,
  [QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE_GROUP]: getDrawRequestMilestoneGroup,
  [QueryNamesEnums.GET_COMPANY_TEAM_BY_ID]: getCompaniesRoleByIdV2,
  [QueryNamesEnums.GET_PROJECT_SERVICE_ORDER_BY_ID]: getServiceOrderByIdV2,
  [QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES]: getDrawRequestMilestones,
  [QueryNamesEnums.GET_SERVICE_ORDER_MILESTONES]: getServiceOrderMilestonesV2,
  [QueryNamesEnums.GET_PROJECT_MILESTONES]: getProjectMilestonesList,
  [QueryNamesEnums.GET_DRAW_REQUEST_V2]: getDrawRequestV2,
  [QueryNamesEnums.GET_COMPANIES_BY_ROLE]: getProjectsCompaniesV2,
  [QueryNamesEnums.GET_PROJECTS_TEAMS]: getProjectsTeamsV2,
  [QueryNamesEnums.GET_COORDINATORS]: getProjectsUsersV2,
  [QueryNamesEnums.GET_COMPANIES]: getCompaniesV2,
  [QueryNamesEnums.GET_INSPECTION_AGENCIES]: getProjectsInspectionAgenciesV2,
  [QueryNamesEnums.GET_SERVICE_AGENCIES]: getProjectsServiceAgenciesV2,
  [QueryNamesEnums.GET_PROJECT_PROPERTY_TYPES]: getBuildingsTypesV2,
  [QueryNamesEnums.GET_PROJECT_TYPES]: getProjectsTypesV2,
  [QueryNamesEnums.GET_DOCUMENTS_TYPES]: getProjectDocumentTypesV2,
  [QueryNamesEnums.GET_PROJECT_TEAMS]: getProjectTeamsWithQuery,
  [QueryNamesEnums.GET_PROJECT_ROLES]: getProjectsRolesV2,
  [QueryNamesEnums.GET_COMPANY_ROLES]: getCompaniesRolesV2,
  [QueryNamesEnums.GET_DRAW_REQUEST_LIST]: getProjectsDrawRequestsV2,
  [QueryNamesEnums.GET_PROJECT_SERVICE_ORDERS]: getProjectServiceOrdersV2,
  [QueryNamesEnums.GET_DRAW_REQUEST_SERVICE_ORDERS]: getDrawRequestServiceOrdersV2,
  [QueryNamesEnums.GET_SERVICE_ORDERS_LIST]: getServiceOrdersListV2,
  [QueryNamesEnums.GET_SERVICE_ORDER_DOCUMENTS]: getServiceOrderDocumentsV2,
  //  [QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES_WITH_PHOTOS]: getDrawRequestMilestonesWithPhotos,
} as const;
