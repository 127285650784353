import find from 'lodash/find';
import map from 'lodash/map';

import {
  IInspection,
  IInspectionTotal,
  IStatusChip,
  IUISettings,
  QueryNamesEnums,
  ServiceOrderStatusEnum,
  IServiceOrder,
} from '@interfaces';
import { QueryClient } from 'react-query';
import { statusMap, onlyProgressPhotos } from '@constants';

export const getServiceOrderMilestoneTotals = (
  inspection: Pick<IServiceOrder, 'totals'>,
  filterValue: string,
  defaultOptions: Array<{ filterValue: string; totalKey: string }>,
): IInspectionTotal => {
  const key = find(defaultOptions, { filterValue })?.totalKey;
  return {
    previous_inspector_allowance_rate: inspection?.totals?.[key]?.previous_inspector_allowance_rate,
    inspector_allowance_rate: inspection?.totals?.[key]?.inspector_allowance_rate,
    requested_amount: inspection?.totals?.[key]?.requested_amount,
    revised_estimate: inspection?.totals?.[key]?.revised_estimate,
  };
};

interface GetInitColumnsParams {
  isEditable: boolean;
  isServiceOrderLinkedToDR: boolean;
  showInspectorRate?: boolean;
}

export const getInitColumns = ({
  isEditable,
  isServiceOrderLinkedToDR,
  showInspectorRate,
}: GetInitColumnsParams): string[] => [
  'name',
  ...(isEditable ? ['previousInspectorAllowanceRate'] : []),
  ...(showInspectorRate ? ['inspectorAllowanceRate'] : []),
  'revisedEstimate',
  ...(isServiceOrderLinkedToDR ? ['requestedAmount'] : []),
  'inspectionPhotoUploader',
  'inspectionPhotosV2',
  'comments',
];

export const getStatusChipProps = (
  inspection: IInspection | undefined,
  settings: Partial<IUISettings>,
): IStatusChip => {
  const serviceStatusValue = statusMap(inspection?.status, settings.display, 'service_order');
  return {
    color: serviceStatusValue.color,
    backgroundColor: serviceStatusValue.backgroundColor,
    label: serviceStatusValue.text || inspection?.status,
    dataTestName: 'data-cy="service_results__status_chip"',
  };
};

export const getProviderStatusChipProps = (
  inspection: IInspection | undefined,
  settings: Partial<IUISettings>,
): IStatusChip => {
  const providerStatusValue = statusMap(
    ServiceOrderStatusEnum.ORDERED,
    settings.display,
    'service_order',
  );

  return {
    color: providerStatusValue.color,
    backgroundColor: providerStatusValue.backgroundColor,
    label: inspection?.provider_status_display,
    dataTestName: 'inspection_results__provider_status_chip',
  };
};

export const getFilterOptions = (defaultOptions: Array<{ filterValue: string }>) =>
  map(defaultOptions, 'filterValue');

export const updateAllQueries = (
  queryClient: QueryClient,
  {
    projectId,
    serviceOrderId,
    drawRequestId,
  }: {
    projectId: string;
    serviceOrderId: string;
    drawRequestId?: string;
  },
) => {
  const params = { project_id: projectId, draw_request_id: drawRequestId };

  const queriesToInvalidate = [
    [QueryNamesEnums.GET_SERVICE_ORDERS_LIST],
    [QueryNamesEnums.GET_PROJECT_SERVICE_ORDERS, { project_id: projectId }],
    [QueryNamesEnums.GET_DRAW_REQUEST_SERVICE_ORDERS, params],
    [
      QueryNamesEnums.GET_PROJECT_SERVICE_ORDER_BY_ID,
      { project_id: projectId, service_order_id: serviceOrderId },
    ],
    [
      QueryNamesEnums.GET_SERVICE_ORDER_MILESTONES,
      { project_id: projectId, service_order_id: serviceOrderId },
    ],
    [QueryNamesEnums.GET_DRAW_REQUEST, params],
    [QueryNamesEnums.GET_DRAW_REQUEST_V2, params],
    [QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES, params],
    [
      QueryNamesEnums.GET_SERVICE_ORDER_DOCUMENTS,
      { project_id: projectId, service_order_id: serviceOrderId, query: onlyProgressPhotos },
    ],
  ];

  queriesToInvalidate.forEach((query) => queryClient.invalidateQueries(query));
};
