import { useCallback, useContext, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import {
  IDrawRequest,
  IDrawRequestsStats,
  IRightDrawerParams,
  QueryNamesEnums,
  TableKeyEnum,
} from '@interfaces';
import {
  checkIsCustomerSuccess,
  checkIsInvestor,
  checkIsOwner,
  createColumnVisibleModel,
  getHookState,
  getLink,
  getSortStringByGridSortModel,
  getTeamRole,
  ColumnConfig,
  generateGraphKeys,
} from '@utils';
import { getDrawRequestListStats } from '@globalService';
import { defaultPersonalSetting, excludeRequestColumns } from '@constants';
import { AuthContext, PermissionsContext, useGraphQuery, useLaunchDarklyFlags } from '@context';
import {
  useCommentsAndDocumentsPreview,
  useRightMenu,
  useTablePagination,
  useUserTableSettings,
} from '@hooks';
import { GridSortModel } from '@mui/x-data-grid-premium';
import ColumnsConfig from './columns';
import { FiltersTypes } from '@components';
import { useNavigate } from 'react-router';

export const useRequestsQueueBody = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { userTableSettings } = useUserTableSettings({ tableKey: TableKeyEnum.REQUESTS });
  const { permissions } = useContext(PermissionsContext);
  const teamRole = getTeamRole(user);
  const flags = useLaunchDarklyFlags();

  const [filtersReady, setFiltersReady] = useState<boolean>(false);
  const [tableSortingModel, setTableSortingModel] = useState<GridSortModel>([]);
  const [filterStringQuery, setFilterStringQuery] = useState<string>('');
  const [coordinatorPopupOpen, setCoordinatorPopupOpen] = useState<boolean>(false);
  const [approverPopupOpen, setApproverPopupOpen] = useState<boolean>(false);
  const [currentRequest, setCurrentRequest] = useState<IDrawRequest>();
  const [isAddRequestPopupOpen, setIsAddRequestPopupOpen] = useState<boolean>(false);
  const [rightDrawerParams, setRightDrawerParams] = useState<IRightDrawerParams>({});

  const isInvestor = useMemo(() => checkIsInvestor(teamRole), [teamRole]);
  const isOwner = useMemo(() => checkIsOwner(teamRole), [teamRole]);
  const isCustomerSuccess = useMemo(() => checkIsCustomerSuccess(teamRole), [teamRole]);

  const { page, rowsPerPage, onPageChange, rowsPerPageOptions, onRowsPerPageChange } =
    useTablePagination({});

  const columnsToFetch = useMemo(() => {
    const columnsModel = userTableSettings?.columns.columnVisibilityModel || {
      ...createColumnVisibleModel(
        ColumnsConfig.map((column) => column({}).field),
        true,
      ),
      ...createColumnVisibleModel(
        defaultPersonalSetting.tables[TableKeyEnum.REQUESTS]?.hidden_columns || [],
      ),
    };
    if (columnsModel) return Object.keys(columnsModel).filter((key) => columnsModel[key]);
    return [];
  }, [userTableSettings?.columns.columnVisibilityModel]);

  const queryTest = useMemo(
    () =>
      generateGraphKeys<IDrawRequest>(
        columnsToFetch?.map((column) =>
          ColumnsConfig.map((column) => column({})).find((config) => config.field === column),
        ),
      ),
    [columnsToFetch],
  );

  const requestsQuery = useGraphQuery({
    type: QueryNamesEnums.GET_DRAW_REQUEST_LIST,
    keys: queryTest.keys as (keyof IDrawRequest)[],
    nested: queryTest.nested,
    args: {
      pagination: {
        page,
        rowsPerPage,
      },
      sorting: getSortStringByGridSortModel(tableSortingModel),
      filter: filterStringQuery,
    },
    options: {
      skip: !columnsToFetch?.length || !filtersReady,
    },
  });

  const { data: statsData } = useQuery<IDrawRequestsStats, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST_LIST_STATS],
    getDrawRequestListStats.bind(this, ''),
  );
  const allRequestCount = useMemo(() => statsData?.total_count, [statsData?.total_count]);

  const formattedData = useMemo(() => {
    const requests = requestsQuery.data?.results || [];
    return requests;
  }, [requestsQuery.data]);

  const { updateCommentsPreviewInfo } = useCommentsAndDocumentsPreview({
    projectId: rightDrawerParams.projectId,
    drawRequestId: rightDrawerParams.requestId,
  });

  const onRightDrawerClose = useCallback(() => {
    updateCommentsPreviewInfo();
    setRightDrawerParams((old) => ({ ...old, activeTab: '' }));
  }, [updateCommentsPreviewInfo]);

  const { handleRightDrawerOpenerClick, ...rightMenu } = useRightMenu({
    onClose: onRightDrawerClose,
  });

  const updateRightDrawer = ({
    title,
    projectId,
    requestId,
    activeTab,
  }: {
    title: string;
    projectId: string;
    requestId: string;
    activeTab: string;
  }) => {
    handleRightDrawerOpenerClick({ title });
    setRightDrawerParams({ projectId, requestId, activeTab });
  };

  const updateAssignCoordinatorPopup = (request: IDrawRequest) => {
    setCurrentRequest(request);
    setCoordinatorPopupOpen(true);
  };
  const updateApproverPopup = (request: IDrawRequest) => {
    setCurrentRequest(request);
    setApproverPopupOpen(true);
  };

  const handleRowClick = ({ row }) => {
    const link = getLink({ row, tableKey: TableKeyEnum.REQUESTS, teamRole });
    link && navigate(link);
  };

  const filters = useMemo<FiltersTypes[]>(
    () =>
      [
        'textSearchFilter',
        'isOnHoldFilter',
        'requestStatusFilter',
        'currentReviewerTeamFilter',
        ...(flags?.['ENG_10222_inactive_projects_visibility']
          ? ['projectStatusFilterV2']
          : ['projectStatusFilter']),
        'inspectionStatusFilter',
        'lenderFilter',
        'borrowerFilter',
        'coordinatorRequestFilter',
        'projectHealthFilter',
        'customerFilter',
        'investorFilter',
      ] as FiltersTypes[],
    [flags?.['ENG_10222_inactive_projects_visibility']],
  );
  const requestListCustomRules = useCallback(
    (column: ColumnConfig<IDrawRequest>) => {
      switch (column.field) {
        case 'project.lender_name':
          return isInvestor || isCustomerSuccess;
        case 'project.borrower_name':
          return !isOwner;
        default:
          return column.enableByPermissions ? column.enableByPermissions(permissions) : true;
      }
    },
    [permissions, isOwner],
  );

  const columns = useMemo(
    () =>
      ColumnsConfig.map((column) =>
        column({ updateAssignCoordinatorPopup, updateApproverPopup, updateRightDrawer }),
      ).filter(requestListCustomRules),
    [updateAssignCoordinatorPopup, updateApproverPopup, updateRightDrawer, requestListCustomRules],
  );

  const csvHeaders = useMemo(
    () =>
      columns
        .filter(
          (column: ColumnConfig<IDrawRequest>) => !excludeRequestColumns.includes(column.field),
        )
        .map((column: ColumnConfig<IDrawRequest>) => ({
          label: column.headerName,
          key: column.field,
        })),
    [columns],
  );

  return {
    isLoading: requestsQuery.isLoading || requestsQuery.isPostLoading,
    state: getHookState(requestsQuery),
    count: requestsQuery.data?.count,
    results: requestsQuery.data?.results,
    allRequestCount,
    formattedData,
    onFiltersReady: () => setFiltersReady(true),
    page,
    rowsPerPage,
    onPageChange,
    rowsPerPageOptions,
    onRowsPerPageChange,
    onSortModelChange: setTableSortingModel,
    setFilterStringQuery,
    isOwner,
    approverPopupOpen,
    columns,
    coordinatorPopupOpen,
    currentRequest,
    handleRowClick,
    setCoordinatorPopupOpen,
    setApproverPopupOpen,
    rightDrawerParams,
    rightMenu,
    csvHeaders,
    isAddRequestPopupOpen,
    setIsAddRequestPopupOpen,
    filters,
    updateAssignCoordinatorPopup,
    updateApproverPopup,
    updateRightDrawer,
  };
};
