import React from 'react';
import { DrawChangeRequestTab, RequestsQueueBody } from './components';
import { Stack } from '@mui/material';
import { colors } from '@theme';
import { useLaunchDarklyFlags } from '@context';
import { TableKeyEnum } from '@interfaces';

const RequestsQueue = () => {
  const flags = useLaunchDarklyFlags();
  return flags?.[`ENG_7895_table_v3__${TableKeyEnum.REQUESTS}`] ? (
    <RequestsQueueBody />
  ) : (
    <Stack sx={{ height: '100%' }}>
      <Stack
        sx={{
          backgroundColor: colors.background.gray,
          flexGrow: 1,
          p: { md: 3, xs: 2 },
        }}
      >
        <DrawChangeRequestTab />
      </Stack>
    </Stack>
  );
};

export default RequestsQueue;
