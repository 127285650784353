import { useCallback, useState } from 'react';
import { ActiveFundingSource, FundingSourceInternalIdEnums } from '@interfaces';
import { StringFieldModel } from '@models';
import { updateFSValues } from '@utils';

type CustomFundingSourcesProps = {
  initSources: ActiveFundingSource[];
  constructionHoldback: StringFieldModel;
  constructionHoldbackFraction: StringFieldModel;
  borrowerEquity: StringFieldModel;
  borrowerEquityFraction: StringFieldModel;
  postFundingConstructionBudget: StringFieldModel;
  prefundingCost: StringFieldModel;
};

type CustomFundingSourcesReturn = {
  customSources: ActiveFundingSource[];
  handleSaveFundingSource: (value: ActiveFundingSource) => void;
  isCustomSourcesChanged: (initCustomSources: ActiveFundingSource[]) => boolean;
  handleInitSourcesChange: (sources: ActiveFundingSource[]) => void;
};

export const useCustomFundingSources = ({
  initSources,
  constructionHoldback,
  constructionHoldbackFraction,
  borrowerEquity,
  borrowerEquityFraction,
  postFundingConstructionBudget,
  prefundingCost,
}: CustomFundingSourcesProps): CustomFundingSourcesReturn => {
  const [customSources, setCustomSources] = useState<ActiveFundingSource[]>(initSources || []);

  const handleInitSourcesChange = useCallback((sources: ActiveFundingSource[]) => {
    setCustomSources(sources);
  }, []);

  const addCustomFundingSource = useCallback(
    (source: ActiveFundingSource) => {
      setCustomSources([...customSources, source]);
    },
    [customSources],
  );

  const updateCustomFundingSource = useCallback((source: ActiveFundingSource) => {
    setCustomSources((prevSources) =>
      prevSources.map((s) =>
        s.id === source.id
          ? {
              ...s,
              name: source.name,
              total: source.total,
              is_transactable: source.is_transactable,
            }
          : s,
      ),
    );
  }, []);

  const handleSaveFundingSource = useCallback(
    (value: ActiveFundingSource) => {
      switch (value.internal_identifier) {
        case FundingSourceInternalIdEnums.CONSTRUCTION_HOLDBACK:
          updateFSValues({
            value: value.total.toString(),
            amountField: constructionHoldback,
            otherValue: borrowerEquity.floatValue,
            fieldFraction: constructionHoldbackFraction,
            otherFieldFraction: borrowerEquityFraction,
            constructionBudget: postFundingConstructionBudget,
          });
          break;

        case FundingSourceInternalIdEnums.BORROWER_EQUITY: {
          updateFSValues({
            value: value.total.toString(),
            amountField: borrowerEquity,
            otherValue: constructionHoldback.floatValue,
            fieldFraction: borrowerEquityFraction,
            otherFieldFraction: constructionHoldbackFraction,
            constructionBudget: postFundingConstructionBudget,
          });
          break;
        }

        case FundingSourceInternalIdEnums.PREFUNDING_COST: {
          prefundingCost.setAsFloat(+value.total);
          break;
        }

        default: {
          const isNew = customSources.every((source) => source.id !== value.id);
          if (isNew) {
            addCustomFundingSource(value);
            return;
          }
          updateCustomFundingSource(value);
          break;
        }
      }
    },
    [
      addCustomFundingSource,
      updateCustomFundingSource,
      constructionHoldback,
      constructionHoldbackFraction,
      borrowerEquity,
      borrowerEquityFraction,
      postFundingConstructionBudget,
      prefundingCost,
    ],
  );

  const isCustomSourcesChanged = useCallback(
    (initCustomSources: ActiveFundingSource[]) => {
      if (customSources.length !== initCustomSources.length) {
        return true;
      }

      return customSources.some((source) => {
        const initialSource = initCustomSources.find((init) => init.id === source.id);

        if (initialSource) {
          return (
            source.name !== initialSource.name ||
            source.is_transactable !== initialSource.is_transactable ||
            source.total !== initialSource.total
          );
        }

        return false;
      });
    },
    [customSources],
  );

  return {
    customSources,
    handleSaveFundingSource,
    isCustomSourcesChanged,
    handleInitSourcesChange,
  };
};
