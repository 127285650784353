import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router';
import { ProjectWarning } from '@components';
import { useProjectActivationWarning } from '@hooks';

const PageLayoutContentWrapper = ({ children }) => {
  const { projectId } = useParams();
  const projectActivationWarning = useProjectActivationWarning({
    projectId,
  });

  let shift = 64; // for AppBar
  if (projectActivationWarning) {
    shift += 56; // for ProjectWarning
  }
  return (
    <>
      {projectActivationWarning && <ProjectWarning innerElement={projectActivationWarning} />}
      <Box
        sx={{
          flexGrow: 1,
          '& > div': {
            // Make sure first component in Outlet at least cover visible part of screen
            minHeight: `calc(100vh - ${shift}px)`,
          },
        }}
      >
        {children}
      </Box>
    </>
  );
};

export default PageLayoutContentWrapper;
