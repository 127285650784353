import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { HookState, IPieChartData, IProjectProgressItem } from '@interfaces';
import { calculateFraction, currencyFormatter, percentFormatter } from '@utils';
import { useRequestPaymentData, useProjectPaymentData } from '@hooks';

interface ControllerInterface {
  state: HookState;
  progressItems: {
    originalScheduledValue: Partial<IProjectProgressItem>;
  };
  originalValuePieChartData: IPieChartData[];
  fundingSources: IProjectProgressItem[];
}

interface ILabels {
  progress: string;
  scheduledValue: string;
  budget: string;
  contributed: string;
  remaining: string;
}

export const getLabels = (isRequestTab: boolean): ILabels => ({
  progress: isRequestTab ? 'Progress' : 'Budget progress',
  scheduledValue: 'Current schedule of values',
  budget: isRequestTab ? 'Draw breakdown' : 'Total budget breakdown',
  contributed: isRequestTab ? 'Contributed to date' : 'Contributed',
  remaining: isRequestTab ? 'Remaining after draw' : 'Remaining',
});

export const usePaymentGraph = (): ControllerInterface => {
  const { drawRequestId: isRequestTab } = useParams();
  const {
    projectProgressItems,
    state: projectState,
    getOriginalValuePieChartData,
    projectFundingSources,
  } = useProjectPaymentData();
  const {
    requestProgressItems,
    state: requestState,
    getRequestOriginalValuePieChartData,
    drawRequestFundingSources,
  } = useRequestPaymentData();

  const originalValuePieChartData = useMemo(
    () => (isRequestTab ? getRequestOriginalValuePieChartData() : getOriginalValuePieChartData()),
    [isRequestTab, getOriginalValuePieChartData, getRequestOriginalValuePieChartData],
  );

  const fundingSources = useMemo(() => {
    if (isRequestTab) {
      return (
        drawRequestFundingSources?.map((source) => {
          const before = calculateFraction(
            source.funded_before_current_request,
            source.revised_total,
          );
          const after = calculateFraction(
            source.funded_after_current_request,
            source.revised_total,
          );
          return {
            after_value: source.funded_after_current_request,
            before_value: source.funded_before_current_request,
            before,
            after,
            total: source.revised_total,
            currentDraw: `${currencyFormatter(
              source.funded_after_current_request - source.funded_before_current_request,
            )} / ${percentFormatter({
              value: after - before,
              roundTo: 2,
            })}`,
            contributedText: `${currencyFormatter(source.funded_before_current_request)} / ${percentFormatter(
              {
                value: before,
                roundTo: 2,
              },
            )}`,
            remainingText: `${currencyFormatter(
              source.revised_total - source.funded_after_current_request,
            )} / ${percentFormatter({
              value: 100 - after,
              roundTo: 2,
            })}`,
            display_name: source.name,
            barValues: [
              source.funded_before_current_request,
              source.funded_after_current_request - source.funded_before_current_request,
            ],
            colorKey: source.color,
          };
        }) || []
      );
    }
    return (
      projectFundingSources?.map((source) => {
        const before = calculateFraction(source.funded_before_latest_request, source.total);
        const after = calculateFraction(source.funded_after_latest_request, source.total);
        return {
          after_value: source.funded_after_latest_request,
          before_value: source.funded_before_latest_request,
          before,
          after,
          total: source.total,
          contributedText: `${currencyFormatter(source.funded_after_latest_request)} / ${percentFormatter(
            {
              value: after,
              roundTo: 2,
            },
          )}`,
          remainingText: `${currencyFormatter(
            source.total - source.funded_after_latest_request,
          )} / ${percentFormatter({
            value: 100 - after,
            roundTo: 2,
          })}`,
          display_name: source.name,
          barValues: [source.funded_after_latest_request],
          colorKey: source.color,
        };
      }) || []
    );
  }, [isRequestTab, drawRequestFundingSources, projectFundingSources]);

  return {
    state: isRequestTab ? requestState : projectState,
    progressItems: isRequestTab ? requestProgressItems : projectProgressItems,
    originalValuePieChartData,
    fundingSources,
  };
};
