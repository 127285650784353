import React, { FC, useContext } from 'react';
import { Button, Stack, Typography } from '@mui/material';

import {
  AssignCoordinatorPopup,
  DataTableV3,
  DrawRequestsListFilters,
  Filters,
  RightDrawer,
  ServiceMessage,
  StyledBox,
  SwitchApproverPopup,
  TableItemRightDrawer,
  TablePaginationNew,
} from '@components';
import { HookState, PermissionNamesEnums, TableKeyEnum } from '@interfaces';
import { useRequestsQueueBody } from './useRequestsQueueBody';
import { AddRequestPopup, RequestsSummaryBlock } from '../index';
import { WithPermission } from '@utils';
import { colors } from '@theme';
import { PermissionsContext } from '@context';

const RequestsQueueBody: FC = () => {
  const {
    state,
    count,
    formattedData,
    allRequestCount,
    onFiltersReady,
    onPageChange,
    onRowsPerPageChange,
    page,
    rowsPerPage,
    rowsPerPageOptions,
    setFilterStringQuery,
    isOwner,
    approverPopupOpen,
    coordinatorPopupOpen,
    currentRequest,
    handleRowClick,
    rightDrawerParams,
    rightMenu,
    setCoordinatorPopupOpen,
    setApproverPopupOpen,
    setIsAddRequestPopupOpen,
    filters,
    isAddRequestPopupOpen,
    columns,
    isLoading,
  } = useRequestsQueueBody();

  return (
    <Stack spacing={1} sx={{ backgroundColor: colors.background.gray, p: 2 }}>
      <StyledBox sx={{ display: 'flex', flexDirection: 'row', p: 2 }}>
        <Typography variant="h2" sx={{ mr: 2 }}>
          Requests {allRequestCount ? `(${allRequestCount})` : ''}
        </Typography>
        {isOwner && (
          <WithPermission permissionKey={PermissionNamesEnums.DRAWREQUESTS_CREATE}>
            <Button
              data-cy="requests__add__button"
              size="small"
              onClick={() => setIsAddRequestPopupOpen(true)}
            >
              Add request
            </Button>
          </WithPermission>
        )}
      </StyledBox>

      <RequestsSummaryBlock />

      <StyledBox sx={{ p: 0, flexGrow: 1 }}>
        {state === HookState.ERROR ? (
          <ServiceMessage text="draw requests list" />
        ) : (
          <>
            <DataTableV3
              controllerLoading={isLoading}
              withoutAdaptiveScroll
              tableKey={TableKeyEnum.REQUESTS}
              onRowClick={handleRowClick}
              withBuiltInSearch={false}
              headerRight={[]}
              footerRight={[
                {
                  Component: (
                    <Stack direction="row" justifyContent="flex-end" sx={{ mt: 2, pr: 2 }}>
                      <TablePaginationNew
                        page={page}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={rowsPerPageOptions}
                        itemsCount={count}
                        onPageChange={onPageChange}
                        onRowsPerPageChange={onRowsPerPageChange}
                        source="all_services__list"
                      />
                    </Stack>
                  ),
                },
              ]}
              headerLeft={[
                {
                  Component: (
                    <Filters
                      padding={0}
                      onReady={onFiltersReady}
                      tableKey={TableKeyEnum.REQUESTS}
                      source="requests"
                      setFilterStringQuery={setFilterStringQuery}
                      LeftComponent={DrawRequestsListFilters}
                      filters={filters}
                      onPageChange={onPageChange}
                    />
                  ),
                },
              ]}
              rows={formattedData}
              columns={columns}
            />
            <RightDrawer {...rightMenu}>
              <TableItemRightDrawer
                projectId={rightDrawerParams.projectId}
                requestId={rightDrawerParams.requestId}
                activeTab={rightDrawerParams.activeTab}
                source="requests_queue__right_drawer"
              />
            </RightDrawer>
            {coordinatorPopupOpen && (
              <AssignCoordinatorPopup
                open={coordinatorPopupOpen}
                requestId={currentRequest?.id}
                prId={currentRequest?.project?.id}
                initCoordinator={currentRequest?.coordinator}
                onClose={() => setCoordinatorPopupOpen(false)}
              />
            )}
            {approverPopupOpen && (
              <SwitchApproverPopup
                open={approverPopupOpen}
                request={currentRequest}
                onClose={() => setApproverPopupOpen(false)}
                projectId={currentRequest?.project?.id}
                source="requests_queue"
              />
            )}
          </>
        )}
      </StyledBox>
      {isAddRequestPopupOpen && <AddRequestPopup onClose={() => setIsAddRequestPopupOpen(false)} />}
    </Stack>
  );
};

export default withPermissionsProtect(RequestsQueueBody);

// TODO: this must be top level on Navigation?
export function withPermissionsProtect(Component) {
  return function ProtectedComponent() {
    const { permissions } = useContext(PermissionsContext);

    if (!permissions.length) {
      return null;
    }

    return <Component />;
  };
}
