import { Box, Stack, Typography } from '@mui/material';
import { colors } from '@theme';
import { isChangeRequest } from '@utils';
import React, { FC } from 'react';
import { LogoLarge, Page } from '../common';
import { AddressIcon } from '@svgAsComponents';

import { ControllerInterface } from '../controller';

export const StartPage: FC<{ controller: ControllerInterface }> = ({ controller }) => {
  const { customerLogo, drawRequestData, project } = controller;

  return (
    <Page>
      <Stack alignItems="center" direction="column">
        <Box sx={{ height: '200px' }} />
        {customerLogo && (
          <LogoLarge alt="" src={`http://local.trustpoint.ai:8000${customerLogo}`} />
        )}
        <Box sx={{ height: '30px' }} />
        <Typography variant="printH1" data-cy="report_title">
          TrustPoint® report
        </Typography>
        <Typography
          variant="printH2"
          data-cy="report_title__request_number"
        >{`${isChangeRequest(drawRequestData) ? 'Change' : 'Draw'} # ${drawRequestData.number}`}</Typography>
        <Box sx={{ height: '50px' }} />
        <Stack direction="row" alignItems="center" mt={1} spacing={1}>
          <AddressIcon size={30} color={colors.text.dark} />
          <Typography
            fontWeight={600}
            variant="h2"
            sx={{ color: colors.text.dark }}
            data-cy="report_address"
          >
            {project?.address.full_address}
          </Typography>
        </Stack>
        <Box
          sx={{
            marginTop: '20px',
            overflow: 'hidden',
            width: '100%',
            height: '300px',
            marginBottom: '25px',
            backgroundColor: colors.main.primary.main,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${project?.thumb_representations?.original?.url})`,
          }}
        />
      </Stack>
    </Page>
  );
};
