import { ColumnType } from '@utils';

const serviceProvider: ColumnType = () => ({
  field: 'service_agency.display_name',
  graphNested: {
    service_agency: ['display_name'],
  },
  headerName: 'Provider',
  minWidth: 100,
  groupable: false,
  aggregable: false,
  renderCell: ({ row }) => {
    return row?.service_agency?.display_name || '-';
  },
});

export default serviceProvider;
