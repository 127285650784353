import { isMobileOnly } from 'react-device-detect';
import { TeamRolesEnum } from '@interfaces';
const commonScreens = [
  '/profile',
  '/requests',
  '/projects',
  '/projects/:projectId',
  '/company',
  '/home',
  '/services',
];

const defaultSceenACL = {
  available: ['/profile', '/projects', '/projects/:projectId', '/company'],
  default: '/projects',
};

// map screens to team role
export const screensACL = isMobileOnly
  ? {
      [TeamRolesEnum.LENDER]: defaultSceenACL,
      [TeamRolesEnum.INVESTOR]: defaultSceenACL,
      [TeamRolesEnum.OWNER]: defaultSceenACL,
      [TeamRolesEnum.GENERAL_CONTRACTOR]: defaultSceenACL,
      [TeamRolesEnum.ADMIN]: defaultSceenACL,
      [TeamRolesEnum.CUSTOMER_SUCCESS]: defaultSceenACL,
      [TeamRolesEnum.SUPERUSER]: defaultSceenACL,
    }
  : {
      [TeamRolesEnum.LENDER]: {
        available: [...commonScreens, '/analytics', '/services'],
        default: '/requests',
      },
      [TeamRolesEnum.INVESTOR]: {
        available: [...commonScreens, '/analytics', '/services'],
        default: '/requests',
      },
      [TeamRolesEnum.OWNER]: {
        available: [...commonScreens, '/analytics'],
        default: '/projects',
      },
      [TeamRolesEnum.GENERAL_CONTRACTOR]: {
        available: commonScreens,
        default: '/projects',
      },
      [TeamRolesEnum.CUSTOMER_SUCCESS]: {
        available: [...commonScreens, '/platform-settings'],
        default: '/requests',
      },
    };
