import { PermissionNamesEnums } from '@interfaces';
import { isAllowed } from '@utils';
import { MilestoneListColumnTypeV2 } from './common';

const externalId: MilestoneListColumnTypeV2 = {
  field: 'external_id',
  headerName: 'External ID',
  description: 'External ID',
  headerClassName: 'text',
  headerAlign: 'left',
  align: 'left',
  cellClassName: 'text',
  sortable: true,
  filterable: false,
  groupable: false,
  aggregable: false,
  resizable: true,
  minWidth: 80,
  editableByMilestone: (row) => {
    return row.isInactiveProjectMode || row.isCreatedProjectMode;
  },
  editableByPermissions: (permissions) =>
    isAllowed(PermissionNamesEnums.DRAWREQUESTS_LINE_ITEM_CREATE, permissions),
};
export default externalId;
