import { useUpdateUiSettings } from '@hooks';
import React, { useContext } from 'react';
import { DataTableV3Interface } from '../interface';
import { PermissionsContext } from '@context';

export function withSettingsProtect(Component) {
  return function ProtectedComponent(props: DataTableV3Interface) {
    const { userSettings } = useUpdateUiSettings();
    const { permissions } = useContext(PermissionsContext);

    if (!userSettings && !permissions.length) {
      return null;
    }

    return <Component {...props} />;
  };
}
