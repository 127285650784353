import { isAllowed, percentFormatter, ColumnType } from '@utils';
import { PermissionNamesEnums, IDrawRequest } from '@interfaces';

const inspector_allowance_rate: ColumnType<IDrawRequest> = () => ({
  field: 'inspector_allowance_rate',
  graphFields: ['inspector_allowance_rate'],
  headerName: 'Inspector allowance (%)',
  minWidth: 120,
  groupable: false,
  aggregable: false,
  valueFormatter: (value) => percentFormatter({ returnValue: ' ', value }),
  enableByPermissions: (permissions) =>
    isAllowed(PermissionNamesEnums.INSPECTIONS_VIEW, permissions),
});

export default inspector_allowance_rate;
