import React from 'react';
import { Stack } from '@mui/material';
import { HookState, PermissionNamesEnums, PolicyListSourceEnums, TeamRolesEnum } from '@interfaces';
import { LoadingSkeleton, ServiceMessage } from '@components';
import * as Controller from './controller';
import { WithPermission } from '@utils';
import PoliciesContainer from '../PoliciesContainer';

const Policies = () => {
  const controller = Controller.usePolicies();
  const {
    borrowerPolicies,
    submissionPolicyTitle,
    approvalPolicyTitle,
    policies,
    state,
    editMode,
    handleCloseEdit,
    getLeavePageConfirmModal,
    handleEditMode,
  } = controller;

  switch (state) {
    case HookState.LOADING: {
      return <LoadingSkeleton type="twoBlocks" />;
    }

    case HookState.ERROR: {
      return <ServiceMessage text="policies" />;
    }

    case HookState.SUCCESS: {
      return (
        <Stack direction={{ lg: 'row', md: 'column' }} spacing={2} flex={1}>
          <PoliciesContainer
            policies={borrowerPolicies}
            title={submissionPolicyTitle}
            assigneeRole={TeamRolesEnum.OWNER}
            source={PolicyListSourceEnums.SUBMISSION}
            editMode={editMode}
            handleCloseEdit={handleCloseEdit}
            handleEditMode={handleEditMode}
          />
          <WithPermission permissionKey={PermissionNamesEnums.POLICIES_VIEW}>
            <PoliciesContainer
              policies={policies}
              title={approvalPolicyTitle}
              assigneeRole={TeamRolesEnum.LENDER}
              source={PolicyListSourceEnums.APPROVAL}
              editMode={editMode}
              handleCloseEdit={handleCloseEdit}
              handleEditMode={handleEditMode}
            />
          </WithPermission>
          {getLeavePageConfirmModal()}
        </Stack>
      );
    }

    default:
      return null;
  }
};

export default Policies;
