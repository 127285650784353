import { IDrawRequest } from '@interfaces';
import { ColumnType } from '@utils';
import React from 'react';

const project_state: ColumnType<IDrawRequest> = () => ({
  field: 'project.address.state',
  renderCell: ({ row }: { row: Partial<IDrawRequest> }) => (
    <span data-cy="requests_queue__table__body__project_state">{row.project?.address?.state}</span>
  ),
  graphFields: [],
  graphNested: {
    'project.address': ['state'],
  },
  headerName: 'State',
  minWidth: 100,
  groupable: false,
  sortable: false,
  filterable: false,
  aggregable: false,
});

export default project_state;
