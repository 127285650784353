import { useQuery } from 'react-query';
import { useMemo } from 'react';
import { Override } from '@utils';
import {
  IRequestReview,
  QueryNamesEnums,
  RequestReviewStatusEnum,
  TeamRolesEnum,
} from '@interfaces';
import { getRequestReviews } from '@globalService';

export type ControllerInterface = Override<
  ReturnType<typeof useApprovalsSummary>,
  {
    approvalReviews: IRequestReview[];
    fundReviews: IRequestReview[];
    isLoading: boolean;
  }
>;

export const useApprovalsSummary = ({
  drawRequestId,
  projectId,
}: {
  drawRequestId: string;
  projectId: string;
}) => {
  const restQlParams = '{status,created_by_team{name,role},created_by{id,full_name},created_at}';
  const reviewsQuery = useQuery<{ results: IRequestReview[] }, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST_REVIEWS, { projectId, drawRequestId, restQlParams }],
    getRequestReviews.bind(this, { projectId, drawRequestId, restQlParams }),
    { enabled: Boolean(projectId && drawRequestId) },
  );

  const approvalReviews = useMemo(
    () =>
      reviewsQuery.data?.results?.filter(
        (review) =>
          review.created_by_team?.role !== TeamRolesEnum.OWNER &&
          review.status === RequestReviewStatusEnum.APPROVE,
      ),
    [reviewsQuery],
  );

  const fundReviews = useMemo(
    () =>
      reviewsQuery.data?.results?.filter(
        (review) =>
          review.created_by_team?.role !== TeamRolesEnum.OWNER &&
          review.status === RequestReviewStatusEnum.FUND,
      ),
    [reviewsQuery],
  );

  return {
    approvalReviews,
    fundReviews,
    isLoading: reviewsQuery.isLoading,
  };
};
