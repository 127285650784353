import React, { FC } from 'react';
import { Button, Grid2, Stack, Typography } from '@mui/material';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import {
  ButtonWithTooltipOnClick,
  CenteredStyledBox,
  CustomRadioGroup,
  FullScreenDialog,
  LabelAndValue,
  LoadingSkeleton,
  ServiceRelatedDocuments,
  StyledBox,
  SuccessModal,
  WysiwygEditor,
  CustomRadioGroupGridLayout,
} from '@components';
import { colors } from '@theme';
import * as Controller from './controller';
import { ServiceTypeEnum } from '@interfaces';
import { ArrowRightIcon, TutorialIcon } from '@svgAsComponents';
import { useDayJsFormatter } from '@hooks';
import { useLaunchDarklyFlags } from '@context';

const ServiceComponent: FC<{
  drawRequestId?: string;
  onClose: () => void;
}> = ({ drawRequestId, onClose }) => {
  const { dateFormatter } = useDayJsFormatter();
  const { projectId } = useParams();
  const [searchParams] = useSearchParams();
  const service_type = searchParams.get('service_type');

  const {
    serviceAgenciesList,
    serviceAgenciesListIsLoading,
    agencySelected,
    setAgencySelected,
    serviceOrder,
    handleServiceOrderRequest,
    isSubmitting,
    isSubmitDisabled,
    isSuccessModalOpen,
    commentField,
    project,
    detailsPopup,
    setDetailsPopup,
    handleInfoIconClick,
    handleWhatIsTheBestOptionClick,
  } = Controller.useService({
    projectId,
    onClose,
    drawRequestId,
    serviceType: service_type as ServiceTypeEnum,
  });

  const flags = useLaunchDarklyFlags();

  if (serviceAgenciesListIsLoading)
    return (
      <CenteredStyledBox>
        <LoadingSkeleton type="overviewBlock" />
      </CenteredStyledBox>
    );

  return (
    <Stack sx={{ flex: 1, width: '100%', alignItems: 'center' }}>
      <Stack justifyContent="space-between" sx={{ flex: 1 }}>
        <Stack spacing={2}>
          <CenteredStyledBox>
            <Stack spacing={2} direction="row" alignItems="center" mb={4}>
              <Typography variant="h3">Service provider</Typography>

              {!!serviceAgenciesList?.length && (
                <Stack
                  onClick={handleWhatIsTheBestOptionClick}
                  direction="row"
                  alignItems="center"
                  sx={{
                    backgroundColor: colors.status.information.lighter,
                    borderRadius: 0.5,
                    minHeight: 24,
                    py: 0.5,
                    px: 1,
                    cursor: 'pointer',
                  }}
                  spacing={0.5}
                >
                  <TutorialIcon color={colors.status.information.medium} size={16} />
                  <Typography
                    variant="label"
                    sx={{ color: colors.status.information.medium, fontWeight: 600 }}
                  >
                    What is the best option?
                  </Typography>
                </Stack>
              )}
            </Stack>

            {flags?.['ENG_10053_order_service_experience_update'] ? (
              <CustomRadioGroupGridLayout
                dataTestName="order_service__service_agencies__radio__group"
                value={agencySelected || ''}
                onChange={(e) => setAgencySelected(e.target.value)}
                options={serviceAgenciesList}
                handleInfoIconClick={handleInfoIconClick}
              />
            ) : (
              <CustomRadioGroup
                dataTestName="order_service__service_agencies__radio__group"
                value={agencySelected || ''}
                onChange={(e) => setAgencySelected(e.target.value)}
                options={serviceAgenciesList}
              />
            )}
          </CenteredStyledBox>

          <CenteredStyledBox sx={{ flex: 1 }}>
            <Stack spacing={1}>
              <LabelAndValue
                label="Property type"
                text={serviceOrder?.project?.property_existing_type}
              >
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Typography variant="body3SemiBold">{project.property_existing_type}</Typography>
                  {project.property_proposed_type && (
                    <>
                      <ArrowRightIcon size={10} />
                      <Typography variant="body3SemiBold">
                        {project.property_proposed_type}
                      </Typography>
                    </>
                  )}
                </Stack>
              </LabelAndValue>
              <LabelAndValue label="Ordered date" text={dateFormatter({ date: new Date() })} />
            </Stack>
          </CenteredStyledBox>

          {serviceOrder?.id && agencySelected && (
            <ServiceRelatedDocuments
              drawRequestId={drawRequestId}
              serviceOrderId={serviceOrder?.id}
              serviceType={service_type as ServiceTypeEnum}
              source="order_service__related_documents"
            />
          )}

          <CenteredStyledBox>
            <Stack spacing={2}>
              <Typography variant="h3">Special instructions</Typography>

              <WysiwygEditor editField={commentField} source="order_service__comments" />
            </Stack>
          </CenteredStyledBox>

          <CenteredStyledBox>
            <Typography variant="body3">
              <span style={{ color: colors.status.information.medium, fontWeight: 700 }}>
                Disclaimer.{' '}
              </span>
              By clicking “Order” below, I agree and acknowledge that I am ordering a product or
              service from a third-party provider (“Provider”), not TrustPoint. I understand and
              agree that this order is subject to the Provider’s, and not TrustPoint’s, Terms and
              Conditions, which can be found{' '}
              <a
                href="https://www.trustpoint.ai/third-party-terms-of-service"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              . I certify that I have read and understood the Provider’s Terms and Conditions, and I
              agree to abide by, and be bound by, the Provider’s Terms and Conditions.
            </Typography>
          </CenteredStyledBox>

          {isSuccessModalOpen && (
            <SuccessModal
              text="Service has been ordered."
              open
              onClose={() => onClose()}
              buttons={<ModalBackToProjectServicesButtons projectId={projectId} />}
            />
          )}
        </Stack>
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        sx={{
          backgroundColor: colors.background.white,
          mt: 2,
          padding: '1.5rem',
          width: '100%',
        }}
      >
        <Button
          variant="new"
          sx={{ mr: '0.5rem' }}
          onClick={() => onClose()}
          data-cy="order_service__cancel__button"
        >
          Cancel
        </Button>
        <ButtonWithTooltipOnClick
          onClick={handleServiceOrderRequest}
          loading={isSubmitting}
          disabled={isSubmitting || !serviceOrder?.id || !agencySelected}
          conditionallyDisabled={isSubmitDisabled}
          dataTestName="order_service__submit__button"
        >
          {isSubmitting ? 'Ordering...' : 'Order'}
        </ButtonWithTooltipOnClick>
      </Stack>

      <FullScreenDialog
        open={detailsPopup.open}
        title={detailsPopup.title}
        actionButtons={null}
        onClose={() => setDetailsPopup({ open: false, title: '', content: null })}
        content={detailsPopup.content}
      />
    </Stack>
  );
};

export default ServiceComponent;

export const OptionsListDescription = ({ list }) => {
  return (
    <Grid2 container spacing={2} flexWrap="wrap">
      {list?.map((item) => (
        <Grid2 size={{ xs: 6 }} key={item.id}>
          <StyledBox>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="h3">{item.label}</Typography>
              {item.logo && (
                <img
                  alt="service agency logo"
                  src={item.logo}
                  style={{ minHeight: '50px', maxHeight: '50px' }}
                />
              )}
            </Stack>

            <Typography
              variant="body1"
              sx={{ mt: 1, fontSize: '14px' }}
              dangerouslySetInnerHTML={{ __html: item.description }}
            />
          </StyledBox>
        </Grid2>
      ))}
    </Grid2>
  );
};

export const OptionDescription = ({ item }) => {
  return (
    <StyledBox>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h3">{item.label}</Typography>
        {item.logo && (
          <img
            alt="service agency logo"
            src={item.logo}
            style={{ minHeight: '50px', maxHeight: '50px' }}
          />
        )}
      </Stack>

      <Typography
        variant="body1"
        sx={{ mt: 1, fontSize: '14px' }}
        dangerouslySetInnerHTML={{ __html: item.description }}
      />
    </StyledBox>
  );
};

const ModalBackToProjectServicesButtons: FC<{
  projectId: string;
}> = ({ projectId }) => {
  const navigate = useNavigate();
  return (
    <Stack direction="row">
      <Button
        variant="new"
        color="secondary"
        onClick={() => navigate(`/projects/${projectId}/services/`)}
        data-cy="order_service_modal_back_to_project_button"
      >
        Go to project
      </Button>
      <Button
        variant="new"
        color="secondary"
        onClick={() => navigate(`/services/`)}
        sx={{ ml: '8px' }}
        data-cy="order_service_modal_back_to_services_button"
      >
        Go to services list
      </Button>
    </Stack>
  );
};
