import { getArrayFromObject } from '@utils';
import { ProjectStatusMap } from '@constants';
import { ProjectStatusEnum } from '@interfaces';
import { FilterObject } from '../interface';

export const projectStatusFilterV2: FilterObject = {
  title: 'Project status',
  filterKey: 'project_status',
  needToUpdateUrl: true,
  needToUpdateSetting: true,
  defaultFilters: () => [ProjectStatusEnum.ACTIVE],
  getStaticValues: () => {
    return getArrayFromObject(ProjectStatusMap, 'value', 'label') as unknown as Array<{
      label: string;
      value: string;
    }>;
  },
  cypressSelector: 'filters__project_status',
};
