import { useContext, useMemo, useState } from 'react';
import {
  checkIsCustomerSuccess,
  getTeamRole,
  isActiveProject,
  isCreatedProject,
  isProjectPaused,
  isAllowed,
} from '@utils';
import { PermissionNamesEnums, ProjectStatusEnum, QueryNamesEnums } from '@interfaces';
import { AuthContext, PermissionsContext, SettingsContext, useGraphQuery } from '@context';
import { ProjectStatusMap, statusMap } from '@constants';
import { useParams } from 'react-router-dom';
import { useProjectActivationWarning } from '@hooks';

export interface ControllerInterface {
  valuesBasedOnPermissions: {
    statusesList: { [key: string]: string };
    labelText: string;
  };
  changeStatusComponent: {
    open: boolean;
    handleOpen: (value: boolean) => void;
  };
  statusValue: {
    color: string;
    backgroundColor: string;
    text: string;
  };
  isCurrentProjectActive: boolean;
  statusChangeReason: string;
}

export const useProjectOptions = (): ControllerInterface => {
  const { projectId } = useParams();
  const [isChangeStatusModalOpened, setIsChangeStatusModalOpened] = useState(false);
  const handleChangeStatusModal = (value) => setIsChangeStatusModalOpened(value);
  const {
    settings: { display: staticStatuses },
    isCurrentProjectActive,
    isCurrentProjectArchived,
  } = useContext(SettingsContext);
  const { permissions } = useContext(PermissionsContext);
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const projectActivationWarning = useProjectActivationWarning({
    projectId,
  });

  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['status', 'status_change_reason'],
    args: { project_id: projectId },
  });

  const valuesBasedOnPermissions = useMemo(() => {
    const isEditStatusEnabled = isAllowed(PermissionNamesEnums.PROJECTS_EDIT_STATUS, permissions);

    let statusesList = {};

    if (isEditStatusEnabled) {
      if (
        isProjectPaused(project.data) ||
        (isCreatedProject(project.data?.status) && !projectActivationWarning) ||
        isCurrentProjectArchived
      ) {
        statusesList[ProjectStatusEnum.ACTIVE] = ProjectStatusMap[ProjectStatusEnum.ACTIVE];
      }

      if (
        isProjectPaused(project.data) ||
        isCreatedProject(project.data?.status) ||
        isCurrentProjectArchived
      ) {
        statusesList[ProjectStatusEnum.DISCARDED] = ProjectStatusMap[ProjectStatusEnum.DISCARDED];
      }

      statusesList[ProjectStatusEnum.INACTIVE_COMPLETE] =
        ProjectStatusMap[ProjectStatusEnum.INACTIVE_COMPLETE];
      statusesList[ProjectStatusEnum.INACTIVE_INCOMPLETE] =
        ProjectStatusMap[ProjectStatusEnum.INACTIVE_INCOMPLETE];

      if (isActiveProject(project.data?.status)) {
        statusesList[ProjectStatusEnum.PAUSED] = ProjectStatusMap[ProjectStatusEnum.PAUSED];
      }

      if (isActiveProject(project.data?.status) || isProjectPaused(project.data)) {
        statusesList[ProjectStatusEnum.CREATED] = ProjectStatusMap[ProjectStatusEnum.CREATED];
      }
    }

    if (checkIsCustomerSuccess(teamRole)) {
      statusesList = ProjectStatusMap;
    }

    return {
      statusesList,
      labelText: 'Status',
    };
  }, [
    permissions,
    teamRole,
    isCurrentProjectActive,
    project.data,
    projectActivationWarning,
    isCurrentProjectArchived,
  ]);

  const statusValue = useMemo(
    () => (staticStatuses ? statusMap(project?.data?.status, staticStatuses, 'project') : {}),
    [project?.data?.status, staticStatuses],
  );

  return {
    valuesBasedOnPermissions,
    changeStatusComponent: {
      open: isChangeStatusModalOpened,
      handleOpen: handleChangeStatusModal,
    },
    statusValue,
    isCurrentProjectActive,
    statusChangeReason: project?.data?.status_change_reason,
  };
};
