import { ColumnType } from '@utils';

const externalId: ColumnType = () => ({
  field: 'provider_order_id',
  graphFields: ['provider_order_id'],
  headerName: 'External ID',
  minWidth: 80,
  groupable: false,
  aggregable: false,
  renderCell: ({ row }) => {
    return row.provider_order_id || '-';
  },
});

export default externalId;
