import React, { FC, memo, useContext } from 'react';
import { IconButton } from '@mui/material';

import { MilestoneListColumnTypeV2 } from './common';
import { InfoIcon, LineItemEditIconWithIndicator } from '@svgAsComponents';
import { CommonRowType, RequestTableTabTypesEnum, RequestTableTabsEnum } from '@interfaces';
import { creditAvailableForMilestone, wasCreditItteraction } from '@utils';
import { DrawerContext } from '../drawer';

const LineItemDetails: FC<{ row: CommonRowType }> = ({ row }) => {
  const { updateRightDrawer, source } = useContext(DrawerContext);
  const creditAvailable = creditAvailableForMilestone(row);
  const showCreditInfo = Boolean(wasCreditItteraction(row));
  if (row.id === 'totals') return null;

  return row.activeToEdit ? (
    <IconButton
      onClick={updateRightDrawer?.({
        milestoneId: row?.id,
        milestoneName: row?.name,
        tab: RequestTableTabsEnum.DETAILS,
        type: RequestTableTabTypesEnum.APPROVE,
        creditAvailable,
      })}
      data-cy={`${source}__body__line_item_details__button__icon__index_${row.project_milestone?.index}`}
    >
      <LineItemEditIconWithIndicator />
    </IconButton>
  ) : (
    <IconButton
      onClick={updateRightDrawer?.({
        milestoneId: row?.id,
        milestoneName: row?.name,
        tab: RequestTableTabsEnum.DETAILS,
        type: RequestTableTabTypesEnum.INFO,
        creditAvailable: showCreditInfo,
      })}
      data-cy={`${source}__body__line_item_details__button__icon__index_${row.project_milestone?.index}`}
    >
      <InfoIcon />
    </IconButton>
  );
};

const Memoized = memo(LineItemDetails);

const lineItemDetails: MilestoneListColumnTypeV2 = {
  field: 'additional_borrower_equity',
  headerAlign: 'right',
  headerClassName: 'icon',
  cellClassName: 'icon',
  renderHeader: () => null,
  editable: false,
  pinnable: false,
  disableColumnMenu: true,
  sortable: false,
  filterable: false,
  groupable: false,
  aggregable: false,
  hideable: false,
  resizable: false,
  renderCell: (params) => <Memoized row={params.row} />,
  maxWidth: 40,
  minWidth: 40,
};

export default lineItemDetails;
