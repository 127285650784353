import React, { FC, useRef, useEffect, useState } from 'react';
import { PieChart } from '@mui/x-charts';
import { IPieChartData } from '@interfaces';
import { CardinalDirections } from '@mui/x-charts/models/layout';
import { typography } from '@theme';
import { AnchorPosition, Direction } from '@mui/x-charts/ChartsLegend/legend.types';
import { LegendPerItemProps } from '@mui/x-charts/ChartsLegend/LegendPerItem';
import { Box, useTheme, useMediaQuery } from '@mui/material';

const PieChartItem: FC<{
  data: IPieChartData[];
  legend?: string;
  direction?: Direction;
  padding?: number | Partial<CardinalDirections<number>>;
  height?: number;
  legendPosition?: AnchorPosition;
  margin?: Partial<CardinalDirections<number>>;
  customLabels?: LegendPerItemProps['itemsToDisplay'];
  showTooltip?: boolean;
}> = ({
  data,
  legend,
  padding = 16,
  height = 120,
  legendPosition = {
    vertical: 'middle',
    horizontal: 'left',
  },
  margin,
  direction = 'column',
  customLabels,
  showTooltip = false,
}) => {
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(300);

  // Use MUI's useMediaQuery hook for responsive design
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Adjust chart properties based on screen size
  const chartCx = isSmallScreen ? '50%' : '100%';
  const legendPos = isSmallScreen
    ? ({ vertical: 'bottom', horizontal: 'middle' } as AnchorPosition)
    : legendPosition;

  useEffect(() => {
    // Function to measure container width
    const updateWidth = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };

    // Initial measurement
    updateWidth();

    // Use ResizeObserver for efficient resize detection
    if (typeof ResizeObserver !== 'undefined') {
      const resizeObserver = new ResizeObserver(updateWidth);
      if (containerRef.current) {
        resizeObserver.observe(containerRef.current);
      }
      return () => resizeObserver.disconnect();
    }

    return undefined;
  }, []);

  return (
    <Box
      ref={containerRef}
      sx={{
        width: '100%',
        // MUI's responsive styling
        [theme.breakpoints.down('sm')]: {
          maxWidth: '100%',
        },
      }}
    >
      <PieChart
        margin={margin}
        height={height}
        width={containerWidth}
        series={[
          {
            data,
            cx: chartCx,
            ...(showTooltip ? { valueFormatter: () => '' } : {}),
          },
        ]}
        slotProps={{
          legend: {
            position: legendPos,
            padding,
            ...(legend ? { labelStyle: { ...typography[legend] } } : {}),
            itemGap: 8,
            markGap: 4,
            direction: isSmallScreen ? 'row' : direction,
            ...(customLabels ? { seriesToDisplay: customLabels } : {}),
          },
        }}
        tooltip={{
          trigger: showTooltip ? 'item' : 'none',
        }}
      />
    </Box>
  );
};

export default PieChartItem;
