import React from 'react';
import { MilestoneListColumnTypeV2 } from './common';
import { isPercentAllowed, percentFormatter } from '@utils';

import { PercentsInput } from '@components';
import { GridEditInputCell } from '@mui/x-data-grid-premium';
import { InputBaseComponentProps } from '@mui/material';
import { CommonRowType } from '@interfaces';

const editableByMilestone = (row: CommonRowType) => {
  return row.isCreatedProjectMode && row.cost_type['key'] !== 'SOFT';
};

const retainageRateBudget: MilestoneListColumnTypeV2 = {
  field: 'retainage_rate',
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Retainage (%)',
  valueFormatter: (value) => percentFormatter({ returnValue: ' ', value }),
  valueParser: (value) => +value,
  renderEditCell: (props) => (
    <GridEditInputCell
      {...props}
      inputProps={{
        isAllowed: isPercentAllowed,
      }}
      inputComponent={PercentsInput as unknown as React.ElementType<InputBaseComponentProps>}
    />
  ),
  minWidth: 120,
  editableByMilestone,
};

export default retainageRateBudget;
