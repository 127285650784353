import { useContext, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import {
  checkIsCustomerSuccess,
  checkIsInvestor,
  checkIsLender,
  checkIsOwner,
  getTeamRole,
} from '@utils';
import { AuthContext } from '@context';
import { ICompany, IUser, QueryNamesEnums } from '@interfaces';
import { getMyCompany } from '@globalService';
import { useLogout } from '@hooks';
import { colors } from '@theme';

interface ControllerInterface {
  popover:
    | {
        anchorEl: null | HTMLElement;
        handleOpen: (event: React.MouseEvent<HTMLElement>) => void;
        handleClose: () => void;
      }
    | undefined;
  isCustomerSuccess: boolean;
  companyId: string;
  handleLogout: () => void;
  user: IUser;
  avatarBackgroundColor: string;
}

export const useUserProfileMenu = (): ControllerInterface => {
  const { user } = useContext(AuthContext);
  const { logout } = useLogout();
  const teamRole = getTeamRole(user);
  const isCustomerSuccess = checkIsCustomerSuccess(teamRole);
  const companyQuery = useQuery<ICompany, Error>(
    [QueryNamesEnums.GET_MY_COMPANY],
    getMyCompany.bind(this),
  );

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
  };

  const companyId = useMemo(() => {
    return companyQuery?.data?.id;
  }, [companyQuery]);

  const avatarBackgroundColor = useMemo(() => {
    if (checkIsLender(teamRole)) return colors.main.primary.main;
    if (checkIsOwner(teamRole)) return colors.status.information.medium;
    if (checkIsInvestor(teamRole)) return colors.status.violet.medium;
    return colors.neutral.dark;
  }, [teamRole]);

  return {
    popover: {
      anchorEl,
      handleOpen,
      handleClose,
    },
    isCustomerSuccess,
    companyId,
    handleLogout,
    user,
    avatarBackgroundColor,
  };
};
