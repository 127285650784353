import { useContext, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import {
  ErrorDual,
  QueryNamesEnums,
  IServiceOrderPayload,
  IServiceOrder,
  CommonServiceOrderPayload,
  PermissionNamesEnums,
  IInspection,
} from '@interfaces';
import {
  patchServiceOrderToProject,
  deleteServiceOrder,
  scheduleServiceOrderToProject,
  getProjectInspectionsList,
} from '@globalService';
import { AuthContext, useGraphQuery, PermissionsContext, SettingsContext } from '@context';
import {
  checkIsCustomerSuccess,
  getCurrentInspection,
  getTeamRole,
  isAllowed,
  isCancelRequestedService,
} from '@utils';
import { useSafeSnackbar, useServiceOrderStatusInvalidation } from '@hooks';
import { TOOLTIP_TEXTS } from '@constants';

export const useServiceQueriesAndMutations = ({ projectId, serviceOrderId, serviceType }) => {
  const { user } = useContext(AuthContext);
  const { permissions } = useContext(PermissionsContext);
  const { settings } = useContext(SettingsContext);
  const teamRole = getTeamRole(user);
  const isCustomerSuccess = checkIsCustomerSuccess(teamRole);
  const { enqueueSnackbar } = useSafeSnackbar();
  const handleServiceOrderChangeInvalidation = useServiceOrderStatusInvalidation();

  const serviceTypesMap = useMemo(
    () => settings?.display?.service_types,
    [settings?.display?.service_types],
  );

  const serviceAgenciesQuery = useGraphQuery({
    type: QueryNamesEnums.GET_SERVICE_AGENCIES,
    keys: ['id', 'display_name', 'logo', 'description', 'is_premium', 'service_provider'],
    args: { service_type: serviceType },
  });

  const projectInspectionsQuery = useQuery<{ results: IInspection[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_INSPECTIONS, { projectId }],
    getProjectInspectionsList.bind(this, { projectId }),
    {
      enabled: isAllowed(PermissionNamesEnums.INSPECTIONS_VIEW, permissions),
    },
  );

  const [serviceOrder, setServiceOrder] = useState(null);
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);

  const serviceAgenciesList = useMemo(
    () =>
      serviceAgenciesQuery.data?.results?.map((agency) => ({
        value: agency.id,
        label: agency.display_name,
        logo: agency.logo,
        description: agency.description,
        isPremium: agency.is_premium,
        service_provider: agency.service_provider,
      })) || [],
    [serviceAgenciesQuery.data?.results, serviceType],
  );

  const deleteServiceOrderMutation = useMutation<
    Response,
    Error,
    { projectId: string; serviceOrderId: string }
  >(deleteServiceOrder, {
    onSuccess: () => {
      setServiceOrder(null);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const serviceOrderQuery = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT_SERVICE_ORDER_BY_ID,
    args: { project_id: projectId, service_order_id: serviceOrderId },
    keys: [
      'id',
      'status',
      'is_editable',
      'service_type',
      'service_agency',
      'draw_request',
      'project',
    ],
  });

  const patchServiceOrder = useMutation<
    IServiceOrder,
    Error,
    { projectId: string; serviceOrderId: string; json: IServiceOrderPayload }
  >(patchServiceOrderToProject, {
    onSuccess: async (data: IServiceOrder) => {
      await handleSchedulingServiceOrder(data);
    },
    onError: (error, variables) => {
      enqueueSnackbar(error.message, { variant: 'error' });
      handleServiceOrderChangeInvalidation({
        projectId,
        serviceOrderId: serviceOrder?.id,
        drawRequestId: variables?.json?.draw_request,
      });
    },
  });

  const scheduleServiceOrder = useMutation<Response, ErrorDual, CommonServiceOrderPayload>(
    scheduleServiceOrderToProject,
    {
      onSuccess: () => {
        setSuccessModalOpen(true);
      },
      onError: () => {
        const errorText = isCustomerSuccess
          ? 'Project is not configured properly, please check required fields'
          : 'Project is not configured properly, please ask Customer Success for help';
        enqueueSnackbar(errorText, { variant: 'error' });
      },
      onSettled: () =>
        handleServiceOrderChangeInvalidation({ projectId, serviceOrderId: serviceOrder?.id }),
    },
  );

  const handleSchedulingServiceOrder = async (serviceOrder: IServiceOrder) => {
    await scheduleServiceOrder.mutateAsync({
      projectId,
      serviceOrderId: serviceOrder?.id,
    });
  };

  const inProgressInspection = useMemo(
    () => getCurrentInspection(projectInspectionsQuery.data?.results),
    [projectInspectionsQuery.data?.results],
  );
  const isInspectionCancelRequested = useMemo(
    () => isCancelRequestedService(inProgressInspection?.status),
    [inProgressInspection],
  );

  const inspectionDisabledTooltipText = useMemo(() => {
    if (!inProgressInspection) return '';
    if (isInspectionCancelRequested) {
      return TOOLTIP_TEXTS.inspectionCancelRequested;
    }
    return TOOLTIP_TEXTS.inspectionOrdered;
  }, [inProgressInspection, isInspectionCancelRequested]);

  return {
    serviceAgenciesList,
    serviceAgenciesListIsLoading: serviceAgenciesQuery.isLoading,
    patchServiceOrder,
    deleteServiceOrderMutation,
    isSuccessModalOpen,
    serviceOrder: serviceOrderQuery,
    inspectionDisabledTooltipText,
    serviceTypesMap,
  };
};
