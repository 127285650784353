import { IDrawRequest } from '@interfaces';
import { ColumnType } from '@utils';
import React, { useContext } from 'react';
import { statusMap } from '@constants';
import { SettingsContext } from '@context';
import { StatusChip } from '@components';
import { Tooltip } from '@mui/material';

const project_status: ColumnType<IDrawRequest> = () => ({
  field: 'project.status',
  renderCell: ({ row }: { row: Partial<IDrawRequest> }) => {
    const { settings } = useContext(SettingsContext);

    const projectStatusValue = statusMap(row.project.status, settings.display, 'project');
    return (
      <Tooltip title={row?.project?.status_change_reason}>
        <span>
          <StatusChip
            color={projectStatusValue.color}
            backgroundColor={projectStatusValue.backgroundColor}
            label={projectStatusValue.text}
          />
        </span>
      </Tooltip>
    );
  },
  graphFields: [],
  graphNested: {
    project: ['name', 'status', 'status_change_reason'],
  },
  headerName: 'Project status',
  minWidth: 120,
  groupable: false,
  sortable: false,
  filterable: false,
  aggregable: false,
});

export default project_status;
