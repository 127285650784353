// TODO: remove this file after ENG_9657_show_inspections_in_services is released
import { QueryNamesEnums, PermissionNamesEnums } from '@interfaces';
import { FilterObject } from '../interface';
import { isAllowed } from '@utils';

export const serviceProvider: FilterObject = {
  title: 'Other services provider',
  filterKey: 'service_agency',
  permissionKey: PermissionNamesEnums.INSPECTIONS_VIEW,
  needToUpdateUrl: false, // url is not updated because we use display_name instead of id
  needToUpdateSetting: false, // setting is not updated because we use display_name instead of id
  getDataParamsPaginated: ({ pagination, q, skip, id }) => ({
    type: QueryNamesEnums.GET_SERVICE_AGENCIES,
    keys: ['id', 'display_name', 'service_provider'],
    args: { pagination, q, id },
    options: {
      skip,
    },
  }),
  strictSerialize: (data, permissions) => {
    const uniqueItems = new Set();
    if (!data?.pages.length || !data?.pages[0]) return [];
    return data.pages
      ?.flatMap((page) =>
        page?.results.map((item) => {
          const label =
            isAllowed(
              [
                PermissionNamesEnums.CUSTOMER_SUCCESS_ACCESS,
                PermissionNamesEnums.SERVICES_HITL_RESULTS_EDIT,
              ],
              permissions,
            ) && item?.service_provider
              ? `${item.display_name} / ${item.service_provider}`
              : item.display_name;

          // Create a unique key combining both fields
          const uniqueKey = item.id;

          return {
            value: item.display_name,
            label,
            uniqueKey, // Temporary property for deduplication
          };
        }),
      )
      .filter((item) => {
        if (!uniqueItems.has(item.uniqueKey)) {
          uniqueItems.add(item.uniqueKey);
          return true;
        }
        return false;
      })
      .map(({ value, label }) => ({ value, label })); // Remove the temporary uniqueKey
  },
  cypressSelector: 'filters__services_list_agency',
  withPagination: true,
};
