import { useCallback, useContext, useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { useLocation, useParams } from 'react-router-dom';

import {
  ILocalFee,
  PermissionNamesEnums,
  QueryNamesEnums,
  ProjectUpdatePayload,
} from '@interfaces';
import { isCreatedProject, isAllowed } from '@utils';
import { updateProjectFields } from '@globalService';
import { PermissionsContext, useGraphQuery } from '@context';
import { IFeesControllerInterface, useEditPaymentConfigurationType, useFees } from '@hooks';

interface ControllerInterface {
  exitPath: string;
  isSubmitting: boolean;
  handleSubmitClick: (fees: ILocalFee[]) => void;
  feesController: IFeesControllerInterface;
  isUpdated: boolean;
  canEditFees: boolean;
  isLoading: boolean;
}

export const usePayments = (): ControllerInterface => {
  const queryClient = useQueryClient();
  const { projectId } = useParams();
  const { permissions } = useContext(PermissionsContext);
  const { enqueueSnackbar } = useSnackbar();
  const { state: locationState } = useLocation();

  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['payment_configuration_type', 'payment_configuration_comment', 'status'],
    args: { project_id: projectId },
  });

  const { configurationType, isConfigurationTypeChanged } = useEditPaymentConfigurationType({
    initialConfigurationType: project?.data?.payment_configuration_type,
  });
  const feesController = useFees();

  const updateProjectPaymentConfig = useMutation<
    Response,
    Error,
    {
      projectId: string;
      json: ProjectUpdatePayload;
    }
  >(updateProjectFields, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT, { project_id: projectId }]);
      queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST);
      queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_V2);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
      // set initial value if mutation failed
    },
  });

  const isEditable = useMemo(
    () =>
      isCreatedProject(project.data?.status) &&
      isAllowed(PermissionNamesEnums.PROJECTS_DETAILS_EDIT, permissions),
    [project.status, permissions],
  );

  const exitPath = useMemo(
    () => locationState?.['prevUrl'] || `/projects/${projectId}/overview`,
    [locationState, projectId],
  );

  const handleSubmitClick = useCallback(async () => {
    await updateProjectPaymentConfig.mutateAsync({
      projectId,
      json: {
        payment_configuration_type: configurationType,
        fees: feesController.fees.filter((fee) => fee.amount && fee.name.trim()),
      },
    });
    return true;
  }, [updateProjectPaymentConfig, projectId, configurationType, feesController.fees]);

  const canEditFees = useMemo(
    () => isAllowed(PermissionNamesEnums.PAYMENTS_MARK_AS_PAID, permissions),
    [permissions],
  );

  const isUpdated = useMemo(
    () =>
      (isEditable || canEditFees) && (isConfigurationTypeChanged || feesController.isFeesUpdated),
    [isConfigurationTypeChanged, feesController.isFeesUpdated, isEditable, canEditFees],
  );

  return {
    exitPath,
    isSubmitting: updateProjectPaymentConfig.isLoading,
    handleSubmitClick,
    feesController,
    isUpdated,
    canEditFees,
    isLoading: project.isPostLoading || feesController.isFeesLoading,
  };
};
