import React from 'react';
import { useSearchParams } from 'react-router-dom';

import SetPasswordForm from './SetupPassword';
import SimpleLogin from './SimpleLogin';

const LoginForm = () => {
  const [searchParams] = useSearchParams();

  const accountToken = searchParams.get('accountToken');

  if (accountToken) return <SetPasswordForm />;

  return <SimpleLogin />;
};

export default LoginForm;
