import { isAllowed, percentFormatter, ColumnType } from '@utils';
import { PermissionNamesEnums, IDrawRequest } from '@interfaces';

const variance_to_lender_allowance_rate: ColumnType<IDrawRequest> = () => ({
  field: 'variance_to_lender_allowance_rate',
  graphFields: ['variance_to_lender_allowance_rate'],
  headerName: 'Variance to lender allowance (%)',
  minWidth: 120,
  groupable: false,
  aggregable: false,
  valueFormatter: (value) => percentFormatter({ returnValue: ' ', value }),
  enableByPermissions: (permissions) =>
    isAllowed(PermissionNamesEnums.INSPECTIONS_VIEW, permissions),
});

export default variance_to_lender_allowance_rate;
