import { ColumnType, isAllowed } from '@utils';
import { PermissionNamesEnums } from '@interfaces';

const customer: ColumnType = () => ({
  field: 'customer.name',
  graphNested: {
    customer: ['name'],
  },
  headerName: 'Customer',
  minWidth: 150,
  groupable: false,
  aggregable: false,
  renderCell: ({ row }) => {
    return row.customer?.name || '-';
  },
  enableByPermissions: (permissions) =>
    isAllowed(PermissionNamesEnums.CUSTOMER_SUCCESS_ACCESS, permissions),
});

export default customer;
