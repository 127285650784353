import { useContext, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { matchPath, useLocation, useParams } from 'react-router-dom';
import find from 'lodash/find';

import { getHookState, isRequestDraft, isRequestInReview } from '@utils';
import {
  DeletePhotoPayload,
  DocumentArray,
  DocumentTabEnums,
  HookState,
  IDocument,
  IMilestoneWithPhotos,
  IPhoto,
  IProjectDocument,
  ITablePagination,
  QueryNamesEnums,
  UpdateProjectPhotoPayload,
} from '@interfaces';
import { AuthContext, useGraphQuery } from '@context';
import {
  deleteDrawRequestPhoto,
  getDrawRequestDocumentsList,
  getDrawRequestMilestonesWithPhotos,
  getProjectDocuments,
  getProjectMilestonesWithPhotos,
  postThumbToProject,
} from '@globalService';
import {
  ConfirmModalHookInterface,
  useConfirmationModal,
  useSafeSnackbar,
  useTablePagination,
  useUrlParams,
} from '@hooks';
import { notIncludeChildren, onlyProgressPhotos } from '@constants';

export interface ControllerInterface {
  state: HookState;
  photos: IPhoto[];
  projectId: string;
  drawRequestId: string;
  isAddPhotoAvailable: boolean;
  handlePhotoClick: (id: string, photos: DocumentArray) => void;
  activePhotoId: string;
  activePhoto: IPhoto;
  closeCallback: () => void;
  photosForSlider: IPhoto[];
  callbackOnPhotoChange: (id: string) => void;
  handlePhotoDelete: (photoId: string) => Promise<void>;
  isPhotoDeleting: boolean;
  userId: string;
  handleProjectImageUpdate: () => void;
  confirmUpdateProjectCoverModal: ConfirmModalHookInterface;
  milestonesWithPhotos: IMilestoneWithPhotos[];
  tablePagination: ITablePagination;
  itemsCount: number;
  relatedPhotos: {
    results: IProjectDocument[] | IDocument[];
    count: number;
  };
  showEmptyPhotos: boolean;
  isProjectTab: boolean;
  showPhotoHeader: boolean;
}

export const usePhotos = (): ControllerInterface => {
  const { user } = useContext(AuthContext);
  const { projectId } = useParams();
  const { pathname } = useLocation();
  const match = matchPath('/projects/:projectId/:tab/draw-requests/:drawRequestId', pathname);
  const matchTab = matchPath('/projects/:projectId/:tab/*', pathname);
  const tabPathname = matchTab?.params['*']?.split('/')[0];
  const drawRequestId = match?.params?.drawRequestId;
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();
  const confirmUpdateProjectCoverModal = useConfirmationModal();
  const isProjectTab = tabPathname === DocumentTabEnums.PROJECT;
  const rowsPerPage = 20;

  const drawRequest = useGraphQuery({
    type: QueryNamesEnums.GET_DRAW_REQUEST_V2,
    keys: ['status'],
    args: { project_id: projectId, draw_request_id: drawRequestId },
    options: {
      skip: !drawRequestId,
    },
  });

  const tablePagination = useTablePagination({
    initialRowsPerPage: rowsPerPage,
  });

  const queryParams = useMemo(() => {
    return `&offset=${tablePagination.page * rowsPerPage}&limit=${rowsPerPage}`;
  }, [tablePagination.page]);

  const drawRequestMilestonesWithPhotosQuery = useQuery<
    {
      results: IMilestoneWithPhotos[];
      count: number;
    },
    Error
  >(
    [
      QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES_WITH_PHOTOS,
      { projectId, drawRequestId, queryParams },
    ],
    getDrawRequestMilestonesWithPhotos.bind(this, projectId, drawRequestId, queryParams),
    { enabled: Boolean(drawRequestId) },
  );

  const projectTabQuery = useMemo(() => {
    const includeDrawRequestDocuments = isProjectTab ? '&include_draw_request_documents=false' : '';
    return includeDrawRequestDocuments + queryParams;
  }, [isProjectTab, queryParams]);

  const projectMilestonesWithPhotosQuery = useQuery<
    {
      results: IMilestoneWithPhotos[];
      count: number;
    },
    Error
  >(
    [QueryNamesEnums.GET_PROJECT_MILESTONES_WITH_PHOTOS, { projectId, query: projectTabQuery }],
    getProjectMilestonesWithPhotos.bind(this, { projectId, query: projectTabQuery }),
    { enabled: Boolean(projectId) },
  );

  const docQueryParams =
    notIncludeChildren + onlyProgressPhotos + projectTabQuery + '&offset=0&limit=7';
  const projectDocumentsQuery = useQuery<{ results: IProjectDocument[]; count: number }, Error>(
    [QueryNamesEnums.GET_PROJECT_DOCUMENTS, { projectId, stringQueryParams: docQueryParams }],
    getProjectDocuments.bind(this, { projectId, stringQueryParams: docQueryParams }),
    { enabled: Boolean(projectId) },
  );
  const drawRequestDocumentsQuery = useQuery<{ results: IDocument[]; count: number }, Error>(
    [
      QueryNamesEnums.GET_DRAW_REQUEST_DOCUMENTS,
      { projectId, drawRequestId, stringQueryParams: docQueryParams },
    ],
    getDrawRequestDocumentsList.bind(this, {
      projectId,
      drawRequestId,
      stringQueryParams: docQueryParams,
    }),
    {
      enabled: Boolean(projectId && drawRequestId),
    },
  );

  const relatedPhotos = useMemo(() => {
    return drawRequestId
      ? {
          results: drawRequestDocumentsQuery.data?.results || [],
          count: drawRequestDocumentsQuery.data?.count,
        }
      : {
          results: projectDocumentsQuery.data?.results || [],
          count: projectDocumentsQuery.data?.count,
        };
  }, [drawRequestDocumentsQuery.data, projectDocumentsQuery.data]);

  const milestonesWithPhotos = useMemo(() => {
    return drawRequestId
      ? drawRequestMilestonesWithPhotosQuery.data?.results || []
      : projectMilestonesWithPhotosQuery.data?.results || [];
  }, [drawRequestMilestonesWithPhotosQuery.data, projectMilestonesWithPhotosQuery.data]);

  const [photos, setPhotos] = useState([]);

  const findPhotoById = (id) => find(photos, { id });

  // handle photo click
  const [activePhotoId, setActivePhotoId] = useUrlParams(
    null,
    'photoId',
    (s) => s.toString(),
    (s) => s.toString(),
  );
  const [activePhoto, setActivePhoto] = useState(null);

  const handlePhotoClick = (id: string, photos: IPhoto[] | IProjectDocument[] | IDocument[]) => {
    setActivePhotoId(id);
    setPhotos(photos);
  };

  useEffect(() => {
    if (!activePhotoId) setActivePhoto(null);
    photos?.length && activePhotoId && setActivePhoto(findPhotoById(activePhotoId));
  }, [activePhotoId, photos]);

  useEffect(() => {
    setActivePhotoId(null);
  }, [drawRequestId, isProjectTab]);

  const callbackOnPhotoChange = (id: string) => setActivePhotoId(id);

  // remove photo functionality
  const deletePhotoMutation = useMutation<Response, Error, DeletePhotoPayload>(
    deleteDrawRequestPhoto,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST,
          { projectId, drawRequestId },
        ]);
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST_V2,
          { project_id: projectId, draw_request_id: drawRequestId },
        ]);
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES_WITH_PHOTOS,
          { projectId, drawRequestId },
        ]);
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE_DOCS,
          { projectId, drawRequestId },
        ]);
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_MILESTONE_DOCS, { projectId }]);
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST_DOCUMENTS,
          { projectId, drawRequestId },
        ]);
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_DOCUMENTS, { projectId }]);
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_PROJECT_MILESTONES_WITH_PHOTOS,
          { projectId },
        ]);
        queryClient.invalidateQueries([QueryNamesEnums.GET_INSPECTION_DOCUMENTS, { projectId }]);
        enqueueSnackbar('Photo deleted', { variant: 'success' });
        setActivePhotoId(null);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const handlePhotoDelete = async (photoId) => {
    await deletePhotoMutation.mutateAsync({
      projectId,
      photoId,
    });
  };

  const updateProjectImageMutation = useMutation<Response, Error, UpdateProjectPhotoPayload>(
    postThumbToProject,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT, { project_id: projectId }]);
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECTS_LIST]);
        enqueueSnackbar('Project cover photo updated', { variant: 'success' });
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const handleProjectImageUpdate = () => {
    updateProjectImageMutation.mutateAsync({
      projectId,
      file_representations: activePhoto.file_representations,
    });
  };

  const itemsCount = useMemo(
    () =>
      drawRequestId
        ? drawRequestMilestonesWithPhotosQuery.data?.count
        : projectMilestonesWithPhotosQuery.data?.count,
    [
      drawRequestMilestonesWithPhotosQuery.data,
      projectMilestonesWithPhotosQuery.data,
      drawRequestId,
    ],
  );

  const showEmptyPhotos = useMemo(() => {
    return milestonesWithPhotos?.length === 0 && relatedPhotos?.results?.length === 0;
  }, [milestonesWithPhotos, relatedPhotos]);

  const showPhotoHeader = useMemo(
    () => Boolean(itemsCount || relatedPhotos?.count),
    [itemsCount, relatedPhotos],
  );

  return {
    state: getHookState(
      drawRequestId ? drawRequestMilestonesWithPhotosQuery : projectMilestonesWithPhotosQuery,
    ),
    photos,
    projectId,
    drawRequestId,
    isAddPhotoAvailable:
      isProjectTab ||
      isRequestInReview(drawRequest.data?.status) ||
      isRequestDraft(drawRequest.data?.status),
    handlePhotoClick,
    activePhoto,
    activePhotoId,
    closeCallback: () => setActivePhotoId(null),
    photosForSlider: [],
    callbackOnPhotoChange,
    handlePhotoDelete,
    isPhotoDeleting: deletePhotoMutation.isLoading,
    userId: user?.id,
    handleProjectImageUpdate,
    confirmUpdateProjectCoverModal,
    milestonesWithPhotos,
    tablePagination,
    itemsCount,
    relatedPhotos,
    showEmptyPhotos,
    isProjectTab,
    showPhotoHeader,
  };
};
