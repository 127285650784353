import React, { FC } from 'react';
import { Button, Checkbox, FormControlLabel, Skeleton, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import * as Controller from './controller';
import { ICoordinator } from '@interfaces';
import { LinkIcon } from '@svgAsComponents';
import { Popup, ButtonWithTooltip, CustomAutocomplete } from '@components';
const AssignCoordinatorPopup: FC<{
  open: boolean;
  onClose: () => void;
  initCoordinator: ICoordinator;
  requestId?: string;
  prId?: string;
}> = ({ open, onClose, initCoordinator, requestId, prId }) => {
  const { projectId } = useParams();
  const {
    coordinatorField,
    list,
    onAssignCoordinator,
    onUnassignCoordinator,
    shouldUpdateProjectCoordinator,
    setShouldUpdateProjectCoordinator,
    isMutating,
    isCoordinatorEditable,
    isChanged,
    isCoordinatorsListLoading,
  } = Controller.useAssignCoordinatorPopup(initCoordinator, prId || projectId, requestId, onClose);

  return (
    <Popup open={open} maxWidth="md" title="Assign coordinator" icon={LinkIcon}>
      <Stack sx={{ width: '100%' }}>
        {isCoordinatorsListLoading && <Skeleton variant="rectangular" height={40} />}
        {Boolean(list.length) && (
          <CustomAutocomplete
            field={coordinatorField}
            label="Coordinator"
            options={list}
            optionsLoading={isCoordinatorsListLoading}
            inputProps={{
              'data-cy': 'draw_switch_coordinator_select',
            }}
            disabled={!isCoordinatorEditable}
            clearIcon={false}
          />
        )}

        {requestId && (
          <FormControlLabel
            control={
              <Checkbox
                checked={shouldUpdateProjectCoordinator}
                onChange={(_e, value) => setShouldUpdateProjectCoordinator(value)}
              />
            }
            label={<Typography variant="body2">Apply to the next requests as well.</Typography>}
          />
        )}

        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
          sx={{ mt: '3.5rem' }}
        >
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          {!coordinatorField.isChanged && (
            <ButtonWithTooltip
              variant="new"
              color="error"
              tooltipText="No changes made"
              onClick={onUnassignCoordinator}
              loading={isMutating}
              dataTestName="draw_switch_coordinator_unassign_button"
            >
              Unassign
            </ButtonWithTooltip>
          )}
          <ButtonWithTooltip
            onClick={onAssignCoordinator}
            loading={isMutating}
            dataTestName="draw_switch_coordinator_save_button"
            tooltipText={
              !coordinatorField.value?.id ? 'Please choose a coordinator' : 'No changes made'
            }
            disabled={!coordinatorField.value?.id || !isChanged}
          >
            Assign
          </ButtonWithTooltip>
        </Stack>
      </Stack>
    </Popup>
  );
};

export default AssignCoordinatorPopup;
