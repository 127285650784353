import React from 'react';
import { Skeleton, Stack } from '@mui/material';
import { StatusProcessedIcon, StatusUnProcessedIcon } from '@svgAsComponents';
import { documentCanBeProcessed } from '@utils';

const processedStatusColumn = ({ isLoading }) => ({
  Header: 'Processed',
  accessor: 'processed_status',
  disableFilters: true,
  disableSortBy: true,
  Cell: ({ row }) => {
    const { has_smart_summary } = row?.original || {};
    if (isLoading) return <Skeleton />;

    return (
      <Stack direction="row" justifyContent="center" alignItems="center" width="106px">
        {!documentCanBeProcessed(row?.original) ? (
          '-'
        ) : has_smart_summary ? (
          <StatusProcessedIcon />
        ) : (
          <StatusUnProcessedIcon />
        )}
      </Stack>
    );
  },
});

export default processedStatusColumn;
