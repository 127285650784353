import { useContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import { SettingsContext, useGraphQuery } from '@context';
import { useFilesUploader } from '@hooks';
import {
  DocumentContentTypeEnum,
  IMilestone,
  IProjectMilestone,
  QueryNamesEnums,
  TransloaditTemplateEnum,
} from '@interfaces';
import { ControllerProps, UploaderControllerInterface } from './interface';
import { getDrawRequestMilestones, getProjectMilestonesList } from '@globalService';
import { excludeCommentsQueryFields, LineItemFilterValues } from '@constants';

export const useDocumentUploader = ({
  projectId,
  drawRequestId,
  milestoneId,
  milestoneSubmitId,
  inspectionId,
  serviceOrderId,
}: ControllerProps): UploaderControllerInterface => {
  const {
    isFilesUploaderOpened,
    filesUploaderClose,
    transloaditSignature,
    uploadMedia,
    restrictions,
  } = useFilesUploader();
  const { isPHBProject } = useContext(SettingsContext);

  // get documents types
  const documentTypesQuery = useGraphQuery({
    type: QueryNamesEnums.GET_DOCUMENTS_TYPES,
    keys: ['id', 'name'],
    args: {},
  });

  const query = excludeCommentsQueryFields;
  const projectMilestonesQuery = useQuery<{ results: IProjectMilestone[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_MILESTONES, { projectId, query }],
    getProjectMilestonesList.bind(this, { projectId, query }),
    { enabled: !drawRequestId && !isPHBProject },
  );

  const drawRequestMilestonesQuery = useQuery<{ results: IMilestone[] }, Error>(
    [
      QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
      {
        projectId,
        drawRequestId,
        filterKey: LineItemFilterValues.ALL.filterKey,
      },
    ],
    getDrawRequestMilestones.bind(this, {
      projectId,
      drawRequestId,
      filterKey: LineItemFilterValues.ALL.filterKey,
    }),
    { enabled: Boolean(projectId && drawRequestId && !isPHBProject) },
  );

  const handleOpenDocumentUploader = () => {
    const contentType = () => {
      if (inspectionId) return DocumentContentTypeEnum.INSPECTION;
      if (serviceOrderId) return DocumentContentTypeEnum.SERVICE_ORDER;
      if (milestoneId && isPHBProject) return DocumentContentTypeEnum.MILESTONE_GROUP;
      if (milestoneSubmitId) return DocumentContentTypeEnum.MILESTONE_SUBMIT;
      if (drawRequestId) return DocumentContentTypeEnum.DRAW_REQUEST;
      if (milestoneId) return DocumentContentTypeEnum.MILESTONE;
      return DocumentContentTypeEnum.PROJECT;
    };

    const objectId = () =>
      isPHBProject && milestoneId
        ? milestoneId
        : inspectionId ||
          serviceOrderId ||
          milestoneSubmitId ||
          drawRequestId ||
          milestoneId ||
          projectId;

    const fields = {
      content_type: contentType(),
      object_id: objectId(),
    };
    uploadMedia({
      fields,
      templateType: TransloaditTemplateEnum.DOCUMENTS,
    });
  };

  const isMilestoneDocs = useMemo(
    () => Boolean(milestoneId) || Boolean(milestoneSubmitId),
    [milestoneId, milestoneSubmitId],
  );

  const milestones = useMemo(() => {
    if (isMilestoneDocs) return [];
    else if (drawRequestId) return drawRequestMilestonesQuery.data?.results || [];
    return (projectMilestonesQuery.data?.results as IMilestone[]) || [];
  }, [
    isMilestoneDocs,
    drawRequestId,
    projectMilestonesQuery.data?.results,
    drawRequestMilestonesQuery.data?.results,
  ]);

  return {
    isFilesUploaderOpened,
    filesUploaderClose,
    transloaditSignature,
    restrictions,
    handleOpenDocumentUploader,
    milestones,
    documentTypes: documentTypesQuery.data,
  };
};
