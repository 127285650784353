import { useContext, useMemo } from 'react';
import { useQuery } from 'react-query';

import { PermissionsContext } from '@context';
import {
  IServiceOrder,
  PermissionNamesEnums,
  IInspection,
  QueryNamesEnums,
  ServiceOrderStatusEnum,
  ICompany,
  FieldProperties,
} from '@interfaces';
import { getMyCompany, getProjectInspectionsList } from '@globalService';
import {
  isServiceProviderAutomatedByCS,
  isAllowed,
  isServiceProviderManual,
  isTruePicInspection,
  isPendingService,
  isOrderedService,
  isInspectionService,
  isCompletedService,
  isServiceReadyForReview,
} from '@utils';

export interface ServiceFieldsVisibility {
  [key: string]: FieldProperties;
}

export const useServiceOrderFieldsProperties = ({
  serviceOrder,
}: {
  serviceOrder:
    | (Pick<IServiceOrder, 'service_type' | 'status' | 'id'> & {
        project: Pick<IServiceOrder['project'], 'id' | 'name' | 'status'>;
        service_agency: Pick<
          IServiceOrder['service_agency'],
          'display_name' | 'service_provider' | 'id'
        >;
      })
    | Pick<IInspection, 'project' | 'service_type' | 'status' | 'id'>;
}): ServiceFieldsVisibility => {
  const { permissions } = useContext(PermissionsContext);

  const serviceProviderType = useMemo(() => {
    return (
      (serviceOrder as IServiceOrder)?.service_agency?.service_provider ||
      (serviceOrder as IInspection)?.inspection_agency?.service
    );
  }, [serviceOrder]);

  const isEditableByTypeAndPermission = useMemo(() => {
    if (!serviceOrder || !permissions?.length) {
      return false;
    }

    const canBeEditedByExternalUser =
      isAllowed(PermissionNamesEnums.INSPECTIONS_EDIT, permissions) &&
      isServiceProviderManual(serviceProviderType);

    const canBeEditedByCustomerSuccess =
      isAllowed(
        [
          PermissionNamesEnums.CUSTOMER_SUCCESS_ACCESS,
          PermissionNamesEnums.SERVICES_HITL_RESULTS_EDIT,
        ],
        permissions,
      ) && isServiceProviderAutomatedByCS(serviceProviderType);

    return canBeEditedByExternalUser || canBeEditedByCustomerSuccess;
  }, [permissions, serviceOrder]);

  const isEditableByPermissionOnly = useMemo(() => {
    if (!serviceOrder || !permissions?.length) {
      return false;
    }

    const canBeEditedByExternalUser = isAllowed(PermissionNamesEnums.INSPECTIONS_EDIT, permissions);

    const canBeEditedByCustomerSuccess = isAllowed(
      [
        PermissionNamesEnums.CUSTOMER_SUCCESS_ACCESS,
        PermissionNamesEnums.SERVICES_HITL_RESULTS_EDIT,
      ],
      permissions,
    );

    return canBeEditedByExternalUser || canBeEditedByCustomerSuccess;
  }, [permissions, serviceOrder]);

  const isUserHasCSPermission = useMemo(
    () =>
      isAllowed(
        [
          PermissionNamesEnums.CUSTOMER_SUCCESS_ACCESS,
          PermissionNamesEnums.SERVICES_HITL_RESULTS_EDIT,
        ],
        permissions,
      ),
    [permissions],
  );

  const isUserHasEditPermission = useMemo(
    () => isAllowed(PermissionNamesEnums.INSPECTIONS_EDIT, permissions),
    [permissions],
  );

  // ask for the last completed inspection, only id to compare with current inspection id
  // enable only if inspection service and completed status
  const query = '{id}';
  const stringQueryParams = `status=${ServiceOrderStatusEnum.COMPLETED}&limit=1`;
  const projectInspectionsQuery = useQuery<{ results: IInspection[] }, Error>(
    [
      QueryNamesEnums.GET_PROJECT_INSPECTIONS,
      { projectId: serviceOrder?.project?.id, query, stringQueryParams },
    ],
    getProjectInspectionsList.bind(this, {
      projectId: serviceOrder?.project?.id,
      query,
      stringQueryParams,
    }),
    {
      enabled:
        isInspectionService(serviceOrder?.service_type) && isCompletedService(serviceOrder?.status),
    },
  );

  const companyQuery = useQuery<ICompany, Error>(
    [QueryNamesEnums.GET_MY_COMPANY],
    getMyCompany.bind(this),
  );

  const isLastCompletedInspection = useMemo(
    () =>
      isInspectionService(serviceOrder?.service_type) &&
      projectInspectionsQuery.data?.results?.[0]?.id === serviceOrder?.id,
    [projectInspectionsQuery.data, serviceOrder?.service_type, serviceOrder?.id],
  );

  const isInspectionEditable = useMemo(() => {
    if (!isEditableByTypeAndPermission) return false;

    if (isOrderedService(serviceOrder.status) || isLastCompletedInspection) return true;

    if (
      isPendingService(serviceOrder?.status) &&
      isServiceProviderAutomatedByCS(serviceProviderType)
    )
      return true;

    return false;
  }, [serviceOrder, serviceProviderType, isEditableByTypeAndPermission, isLastCompletedInspection]);

  // TruePic scenario - it's automated, but lender can check, enter results, and confirm it when it's 'Ready for review' status or last completed inspection
  const isTruePicInspectionEditable = useMemo(
    () =>
      isTruePicInspection(serviceProviderType) &&
      (isServiceReadyForReview(serviceOrder?.status) || isLastCompletedInspection),
    [serviceProviderType, serviceOrder?.status, isLastCompletedInspection],
  );

  const canViewTotalInspectorAllowanceRate = useMemo(
    () => companyQuery.data?.is_single_value_inspection_enabled,
    [companyQuery.data?.is_single_value_inspection_enabled],
  );

  const fieldsProperties = useMemo(() => {
    const isEditable = isInspectionService(serviceOrder?.service_type)
      ? isInspectionEditable
      : isEditableByTypeAndPermission;
    const isEditableByCSOnly =
      isUserHasCSPermission && isServiceProviderAutomatedByCS(serviceProviderType);
    return {
      provider_order_id: {
        view: true,
        edit: isEditable && isEditableByCSOnly, // TODO remove isEditableByCSOnly when https://github.com/trustpointai/bumblebee/pull/5606 is merged
      },
      status: {
        view: true,
        edit: isEditableByCSOnly,
      },
      provider_status: {
        view: true,
        edit: isEditable && isEditableByCSOnly,
      },
      provider_service_cost: {
        view: isUserHasCSPermission,
        edit: isEditable && isEditableByCSOnly,
      },
      transaction_id: {
        view: isUserHasCSPermission,
        edit: isEditable,
      },
      draw_request: {
        view: true,
        edit: isEditableByPermissionOnly,
      },
      completed_at: {
        view: isEditable || isTruePicInspectionEditable,
        edit: isEditable || isTruePicInspectionEditable,
      },
      documents: {
        view: true,
        edit: isEditable,
      },
      confirm_button: {
        view: isEditable || isTruePicInspectionEditable,
        label: isCompletedService(serviceOrder?.status) ? 'Update' : 'Confirm',
      },
      title: {
        label: isEditable ? 'Service - Enter results' : 'Service results',
      },
      inspection_allowance: {
        view: true,
        edit: isEditable || isTruePicInspectionEditable,
      },
      inspector_allowance_rate: {
        view: canViewTotalInspectorAllowanceRate,
        edit: isEditable && canViewTotalInspectorAllowanceRate,
      },
    };
  }, [
    isEditableByTypeAndPermission,
    isInspectionEditable,
    isUserHasCSPermission,
    isUserHasEditPermission,
    serviceProviderType,
    serviceOrder,
    isTruePicInspectionEditable,
    canViewTotalInspectorAllowanceRate,
  ]);

  return fieldsProperties;
};
