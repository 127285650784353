import { IDrawRequest, PermissionNamesEnums } from '@interfaces';
import { ColumnType, isAllowed } from '@utils';
import React from 'react';

const project_investor: ColumnType<IDrawRequest> = () => ({
  field: 'project.investor_name',
  renderCell: ({ row }: { row: Partial<IDrawRequest> }) => (
    <span data-cy="requests_queue__table__body__project_investor">{row.project.investor_name}</span>
  ),
  graphFields: [],
  graphNested: {
    project: ['investor_name'],
  },
  enableByPermissions: (permissions) => isAllowed(PermissionNamesEnums.INVESTOR_VIEW, permissions),
  headerName: 'Investor',
  minWidth: 190,
  groupable: false,
  sortable: false,
  filterable: false,
  aggregable: false,
});

export default project_investor;
