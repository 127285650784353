import { isInspectionService, ColumnType } from '@utils';

const providerStatus: ColumnType = () => ({
  field: 'provider_status_display',
  graphFields: ['provider_status_display'],
  headerName: 'Provider status',
  minWidth: 120,
  groupable: false,
  aggregable: false,
  renderCell: ({ row }) => {
    if (!isInspectionService(row.service_type)) {
      return null;
    }
    return row.provider_status_display || '-';
  },
});

export default providerStatus;
