import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  DrawRequestSourceEnum,
  DrawRequestTypeEnum,
  IDrawRequest,
  IMenuItem,
  IProjectMilestone,
  PermissionNamesEnums,
  PostDrawRequest,
  QueryNamesEnums,
} from '@interfaces';

import {
  getProjectDrawRequestsList,
  getProjectMilestonesList,
  postDrawRequest,
} from '@globalService';
import { useContinueDraftButton, useReviewRequestInvalidation, useSafeSnackbar } from '@hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext, PermissionsContext, SettingsContext } from '@context';
import {
  getActiveDrawRequestFromList,
  getTeamRole,
  isChangeRequest,
  isRequestDraft,
  isRequestInReview,
  isAllowed,
  checkIsOwner,
} from '@utils';
import { ChangeIcon, DrawRequestIcon, HistoryIcon } from '@svgAsComponents';
import { TOOLTIP_TEXTS } from '@constants';

export interface ControllerInterface {
  createRequest: (values) => void;
  isLoading: boolean;
  isSubmitting: boolean;
  menuItems: IMenuItem[];
  drawRequestInProgress: IDrawRequest;
  isCurrentProjectArchived: boolean;
  infoModalText: string;
  closeInfoModal: () => void;
  openDraft: () => void;
  isCurrentProjectActive: boolean;
  createModalVisible: boolean;
  setCreateModal: React.Dispatch<React.SetStateAction<boolean>>;
  continueDraftButtonLabel: string;
  disabledButton?: boolean;
  disabledTooltipText?: string;
  isOwner: boolean;
}

export const useAddRequestButton = (): ControllerInterface => {
  const [createModalVisible, setCreateModal] = useState<boolean>(false);
  const { permissions } = useContext(PermissionsContext);
  const { isCurrentProjectArchived, isCurrentProjectActive } = useContext(SettingsContext);
  const { enqueueSnackbar } = useSafeSnackbar();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const queryClient = useQueryClient();
  const [infoModalText, setInfoModalText] = useState<string>('');
  const handleRequestReviewInvalidation = useReviewRequestInvalidation();

  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const isOwner = useMemo(() => checkIsOwner(teamRole), [teamRole]);

  const drawRequestsQuery = useQuery<{ results: IDrawRequest[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }],
    getProjectDrawRequestsList.bind(this, projectId),
  );

  const query = '{}';
  const limit = '1';
  // request project milestones list to understand if budget was added or not
  const projectMilestonesQuery = useQuery<{ results: IProjectMilestone[]; count: number }, Error>(
    [QueryNamesEnums.GET_PROJECT_MILESTONES, { projectId, query, limit }],
    getProjectMilestonesList.bind(this, { projectId, query, limit }),
  );

  const drawRequestInProgress = useMemo(
    () => getActiveDrawRequestFromList(drawRequestsQuery.data?.results),
    [drawRequestsQuery.data],
  );

  const draftRequest = useMemo(() => {
    const DRList = drawRequestsQuery.data?.results || [];
    return DRList.find((dr) => isRequestDraft(dr?.status));
  }, [drawRequestsQuery.data]);

  const addRequestMutation = useMutation<IDrawRequest, Error, PostDrawRequest>(postDrawRequest, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT, { project_id: projectId }]);
      handleRequestReviewInvalidation({
        projectId,
        drawRequestId: data?.id,
      });
      if (isRequestDraft(data.status)) {
        navigate(`/projects/${projectId}/requests/${data?.id}/submission/`);
      }

      // TODO: remove this after ENG_9543_lender_submission_2.0
      if (isRequestInReview(data.status)) {
        navigate(`/projects/${projectId}/draws/draw-requests/${data?.id}/`);
      }
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const createRequest = useCallback(
    (chosenType: DrawRequestTypeEnum, chosenSource?: DrawRequestSourceEnum) => {
      if (draftRequest) {
        setInfoModalText(
          `There is Draft ${isChangeRequest(draftRequest) ? 'CR' : 'DR'} created by Borrower user ${
            draftRequest.team?.company_name || ''
          }. If you need to create a new request on behalf of the borrower, please contact Customer Support.`,
        );
        return;
      }
      addRequestMutation.mutateAsync({
        id: projectId,
        type: chosenType,
        ...(chosenSource && { source: chosenSource }),
      });
    },
    [projectId, draftRequest],
  );

  const menuItems = useMemo(() => {
    return [
      ...(isAllowed(PermissionNamesEnums.DRAWREQUESTS_CREATE, permissions)
        ? [
            {
              action: () => createRequest(DrawRequestTypeEnum.DRAW_REQUEST),
              text: 'Draw',
              icon: <DrawRequestIcon />,
              disabled: Boolean(drawRequestInProgress) || !isCurrentProjectActive,
              dataTestName: 'add_draw_draft',
              disabledTooltipText: isCurrentProjectActive
                ? TOOLTIP_TEXTS.activeRequest
                : TOOLTIP_TEXTS.isCurrentProjectArchived,
            },
            {
              action: () => createRequest(DrawRequestTypeEnum.CHANGE_REQUEST),
              text: 'Change',
              icon: <ChangeIcon />,
              dataTestName: 'add_change_request',
              disabled: Boolean(drawRequestInProgress) || !isCurrentProjectActive,
              disabledTooltipText: isCurrentProjectActive
                ? TOOLTIP_TEXTS.activeRequest
                : TOOLTIP_TEXTS.isCurrentProjectArchived,
            },
          ]
        : []),
      ...(isAllowed(PermissionNamesEnums.DRAWREQUESTS_MANUAL_CREATE, permissions)
        ? [
            {
              action: () =>
                createRequest(DrawRequestTypeEnum.DRAW_REQUEST, DrawRequestSourceEnum.MANUAL),
              text: 'Historical draw',
              icon: <HistoryIcon />,
              dataTestName: 'add_historical_draw',
              disabled: Boolean(drawRequestInProgress) || !projectMilestonesQuery?.data?.count,
              disabledTooltipText: drawRequestInProgress
                ? TOOLTIP_TEXTS.activeRequest
                : TOOLTIP_TEXTS.noBudget,
              sectionBreak: isAllowed(PermissionNamesEnums.INSPECTIONS_EDIT, permissions),
            },
          ]
        : []),
    ];
  }, [
    draftRequest,
    drawRequestInProgress,
    permissions,
    isCurrentProjectActive,
    projectMilestonesQuery?.data?.count,
  ]);

  const { openDraft, continueDraftButtonLabel } = useContinueDraftButton({
    projectId,
    drawRequestId: draftRequest?.id,
    drawRequest: draftRequest,
  });

  return {
    menuItems,
    isLoading: drawRequestsQuery.isLoading || addRequestMutation.isLoading,
    isSubmitting: addRequestMutation.isLoading,
    createRequest,
    drawRequestInProgress,
    isCurrentProjectArchived,
    infoModalText,
    closeInfoModal: () => setInfoModalText(''),
    openDraft,
    createModalVisible,
    isCurrentProjectActive,
    setCreateModal,
    continueDraftButtonLabel,
    disabledButton: Boolean(drawRequestInProgress) || !isCurrentProjectActive,
    disabledTooltipText: isCurrentProjectArchived
      ? TOOLTIP_TEXTS.isCurrentProjectArchived
      : isCurrentProjectActive
        ? TOOLTIP_TEXTS.activeRequest
        : TOOLTIP_TEXTS.isCurrentProjectArchived,
    isOwner,
  };
};
