import React, { ReactElement, useContext, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Stack, Typography } from '@mui/material';
import { checkIsOwner, getTeamRole, isAllowed, isRequestDraft } from '@utils';
import { AuthContext, useGraphQuery, PermissionsContext } from '@context';
import {
  ICommentsPreview,
  IDrawRequest,
  IProjectScores,
  PermissionNamesEnums,
  QueryNamesEnums,
  ScoreColorEnum,
  TeamRolesEnum,
} from '@interfaces';
import { getProjectDrawRequestsList, getProjectScoresNew } from '@globalService';
import { colors } from '@theme';
import { StyledLink } from '@components';
import { WIDGET_TYPE } from '@constants';

type WidgetType = (typeof WIDGET_TYPE)[keyof typeof WIDGET_TYPE];

interface ControllerInterface {
  projectCommentsPreview: ICommentsPreview;
  teamRole: TeamRolesEnum;
  returnedRequestWarning: ReactElement;
  openWidget: (widget: WidgetType) => void;
  closeWidget: (widget: WidgetType) => void;
  widgetsOpened: Record<WidgetType, boolean>;
  hasYellowOrRedScore: boolean;
  wideChecklistButton: boolean;
}

export const useProjectMainInfo = (projectId: string): ControllerInterface => {
  const { user } = useContext(AuthContext);
  const { permissions } = useContext(PermissionsContext);
  const teamRole = getTeamRole(user);
  const [widgetsOpened, setWidgetsOpened] = useState<Record<WidgetType, boolean>>({
    [WIDGET_TYPE.SCORES]: false,
    [WIDGET_TYPE.WATCHER]: false,
    [WIDGET_TYPE.CHECKLIST]: false,
  });

  const openWidget = (widget: WidgetType) => {
    setWidgetsOpened((prev) => ({ ...prev, [widget]: true }));
  };

  const closeWidget = (widget: WidgetType) => {
    setWidgetsOpened((prev) => ({ ...prev, [widget]: false }));
  };

  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['status', 'name', 'comments_preview'],
    args: { project_id: projectId },
  });

  const drawRequestsQuery = useQuery<{ results: IDrawRequest[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }],
    getProjectDrawRequestsList.bind(this, projectId),
    { enabled: checkIsOwner(teamRole) },
  );

  const scoresQuery = useQuery<IProjectScores, Error>(
    [QueryNamesEnums.GET_PROJECT_SCORES_NEW, { projectId }],
    getProjectScoresNew.bind(this, projectId),
  );

  const hasYellowOrRedScore = useMemo(() => {
    return (
      [ScoreColorEnum.YELLOW, ScoreColorEnum.RED].includes(
        scoresQuery.data?.project_score?.color as ScoreColorEnum,
      ) ||
      scoresQuery.data?.scores?.some((score) =>
        [ScoreColorEnum.YELLOW, ScoreColorEnum.RED].includes(score.color as ScoreColorEnum),
      )
    );
  }, [scoresQuery.data]);

  const returnedRequest = useMemo(
    () =>
      drawRequestsQuery.data?.results?.find(
        (request) =>
          request?.is_resubmit &&
          isRequestDraft(request?.status) &&
          request?.waits_current_user_approval,
      ),
    [drawRequestsQuery.data],
  );

  const returnedRequestWarning = useMemo(() => {
    if (!returnedRequest) {
      return null;
    }

    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '56px', color: colors.status.orange.medium }}
        spacing={1}
      >
        <Typography variant="body2" sx={{ color: colors.status.orange.medium }}>
          This request was returned for editing by the lender and now is pending your response.
        </Typography>
        <StyledLink
          data-cy="returned_request_banner__link_to_edit_request"
          to={`/projects/${projectId}/requests/${returnedRequest?.id}/submission`}
        >
          Edit request
        </StyledLink>
      </Stack>
    );
  }, [projectId, returnedRequest?.id]);

  const wideChecklistButton = useMemo(() => {
    return !isAllowed(
      [
        PermissionNamesEnums.DRAWREQUESTS_CREATE,
        PermissionNamesEnums.DRAWREQUESTS_MANUAL_CREATE,
        PermissionNamesEnums.INSPECTIONS_EDIT,
        PermissionNamesEnums.CUSTOMER_SUCCESS_ACCESS,
      ],
      permissions,
    );
  }, [permissions]);

  return {
    teamRole,
    projectCommentsPreview: project.data?.comments_preview,
    returnedRequestWarning,
    openWidget,
    closeWidget,
    widgetsOpened,
    hasYellowOrRedScore,
    wideChecklistButton,
  };
};
