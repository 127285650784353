import completedAt from './completedAt';
import drawRequestNumber from './drawRequestNumber';
import externalId from './externalId';
import providerStatus from './providerStatus';
import inspectorAllowance from './inspectorAllowance';
import orderedAt from './orderedAt';
import providerCost from './providerCost';
import scheduledAt from './scheduledAt';
import actions from './actions';
import serviceProvider from './serviceProvider';
import serviceCost from './serviceCost';
import documentsAndComments from './documentsAndComments';
import serviceStatus from './serviceStatus';
import serviceType from './serviceType';
import transactionId from './transactionId';
import serviceNumber from './serviceNumber';
import loanNumber from './loanNumber';
import projectName from './projectName';
import projectStatus from './projectStatus';
import customer from './customer';

export const ProjectServicesColumnsConfig = [
  orderedAt,
  serviceType,
  serviceStatus,
  serviceNumber,
  serviceProvider,
  providerStatus,
  serviceCost,
  providerCost,
  scheduledAt,
  completedAt,
  externalId,
  transactionId,
  drawRequestNumber,
  inspectorAllowance,
  actions,
  documentsAndComments,
];

export const AllServicesColumnsConfig = [
  orderedAt,
  loanNumber,
  projectName,
  serviceType,
  serviceStatus,
  serviceNumber,
  serviceProvider,
  providerStatus,
  serviceCost,
  providerCost,
  scheduledAt,
  completedAt,
  externalId,
  transactionId,
  drawRequestNumber,
  projectStatus,
  inspectorAllowance,
  customer,
  actions,
  documentsAndComments,
];
