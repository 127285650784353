import { hasAllKeysInGraph, hasStatusInGraph, QueryGraphLike } from '@utils';
import { useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';

function useGraph(
  queryKey: unknown[],
  graphData?: { keys?: string[]; nested?: Record<string, string[]> },
) {
  const queryClient = useQueryClient();
  const graphQueryKey = queryClient.getGraphQueryKey(queryKey);
  const initialGraphState = queryClient.getQueryData<QueryGraphLike>(graphQueryKey);

  const { data } = useQuery<QueryGraphLike>(
    graphQueryKey,
    () => queryClient.getQueryData<QueryGraphLike>(graphQueryKey) ?? null,
    {
      enabled: !!initialGraphState,
    },
  );

  const isPostLoading = useMemo(() => {
    if (!data?.graph) return true;
    if (!hasAllKeysInGraph(data?.graph, graphData)) return true;
    const isFetching = hasStatusInGraph(data?.graph, graphData, ['fetching', 'requested']);
    //console.log('data ->', isFetching, data?.graph, graphData);
    return isFetching;
  }, [data?.graph, graphData]);

  const graphReady = useMemo(() => !!data?.readyToInit, [data?.readyToInit]);

  return { graphReady, isPostLoading };
}

export default useGraph;
