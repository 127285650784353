import { ICoordinator, EnumTypeForList } from '@interfaces';
import { useCoordinator } from '@hooks';
import { DropdownFieldModel } from '@models';

interface ControllerInterface {
  coordinatorField: DropdownFieldModel;
  list: EnumTypeForList[];
  onAssignCoordinator: () => Promise<void>;
  onUnassignCoordinator: () => Promise<void>;
  shouldUpdateProjectCoordinator: boolean;
  setShouldUpdateProjectCoordinator: (value: boolean) => void;
  isMutating: boolean;
  isChanged: boolean;
  isCoordinatorEditable: boolean;
  isCoordinatorsListLoading: boolean;
}

export const useAssignCoordinatorPopup = (
  initCoordinator: ICoordinator,
  projectId: string,
  requestId: string,
  onClose: () => void,
): ControllerInterface => {
  const {
    list,
    coordinatorField,
    onAssignCoordinator,
    onUnassignCoordinator,
    isCoordinatorMutating,
    isCoordinatorChanged,
    shouldUpdateProjectCoordinator,
    setShouldUpdateProjectCoordinator,
    isCoordinatorEditable,
    isCoordinatorsListLoading,
  } = useCoordinator({ projectId, requestId, initCoordinator, onClose });

  return {
    coordinatorField,
    list,
    onAssignCoordinator,
    onUnassignCoordinator,
    shouldUpdateProjectCoordinator,
    setShouldUpdateProjectCoordinator,
    isMutating: isCoordinatorMutating,
    isChanged: isCoordinatorChanged,
    isCoordinatorEditable,
    isCoordinatorsListLoading,
  };
};
