import { CellRenderWithStaticError, MilestoneListColumnTypeV2 } from './common';
import { isAllowed } from '@utils';
import { PermissionNamesEnums } from '@interfaces';
import React from 'react';
import { GridEditInputCell } from '@mui/x-data-grid';

const nameV2: MilestoneListColumnTypeV2 = {
  field: 'name',
  headerName: 'Line Item',
  description: 'Line Item',
  headerClassName: 'text',
  headerAlign: 'left',
  align: 'left',
  cellClassName: 'text',
  flex: 1,
  resizable: false,
  editable: true,
  sortable: true,
  filterable: true,
  groupable: false,
  aggregable: true,
  hideable: false,
  minWidth: 200,
  renderCell: (params) => (
    <CellRenderWithStaticError
      params={params}
      errorText="Please, fill the name or delete the row."
    />
  ),
  renderEditCell: (props) => <GridEditInputCell {...props} inputProps={{ maxLength: 256 }} />,
  editableByMilestone: (row) => !row.project_milestone && row.localNew,
  editableByPermissions: (permissions) =>
    isAllowed(PermissionNamesEnums.DRAWREQUESTS_LINE_ITEM_CREATE, permissions),
};

export default nameV2;
