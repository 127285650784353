import React, { FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { LoadingSkeleton, StyledBox, TablePaginationNew } from '@components';
import { DocumentArray } from '@interfaces';
import { PhotoCardWithPopper } from '@components';
import { IMilestonePhotosItem, useMilestonePhotosItem } from './controller';

const MilestonePhotosItem: FC<{
  item: IMilestonePhotosItem;
  index: number;
  handlePhotoClick: (id: string, photos: DocumentArray) => void;
  projectId: string;
  drawRequestId: string;
  isProjectTab: boolean;
}> = ({ item, index, handlePhotoClick, projectId, drawRequestId, isProjectTab }) => {
  const { photos, isLoading, tablePagination, onPageChange } = useMilestonePhotosItem({
    projectId,
    drawRequestId,
    item,
    isProjectTab,
  });

  return (
    <StyledBox sx={{ display: 'flex', flexDirection: 'column' }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography
          variant="body2SemiBold"
          sx={{ pb: 2 }}
        >{`${item.name} (${item.photos_count})`}</Typography>
        {item.photos_count > 7 && (
          <Stack direction="row" spacing={1} alignItems="center">
            <TablePaginationNew
              page={tablePagination.page}
              rowsPerPage={tablePagination.rowsPerPage}
              rowsPerPageOptions={tablePagination.rowsPerPageOptions}
              itemsCount={item.photos_count}
              onPageChange={onPageChange}
              onRowsPerPageChange={tablePagination.onRowsPerPageChange}
              source={`photos_tab__list_${index}`}
              showPerPageSelect={false}
            />
          </Stack>
        )}
      </Stack>
      <Stack direction="row" flexWrap="wrap">
        {isLoading ? (
          <LoadingSkeleton />
        ) : (
          photos?.map((photo, idx) => (
            <Box
              sx={{ minWidth: '107px', mr: 1, mt: 1 }}
              key={idx}
              onClick={() => handlePhotoClick(photo?.id, photos)}
            >
              <PhotoCardWithPopper
                photo={photo}
                size="small"
                dataTestName="photos_tab__card__image__button"
              />
            </Box>
          ))
        )}
      </Stack>
    </StyledBox>
  );
};

export default MilestonePhotosItem;
